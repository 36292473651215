import {
	API_FETCH_CAMERAS_REORDER_ROUTE,
	API_FETCH_CREATE_GALLERIE_ROUTE,
	API_FETCH_UPDATE_IMAGE_GALLERIE_ORDER_ROUTE,
	API_FETCH_UPDATE_IMAGE_GALLERIE_ROUTE,
	API_FETCH_UPDATE_VIDEO_GALLERIE_ORDER_ROUTE,
	API_FETCH_UPDATE_VIDEO_GALLERIE_ROUTE
} from "../../utils/consts";
import { setError, setLoading, setMessage } from "../reducers/rootReducer";
import { GALLERIES, GALLERY, GALLERY_UPDATE } from "../types";
import { getCameras } from "./cameraAction";

export const allGalleries = (data) => {
	return {
		type: GALLERIES,
		payload: { data }
	};
};

export const patchGallery = (data) => {
	return {
		type: GALLERY_UPDATE,
		payload: { data }
	};
};
export const OneGalery = (data) => {
	return {
		type: GALLERY,
		payload: { data }
	};
};
export const GetGalleryAction = (id) => async (dispatch) => {
	dispatch(setLoading({ camera: "gallery", type: true }));
	try {
		const options = {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: `Bearer ${sessionStorage.getItem("token")}`
			}
			//credentials: 'include',
		};
		const response = await fetch(
			process.env.REACT_APP_BACKEND_URL +
				API_FETCH_CREATE_GALLERIE_ROUTE +
				"/" +
				id,
			options
		);
		//response.data
		const data = await response.json();

		if (data.type == "success") {
			dispatch(OneGalery(data.data));
			dispatch(setLoading({ camera: "gallery", type: false }));
		}
	} catch (error) {
		console.log(error);
		dispatch(setLoading({ camera: "gallery", type: false }));
	}
};
export const UpdateGallery = (item, id) => async (dispatch) => {
	dispatch(setLoading({ camera: "gallery", type: false }));
	try {
		const options = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: `Bearer ${sessionStorage.getItem("token")}`
			},
			body: JSON.stringify(item)
			//credentials: 'include',
		};
		const response = await fetch(
			process.env.REACT_APP_BACKEND_URL +
				API_FETCH_CREATE_GALLERIE_ROUTE +
				"/" +
				id,
			options
		);
		//response.data
		const data = await response.json();

		if (data.type == "success") {
			dispatch(OneGalery(data.data));
			dispatch(setMessage(data.message, data.type));
			dispatch(setLoading({ camera: "gallery", type: false }));
		}
	} catch (error) {
		console.log(error);
		dispatch(setLoading({ camera: "gallery", type: false }));
	}
};
export const DeleteGallery = (id) => async (dispatch) => {
	dispatch(setLoading({ camera: "gallery", type: false }));
	try {
		const options = {
			method: "DELETE",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: `Bearer ${sessionStorage.getItem("token")}`
			}
			//credentials: 'include',
		};
		const response = await fetch(
			process.env.REACT_APP_BACKEND_URL +
				API_FETCH_CREATE_GALLERIE_ROUTE +
				"/" +
				id,
			options
		);
		//response.data
		const data = await response.json();

		if (data.type == "success") {
			dispatch(setMessage(data.message, data.type));
			dispatch(setLoading({ camera: "gallery", type: false }));
		}
	} catch (error) {
		console.log(error);
		dispatch(setLoading({ camera: "gallery", type: false }));
	}
};

export const UpdateImage =
	(id, item, isModel = false) =>
	async (dispatch) => {
		try {
			const options = {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
					Authorization: `Bearer ${sessionStorage.getItem("token")}`
				},
				body: JSON.stringify(item)
				//credentials: 'include',
			};
			const response = await fetch(
				process.env.REACT_APP_BACKEND_URL +
					API_FETCH_UPDATE_IMAGE_GALLERIE_ROUTE +
					"/" +
					id,
				options
			);
			//response.data
			const data = await response.json();

			if (data.type == "success") {
				if (isModel === false) {
					dispatch(patchGallery(data.data));
				}
				dispatch(setMessage(data.message, data.type));
			}
		} catch (error) {
			console.log(error);
		}
	};
export const UpdateVideo =
	(id, item, posterImage = false, isModel = false) =>
	async (dispatch) => {
		try {
			const options = {
				method: "POST",
				headers: posterImage
					? {
							Authorization: `Bearer ${sessionStorage.getItem("token")}`
						}
					: {
							Authorization: `Bearer ${sessionStorage.getItem("token")}`,
							"Content-Type": "application/json",
							Accept: "application/json"
						},
				body: posterImage ? item : JSON.stringify(item)
				//credentials: 'include',
			};
			const response = await fetch(
				process.env.REACT_APP_BACKEND_URL +
					API_FETCH_UPDATE_VIDEO_GALLERIE_ROUTE +
					"/" +
					id,
				options
			);
			//response.data
			const data = await response.json();

			if (data.type == "success") {
				if (isModel === false) {
					dispatch(patchGallery(data.data));
				}
				dispatch(setMessage(data.message, data.type));
			}
		} catch (error) {
			console.log(error);
		}
	};

export const changeGalleryItemsOrder =
	(items, image = false, id) =>
	async (dispatch) => {
		try {
			const options = {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
					Authorization: `Bearer ${sessionStorage.getItem("token")}`
				},
				body: JSON.stringify(
					image
						? {
								images: items
							}
						: {
								videos: items
							}
				)
				//credentials: 'include',
			};
			const response = await fetch(
				process.env.REACT_APP_BACKEND_URL +
					(image
						? API_FETCH_UPDATE_IMAGE_GALLERIE_ORDER_ROUTE
						: API_FETCH_UPDATE_VIDEO_GALLERIE_ORDER_ROUTE) +
					id,
				options
			);
			const data = await response.json();
			if (response.status >= 200 && response.status <= 299) {
				dispatch(GetGalleryAction(id));
				dispatch(setMessage(data.message));
			} else {
				dispatch(setMessage(data.message, "error"));
			}
		} catch (error) {
			dispatch(setError(error));
		}
	};
