import * as React from "react";
import { useEffect } from "react";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import {
	Box,
	MenuItem,
	Select,
	Stack,
	SvgIcon,
	Table,
	TableBody,
	TableContainer,
	TableHead,
	TableRow,
	TableSortLabel,
	TextField,
	Typography
} from "@mui/material";
import SearchBar from "material-ui-search-bar";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import Autocomplete from "@mui/material/Autocomplete";
import { styled } from "@mui/material/styles";
import GirlIcon from "@material-ui/icons/Face";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import { Link, useNavigate } from "react-router-dom";
import { getUsers } from "../../redux/actions/userAction";
import { connect, useDispatch, useSelector } from "react-redux";
import TablePagination from "@mui/material/TablePagination";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";

const mapStateToProps = (state) => {
	return {
		users: state.users,
		time_picker: state.time_picker
	};
};

function Users({ users, time_picker }) {
	const dispatch = useDispatch();
	React.useEffect(() => {
		dispatch(getUsers());
	}, []);

	let navigate = useNavigate();
	const [open, setOpen] = React.useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	const roles = [
		{ id: 1, title: "member" },
		{ id: 2, title: "admin" }
	];

	const StyledTableRow = styled(TableRow)(({ theme }) => ({
		"&:nth-of-type(odd)": {
			backgroundColor: "rgb(0 0 0)"
		},
		"&:nth-of-type(even)": {
			backgroundColor: "#ffffff14"
		},
		// hide last border
		"&:last-child td, &:last-child th": {
			border: 0
		},
		"&.MuiTableRow-root:hover": {
			backgroundColor: "#555555"
		}
	}));

	const StyledTableCell = styled(TableCell)(({ theme }) => ({
		[`&.${tableCellClasses.head}`]: {
			backgroundColor: "#292929",
			color: theme.palette.common.white
		},
		[`&.${tableCellClasses.body}`]: {
			fontSize: 14
		}
	}));
	const [rows, setRows] = React.useState(users && users?.users);

	const [orderDirection, setOrderDirection] = React.useState("desc");
	const [valueToOrderBy, setvalueToOrderBy] = React.useState("createdAt");
	const [rowData, setRowData] = React.useState(rows && rows);

	const sorting = (item) => {
		const isAscending = valueToOrderBy === item && orderDirection === "asc";
		let sorted;
		// if (isAscending) {
		//     if (item == "credits") {
		//         sorted = [...rows].sort((a, b) => a[item] > b[item] ? 1 : -1)
		//     }
		//     if (item == 'lastIP') {
		//         sorted = [...rows].sort((a, b) =>
		//             (a[item] ? Number(a[item].split(".").map((num) => (`000${num}`).slice(-3)).join("")) : 0) >
		//             (b[item] ? Number(b[item].split(".").map((num) => (`000${num}`).slice(-3)).join("")) : 0) ? 1 : -1
		//         );
		//     }
		//     if (item != 'credits' && item != 'lastIP') {
		//         sorted = [...rows].sort((a, b) => a[item].toLowerCase() > b[item].toLowerCase() ? 1 : -1)
		//     }
		// }
		// if (!isAscending) {
		//     if (item == "credits") {
		//         sorted = [...rows].sort((a, b) => a[item] < b[item] ? 1 : -1)
		//     }
		//     if (item == 'lastIP') {
		//         sorted = [...rows].sort((a, b) =>
		//             (a[item] ? Number(a[item].split(".").map((num) => (`000${num}`).slice(-3)).join("")) : 0) <
		//             (b[item] ? Number(b[item].split(".").map((num) => (`000${num}`).slice(-3)).join("")) : 0) ? 1 : -1
		//         );
		//     }
		//     if (item != 'credits' && item != 'lastIP') {
		//         sorted = [...rows].sort((a, b) => a[item].toLowerCase() < b[item].toLowerCase() ? 1 : -1)
		//     }
		// }
		setPage(0);
		dispatch(
			getUsers(
				1,
				rowsPerPage,
				selected?.name,
				searched,
				item,
				isAscending ? "desc" : "asc",
				role
			)
		);
		setvalueToOrderBy(item);
		// setRows(sorted)
		setOrderDirection(isAscending ? "desc" : "asc");
	};

	React.useEffect(() => {
		setRows(users?.users);
	}, [users]);

	const [searched, setSearched] = React.useState("");
	const [searched2, setSearched2] = React.useState(null);

	useEffect(() => {
		const savedSearched = sessionStorage.getItem("searched");
		if (savedSearched) {
			setSearched(savedSearched);
		}
	}, []);
	const searchParam = React.useState([
		{ id: 1, name: "username" },
		{ id: 2, name: "email" },
		{ id: 3, name: "credits" },
		{ id: 4, name: "createdAt" },
		{ id: 5, name: "lastIP" }
	]);

	const [selected, setSelected] = React.useState(searchParam[0][0]);
	const handleChangeCampaign = (event) => {
		setSelected(event.target.value);
	};

	const requestSearch = (searchVal) => {
		// const filtereredRows = users?.users.filter((row) => {
		//     const check = selected.name;
		//     if (!row[check]) {
		//         return false
		//     }
		//     return row[check].toString().toLowerCase().includes(searchVal.toLowerCase());
		//
		// });
		// setPage(0);
		// setRows(filtereredRows);
		setPage(0);
		setSearched(searchVal);
	};
	const [role, setRole] = React.useState(null);
	const cancelSearch = () => {
		setSearched("");
		sessionStorage.removeItem("searched");
		if (page == 0) {
			dispatch(
				getUsers(
					page + 1,
					rowsPerPage,
					selected?.name,
					null,
					valueToOrderBy,
					orderDirection,
					role
				)
			);
		}

		setPage(0);

		// requestSearch(searched);
	};

	const requestSearchByRole = (val) => {
		const filteredRows = users?.users.filter((row) => {
			if (val !== null) {
				return row.role.toLowerCase().includes(val.title.toLowerCase());
			} else {
				return row;
			}
		});
		setRole(val?.title);
		dispatch(
			getUsers(
				page + 1,
				rowsPerPage,
				selected?.name,
				searched,
				valueToOrderBy,
				orderDirection,
				val?.title
			)
		);
	};

	const cancelSearchRole = () => {
		setRole(null);
		dispatch(
			getUsers(
				page + 1,
				rowsPerPage,
				selected?.name,
				searched,
				valueToOrderBy,
				orderDirection
			)
		);
	};

	const [value, setValue] = React.useState([null, null]);

	// const requestSearchByTime = (time_picker) => {
	//     const filteredRows = users?.users?.filter((row) => {
	//         if (time_picker[0].startDate != null && time_picker[0].endDate != null) {
	//             const dateStr = row.createdAt.slice(0, -6);
	//             const [day1, month1, year1] = dateStr.split(".");
	//             const date = new Date(+year1, month1 - 1, +day1);
	//             const startStr = format(time_picker[0].startDate, "dd.MM.yyy");
	//             const [day2, month2, year2] = startStr.split(".");
	//             const startDate = new Date(+year2, month2 - 1, +day2);
	//             const endStr = format(time_picker[0].endDate, "dd.MM.yyy");
	//             const [day3, month3, year3] = endStr.split(".");
	//             const endDate = new Date(+year3, month3 - 1, +day3);
	//             if (date >= startDate && date <= endDate) {
	//                 return row;
	//             }
	//         } else {
	//             return row;
	//         }
	//     });
	//     setRows(filteredRows);
	// };

	// if (time_picker != undefined) {
	//     React.useEffect(() => {
	//         setTimeout(() => {
	//             requestSearchByTime(time_picker && time_picker);
	//         });
	//     }, [time_picker && time_picker]);
	// }

	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	};

	React.useEffect(() => {
		dispatch(
			getUsers(
				page + 1,
				rowsPerPage,
				selected?.name,
				searched,
				valueToOrderBy,
				orderDirection,
				role
			)
		);
	}, [page, rowsPerPage]);

	React.useEffect(() => {
		const timeoutID = setTimeout(() => {
			if (searched.length != 0) {
				dispatch(
					getUsers(
						1,
						rowsPerPage,
						selected?.name,
						searched,
						valueToOrderBy,
						orderDirection,
						role
					)
				);
				sessionStorage.setItem("searched", searched);
			}
		}, 500);
		return () => clearTimeout(timeoutID);
	}, [searched, selected]);

	const loading = useSelector((state) => state.loading);
	return (
		<>
			<Stack
				className="page-container"
				spacing={3}
				sx={{ width: 900, marginLeft: "290px" }}
			>
				{loading && loading.isLoading == true ? (
					<div className="loader-container">
						<div className="spinner"></div>
					</div>
				) : (
					""
				)}
				<Box
					style={{ marginTop: loading && loading.isLoading == true && "0px" }}
				>
					<Grid container spacing={0}>
						<Grid item xs={4} md={2} spacing={0} className="LiveShow">
							<Typography
								variant="h4"
								gutterBottom
								component="div"
								style={{ color: "white" }}
							>
								Users
							</Typography>
						</Grid>
					</Grid>
				</Box>
				{/*<Box className="Search_box">*/}
				{/*    <Typography*/}
				{/*        variant="h6"*/}
				{/*        gutterBottom*/}
				{/*        component="div"*/}
				{/*        style={{color: "white", minWidth: "150px"}}*/}
				{/*    >*/}
				{/*        Select period*/}
				{/*    </Typography>*/}
				{/*    <DateRangePickerComp closeOnSelect={true}/>*/}
				{/*</Box>*/}
				<Grid className="stats_type" item>
					<Typography className="Support_selector">Filter type</Typography>
					<Select
						className="LandingSelect"
						value={selected && selected}
						onChange={handleChangeCampaign}
						displayEmpty
						renderValue={(value) => {
							return (
								<Box sx={{ display: "flex" }}>
									<SvgIcon color="black">
										<FilterListOutlinedIcon />
									</SvgIcon>
									<div
										style={{
											display: "flex",
											width: "-webkit-fill-available",
											justifyContent: "center"
										}}
									>
										{value && value.name}
									</div>
								</Box>
							);
						}}
					>
						{searchParam &&
							searchParam[0].map((option) => (
								<MenuItem
									style={{ textAlign: "center" }}
									key={option.id}
									value={option}
								>
									{option.name}
								</MenuItem>
							))}
					</Select>
				</Grid>
				<Box className="Search_box" style={{ width: "1000px" }}>
					<Typography
						variant="h6"
						gutterBottom
						component="div"
						style={{ color: "white", marginRight: "50px" }}
					>
						Term
					</Typography>
					<SearchBar
						style={{ height: "35px" }}
						className="SearchBar"
						placeholder=""
						value={searched}
						onChange={(searchVal) => requestSearch(searchVal)}
						onCancelSearch={() => cancelSearch()}
					/>

					<Typography
						variant="h6"
						gutterBottom
						component="div"
						style={{ color: "white", marginLeft: "50px" }}
					>
						Role
					</Typography>

					<GirlIcon
						style={{
							color: "white",
							position: "relative",
							marginLeft: "10px"
						}}
					/>
					<Autocomplete
						className="RoleInput"
						disablePortal
						id="combo-box-demo"
						options={roles}
						style={{ alignItems: "left" }}
						getOptionLabel={(option) => option.title}
						onChange={(event, value) => requestSearchByRole(value)}
						onAbort={() => cancelSearchRole()}
						renderInput={(params) => (
							<TextField {...params} disabled variant="standard" />
						)}
					/>
				</Box>
				<Box
					style={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "flex-end",
						width: "1100px"
					}}
				>
					<Link to={"/addUserProfile"}>
						<Button
							size="small"
							variant="contained"
							style={{
								color: "black",
								backgroundColor: "#78F73B",
								maxWidth: "160px",
								maxHeight: "33px",
								minWidth: "160px",
								minHeight: "33px",
								borderRadius: "4px",
								textTransform: "unset"
							}}
							className="create"
						>
							<PersonAddAltIcon style={{ marginRight: "10px" }} />
							Create New User
						</Button>
					</Link>
				</Box>
				<TableContainer
					className="SchrollBar"
					component={Paper}
					style={{ overflow: "auto", marginRight: "40px", width: "1100px" }}
					sx={{ maxHeight: "700px" }}
				>
					<Table aria-label="simple table" stickyHeader>
						<TableHead>
							<StyledTableRow>
								<StyledTableCell style={{ borderBottom: "none" }} align="left">
									<TableSortLabel
										active={valueToOrderBy === "username"}
										direction={
											valueToOrderBy === "username" ? orderDirection : "asc"
										}
										onClick={() => {
											sorting("username");
										}}
									>
										User
									</TableSortLabel>
								</StyledTableCell>
								<StyledTableCell style={{ borderBottom: "none" }} align="left">
									<TableSortLabel
										active={valueToOrderBy === "email"}
										direction={
											valueToOrderBy === "email" ? orderDirection : "asc"
										}
										onClick={() => {
											sorting("email");
										}}
									>
										Email
									</TableSortLabel>
								</StyledTableCell>
								<StyledTableCell style={{ borderBottom: "none" }} align="left">
									<TableSortLabel
										active={valueToOrderBy === "credits"}
										direction={
											valueToOrderBy === "credits" ? orderDirection : "asc"
										}
										onClick={() => {
											sorting("credits");
										}}
									>
										Credits
									</TableSortLabel>
								</StyledTableCell>

								<StyledTableCell style={{ borderBottom: "none" }} align="right">
									<TableSortLabel
										active={valueToOrderBy === "createdAt"}
										direction={
											valueToOrderBy === "createdAt" ? orderDirection : "asc"
										}
										onClick={() => {
											sorting("createdAt");
										}}
									>
										Since
									</TableSortLabel>
								</StyledTableCell>
								<StyledTableCell style={{ borderBottom: "none" }} align="right">
									<TableSortLabel
										active={valueToOrderBy === "lastIP"}
										direction={
											valueToOrderBy === "lastIP" ? orderDirection : "asc"
										}
										onClick={() => {
											sorting("lastIP");
										}}
									>
										IP Address
									</TableSortLabel>
								</StyledTableCell>
								<StyledTableCell
									style={{ borderBottom: "none" /* display: "none" */ }}
									align="right"
								>
									Banned
								</StyledTableCell>
							</StyledTableRow>
						</TableHead>
						<TableBody>
							{rows?.map((row) => (
								<StyledTableRow
									hover={true}
									onClick={() => {
										navigate("/userProfile/" + row.id);
									}}
									key={row.id}
									tabIndex={-1}
									sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
								>
									<TableCell
										align="left"
										style={{
											borderBottom: "none",
											color: "#78F73B",
											display: "flex",
											alignItems: "center"
										}}
									>
										<GirlIcon style={{ color: "white", marginRight: "10px" }} />
										{row.username}
									</TableCell>

									<TableCell
										align="left"
										style={{ borderBottom: "none", color: "white" }}
									>
										{row.email}
									</TableCell>
									<TableCell
										align="left"
										style={{ borderBottom: "none", color: "#FFD800" }}
									>
										{row.credits}
									</TableCell>
									<TableCell
										align="right"
										style={{ borderBottom: "none", color: "#FFD800" }}
									>
										{row.createdAt}
									</TableCell>
									<TableCell
										align="right"
										style={{ borderBottom: "none", color: "#FFD800" }}
									>
										{row.lastIP}
									</TableCell>
									<TableCell
										align="right"
										style={{
											borderBottom: "none",
											color: `${row.is_banned == 0 ? "green" : "red"}`
											/* display: "none", */
										}}
									>
										{row.is_banned == 0 ? "not banned" : "banned"}
									</TableCell>
								</StyledTableRow>
							))}
						</TableBody>
					</Table>
				</TableContainer>
				<TablePagination
					style={{ background: "#292929", color: "white" }}
					rowsPerPageOptions={[10, 25, 50]}
					component="div"
					count={users?.pagination?.total_count}
					rowsPerPage={rowsPerPage}
					page={page}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>
			</Stack>
		</>
	);
}

export default connect(mapStateToProps)(Users);
