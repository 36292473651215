import { useDispatch } from "react-redux";
import { setPrivateChats } from "../actions/chatActions";
import {
	FETCH_CHATS,
	ADD_MESSAGES_TO_CHAT,
	ADD_MESSAGE_TO_CHAT,
	REMOVE_CHATS,
	REMOVE_CHAT,
	ADD_MORE_MESSAGES_TO_CHAT,
	ADD_MESSAGE_TO_CHAT_OFFSET
} from "../types";

export const chatsReducer = (state = [], action) => {
	const { type, payload, chat } = action;
	switch (type) {
		case FETCH_CHATS: {
			const { data } = payload;
			/* state = []; */

			return data;
		}
		case ADD_MESSAGES_TO_CHAT: {
			const { data } = payload;
			const newState = Object.assign([], state);
			let obj = newState.find((item) => item.chat.id === data.chat.id);
			const idx = newState.indexOf(obj);
			newState[idx] = data;
			return newState;
		}
		case ADD_MORE_MESSAGES_TO_CHAT: {
			const { data } = payload;

			const newState = Object.assign([], state);
			let obj = newState.find((item) => item.chat.id === data.chatId);

			const idx = newState.indexOf(obj);
			var newArray = data.messages.concat(newState[idx].messages);
			newState[idx].messages = newArray;
			return newState;
		}
		case ADD_MESSAGE_TO_CHAT: {
			const { data } = payload;
			const newState = Object.assign([], state);

			const obj = newState.find(
				(chat_main) => chat_main.chat.id == chat.chat.chat.id
			);

			if (obj.messages.some((item) => item.id === data.id)) return newState;
			obj && obj.messages.push(data);

			const idx = newState.indexOf(obj);
			newState[idx] = obj;

			/* if(state.some(item => data.id === item.id))
        return state */
			return newState;
		}
		case ADD_MESSAGE_TO_CHAT_OFFSET: {
			const { data } = payload;
			const newState = Object.assign([], state);

			const obj = newState.find(
				(chat_main) => chat_main.chat.id == chat.chat.chat.id
			);

			if (obj.messages.some((item) => item.id === data.id)) return newState;
			obj && obj.messages.unshift(data);

			const idx = newState.indexOf(obj);
			newState[idx] = obj;
			return newState;
		}
		case REMOVE_CHATS: {
			return [];
		}
		case REMOVE_CHAT: {
			const { data } = payload;
			return state.filter((chat) => chat.chat.id !== data);
		}
		default: {
			return state;
		}
	}
};
