import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import "../../App";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useNavigate, useParams } from "react-router-dom";
import { Container } from "@mui/material";
import { connect, useSelector } from "react-redux";
import {
	delete_transaction,
	getTransaction
} from "../../redux/actions/userAction";
import Skeleton from "@mui/material/Skeleton";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 400,
	bgcolor: "background.paper",
	border: "2px solid #000",
	textAlign: "center",
	margin: "0 auto",
	p: 4
};

const roles = [
	{ id: 1, title: "admin" },
	{ id: 2, title: "member" }
];

const mapStateToProps = (state) => {
	return {
		transaction: state.transaction
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		getTransaction: (id) => dispatch(getTransaction(id)),
		deleteTransaction: (id) => dispatch(delete_transaction(id))
	};
};

function TransactionDetails({
	transaction,
	getTransaction,
	deleteTransaction
}) {
	const param = useParams();
	const navigate = useNavigate();

	React.useEffect(() => {
		deleteTransaction(param.id);
		getTransaction(param.id);
	}, []);

	React.useEffect(() => {
		setImageUrl(transaction && transaction.smallAvatar);
	}, [transaction]);

	const [selectedImage, setSelectedImage] = React.useState(null);
	const [imageUrl, setImageUrl] = React.useState(null);

	React.useEffect(() => {
		if (selectedImage) {
			setImageUrl(URL.createObjectURL(selectedImage));
			const newFormData = { ...addFormData };
			newFormData["myFiles"] = selectedImage;
			setAddFormData(newFormData);
		}
	}, [selectedImage]);

	function returnToTransactions(event) {
		event.preventDefault();
		navigate("/transactions");
	}

	let roundOff = (num, places) => {
		const x = Math.pow(10, places);
		return Math.round(num * x) / x;
	};
	const loading = useSelector((state) => state.loading);
	return (
		<>
			<Box
				component="div"
				sx={{
					flexGrow: 1,
					maxWidth: "900px",
					margin: "0",
					height: "100%",
					minHeight: "1000px",
					marginLeft: "290px"
				}}
			>
				<Grid container spacing={0}>
					<Grid item xs={4} md={2} spacing={0} className="LiveShow">
						{!transaction.username ? (
							<Skeleton width={330} height={55} />
						) : (
							<Typography
								variant="h4"
								gutterBottom
								component="div"
								style={{ color: "white" }}
							>
								{transaction && transaction.username}'s Transaction
							</Typography>
						)}
					</Grid>
				</Grid>
				<Button
					startIcon={<ArrowBackIosIcon />}
					className="ModelProfilePage_arrow"
					variant="outlined"
					onClick={returnToTransactions}
				>
					All transactions
				</Button>
				<Container
					maxWidth="xl"
					style={{
						color: "#ffffff",
						width: "100%",
						height: "100%",
						backgroundColor: "#000000",
						position: "relative"
					}}
					sx={{ my: 3 }}
					className="ProvileContainer"
				>
					{loading && loading.isLoading == true ? (
						<div className="loader-container">
							<div className="spinner"></div>
						</div>
					) : (
						""
					)}
					<Grid spacing={1} className="ModelProfilePage_wrap">
						<Grid item xs={6} md={4}>
							<Box component="div" className="ModelProfilePage_main">
								<Stack
									direction="row"
									className="ModelProfilePage_row"
									spacing={1}
									style={{ flexGrow: "0.6", paddingBottom: "30px" }}
								>
									<Typography
										gutterBottom
										component="div"
										className="ModelProfilePage_elements"
									>
										Profile image
									</Typography>
									<Box
										style={{
											display: "flex",
											flexDirection: "column",
											alignItems: "center",
											width: "100%"
										}}
									>
										<Box
											mt={2}
											textAlign="center"
											style={{
												width: "100px",
												height: "100px",
												backgroundColor: "rgb(41, 41, 41)",
												borderRadius: "50%"
											}}
										>
											{imageUrl && (
												<img
													src={imageUrl}
													height="100px"
													width="100px"
													accept="image/x-png,image/gif,image/jpeg"
													style={{ borderRadius: "50%" }}
												/>
											)}
										</Box>

										<input
											accept="image/*"
											style={{ display: "none" }}
											id="select-image"
											multiple
											type="file"
											name="myFiles"
											onChange={(e) => {
												setSelectedImage(e.target.files[0]);
											}}
										/>
									</Box>
								</Stack>
								<Stack
									direction="row"
									className="ModelProfilePage_row"
									spacing={1}
								>
									<Typography
										gutterBottom
										component="div"
										className="ModelProfilePage_elements"
									>
										Name:
									</Typography>
									<Typography
										gutterBottom
										defaultValue={transaction && transaction.username}
										key={transaction && transaction.username}
										component="div"
										className="UserProfilePage_elements"
										style={{
											color: "yellow",
											display: "flex",
											justifyContent: "start",
											fontSize: "16px"
										}}
									>
										{transaction && transaction.username}
									</Typography>
								</Stack>
								<Stack
									direction="row"
									className="ModelProfilePage_row"
									spacing={1}
								>
									<Typography
										gutterBottom
										component="div"
										className="ModelProfilePage_elements"
									>
										Time:
									</Typography>
									<Typography
										gutterBottom
										defaultValue={transaction && transaction.createdAt}
										key={transaction && transaction.createdAt}
										component="div"
										className="UserProfilePage_elements"
										style={{
											color: "yellow",
											display: "flex",
											justifyContent: "start",
											fontSize: "16px"
										}}
									>
										{transaction && transaction.createdAt}
									</Typography>
								</Stack>
								<Stack
									direction="row"
									className="ModelProfilePage_row"
									spacing={1}
								>
									<Typography
										gutterBottom
										component="div"
										className="ModelProfilePage_elements"
									>
										Currency:
									</Typography>
									<Typography
										gutterBottom
										defaultValue={transaction && transaction.currency}
										key={transaction && transaction.currency}
										component="div"
										className="UserProfilePage_elements"
										style={{
											color: "yellow",
											display: "flex",
											justifyContent: "start",
											fontSize: "16px"
										}}
									>
										{transaction && roundOff(transaction.price, 2)}{" "}
										{transaction && transaction.currency}
									</Typography>
								</Stack>
								<Stack
									direction="row"
									className="ModelProfilePage_row"
									spacing={1}
								>
									<Typography
										gutterBottom
										component="div"
										className="ModelProfilePage_elements"
									>
										Description:
									</Typography>
									<Typography
										gutterBottom
										defaultValue={transaction && transaction.description}
										key={transaction && transaction.description}
										component="div"
										className="UserProfilePage_elements"
										style={{
											color: "yellow",
											display: "flex",
											justifyContent: "start",
											fontSize: "16px"
										}}
									>
										{transaction && transaction.description}
									</Typography>
								</Stack>
							</Box>
						</Grid>
						<Grid
							item
							xs={6}
							md={8}
							style={{
								margin: "0 auto",
								textAlign: "center",
								display: "flex",
								flexDirection: "row",
								alignItems: "center"
							}}
						>
							<Box component="div" className="ModelProfilePage_main">
								<Stack
									direction="row"
									className="ModelProfilePage_row"
									spacing={1}
								>
									<Typography
										gutterBottom
										component="div"
										className="ModelProfilePage_elements"
									>
										referenceID:
									</Typography>
									<Typography
										gutterBottom
										defaultValue={transaction && transaction.referenceID}
										key={transaction && transaction.referenceID}
										component="div"
										className="UserProfilePage_elements"
										style={{
											color: "yellow",
											display: "flex",
											justifyContent: "start",
											fontSize: "16px"
										}}
									>
										{transaction && transaction.referenceID}
									</Typography>
								</Stack>
								<Stack
									direction="row"
									className="ModelProfilePage_row"
									spacing={1}
								>
									<Typography
										gutterBottom
										component="div"
										className="ModelProfilePage_elements"
									>
										transactionID:
									</Typography>
									<Typography
										gutterBottom
										defaultValue={transaction && transaction.transactionID}
										key={transaction && transaction.transactionID}
										component="div"
										className="UserProfilePage_elements"
										style={{
											color: "yellow",
											display: "flex",
											justifyContent: "start",
											fontSize: "16px"
										}}
									>
										{transaction && transaction.transactionID}
									</Typography>
								</Stack>
								<Stack
									direction="row"
									className="ModelProfilePage_row"
									spacing={1}
								>
									<Typography
										gutterBottom
										component="div"
										className="ModelProfilePage_elements"
									>
										saleID:
									</Typography>
									<Typography
										gutterBottom
										defaultValue={transaction && transaction.saleID}
										key={transaction && transaction.saleID}
										component="div"
										className="UserProfilePage_elements"
										style={{
											color: "yellow",
											display: "flex",
											justifyContent: "start",
											fontSize: "16px"
										}}
									>
										{transaction && transaction.saleID}
									</Typography>
								</Stack>
								<Stack
									direction="row"
									className="ModelProfilePage_row"
									spacing={1}
								>
									<Typography
										gutterBottom
										component="div"
										className="ModelProfilePage_elements"
									>
										Status:
									</Typography>
									<Typography
										gutterBottom
										defaultValue={transaction && transaction.status}
										key={transaction && transaction.status}
										component="div"
										className="UserProfilePage_elements"
										style={{
											color: "yellow",
											display: "flex",
											justifyContent: "start",
											fontSize: "16px"
										}}
									>
										{transaction && transaction.status}
									</Typography>
								</Stack>
								<Stack
									direction="row"
									className="ModelProfilePage_row"
									spacing={1}
								>
									<Typography
										gutterBottom
										component="div"
										className="ModelProfilePage_elements"
									>
										Vendor:
									</Typography>
									<Typography
										gutterBottom
										defaultValue={transaction && transaction.vendor}
										key={transaction && transaction.vendor}
										component="div"
										className="UserProfilePage_elements"
										style={{
											color: "yellow",
											display: "flex",
											justifyContent: "start",
											fontSize: "16px"
										}}
									>
										{transaction && transaction.vendor}
									</Typography>
								</Stack>
							</Box>
						</Grid>
					</Grid>
				</Container>
			</Box>
		</>
	);
}

export default connect(mapStateToProps, mapDispatchToProps)(TransactionDetails);
