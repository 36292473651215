import { combineReducers } from "redux";
import { modelReducer } from "./modelReducer";
import { modelsReducer } from "./modelReducers";
import { userReducer } from "../admin_reducers/userReducer";
import { adminReducer } from "../admin_reducers/adminReducer";
import { usersReducer } from "../admin_reducers/userReducers";
import { camerasReducer } from "../admin_reducers/cameraReducers";
import { liveshowsReducer } from "./liveshowReducers";
import { progressReducer } from "./progressReducer";
// import { usersReducer } from './usersReducer'
// import { reducerCarts } from './reducerCarts'
import { liveshowReducer } from "./liveshowReducer";
import { liveshowSheduleReducer } from "./liveshowSheduleReducer";
import {
	ERROR,
	ERROR_REMOVE,
	ERRORS,
	ERRORS_REMOVE,
	LIVESHOW_TAB,
	LIVESHOW_TAB_REMOVE,
	MESSAGE,
	MESSAGE_REMOVE,
	MESSAGES,
	MESSAGES_REMOVE,
	MODAL_DISABLE,
	MODAL_ENABLE,
	REMOVE_LOADING,
	SET_LOADING,
	SET_LOADING_CHAT,
	SET_LOADINGS,
	SIDEBAR_HIDE
} from "../types";
import { modelMediaReducer } from "./modelMediaReducer";
import { timePickerReducer } from "./timePickerReducer";
import { chatsReducer } from "./chatsReducer";
import { chatReducer } from "./chatReducer";
import { messagesReducer } from "./messagesReducer";
import { messageReducer } from "./messageReducer";
import { chatsFiltersReducer } from "./chatsFiltersReducer";
import { modelsEarningsReducer } from "./modelsEarningsReducer";
import { usersEarningsReducer } from "../admin_reducers/usersEarningsReducer";
import { errorsReducer } from "./errorReducers";
import { errorReducer } from "./errorReducer";
import { geoblockExceptionsReducer } from "./geoblockExceptionsReducer";
import { geoblockRestrictionsReducer } from "./geoblockRestrictionsReducer copy";
import { GeoblockSettingsReducer } from "./geoblockSettingsReducer";
import { userTransactionReducer } from "../admin_reducers/userTransactionReducer";
import { modalReducer } from "./modalReducer";
import { modelEarningsReducer } from "./modelEarningsReducer";
import { PoolsReducer } from "./PoolsReducer";
import { BlogReducer } from "./blogReducer";
import { BlogsReducer } from "./blogReducers";
import { loadingReducer } from "./loadingReducer";
import { User_TransactionsReducer } from "./UserTransactionsReducer";
import { DashBoardReducer } from "./dashboardReducer";
import { PromosReducer } from "./PromosReducer";
import { PromoReducer } from "./PromoReducer";
import { EventsReducer } from "./eventsReducer";
import { GalleriesReducer } from "./GalleriesReducer";
import { GalleryReducer } from "./GalleryReducer";
import { modelsOrderReducer } from "./modelsOrderReducer";
import { MagicsReducer } from "./MagicsReducer";
import { sidebarReducer } from "./sidebarReducer";
import { UserSubscriptionsReducer } from "./UserSubscriptionsReducer";
import { BlogCommentsReducer } from "./BlogCommentsReducer";
import { liveshowTabReducer } from "./liveshowTabReducer";
import { EnvironmentReducer } from "./environmentReducer";
import { TubeReducer } from "./TubeReducer";
import { MicrophonesReducer } from "./MicrophonesReducer";
import { whxEventReducer } from "./whxEventReducer";

export const rootReducer = combineReducers({
	session: adminReducer,
	user: userReducer,
	model: modelReducer,
	model_earnings: modelEarningsReducer,
	pools: PoolsReducer,
	models: modelsReducer,
	blog: BlogReducer,
	blogs: BlogsReducer,
	blog_comments: BlogCommentsReducer,
	cameras: camerasReducer,
	progress: progressReducer,
	loading: loadingReducer,
	liveshows: liveshowsReducer,
	liveshow: liveshowReducer,
	liveshowShedule: liveshowSheduleReducer,
	error: errorReducer,
	errors: errorsReducer,
	model_media: modelMediaReducer,
	users: usersReducer,
	admin: adminReducer,
	time_picker: timePickerReducer,
	chats: chatsReducer,
	chat: chatReducer,
	message: messageReducer,
	messages: messagesReducer,
	modal: modalReducer,
	filters: chatsFiltersReducer,
	models_earnings: modelsEarningsReducer,
	models_order: modelsOrderReducer,
	users_transactions: usersEarningsReducer,
	user_transactions: User_TransactionsReducer,
	user_subscriptions: UserSubscriptionsReducer,
	chat_messages: messagesReducer,
	geoblock_exceptions: geoblockExceptionsReducer,
	geoblock_restrictions: geoblockRestrictionsReducer,
	geoblock_settings: GeoblockSettingsReducer,
	transaction: userTransactionReducer,
	dashboard: DashBoardReducer,
	environment: EnvironmentReducer,
	promos: PromosReducer,
	promo: PromoReducer,
	events: EventsReducer,
	liveshow_tab: liveshowTabReducer,
	magics: MagicsReducer,
	galleries: GalleriesReducer,
	gallery: GalleryReducer,
	sidebar: sidebarReducer,
	tube: TubeReducer,
	microphones: MicrophonesReducer,
	whx_event: whxEventReducer
});

export const showModal = (data, content = null) => {
	return {
		type: MODAL_ENABLE,
		payload: { data },
		content: { content }
	};
};

export const hideModal = (data) => {
	return {
		type: MODAL_DISABLE,
		payload: { data }
	};
};

export const setLiveshowTab = (data) => {
	return {
		type: LIVESHOW_TAB,
		payload: { data }
	};
};

export const removeLiveshowTab = () => {
	return {
		type: LIVESHOW_TAB_REMOVE
	};
};

export const setLoading = (data) => {
	return {
		type: SET_LOADING,
		payload: { data }
	};
};
export const setLoadingChat = (data) => {
	return {
		type: SET_LOADING_CHAT,
		payload: { data }
	};
};

export const setLoadings = (data) => {
	return {
		type: SET_LOADINGS,
		payload: { data }
	};
};

export const removeLoading = (data) => {
	return {
		type: REMOVE_LOADING,
		payload: { data }
	};
};

export const setMessages = (text, type) => {
	return {
		type: MESSAGES,
		payload: { text, type }
	};
};
export const removeMessages = () => {
	return {
		type: MESSAGES_REMOVE
	};
};

export const setMessage = (message, type) => {
	return {
		type: MESSAGE,
		payload: { message, type }
	};
};
export const removeMessage = () => {
	return {
		type: MESSAGE_REMOVE
	};
};

export const setError = (data) => {
	return {
		type: ERROR,
		payload: { data }
	};
};

export const setErrors = (data) => {
	return {
		type: ERRORS,
		payload: { data }
	};
};

export const removeError = (data) => {
	return {
		type: ERROR_REMOVE,
		payload: { data }
	};
};

export const removeErrors = () => {
	return {
		type: ERRORS_REMOVE
	};
};

export const SidebarHide = (data) => {
	return {
		type: SIDEBAR_HIDE,
		payload: { data }
	};
};
