import { FILTERS } from "../types";

export const chatsFiltersReducer = (state = [], action) => {
	const { type, payload } = action;

	switch (type) {
		case FILTERS: {
			const { data } = payload;
			return data;
		}
		default: {
			return state;
		}
	}
};
