import {
	UPDATE_ENVIRONMENT_DATA,
	UPDATE_ENVIRONMENT_PRERECORDING_STATUS
} from "../types";

export const EnvironmentReducer = (state = {}, action) => {
	const { type, payload } = action;

	switch (type) {
		case UPDATE_ENVIRONMENT_DATA: {
			const { data } = payload;
			return data;
		}

		case UPDATE_ENVIRONMENT_PRERECORDING_STATUS: {
			const { data } = payload;
			return {
				...state,
				prerecording_mode: data // Replace prop1 with the new value from action payload
			};
		}

		default: {
			return state;
		}
	}
};
