import * as React from "react";
import { useRef, useState } from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import {
	Backdrop,
	Box,
	Input,
	Modal,
	Stack,
	Table,
	TableBody,
	TableContainer,
	TableHead,
	TableRow,
	Typography
} from "@mui/material";
import TableCell from "@mui/material/TableCell";
import { useDispatch } from "react-redux";
import ClearIcon from "@mui/icons-material/Clear";
import IconButton from "@mui/material/IconButton";
import { setMessage } from "../../redux/reducers/rootReducer";
import { LoadingButton } from "@mui/lab";
import {
	DeleteStageTaskMedia,
	UpdateStageTaskMedia
} from "../../redux/actions/studActions";
import ReactHlsPlayer from "react-hls-player";
import useVaporUpload from "../Upload/VaporUpload";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 700,
	bgcolor: "background.paper",
	border: "2px solid #000",
	textAlign: "center",
	margin: "0 auto",
	p: 4,
	maxHeight: "700px",
	overflow: "auto"
};

function EventMedia({ galleryID, stage, backend_url }) {
	const dispatch = useDispatch();
	const inputRefs = useRef([]);
	const [sources, setSources] = useState(
		Array.from({ length: stage?.tasks?.length }, () => null)
	);
	const [uploadings, setUploadings] = useState(
		Array.from({ length: stage?.tasks?.length }, () => false)
	);
	const { uploadFile } = useVaporUpload();
	const handleFileChange = async (event, taskId, index) => {
		const file = event.target.files[0];
		const url = URL.createObjectURL(file);

		const { vaporResponse } = await uploadFile(file);
		const formData = new FormData();
		formData.append("mediaType", "video");
		formData.append("title", vaporResponse.uuid);
		formData.append("fullMovie", vaporResponse.key);

		const newUploadings = [...uploadings];
		newUploadings[index] = true;
		setUploadings(newUploadings);

		fetch(
			process.env.REACT_APP_BACKEND_URL +
				"/api/v1/admin/manager/gallery/upload-video/" +
				galleryID,
			{
				method: "POST",
				body: formData,
				headers: {
					Authorization: `Bearer ${sessionStorage.getItem("token")}`
				}
			}
		)
			.then((response) => {
				if (!response.ok) {
					throw new Error(`Can't upload video, try another one`);
				}
				return response.json();
			})
			.then((data) => {
				if (data.type == "success") {
					const body = JSON.stringify({
						video_id: data.data?.id
					});
					dispatch(UpdateStageTaskMedia(backend_url, taskId, body));
					dispatch(setMessage(data.message, data.type));
					const newSources = [...sources];
					newSources[index] = url;
					setSources(newSources);
				}
			})
			.catch((error) => {
				dispatch(setMessage(error?.message, "error"));
			})
			.finally(() => {
				const newUploadings = [...uploadings];
				newUploadings[index] = false;
				setUploadings(newUploadings);
			});
	};

	const handleChoose = (index) => {
		inputRefs.current[index].click();
	};

	const [modalOpen, setModalOpen] = useState(false);
	const [mediaItem, setMediaItem] = useState({});

	const handleOpenModal = (media) => {
		setMediaItem(media);
		setModalOpen(true);
	};

	const handleCloseModal = () => {
		setModalOpen(false);
		setMediaItem("");
	};
	return (
		<>
			<Stack spacing={3} sx={{ width: 750 }}>
				<Grid
					container
					spacing={0}
					style={{ display: "flex", flexDirection: "column" }}
				>
					<Grid
						item
						xs={12}
						md={12}
						spacing={0}
						className="Magicx_text"
						style={{
							paddingTop: "25px",
							flexDirection: "row",
							justifyContent: "space-between"
						}}
					>
						<Typography
							variant="h4"
							gutterBottom
							component="div"
							style={{ color: "white", marginBottom: "unset" }}
						>
							Tasks Media - ${stage?.name}
						</Typography>
					</Grid>
					<Box component="div" className="Voting_tricks">
						<TableContainer className="Magics_table">
							<Table sx={{ minWidth: 650 }} aria-label="simple table">
								<TableHead>
									<TableRow>
										<TableCell align="left">Task</TableCell>
										<TableCell align="center"></TableCell>
										<TableCell align="center"></TableCell>
										<TableCell align="center"></TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{stage &&
										stage?.tasks &&
										stage?.tasks.map((task, index) => (
											<TableRow
												key={task.id}
												sx={{
													"&:last-child td, &:last-child th": { border: 0 }
												}}
											>
												<TableCell align="left">
													<Input
														key={task.id}
														className="inputBoxTrick"
														disableUnderline="true"
														name="password"
														disabled
														style={{
															width: "50%"
														}}
														defaultValue={task?.name}
													></Input>
												</TableCell>
												<TableCell align="center">
													<div className="VideoInput">
														<input
															ref={(ref) => (inputRefs.current[index] = ref)}
															className="VideoInput_input"
															type="file"
															onChange={(e) =>
																handleFileChange(e, task?.id, index)
															}
															accept="video/*"
														/>

														<LoadingButton
															loading={uploadings[index]}
															sx={{
																borderRadius: "10px",
																textTransform: "unset",
																fontSize: "1.1rem",
																".MuiCircularProgress-root": {
																	width: "23px !important",
																	height: "23px !important"
																},
																".MuiLoadingButton-loadingIndicator": {
																	color: "black"
																}
															}}
															style={{
																width: "100%",
																color: uploadings[index] ? "#78F73B" : "black",
																backgroundColor: "#78F73B",
																fontSize: "0.875rem"
															}}
															onClick={() => handleChoose(index)}
														>
															Upload Video
														</LoadingButton>
													</div>
												</TableCell>
												<TableCell align="center">
													{task?.video?.id && (
														<Button
															style={{
																width: "100%",
																color: "black",
																backgroundColor: "red",
																borderRadius: "10px",
																textTransform: "unset"
															}}
															onClick={() => {
																dispatch(
																	DeleteStageTaskMedia(backend_url, task.id)
																);
															}}
															variant="contained"
															color="primary"
														>
															Delete Video
														</Button>
													)}
												</TableCell>
												<TableCell align="center">
													{task?.video?.fullMovie && (
														<Button
															style={{
																width: "100%",
																color: "black",
																backgroundColor: "yellow",
																borderRadius: "10px",
																textTransform: "unset"
															}}
															onClick={() => handleOpenModal(task?.video)}
															variant="contained"
															color="primary"
														>
															Watch Video
														</Button>
													)}
												</TableCell>
											</TableRow>
										))}
								</TableBody>
							</Table>
						</TableContainer>
					</Box>
				</Grid>
			</Stack>
			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				closeAfterTransition
				open={modalOpen}
				onCancel={handleCloseModal}
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
					onClick: handleCloseModal
				}}
			>
				<Box sx={style}>
					<IconButton className="BackButton" onClick={handleCloseModal}>
						<ClearIcon />
					</IconButton>
					<div className="VideoInput">
						{mediaItem?.fullMovie ? (
							<ReactHlsPlayer
								className="VideoInput_video"
								src={mediaItem?.fullMovie}
								controls={true}
								style={{
									minWidth: "300px",
									width: "100%",
									height: "300px",
									objectFit: "contain"
								}}
								muted={false}
								autoPlay
							/>
						) : (
							<div
								className="loader-container"
								style={{ position: "relative" }}
							>
								<div className="spinner"></div>
							</div>
						)}
					</div>
				</Box>
			</Modal>
		</>
	);
}

export default EventMedia;
