import { GEOBLOCK_SETTINGS } from "../types";

export const GeoblockSettingsReducer = (state = {}, action) => {
	const { type, payload } = action;

	switch (type) {
		case GEOBLOCK_SETTINGS: {
			const { data } = payload;
			return data;
		}

		/*       case UPDATE_MODEL: {
        const { data } = payload;
        return data;
      } */
		/*     case MODEL_MEDIA: {
        const { data } = payload;
        return data;
      } */

		default: {
			return state;
		}
	}
};
