import * as React from "react";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import VisibilityIcon from "@mui/icons-material/Visibility";
export default function GirlCardModal({ model }) {
	const cardClass = "cameraCardModal";

	return (
		<Card className={cardClass} style={{ border: "5px solid black" }}>
			<CardMedia
				component="img"
				height="80"
				image={model.avatar.imageMedium}
				title={model.inHouseName}
			/>
			<Typography
				gutterBottom
				variant="h7"
				component="span"
				className="BadgeTextModal"
			>
				{model.inHouseName}
			</Typography>
		</Card>
	);
}
