import {
	BLOG_COMMENT_UPDATE,
	BLOG_COMMENTS,
	BLOG_COMMENTS_PAGINATION
} from "../types";

export const BlogCommentsReducer = (state = {}, action) => {
	const { type, payload } = action;

	switch (type) {
		case BLOG_COMMENTS: {
			const { data } = payload;
			return data;
		}
		case BLOG_COMMENTS_PAGINATION: {
			const { data } = payload;
			const updatedComments = [...state.comments, ...data.comments];
			const updatedPagination = {
				...state.pagination,
				page: data.pagination.page,
				total_count: data.pagination.total_count,
				limit: data.pagination.limit
			};
			// Now you can use the updatedComments and updatedPagination in your state or do further processing
			// For example, you might want to update the state with the updatedComments and updatedPagination:
			return {
				...state,
				comments: updatedComments,
				pagination: updatedPagination
			};
		}
		case BLOG_COMMENT_UPDATE: {
			const { data } = payload;
			const updatedComments = state.comments.map((comment) => {
				if (comment.id === data.id) {
					// If the comment ID matches the updated comment ID, return the updated comment
					return data;
				} else {
					// Otherwise, return the original comment without any changes
					return comment;
				}
			});

			// Now you can use the updatedComments in your state or do further processing
			// For example, you might want to update the state with the updatedComments:
			return {
				...state,
				comments: updatedComments
			};
		}
		default: {
			return state;
		}
	}
};
