import { USER, UPDATE_USER, DELETE_USER } from "../types";

export const userReducer = (state = {}, action) => {
	const { type, payload } = action;

	switch (type) {
		case USER: {
			const { data } = payload;
			return data;
		}
		case UPDATE_USER: {
			const { data } = payload;
			return data;
		}
		case DELETE_USER: {
			return {};
		}
		default: {
			return state;
		}
	}
};
