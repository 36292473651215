import {
	API_FETCH_CREATE_POOL_ROUTE,
	API_FETCH_POOLS_ROUTE
} from "../../utils/consts";
import { POOL_UPDATE, POOLS } from "../types";
import { userLogout } from "./userAction";
import { setLoading, setMessage } from "../reducers/rootReducer";

const allPools = (data) => {
	return {
		type: POOLS,
		payload: { data }
	};
};

const patchPool = (data) => {
	return {
		type: POOL_UPDATE,
		payload: { data }
	};
};

export const GetPools = () => async (dispatch) => {
	dispatch(setLoading({ camera: "pools", type: true }));
	try {
		const options = {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: `Bearer ${sessionStorage.getItem("token")}`
			}
		};
		const response = await fetch(
			process.env.REACT_APP_BACKEND_URL + API_FETCH_POOLS_ROUTE,
			options
		);
		//credentials: 'include',

		const data = await response.json();
		if (response.status >= 200 && response.status <= 299) {
			dispatch(allPools(data.data));
			dispatch(setLoading({ camera: "pools", type: false }));
		} else {
			dispatch(userLogout());
			sessionStorage.removeItem("token");
		}
	} catch (error) {
		console.log(error);
		dispatch(setLoading({ camera: "pools", type: false }));
	}
};

export const CreatePool = (item) => async (dispatch) => {
	dispatch(setLoading({ camera: "pools", type: true }));
	try {
		const options = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: `Bearer ${sessionStorage.getItem("token")}`
			},
			body: JSON.stringify(item)
			//credentials: 'include',
		};
		const response = await fetch(
			process.env.REACT_APP_BACKEND_URL + API_FETCH_CREATE_POOL_ROUTE,
			options
		);
		//response.data
		const data = await response.json();

		if (data.type == "success") {
			dispatch(GetPools());
			dispatch(setMessage(data.message, data.type));
			dispatch(setLoading({ camera: "pools", type: false }));
		}
	} catch (error) {
		console.log(error);
		dispatch(setLoading({ camera: "pools", type: false }));
	}
};
export const ExtendPool = (item, id) => async (dispatch) => {
	try {
		const options = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: `Bearer ${sessionStorage.getItem("token")}`
			},
			body: JSON.stringify(item)
			//credentials: 'include',
		};
		const response = await fetch(
			process.env.REACT_APP_BACKEND_URL +
				API_FETCH_POOLS_ROUTE +
				"/" +
				id +
				"/edit",
			options
		);
		//response.data
		const data = await response.json();

		if (data.type == "success") {
			dispatch(GetPools());
			dispatch(setMessage(data.message, data.type));
		}
	} catch (error) {
		console.log(error);
	}
};
export const AccomplishPool = (id) => async (dispatch) => {
	try {
		const options = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: `Bearer ${sessionStorage.getItem("token")}`
			}
			//credentials: 'include',
		};
		const response = await fetch(
			process.env.REACT_APP_BACKEND_URL +
				API_FETCH_POOLS_ROUTE +
				"/" +
				id +
				"/edit/accomplish",
			options
		);
		//response.data
		const data = await response.json();

		if (data.type == "success") {
			dispatch(GetPools());
			dispatch(setMessage(data.message, data.type));
		}
	} catch (error) {
		console.log(error);
	}
};
export const TerminatePool = (id) => async (dispatch) => {
	try {
		const options = {
			method: "DELETE",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: `Bearer ${sessionStorage.getItem("token")}`
			}
			//credentials: 'include',
		};
		const response = await fetch(
			process.env.REACT_APP_BACKEND_URL +
				API_FETCH_POOLS_ROUTE +
				"/" +
				id +
				"/terminate",
			options
		);
		//response.data
		const data = await response.json();

		if (data.type == "success") {
			dispatch(GetPools());
			dispatch(setMessage(data.message, data.type));
		}
	} catch (error) {
		console.log(error);
	}
};
