import * as React from "react";
import Box from "@mui/material/Box";
import { Avatar, ListItem, Slide, Typography } from "@mui/material";
import { useEffect } from "react";

function Message({ message, userId, odd }) {
	const [checked, setChecked] = React.useState(false);
	const [checked2, setChecked2] = React.useState(true);

	const handleChange = () => {
		setChecked((prev) => !prev);
	};
	useEffect(() => {
		if (message.message == "") {
			return;
		}
		setTimeout(() => {
			setChecked(true);
		}, 30);
	}, []);

	useEffect(() => {
		if (message.message == "") {
			return;
		}
	});

	return (
		<ListItem
			key={message.id}
			id={message.id}
			className={
				message.data !== null &&
				userId !== null &&
				message?.data?.userId === userId
					? "ChatListElem odd"
					: "ChatListElem"
			}
		>
			<Slide
				direction={
					message.data !== null &&
					userId !== null &&
					message.data.userId === userId
						? "right"
						: "left"
				}
				in={checked}
				mountOnEnter
				unmountOnExit
			>
				<Box className="ChatListElem_wrap">
					{message.data !== null &&
						userId !== null &&
						message.data.userId !== userId && (
							<Box className="ImgPlace">
								<Avatar
									className={
										message !== null && message.vip ? "img vip" : "img"
									}
									src={message.data !== null && message.data.avatar}
									alt=""
								/>
							</Box>
						)}
					<Box
						className={
							message.type == "tip"
								? message.vip
									? "tip vip message_wrap"
									: "tip message_wrap"
								: message.vip
									? "vip message_wrap"
									: "message_wrap"
						}
						id={"message_wrap_" + message.id}
					>
						<Box
							id={"message_" + message.id}
							className={message.deleted ? "deletedMsg" : "msg"}
						>
							{message.data !== null &&
								userId !== null &&
								message.data.userId !== userId && (
									<Typography className="username">
										{message !== null &&
											message.data !== null &&
											message.data.name}
									</Typography>
								)}
							{message !== null && message.message}
							<Typography className="time">
								{
									message !== null &&
										message.data !== null &&
										message.data.time &&
										message.data.time
									/* moment(message.data.time).format("HH:mm") */
									/*   getTimeStamp(message.createdAt) */
								}
							</Typography>
							<Typography className="ban" style={{ opacity: "1" }}>
								{message.deleted !== null && message.deleted == true
									? "Deleted"
									: null}
							</Typography>
						</Box>
					</Box>
				</Box>
			</Slide>
		</ListItem>
	);
}

export default Message;
