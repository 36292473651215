import {
	MODEL,
	UPDATE_MODEL,
	DELETE_MODEL,
	MODEL_MEDIA,
	DELETE_MODEL_MEDIA,
	MODEL_EARNINGS
} from "../types";

export const modelReducer = (state = {}, action) => {
	const { type, payload } = action;

	switch (type) {
		case MODEL: {
			const { data } = payload;
			return data;
		}
		case UPDATE_MODEL: {
			const { data } = payload;
			return data;
		}
		/*     case MODEL_MEDIA: {
      const { data } = payload;
      return data;
    } */
		case DELETE_MODEL: {
			return {};
		}

		default: {
			return state;
		}
	}
};
