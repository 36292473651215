import {
	MESSAGES,
	ADD_MESSAGE_TO_MESSAGES,
	REMOVE_MESSAGES,
	MESSAGES_REMOVE,
	MORE_MESSAGES
} from "../types";

const initialState = [];
export const messagesReducer = (state = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
		case MESSAGES: {
			const { data } = payload;
			state = [];
			return [...state, ...data];
		}
		case MORE_MESSAGES: {
			const { data } = payload;
			state = [];
			return [...data, ...state];
		}
		case ADD_MESSAGE_TO_MESSAGES: {
			const { data } = payload;
			if (state.some((item) => data.id === item.id)) return state;
			return [...state, data];
		}
		case MESSAGES_REMOVE: {
			return initialState;
		}
		default: {
			return state;
		}
	}
};
