import { API_FETCH_ENVIRONMENT } from "../../utils/consts";
import { UPDATE_ENVIRONMENT_DATA } from "../types";
import { setLoading } from "../reducers/rootReducer";
import { userLogout } from "./userAction";

const updateEnvironment = (data) => {
	return {
		type: UPDATE_ENVIRONMENT_DATA,
		payload: { data }
	};
};
export const getEnvironment = () => async (dispatch) => {
	dispatch(setLoading({ camera: "environment", type: true }));
	try {
		const response = await fetch(
			process.env.REACT_APP_BACKEND_URL + API_FETCH_ENVIRONMENT,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
					Authorization: `Bearer ${sessionStorage.getItem("token")}`
				}
				//credentials: 'include',
			}
		);

		const data = await response.json();
		if (response.status >= 200 && response.status <= 299) {
			dispatch(updateEnvironment(data));
		} else if (response.status === 401) {
			dispatch(userLogout());
			sessionStorage.removeItem("token");
		}
	} catch (error) {
		console.log(error);
	}
	dispatch(setLoading({ camera: "environment", type: false }));
};
