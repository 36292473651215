import {
	AUTH_ADMIN_LOGIN,
	AUTH_ADMIN_LOGOUT,
	UPDATE,
	USER,
	UPDATE_USER,
	DELETE_USER
} from "../types";

const initialState = {};

export const adminReducer = (state = initialState, action) => {
	const { type, payload } = action;

	switch (type) {
		case AUTH_ADMIN_LOGIN: {
			let { data } = payload;
			return data;
		}

		case AUTH_ADMIN_LOGOUT: {
			return initialState;
		}

		case UPDATE: {
			const { data } = payload;
			return data;
		}

		default: {
			return state;
		}
	}
};
