import * as React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import {
	getCamerasByGroup,
	offCamerasSDN
} from "../../redux/actions/cameraAction";
import { connect, useDispatch } from "react-redux";
import { hideModal, showModal } from "../../redux/reducers/rootReducer";
import CameraCardActivity from "../CameraCard/CameraCardActivity";
import { MenuItem, Select } from "@mui/material";

const mapStateToProps = (state) => {
	return {
		cameras: state.cameras,
		modal: state.modal,
		sidebar: state.sidebar
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		getCamerasByGroup: () => dispatch(getCamerasByGroup())
	};
};

function CamerasActivity({
	cameras,
	getCamerasByGroup,
	modal,
	admin,
	sidebar
}) {
	const dispatch = useDispatch();

	React.useEffect(() => {
		getCamerasByGroup();
	}, []);

	function changeHandleSDN() {
		dispatch(offCamerasSDN());
	}
	const [selected, setSelected] = React.useState("");
	const handleOpenPass = (item) => {
		setSelected(item);
		{
			modal && modal.name == "password"
				? dispatch(hideModal("password"))
				: dispatch(showModal("password"));
		}
	};
	// const [check, setcheck] = useState(cameras && cameras.prerecording_mode);
	// function changeHandleCheck() {
	//   dispatch(changePrerecordingMode(check == true ? 0 : 1));
	//   setcheck(!check);
	// }

	const [groups, setGroups] = useState([]);

	const [selectedPeriod, setselectedPeriod] = useState("");
	React.useEffect(() => {
		if (cameras && cameras?.room_groups) {
			setGroups(cameras && cameras.room_groups && cameras.room_groups);
		}
	}, [cameras]);
	React.useEffect(() => {
		if (groups.length > 0) {
			setselectedPeriod(groups[0]);
		}
	}, [groups]);

	const [selectedCameras, setselectedCameras] = useState("");

	const handleChangeCampaignPeriod = (event) => {
		setselectedPeriod(event.target.value);
	};
	React.useEffect(() => {
		cameras &&
			Object.entries(cameras).map((key) => {
				if (key[0] == selectedPeriod) {
					setselectedCameras(key[1]);
				}
				// if (task == selectedPeriod) {
				//   return console.log(task);
				// }
			});
	}, [selectedPeriod]);
	return (
		<>
			<Container
				maxWidth="xl"
				className="page-container page-cameras"
				style={{
					color: "#ffffff",
					width: "-webkit-fill-available",
					height: "100%!important",
					marginLeft: sidebar && sidebar?.hide == true ? "0px" : "230px",
					maxWidth: "unset",
					paddingLeft: "0px",
					paddingRight: "0px"
				}}
			>
				<Box
					style={{
						marginLeft: "5%"
					}}
				>
					<Grid
						style={{
							display: "flex",
							flexDirection: "row",
							gap: "20px",
							whiteSpace: "nowrap",
							alignItems: "center",
							width: "fit-content",
							paddingBottom: "20px"
						}}
					>
						{" "}
						<Typography variant="h4" component="div">
							Camera Activity
						</Typography>
						<Select
							className="filter_result"
							defaultValue=""
							displayEmpty
							value={selectedPeriod && selectedPeriod}
							onChange={handleChangeCampaignPeriod}
							renderValue={(value) => {
								return (
									<Box sx={{ display: "flex", gap: 1 }}>
										<div
											style={{
												display: "flex",
												width: "-webkit-fill-available",
												justifyContent: "center"
											}}
										>
											{value}
										</div>
									</Box>
								);
							}}
						>
							{groups &&
								groups?.map((option) => (
									<MenuItem
										style={{ textAlign: "center" }}
										key={option}
										value={option}
									>
										{option}
									</MenuItem>
								))}
						</Select>
					</Grid>

					<Grid container spacing={0}></Grid>

					<Grid className="AllRoomBlock_list" style={{ gap: "20px" }}>
						<Box
							style={{
								display: "flex",
								width: "100%",
								flexWrap: "wrap",
								gap: "10px"
							}}
						>
							{selectedCameras &&
								selectedCameras?.map((camera) => {
									return (
										<CameraCardActivity
											key={camera.id}
											camera={camera}
											admin={admin}
										/>
									);
								})}
						</Box>
					</Grid>
				</Box>
			</Container>
		</>
	);
}
export default connect(mapStateToProps, mapDispatchToProps)(CamerasActivity);
