// api-client.js

import axios from "axios";
import { useNavigate } from "react-router-dom";
import { setErrors, setMessage } from "../redux/reducers/rootReducer";
import { store } from "../redux/store";

// Request middleware
axios.interceptors.request.use(
	function (config) {
		// Do something before request is sent
		if (sessionStorage.getItem("token") === null) {
			let root = localStorage.getItem("persist:root");
			root = JSON.parse(root);
			let session = JSON.parse(root.session);
			if (!session) return config;
			const access_token = session.access_token;
			sessionStorage.setItem("token", access_token);
		}

		return config;
	},
	function (error) {
		// Do something with request error
		return Promise.reject(error);
	}
);

// Response middleware
axios.interceptors.response.use(
	function (response) {
		// Do something with response data

		if (
			response.message &&
			response.message !== null &&
			response.message !== ""
		)
			store.dispatch(setMessage(response.data.message, response.data.type));
		return response;
	},
	function (error) {
		// Do something with response error
		if (error.response.status == 401) {
			store.dispatch(setMessage("You are not authenticated.", "error"));
			localStorage.removeItem("persist:root");
			window.location.href = "/login";
		} else if (error.response.status == 404) {
		} else {
			error.response.data.message &&
			error.response.data.message !== null &&
			error.response.data.message !== ""
				? store.dispatch(setMessage(error.response.data.message, "error"))
				: error.response.data.errors
					? store.dispatch(setErrors(error.response.data.errors))
					: store.dispatch(setMessage(error.response.data.error, "error"));
		}
		return Promise.reject(error);
	}
);

export default axios;
