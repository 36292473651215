import {
	API_FETCH_CAMERA_STATUS_ROUTE,
	API_FETCH_CAMERAS_GROUPS_ROUTE,
	API_FETCH_CAMERAS_REORDER_ROUTE,
	API_FETCH_CAMERAS_ROUTE
} from "../../utils/consts";
import {
	ADMIN_CAMERA,
	CAMERAS,
	UPDATE,
	UPDATE_ENVIRONMENT_PRERECORDING_STATUS
} from "../types";
import { userLogout } from "./userAction";
import { setError, setLoading, setMessage } from "../reducers/rootReducer";

const allCameras = (data) => {
	return {
		type: CAMERAS,
		payload: { data }
	};
};

const oneCamera = (data) => {
	return {
		type: ADMIN_CAMERA,
		payload: { data }
	};
};

const patchCamera = (data) => {
	return {
		type: UPDATE,
		payload: { data }
	};
};

const updatePrerecordingStatus = (data) => {
	return {
		type: UPDATE_ENVIRONMENT_PRERECORDING_STATUS,
		payload: { data }
	};
};

export const getCamera = (id) => async (dispatch) => {
	try {
		const response = await fetch(
			process.env.REACT_APP_BACKEND_URL + API_FETCH_CAMERA_ROUTE + id,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
					Authorization: `Bearer ${sessionStorage.getItem("token")}`
				}
				//credentials: 'include',
			}
		);
		//response.data
		const data = await response.json();
		dispatch(oneCamera(data));
	} catch (error) {
		console.log(error);
	}
};

export const getCameras = () => async (dispatch) => {
	dispatch(setLoading({ camera: "camera", type: true }));
	try {
		const options = {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: `Bearer ${sessionStorage.getItem("token")}`
			}
		};
		const response = await fetch(
			process.env.REACT_APP_BACKEND_URL + API_FETCH_CAMERAS_ROUTE,
			options
		);
		//credentials: 'include',

		const data = await response.json();
		if (response.status >= 200 && response.status <= 299) {
			dispatch(allCameras(data.data));
			dispatch(setLoading({ camera: "camera", type: false }));
		} else {
			dispatch(userLogout());
			sessionStorage.removeItem("token");
		}
	} catch (error) {
		console.log(error);
		dispatch(setLoading({ camera: "camera", type: false }));
	}
};

export const changeCameraStatus = (id, item) => async (dispatch) => {
	dispatch(setLoading({ camera: "camera", type: true }));
	try {
		const options = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: `Bearer ${sessionStorage.getItem("token")}`
			},
			//credentials: 'include',
			body: JSON.stringify(item)
		};
		const response = await fetch(
			process.env.REACT_APP_BACKEND_URL +
				API_FETCH_CAMERA_STATUS_ROUTE +
				id +
				"/update",
			options
		);
		const data = await response.json();
		if (response.status >= 200 && response.status <= 299) {
			dispatch(getCameras());
			dispatch(setMessage(data.message));
			dispatch(setLoading({ camera: "camera", type: false }));
		} else {
			dispatch(setMessage(data.message, "error"));
			dispatch(setLoading({ camera: "camera", type: false }));
		}
	} catch (error) {
		dispatch(setError(error));
		dispatch(setLoading({ camera: "camera", type: false }));
	}
};
export const changeCameraSDN = (id, item) => async (dispatch) => {
	let route = item != true ? "/cdn/enable" : "/cdn/disable";
	try {
		const options = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: `Bearer ${sessionStorage.getItem("token")}`
			}
			//credentials: 'include',
		};
		const response = await fetch(
			process.env.REACT_APP_BACKEND_URL +
				API_FETCH_CAMERA_STATUS_ROUTE +
				id +
				route,
			options
		);
		const data = await response.json();
		if (response.status >= 200 && response.status <= 299) {
			dispatch(getCameras());
			dispatch(setMessage(data.message));
		} else {
			dispatch(setMessage(data.message, "error"));
		}
	} catch (error) {
		dispatch(setError(error));
	}
};

export const changeCameraRecord = (id) => async (dispatch) => {
	dispatch(setLoading({ camera: "camera", type: true }));
	try {
		const options = {
			method: "PUT",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: `Bearer ${sessionStorage.getItem("token")}`
			}
			//credentials: 'include',
		};
		const response = await fetch(
			process.env.REACT_APP_BACKEND_URL +
				API_FETCH_CAMERA_STATUS_ROUTE +
				id +
				"/recording-status",
			options
		);
		const data = await response.json();
		if (response.status >= 200 && response.status <= 299) {
			dispatch(setLoading({ camera: "camera", type: false }));
			dispatch(getCameras());
			dispatch(setMessage(data.message));
		} else {
			dispatch(setMessage(data.message, "error"));
			dispatch(setLoading({ camera: "camera", type: false }));
		}
	} catch (error) {
		dispatch(setError(error));
		dispatch(setLoading({ camera: "camera", type: false }));
	}
};

export const offCamerasSDN = () => async (dispatch) => {
	try {
		const options = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: `Bearer ${sessionStorage.getItem("token")}`
			}
			//credentials: 'include',
		};
		const response = await fetch(
			process.env.REACT_APP_BACKEND_URL +
				API_FETCH_CAMERAS_ROUTE +
				"/cdns/disable",
			options
		);
		const data = await response.json();
		if (response.status >= 200 && response.status <= 299) {
			dispatch(getCameras());
			dispatch(setMessage(data.message));
		} else {
			dispatch(setMessage(data.message, "error"));
		}
	} catch (error) {
		dispatch(setError(error));
	}
};
export const assignCameraName = (item, id) => async (dispatch) => {
	try {
		const options = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: `Bearer ${sessionStorage.getItem("token")}`
			},
			body: JSON.stringify(item)
			//credentials: 'include',
		};
		const response = await fetch(
			process.env.REACT_APP_BACKEND_URL +
				API_FETCH_CAMERA_STATUS_ROUTE +
				id +
				"/update",
			options
		);

		const data = await response.json();
		if (response.status >= 200 && response.status <= 299) {
			if ((data.type = "success")) {
				//dispatch(liveShowSchedule([]));
				dispatch(getCameras());
				dispatch(setMessage(data.message));
			}
		} else {
			dispatch(setMessage(data.message, "error"));
		}
	} catch (error) {
		dispatch(setError(error));
	}
};

export const changeCamerasOrder = (items) => async (dispatch) => {
	try {
		const options = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: `Bearer ${sessionStorage.getItem("token")}`
			},
			body: JSON.stringify({
				rooms: items
			})
			//credentials: 'include',
		};
		const response = await fetch(
			process.env.REACT_APP_BACKEND_URL + API_FETCH_CAMERAS_REORDER_ROUTE,
			options
		);
		const data = await response.json();
		if (response.status >= 200 && response.status <= 299) {
			dispatch(getCameras());
			dispatch(setMessage(data.message));
		} else {
			dispatch(setMessage(data.message, "error"));
		}
	} catch (error) {
		dispatch(setError(error));
	}
};

export const changePrerecordingMode = (item) => async (dispatch) => {
	try {
		dispatch(setLoading({ camera: "dashboard", type: true }));

		const options = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: `Bearer ${sessionStorage.getItem("token")}`
			},
			body: JSON.stringify({
				status: item
			})
			//credentials: 'include',
		};
		const response = await fetch(
			process.env.REACT_APP_BACKEND_URL +
				API_FETCH_CAMERAS_ROUTE +
				"/prerecording-status",
			options
		);
		const data = await response.json();
		if (response.status >= 200 && response.status <= 299) {
			// dispatch(getCameras());
			dispatch(updatePrerecordingStatus(item));
			dispatch(setMessage(data.message));
		} else {
			dispatch(setMessage(data.message, "error"));
		}
	} catch (error) {
		dispatch(setError(error));
	}
	dispatch(setLoading({ camera: "dashboard", type: false }));
};

export const setCameraActivity = (id, item) => async (dispatch) => {
	try {
		const options = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: `Bearer ${sessionStorage.getItem("token")}`
			},
			body: JSON.stringify({
				score: item
			})
			//credentials: 'include',
		};
		const response = await fetch(
			process.env.REACT_APP_BACKEND_URL +
				API_FETCH_CAMERAS_ROUTE +
				"/" +
				id +
				"/activity/add",
			options
		);
		const data = await response.json();
		if (response.status >= 200 && response.status <= 299) {
			dispatch(setMessage(data.message));
		} else {
			dispatch(setMessage(data.message, "error"));
		}
	} catch (error) {
		dispatch(setError(error));
	}
};

export const getCamerasByGroup = () => async (dispatch) => {
	try {
		const options = {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: `Bearer ${sessionStorage.getItem("token")}`
			}
		};
		const response = await fetch(
			process.env.REACT_APP_BACKEND_URL + API_FETCH_CAMERAS_GROUPS_ROUTE,
			options
		);
		//credentials: 'include',

		const data = await response.json();
		if (response.status >= 200 && response.status <= 299) {
			dispatch(allCameras(data.data));
		} else {
			dispatch(setMessage(data.message, "error"));
		}
	} catch (error) {
		console.log(error);
	}
};
