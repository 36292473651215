import React from "react";
import { Checkbox, FormControlLabel } from "@mui/material";

export default function CheckboxUI({
	title,
	onChange,
	disabled,
	checked,
	sx,
	style
}) {
	return (
		<FormControlLabel
			sx={sx}
			style={style}
			disabled={disabled}
			className="checkboxUI"
			control={<Checkbox onChange={onChange} checked={checked} />}
			label={title ?? ""}
		/>
	);
}
