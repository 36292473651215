//frontend
const API = "/api/v1/";
export const WHX_BACKEND_API_CHAT_URL = "https://do-srv1.warehouse-x.io:5000";
//api
//https://whxlbo-fr.warehouse-x.io
//https://chat-srv-dev.warehouse-x.io:5000

export const API_ADMIN_LOGIN_ROUTE = API + "admin/auth/login";
export const API_FETCH_ENVIRONMENT = API + "admin/environment";
export const API_ADMIN_LOGOUT_ROUTE = API + "admin/auth/logout";

export const API_FETCH_CHATS_ROUTE = API + "admin/manager/liveshows/live";

export const API_FETCH_POOLS_ROUTE = API + "admin/manager/pools";
export const API_FETCH_CREATE_POOL_ROUTE = API + "admin/manager/pools/add";

export const API_FETCH_GALLERIES_ROUTE = API + "admin/manager/galleries";
export const API_FETCH_CREATE_GALLERIE_ROUTE = API + "admin/manager/gallery";
export const API_FETCH_UPDATE_IMAGE_GALLERIE_ROUTE =
	API + "admin/manager/gallery/image";
export const API_FETCH_UPDATE_VIDEO_GALLERIE_ROUTE =
	API + "admin/manager/gallery/video";
export const API_FETCH_UPDATE_TUBE_MEDIA_ITEM_ROUTE =
	API + "admin/manager/gallery/image/update";
export const API_FETCH_UPDATE_VIDEO_GALLERIE_ORDER_ROUTE =
	API + "admin/manager/gallery/videos-order/";
export const API_FETCH_UPDATE_IMAGE_GALLERIE_ORDER_ROUTE =
	API + "admin/manager/gallery/images-order/";

export const API_FETCH_GET_PROMOS_ROUTE = API + "admin/manager/promos";
export const API_FETCH_PROMO_ROUTE = API + "admin/manager/promo";

export const API_FETCH_CAMERAS_ROUTE = API + "admin/manager/rooms";
export const API_FETCH_CAMERAS_GROUPS_ROUTE =
	API + "admin/manager/rooms/groups";
export const API_FETCH_CAMERAS_REORDER_ROUTE =
	API + "admin/manager/rooms/update-order";
export const API_FETCH_CAMERA_STATUS_ROUTE = API + "admin/manager/room/";

export const API_FETCH_LIVESHOWS_ROUTE = API + "admin/manager/liveshow-rooms";
export const API_FETCH_LIVESHOW_ROUTE = API + "liveshow/";

export const API_FETCH_LIVESHOW_LOGOUT_ROUTE = API + "admin/manager/liveshow/";
export const API_FETCH_LIVESHOW_ASSIGN_ROUTE = API + "admin/manager/liveshow/";
export const API_FETCH_LIVESHOW_RENAME_ROUTE = API + "admin/manager/room/";

export const API_ADMIN_MODELS_ROUTE = API + "admin/manager/performers";
export const API_ADMIN_MODELS_EARNINGS_ROUTE = API + "admin/manager/earnings";

export const API_ADMIN_MODEL_ROUTE = API + "admin/manager/model-profile/";
export const API_ADMIN_MODEL_EARNINGS_ROUTE =
	API + "admin/manager/earnings/model/";
export const API_ADMIN_MODEL_DELETE_ROUTE =
	API + "admin/manager/profile/delete/";
export const API_ADMIN_MODEL_UPDATE_ROUTE =
	API + "admin/manager/model-profile/";
export const API_ADMIN_MODEL_ADD_ROUTE = API + "admin/manager/model/add";
export const API_ADMIN_MODELS_ORDER_ROUTE = API + "admin/manager/models-order";

export const API_ADMIN_BLOGS_ACTIONS_ROUTE = API + "admin/manager/blogs";
export const API_ADMIN_BLOG_ACTIONS_ROUTE = API + "admin/manager/blog";
export const API_ADMIN_BLOG_CATEGORIES_ROUTE =
	API + "admin/manager/blog-categories";

export const API_ADMIN_DASHBOARD_ACTIONS_ROUTE =
	API + "admin/manager/dashboard";

export const API_ADMIN_USERS_ROUTE = API + "admin/manager/members";
export const API_ADMIN_USERS_TRANSACTIONS_ROUTE =
	API + "admin/manager/transactions";

export const API_ADMIN_USER_SUBSCRIPTION_CANCEL_ROUTE =
	API + "admin/manager/subscriptions/";

export const API_ADMIN_USER_ROUTE = API + "user/profile/";
export const API_ADMIN_USER_UPDATE_ROUTE =
	API + "admin/manager/member-profile/";
export const API_ADMIN_USER_DELETE_ROUTE =
	API + "admin/manager/profile/delete/";
export const API_ADMIN_USER_UNBAN_ROUTE = API + "admin/manager/user/";
export const API_ADMIN_USER_ADD_ROUTE = API + "admin/manager/member/add";
export const API_ADMIN_USER_WARN_ROUTE = API + "admin/manager/user/";

export const CORE_API_CHAT_ROUTE = "/chat/";
export const CORE_API_GET_MESSAGES_ROUTE = "/messages";
export const CORE_API_SEND_MESSAGE_ROUTE = "/send";
export const CORE_API_SEND_GLOBAL_TIPS_ROUTE = API + "tips";

export const API_FETCH_GEOBLOCK_SETTINGS_ROUTE =
	API + "admin/manager/geoblock/settings";

export const API_FETCH_GEOBLOCK_EXCEPTIONS_ROUTE =
	API + "admin/manager/geoblock/exceptions";
export const API_ADMIN_GEOBLOCK_EXCEPTIONS_ADD_ROUTE =
	API + "admin/manager/geoblock/exception/add";
export const API_ADMIN_GEOBLOCK_EXCEPTIONS_DELETE_ROUTE =
	API + "admin/manager/geoblock/exception/";

export const API_FETCH_GEOBLOCK_RESTRICTIONS_ROUTE =
	API + "admin/manager/geoblock/restrictions";
export const API_ADMIN_GEOBLOCK_RESTRICTIONS_ADD_ROUTE =
	API + "admin/manager/geoblock/restriction/add";
export const API_ADMIN_GEOBLOCK_RESTRICTIONS_DELETE_ROUTE =
	API + "admin/manager/geoblock/restriction/";

export const API_ADMIN_EVENTS_ROUTE = API + "admin/manager/events";
export const API_ADMIN_MAGICS_ROUTE = API + "admin/manager/magicx/stages";
export const API_ADMIN_TRICK_ROUTE = API + "admin/manager/magicx/trick/";
export const API_ADMIN_TRICK_CREATE_ROUTE = API + "admin/manager/magicx/trick";

export const API_ADMIN_TUBE_ROUTE = API + "admin/manager/tube";
export const API_ADMIN_TUBE_IMAGES_ROUTE = API + "admin/manager/tube/images";

export const API_ADMIN_MICROPHONES_ROUTE = API + "admin/manager/microphones";

//stud routes
export const API_ADMIN_STUD_STAGES_ROUTE = API + "admin/manager/studx/stages";
export const API_ADMIN_STUD_STAGE_ROUTE = API + "admin/manager/studx/stage";
export const API_ADMIN_TASK_ROUTE = API + "admin/manager/studx/task";
export const API_ADMIN_STAGE_TASKS_ROUTE = API + "admin/manager/stage/tasks";

//queen routes
export const API_ADMIN_QUEEN_STAGES_ROUTE = API + "admin/manager/queenx/stages";
export const API_ADMIN_QUEEN_STAGE_ROUTE = API + "admin/manager/queenx/stage";
export const API_ADMIN_QUEEN_TASK_ROUTE = API + "admin/manager/queenx/task";
export const API_ADMIN_QUEEN_STAGE_TASKS_ROUTE =
	API + "admin/manager/stage/tasks";

//milf routes
export const API_ADMIN_MILF_STAGES_ROUTE = API + "admin/manager/milfx/stages";
export const API_ADMIN_MILF_STAGE_ROUTE = API + "admin/manager/milfx/stage";
export const API_ADMIN_MILF_TASK_ROUTE = API + "admin/manager/milfx/task";
export const API_ADMIN_MILF_STAGE_TASKS_ROUTE =
	API + "admin/manager/stage/tasks";
