import { MICROPHONE_UPDATE, MICROPHONES } from "../types";

export const MicrophonesReducer = (state = [], action) => {
	const { type, payload } = action;

	switch (type) {
		case MICROPHONES: {
			const { data } = payload;
			return data;
		}
		case MICROPHONE_UPDATE: {
			const { data } = payload;
			console.log(data);
			let newState = Object.assign([], state);
			// Find the index of the microphone to be updated
			const index = state.findIndex((microphone) => microphone.id === data.id);
			console.log(index);
			// If the microphone is found in the state, update it
			if (index !== -1) {
				newState[index] = data;
			}

			// If the microphone is not found, return the current state
			return newState;
		}

		default: {
			return state;
		}
	}
};
