import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Form, FormikProvider, useFormik } from "formik";
import {
	Box,
	Grid,
	InputAdornment,
	Stack,
	TextField,
	Typography
} from "@mui/material";
import * as Yup from "yup";
import AccountCircle from "@mui/icons-material/AccountCircle";
import LockIcon from "@mui/icons-material/Lock";
import { LoadingButton } from "@mui/lab";
import { loginUser } from "../../redux/actions/userAction";
import { useDispatch } from "react-redux";

let easing = [0.6, -0.05, 0.01, 0.99];
const animate = {
	opacity: 1,
	y: 0,
	transition: {
		duration: 0.6,
		ease: easing,
		delay: 0.16
	}
};

const LoginForm = () => {
	const navigate = useNavigate();
	const location = useLocation();
	const from = location.state?.from?.pathname || "/";

	const [showPassword, setShowPassword] = useState(false);
	const dispatch = useDispatch();

	const LoginSchema = Yup.object().shape({
		email: Yup.string()
			.email("Provide a valid email address")
			.required("Email is required"),
		password: Yup.string()
			.min(8, "Password should be  minimum 6 characters ")
			.required("Password is required")
	});

	const formik = useFormik({
		initialValues: {
			email: "",
			password: ""
		},
		validationSchema: LoginSchema,

		onSubmit: () => {
			dispatch(loginUser(formik.values));
			navigate(from);
		}
	});

	const { errors, touched, values, isSubmitting, getFieldProps } = formik;

	return (
		<FormikProvider value={formik}>
			<Form
				autoComplete="off"
				noValidate
				style={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					paddingLeft: "20px"
				}}
			>
				<Box style={{ paddingLeft: "44px" }}>
					<Grid container spacing={0}>
						<Grid item xs={4} md={2} spacing={0} className="LoginForm">
							<Typography
								variant="h4"
								gutterBottom
								component="div"
								style={{ color: "white" }}
							>
								Login to X
							</Typography>
						</Grid>
					</Grid>
				</Box>
				<Box
					animate={{
						transition: {
							staggerChildren: 0.55
						}
					}}
					style={{
						display: "flex",
						flexDirection: "column",
						alignItems: "center"
					}}
				>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							gap: 3,
							width: "80%"
						}}
						initial={{ opacity: 0, y: 40 }}
						animate={animate}
					>
						<TextField
							fullWidth
							style={{
								backgroundColor: "#292929",
								paddingTop: "5px",
								borderRadius: "5px"
							}}
							type="email"
							{...getFieldProps("email")}
							error={touched.email && Boolean(touched.email && errors.email)}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<AccountCircle />
									</InputAdornment>
								)
							}}
							helperText={touched.email && touched.email && errors.email}
						/>

						<TextField
							fullWidth
							style={{
								backgroundColor: "#292929",
								paddingTop: "5px",
								borderRadius: "5px"
							}}
							autoComplete="current-password"
							type={showPassword ? "text" : "password"}
							{...getFieldProps("password")}
							error={Boolean(touched.password && errors.password)}
							helperText={touched.password && errors.password}
							InputProps={{
								startAdornment: (
									<InputAdornment position="start">
										<LockIcon />
									</InputAdornment>
								)
							}}
						/>
					</Box>

					<Box
						initial={{ opacity: 0, y: 20 }}
						animate={animate}
						style={{ width: "40%" }}
					>
						<Stack
							direction="row"
							alignItems="center"
							justifyContent="space-between"
							sx={{ my: 2, color: "black" }}
						></Stack>

						<LoadingButton
							fullWidth
							size="large"
							type="submit"
							variant="contained"
							loading={isSubmitting}
							style={{ backgroundColor: "#77F73B" }}
						>
							{isSubmitting ? "loading..." : "Login"}
						</LoadingButton>
					</Box>
				</Box>
			</Form>
		</FormikProvider>
	);
};

export default LoginForm;
