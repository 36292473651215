import { LIVESHOW_TAB, LIVESHOW_TAB_REMOVE } from "../types";

export const liveshowTabReducer = (state = "liveshows", action) => {
	const { type, payload } = action;

	switch (type) {
		case LIVESHOW_TAB: {
			let { data } = payload;
			return data;
		}
		case LIVESHOW_TAB_REMOVE: {
			return {};
		}
		default: {
			return state;
		}
	}
};
