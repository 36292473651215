import "./App";
import React from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import MainContainer from "./components/MainContainer/MainContainer";

import Login from "./components/Login/Login";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { Snackbar } from "@mui/material";
import { removeMessage } from "./redux/reducers/rootReducer";
import MuiAlert from "@mui/material/Alert";

const darkTheme = createTheme({
	palette: {
		mode: "dark"
	}
});
const mapStateToProps = (state) => {
	return {
		admin: state.admin,
		message: state.message
	};
};
const Alert = React.forwardRef(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function App({ admin, message }) {
	const location = useLocation();
	const dispatch = useDispatch();
	let alertClass = ["alert-whx"];
	const handleClose = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}
		dispatch(removeMessage());
	};
	const handleExited = () => {
		dispatch(removeMessage());
	};
	if (message && message.type == "success") {
		alertClass.push("success");
		alertClass.filter((data) => data != "error");
	}

	if (message && message.type == "error") {
		alertClass.push("error");
		alertClass.filter((data) => data != "success");
	}

	return (
		<div className="App">
			<ThemeProvider theme={darkTheme}>
				<Routes>
					{!sessionStorage.getItem("token") ? (
						<>
							<Route path="/login" element={<Login />} />
							<Route
								path="*"
								element={
									<Navigate to="/login" state={{ from: location }} replace />
								}
							></Route>
						</>
					) : (
						<Route path="*" element={<MainContainer />} />
					)}
				</Routes>
			</ThemeProvider>

			{message && Object.keys(message).length > 0 && (
				<Snackbar
					open={Object.keys(message).length > 0}
					anchorOrigin={{ vertical: "top", horizontal: "right" }}
					autoHideDuration={3000}
					TransitionProps={{ onExited: handleExited }}
					onClose={handleClose}
				>
					<Alert
						onClose={handleClose}
						className={alertClass.join(" ")}
						severity={message.type}
						sx={{ width: "100%" }}
					>
						{message.text}
					</Alert>
				</Snackbar>
			)}
		</div>
	);
}

export default connect(mapStateToProps)(App);
