import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import "../../App";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import { Container, Input } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { addBlog, getBlogCategory } from "../../redux/actions/blogActions";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { TagsInput } from "react-tag-input-component";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { useSnackbar } from "notistack";
import { removeErrors } from "../../redux/reducers/rootReducer";
import useVaporUpload from "../Upload/VaporUpload";

export default function AddBlog() {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	React.useEffect(() => {
		dispatch(getBlogCategory());
	}, []);
	const blog_category = useSelector((state) => state.blog);
	const [addFormData, setAddFormData] = React.useState({
		title: "",
		description: "",
		slug: ""
	});

	const handleAddForm = (event) => {
		event.preventDefault();
		const fieldName = event.target.getAttribute("name");
		const fieldValue = event.target.value;
		const newFormData = { ...addFormData };
		newFormData[fieldName] = fieldValue;
		setAddFormData(newFormData);
	};

	const [selectedImage, setSelectedImage] = React.useState(null);
	const [imageUrl, setImageUrl] = React.useState(null);

	const { uploadFile, uploadProgress } = useVaporUpload();
	React.useEffect(() => {
		if (selectedImage) {
			const updateImage = async () => {
				setImageUrl(URL.createObjectURL(selectedImage));
				const newFormData = { ...addFormData };
				const { vaporResponse } = await uploadFile(selectedImage);
				newFormData["image"] = vaporResponse.key;

				setAddFormData(newFormData);
			};
			updateImage();
		}
	}, [selectedImage]);

	function returnToUsers(event) {
		event.preventDefault();
		navigate("/blogs");
	}

	const [blogCategoryId, setBlogCategoryId] = React.useState();

	const handleAutocompleteChange = (event, value) => {
		event.preventDefault();
		setBlogCategoryId(value.id);
	};
	const handleAddFormSubmit = (event) => {
		event.preventDefault();
		addFormData["tags"] = selected;
		addFormData["content"] = value;
		addFormData["blog_category_id"] = blogCategoryId;
		dispatch(addBlog(addFormData));
		// navigate("/blogs");
	};

	const [selected, setSelected] = React.useState([]);

	const [value, setValue] = React.useState();

	const modules = {
		toolbar: [
			[{ font: [] }],
			[{ header: [1, 2, 3, 4, 5, 6, false] }],
			["bold", "italic", "underline", "strike"],
			[{ color: [] }, { background: [] }],
			[{ list: "ordered" }, { list: "bullet" }],
			[{ indent: "-1" }, { indent: "+1" }, { align: [] }],
			["link", "image", "video"],
			["clean"]
		]
	};

	const { enqueueSnackbar } = useSnackbar();
	const errorsSubmit = useSelector((state) => state.errors);
	React.useEffect(() => {
		if (errorsSubmit && errorsSubmit.slug) {
			enqueueSnackbar(errorsSubmit.slug[0], { variant: "error" });
		}
		if (errorsSubmit && errorsSubmit.title) {
			enqueueSnackbar(errorsSubmit.title[0], { variant: "error" });
		}
		if (errorsSubmit && errorsSubmit.description) {
			enqueueSnackbar(errorsSubmit.description[0], { variant: "error" });
		}
		if (errorsSubmit && errorsSubmit.blog_category_id) {
			enqueueSnackbar(errorsSubmit.blog_category_id[0], {
				variant: "error"
			});
		}
		setTimeout(() => {
			dispatch(removeErrors());
		}, 5000);
	}, [errorsSubmit]);
	return (
		<>
			<Box
				component="div"
				className="page-container"
				sx={{
					flexGrow: 1,
					maxWidth: "900px",
					margin: "0",
					height: "100%",
					minHeight: "1000px",
					marginLeft: "290px"
				}}
			>
				<Grid container spacing={0}>
					<Grid item xs={4} md={2} spacing={0} className="LiveShow">
						<Typography
							variant="h4"
							gutterBottom
							component="div"
							style={{ color: "white" }}
						>
							Add new Blog
						</Typography>
					</Grid>
				</Grid>
				<Button
					startIcon={<ArrowBackIosIcon />}
					className="ModelProfilePage_arrow"
					variant="outlined"
					onClick={returnToUsers}
				>
					All Blogs
				</Button>
				<Container
					maxWidth="xl"
					style={{
						color: "#ffffff",
						width: "100%",
						height: "100%",
						backgroundColor: "#000000"
					}}
					sx={{ my: 3 }}
					className="ProvileContainer"
				>
					<Grid spacing={1} className="ModelProfilePage_wrap">
						<Grid item xs={6} md={4}>
							<Box component="div" className="Blog_main">
								<Stack
									direction="row"
									className="ModelProfilePage_row"
									spacing={1}
									style={{ flexGrow: "0.6" }}
								>
									<Typography
										gutterBottom
										component="div"
										className="Blog_elements"
									>
										Blog Image
									</Typography>
									<Box
										style={{
											display: "flex",
											flexDirection: "column",
											alignItems: "center",
											width: "100%"
										}}
									>
										<Box
											mt={2}
											textAlign="center"
											style={{
												width: "200px",
												height: "100px",
												backgroundColor: "rgb(41, 41, 41)",
												borderRadius: "5px"
											}}
										>
											{imageUrl && (
												<img
													src={imageUrl}
													height="100px"
													width="200px"
													accept="image/x-png,image/gif,image/jpeg"
													style={{ borderRadius: "5px" }}
												/>
											)}
										</Box>

										<input
											accept="image/*"
											style={{ display: "none" }}
											id="select-image"
											multiple
											type="file"
											name="myFiles"
											onChange={(e) => {
												setSelectedImage(e.target.files[0]);
											}}
										/>
										<label htmlFor="select-image" style={{ marginTop: "10px" }}>
											<Button variant="raised" component="span">
												Upload
											</Button>
										</label>
									</Box>
								</Stack>
								<Stack
									direction="row"
									className="ModelProfilePage_row"
									spacing={1}
								>
									<Typography
										gutterBottom
										component="div"
										className="Blog_elements"
									>
										title
									</Typography>
									<Input
										className="inputBoxProfile"
										name="title"
										disableUnderline="true"
										style={{ backgroundColor: "#292929" }}
										onChange={handleAddForm}
									></Input>
								</Stack>
								<Stack
									direction="row"
									className="ModelProfilePage_row"
									spacing={1}
								>
									<Typography
										gutterBottom
										component="div"
										className="Blog_elements"
									>
										description
									</Typography>
									<Input
										className="inputBoxProfile"
										name="description"
										disableUnderline="true"
										style={{ backgroundColor: "#292929" }}
										onChange={handleAddForm}
									></Input>
								</Stack>
								<Stack
									direction="row"
									className="ModelProfilePage_row"
									spacing={1}
								>
									<Typography
										gutterBottom
										component="div"
										className="Blog_elements"
									>
										slug
									</Typography>
									<Input
										className="inputBoxProfile"
										name="slug"
										disableUnderline="true"
										style={{ backgroundColor: "#292929" }}
										onChange={handleAddForm}
									></Input>
								</Stack>
								<Stack
									direction="row"
									className="ModelProfilePage_row"
									spacing={1}
								>
									<Typography
										gutterBottom
										component="div"
										className="Blog_elements"
									>
										category
									</Typography>
									<Autocomplete
										className="ScheduleInputStatus"
										disablePortal
										id="combo-box-demo"
										disableClearable
										options={
											blog_category && blog_category?.blog_categories
												? blog_category?.blog_categories
												: []
										}
										getOptionLabel={(option) => option.name}
										renderInput={(params) => (
											<TextField
												{...params}
												disabled
												variant="standard"
												name="role"
											/>
										)}
										onChange={handleAutocompleteChange}
									/>
								</Stack>
							</Box>
						</Grid>
					</Grid>
					<Grid spacing={1} className="userProfile_grid">
						<Grid
							item
							xs={12}
							style={{
								minWidth: "100%",
								display: "flex",
								flexDirection: "column",
								justifyContent: "center"
							}}
						>
							<Stack
								direction="row"
								className="ModelProfilePage_row"
								spacing={1}
							>
								<TagsInput
									value={selected}
									onChange={setSelected}
									name="tags"
									placeHolder="enter tags"
								/>
							</Stack>
							<Stack direction="row" className="Blog_content" spacing={1}>
								<Typography
									gutterBottom
									component="div"
									className="Blog_elements"
								></Typography>
								<ReactQuill
									theme="snow"
									modules={modules}
									value={value}
									onChange={setValue}
								/>
							</Stack>
						</Grid>
					</Grid>
					<Grid spacing={1} className="userProfile_grid">
						<Grid
							item
							xs={6}
							style={{
								width: "100%",
								display: "flex",
								flexDirection: "row",
								justifyContent: "center"
							}}
						>
							<Button
								onClick={handleAddFormSubmit}
								size="small"
								variant="contained"
								style={{
									color: "black",
									backgroundColor: "#FFD800",
									maxWidth: "100px",
									maxHeight: "33px",
									minWidth: "100px",
									minHeight: "33px",
									borderRadius: "4px"
								}}
								className="create"
							>
								Save
							</Button>
						</Grid>
					</Grid>
				</Container>
			</Box>
		</>
	);
}
