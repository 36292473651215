import { DELETE_USER_SUBSCRIPTIONS, USER_SUBSCRIPTIONS } from "../types";

export const UserSubscriptionsReducer = (state = {}, action) => {
	const { type, payload } = action;

	switch (type) {
		case USER_SUBSCRIPTIONS: {
			const { data } = payload;
			return data;
		}
		case DELETE_USER_SUBSCRIPTIONS: {
			return {};
		}
		default: {
			return state;
		}
	}
};
