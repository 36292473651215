import { TUBE, TUBE_PAGINATION, TUBE_VIDEO, TUBE_VIDEO_UPLOAD } from "../types";

export const TubeReducer = (state = {}, action) => {
	const { type, payload } = action;

	switch (type) {
		case TUBE: {
			const { data } = payload;
			return data;
		}
		case TUBE_PAGINATION: {
			const { data } = payload;
			const { media: newMedia, pagination: newPagination } = data;

			return {
				...state, // preserve the existing state properties (like filters)
				media: [...state.media, ...newMedia], // append new media to the existing media array
				pagination: { ...newPagination } // update the pagination object with the new one
			};
		}
		case TUBE_VIDEO: {
			const { data } = payload;

			if (state.media && state.media.length > 0) {
				const updatedMedia = state.media.map((mediaItem) => {
					if (mediaItem.id === data.id) {
						return data; // Replace old mediaItem with the new one
					}
					return mediaItem;
				});

				return { ...state, media: updatedMedia };
			}

			return state;
		}
		case TUBE_VIDEO_UPLOAD: {
			const { data } = payload;

			// Add the new video object to the media list
			const updatedMedia = state.media ? [data, ...state.media] : [data];

			return { ...state, media: updatedMedia };
		}

		default: {
			return state;
		}
	}
};
