import * as React from "react";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import {
	Autocomplete,
	Box,
	Container,
	Input,
	List,
	Select,
	Skeleton,
	Stack,
	TableRow,
	TextField,
	Typography
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { createTheme, styled, ThemeProvider } from "@mui/material/styles";
import { connect, useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { MenuItem } from "@material-ui/core";
import { allGalleries, OneGalery } from "../../redux/actions/GalleriesActions";
import {
	API_FETCH_CREATE_GALLERIE_ROUTE,
	API_FETCH_GALLERIES_ROUTE
} from "../../utils/consts";
import { setLoading, setMessage } from "../../redux/reducers/rootReducer";
import { useNavigate } from "react-router-dom";
import GalleryCard from "./GalleryCard";

const mapStateToProps = (state) => {
	return {
		performers: state.models,
		galleries: state.galleries,
		gallery: state.gallery
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		GetGalleries: () => dispatch(GetGalleries())
	};
};

function Galleries({ galleries, gallery, admin }) {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const GetGalleries = async () => {
		dispatch(setLoading({ camera: "gallery", type: true }));
		try {
			const options = {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
					Authorization: `Bearer ${sessionStorage.getItem("token")}`
				}
			};
			const response = await fetch(
				process.env.REACT_APP_BACKEND_URL + API_FETCH_GALLERIES_ROUTE,
				options
			);
			//credentials: 'include',

			const data = await response.json();
			if (response.status >= 200 && response.status <= 299) {
				dispatch(allGalleries(data.data));
				dispatch(setLoading({ camera: "gallery", type: false }));
			} else {
				// dispatch(userLogout());
				// sessionStorage.removeItem("token");
			}
		} catch (error) {
			console.log(error);
			dispatch(setLoading({ camera: "gallery", type: false }));
		}
	};
	const CreateGallery = async (item) => {
		dispatch(setLoading({ camera: "gallery", type: true }));
		try {
			const options = {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
					Authorization: `Bearer ${sessionStorage.getItem("token")}`
				},
				body: JSON.stringify(item)
				//credentials: 'include',
			};
			const response = await fetch(
				process.env.REACT_APP_BACKEND_URL + API_FETCH_CREATE_GALLERIE_ROUTE,
				options
			);
			//response.data
			const data = await response.json();

			if (data.type == "success") {
				dispatch(setMessage(data.message, data.type));
				dispatch(GetGallery(data.data.gallery.id));
			}
		} catch (error) {
			console.log(error);
			dispatch(setLoading({ camera: "gallery", type: false }));
		}
	};
	const GetGallery = async (id) => {
		dispatch(setLoading({ camera: "gallery", type: true }));
		try {
			const options = {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
					Authorization: `Bearer ${sessionStorage.getItem("token")}`
				}
				//credentials: 'include',
			};
			const response = await fetch(
				process.env.REACT_APP_BACKEND_URL +
					API_FETCH_CREATE_GALLERIE_ROUTE +
					"/" +
					id,
				options
			);
			//response.data
			const data = await response.json();

			if (data.type == "success") {
				dispatch(OneGalery(data.data));
				navigate("/gallery/" + id);
				dispatch(setLoading({ camera: "gallery", type: false }));
			}
		} catch (error) {
			console.log(error);
			dispatch(setLoading({ camera: "gallery", type: false }));
		}
	};

	React.useEffect(() => {
		GetGalleries();
	}, []);

	// React.useEffect(() => {
	//     gallery &&gallery.gallery && gallery.gallery.id && navigate('/gallery/' + gallery.gallery.id)
	// }, [gallery])

	const theme = createTheme({
		palette: {
			success: {
				// Purple and green play nicely together.
				main: "#78F73B"
			},
			error: {
				// This is green.A700 as hex.
				main: "#F73B3B"
			}
		}
	});

	const [inputList, setInputList] = React.useState({
		description: "",
		metaDescription: "",
		tags: ""
	});
	const handleAddClick = () => {
		setInputList([
			...inputList,
			{ description: "", metaDescription: "", tags: "" }
		]);
	};
	const handleInputChange = (e) => {
		const fieldName = event.target.getAttribute("name");
		const fieldValue = event.target.value;
		const newFormData = { ...inputList };
		newFormData[fieldName] = fieldValue;
		setInputList(newFormData);
	};

	const [characterLimit] = React.useState(70);
	const all_events = galleries && galleries.filters && galleries.filters.events;
	const all_models = galleries && galleries.filters && galleries.filters.models;
	const [selectedEvents, setSelectedEvents] = React.useState("");
	const [selectedHousemate, setSelectedHousemate] = React.useState("");
	const [addFormData, setAddFormData] = React.useState([]);

	const handleAutocompleteChange = (target) => {
		var task_names = target.target.value;
		setSelectedEvents(task_names);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		let obj;
		if (selected && selected === "Event") {
			obj = {
				name: inputList.description,
				selected_type: selected,
				selected_id: selectedEvents,
				metaDescription: inputList.metaDescription,
				metaTags: selectedTags
			};
		}
		if (selected && selected === "Housemate") {
			obj = {
				name: inputList.description,
				selected_type: selected,
				selected_id: selectedHousemate,
				metaDescription: inputList.metaDescription,
				metaTags: selectedTags
			};
		}
		if (selected && selected === "Main") {
			obj = {
				name: inputList.description,
				selected_type: selected,
				metaDescription: inputList.metaDescription,
				metaTags: selectedTags
			};
		}

		dispatch(CreateGallery(obj));
		setInputList({ description: "" });
	};
	const [open, setOpen] = React.useState(false);

	const handleOpen = () => {
		setOpen(true);
	};
	const handleClose = () => setOpen(false);

	const style = {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		maxWidth: 1200,
		minWidth: 850,
		minHeight: 350,
		maxHeight: 500,
		/* width: '100%', */
		bgcolor: "background.paper",
		textAlign: "center",
		margin: "0 auto",
		p: 4
	};

	const StyledTableRow = styled(TableRow)(({ theme }) => ({
		"&:nth-of-type(odd)": {
			backgroundColor: "rgb(0 0 0)"
		},
		"&:nth-of-type(even)": {
			backgroundColor: "#ffffff14"
		},
		// hide last border
		"&:last-child td, &:last-child th": {
			border: 0
		},
		"&.MuiTableRow-root:hover": {
			backgroundColor: "#555555"
		}
	}));

	const StyledTableCell = styled(TableCell)(({ theme }) => ({
		[`&.${tableCellClasses.head}`]: {
			/*    backgroundColor: "#292929", */
			color: theme.palette.common.white
		},
		[`&.${tableCellClasses.body}`]: {
			fontSize: 14
		}
	}));

	function getTimeStamp(timeCreated, TimeEnded) {
		const endDate = moment(TimeEnded);
		const startDate = moment(timeCreated);
		const diff = endDate - startDate;
		var diffDuration = moment.duration(diff);
		const days = diffDuration.days();
		const hours = diffDuration.hours() + days * 24; // get hours with getHours method
		const minutes = diffDuration.minutes();
		const seconds = diffDuration.seconds(); // get minutes with getMinutes method // get seconds with getSeconds method
		const timeString = `${pad(hours)}h ${pad(minutes)}m ${pad(seconds)}s`;
		return timeString; // finally, join to a time string
	}

	function pad(num) {
		return ("0" + num).slice(-2);
	}

	const currencies = [
		{
			value: "Main",
			label: "$"
		},
		{
			value: "Event",
			label: "€"
		},
		{
			value: "Housemate",
			label: "฿"
		}
	];
	const [selected, setSelected] = React.useState("Main");
	const handleChangeCampaign = (event) => {
		setSelected(event.target.value);
	};

	const handleChangeEvent = (event) => {
		setSelectedEvents(event.target.value);
	};

	const handleChangeHousemate = (target) => {
		var task_names = target.target.value;
		setSelectedHousemate(task_names);
	};
	const [selectedTags, setSelectedTags] = React.useState("");

	const loading = useSelector((state) => state.loading);
	return (
		<>
			<Stack
				spacing={3}
				sx={{ width: 1300, marginLeft: "290px", marginTop: "40px" }}
			>
				{loading && loading.isLoading == true ? (
					<div className="loader-container">
						<div className="spinner"></div>
					</div>
				) : (
					""
				)}
				<Box
					style={{ marginTop: loading && loading.isLoading == true && "0px" }}
				>
					<Grid
						container
						spacing={0}
						style={{ display: "flex", flexDirection: "column" }}
					>
						<Grid item xs={4} md={2} spacing={0} className="Pools">
							<Typography
								variant="h4"
								gutterBottom
								component="div"
								style={{ color: "white", marginBottom: "unset" }}
							>
								Media Bank
							</Typography>
						</Grid>
						<Box
							component="div"
							sx={{
								flexGrow: 1,
								maxWidth: "650px",
								margin: "0",
								height: "100%"
								/* minHeight: "500px", */
							}}
						>
							<Typography style={{ color: "white", fontSize: "1.3rem" }}>
								Create Gallery
							</Typography>
							<Container
								maxWidth="xl"
								style={{
									color: "#ffffff",
									width: "100%",
									height: "100%",
									backgroundColor: "#000000",
									borderRadius: "5px"
								}}
								sx={{ my: 3 }}
								className="PoolContainer"
							>
								<Grid spacing={1} className="Pool_wrap">
									<Grid item xs={12}>
										<Box component="div" className="Pool_main">
											<Stack direction="row" className="Pools_row">
												<Input
													value={inputList.description}
													name="description"
													onChange={(e) => {
														handleInputChange(e);
													}}
													placeholder="Title"
													className="Pools_input_text"
													disableUnderline="true"
													inputProps={{ maxLength: 70 }}
												></Input>
												<Typography
													color={
														inputList.description.length >= characterLimit
															? "red"
															: "white"
													}
													style={{ position: "absolute", left: "890px" }}
												>
													{inputList.description.length}/{characterLimit}
												</Typography>
											</Stack>
											<Stack className="Pools_row">
												<Select
													className="PayoutSelect"
													value={selected && selected}
													onChange={handleChangeCampaign}
													displayEmpty
													renderValue={(value) => {
														return (
															<Box sx={{ display: "flex", gap: 1 }}>
																<div
																	style={{
																		display: "flex",
																		width: "-webkit-fill-available",
																		justifyContent: "center"
																	}}
																>
																	{value}
																</div>
															</Box>
														);
													}}
												>
													{currencies.map((option) => (
														<MenuItem
															style={{ textAlign: "center" }}
															key={option.value}
															value={option.value}
														>
															{option.value}
														</MenuItem>
													))}
												</Select>
												{selected && selected == "Event" && (
													// <Select
													//   className="PayoutSelect"
													//   value={selectedEvents && selectedEvents}
													//   onChange={handleChangeEvent}
													//   displayEmpty
													//   style={{ marginLeft: "10px" }}
													//   renderValue={(value) => {
													//     return (
													//       <Box sx={{ display: "flex", gap: 1 }}>
													//         <div
													//           style={{
													//             display: "flex",
													//             width: "-webkit-fill-available",
													//             justifyContent: "center",
													//           }}
													//         >
													//           {all_events[value]}
													//         </div>
													//       </Box>
													//     );
													//   }}
													// >
													//   {Object.keys(all_events).map((option) => (
													//     <MenuItem
													//       style={{ textAlign: "center" }}
													//       key={option}
													//       value={option}
													//     >
													//       {all_events[option]}
													//     </MenuItem>
													//   ))}
													// </Select>
													<Autocomplete
														className="GalleryInput"
														name="participants"
														id="id"
														style={{
															width: "40%",
															marginLeft: "10px",
															height: "35px"
														}}
														onChange={(event, value) => {
															handleAutocompleteChange({
																target: {
																	value: value
																}
															});
														}}
														options={Object.keys(all_events && all_events)}
														getOptionLabel={(option) =>
															all_events[option] || ""
														}
														renderInput={(params) => (
															<TextField
																{...params}
																InputProps={{
																	...params.InputProps,
																	disableUnderline: true
																}}
																variant="standard"
																name="participants"
															/>
														)}
													/>
												)}
												{selected && selected == "Housemate" && (
													<Autocomplete
														className="GalleryInput"
														name="participants"
														id="id"
														style={{
															width: "40%",
															marginLeft: "10px",
															height: "35px"
														}}
														onChange={(event, value) => {
															handleChangeHousemate({
																target: {
																	value: value
																}
															});
														}}
														options={Object.keys(all_models && all_models)}
														getOptionLabel={(option) =>
															all_models[option] || ""
														}
														renderInput={(params) => (
															<TextField
																{...params}
																InputProps={{
																	...params.InputProps,
																	disableUnderline: true
																}}
																variant="standard"
																name="participants"
															/>
														)}
													/>
												)}
											</Stack>
											<Stack direction="row" className="Pools_row" spacing={1}>
												<Autocomplete
													multiple
													id="tags-standard"
													freeSolo={true}
													fullWidth
													onChange={(event, value) => setSelectedTags(value)}
													options={[]}
													sx={{
														backgroundColor: "#1d1d1d",
														borderRadius: "4px",
														textAlign: "left",
														fontSize: "14px"
													}}
													className="tags-input"
													renderInput={(params) => (
														<TextField
															{...params}
															variant="standard"
															placeholder="Input tags"
															placeholderStyle={{ color: "red" }}
															sx={{
																borderRadius: "4px",
																padding: "0px 10px 0px 5px",
																textAlign: "left",
																fontSize: "14px"
															}}
														/>
													)}
												/>
											</Stack>
											<Stack direction="row" className="Pools_row">
												<Input
													value={inputList.metaDescription}
													name="metaDescription"
													multiline
													onChange={(e) => {
														handleInputChange(e);
													}}
													style={{ padding: "5px", height: "unset" }}
													placeholder="Meta Description"
													className="Pools_input_text"
													disableUnderline="true"
												></Input>
											</Stack>
											<Stack direction="row" className="Galleries_row">
												<ThemeProvider theme={theme}>
													<Button
														size="medium"
														variant="outlined"
														/* color="success" */
														style={{
															paddingLeft: "15px",
															marginLeft: "10px",
															maxWidth: "90px",
															border: "1px solid #78f73b80",
															color: "#78F73B",
															padding: "5px 15px",
															borderRadius: "4px"
														}}
														color={"success"}
														onClick={(e) => {
															handleSubmit(e);
														}}
													>
														{"Create"}
													</Button>
												</ThemeProvider>
											</Stack>
										</Box>
									</Grid>
								</Grid>
							</Container>
						</Box>
					</Grid>
				</Box>
				<Typography style={{ color: "white", fontSize: "1.3rem" }}>
					Existing Galleries
				</Typography>
				<Paper
					style={{
						display: "flex",
						width: "100%",
						flexDirection: "column",
						flexWrap: "wrap",
						marginBottom: "100px" // Add margin between groups
					}}
				>
					<List className="AllRoomBlock_list">
						{!(galleries && galleries.galleries) &&
							[...Array(8)].map((e, i) => (
								<Skeleton
									key={"skelet-l-" + i}
									width={150}
									height={90}
									style={{ margin: "5px" }}
								/>
							))}
						<Box
							style={{
								display: "flex",
								width: "100%",
								flexWrap: "wrap"
							}}
						>
							{galleries &&
								galleries.galleries &&
								galleries.galleries.map((gallery) => {
									return (
										<GalleryCard
											key={gallery.id}
											gallery={gallery}
											admin={admin}
										/>
									);
								})}
						</Box>
					</List>
				</Paper>
			</Stack>
		</>
	);
}

export default connect(mapStateToProps, mapDispatchToProps)(Galleries);
