import React from "react";
import { Container, Box } from "@mui/material";
import styled from "@emotion/styled";
import LoginForm from "../LoginForm/LoginForm";
import Logo from "../../assets/lgbg.svg";
import Logo2 from "../../assets/lgbgsmall.svg";
import Warehouse from "../../assets/lglogo.svg";
import { Avatar } from "@mui/material";

const RootStyle = styled("div")({
	background: "rgb(249, 250, 251)",
	height: "100vh",
	display: "grid",
	placeItems: "center"
});

const ContentStyle = styled("div")({
	width: "450px",
	height: "480px",
	padding: 25,
	display: "flex",
	justifyContent: "center",
	flexDirection: "row",
	backgroundColor: "#000000"
});

let easing = [0.6, -0.05, 0.01, 0.99];
const fadeInUp = {
	initial: {
		y: 60,
		opacity: 0,
		transition: { duration: 0.6, ease: easing }
	},
	animate: {
		y: 0,
		opacity: 1,
		transition: {
			duration: 0.6,
			ease: easing
		}
	}
};

const Login = () => {
	return (
		<RootStyle
			style={{
				backgroundImage: `url(${Logo})`,
				backgroundRepeat: "no-repeat",
				backgroundSize: "cover"
			}}
		>
			<Container
				style={{
					justifyContent: "center",
					width: "850px",
					paddingLeft: "0px",
					paddingRight: "0px",
					boxShadow: "0px 0px 20px #0000009B",
					borderRadius: "5px"
				}}
			>
				<Box
					style={{
						width: "400px",
						height: "480px",
						backgroundImage: `url(${Logo2})`,
						backgroundColor: "#292929",
						display: "flex",
						flexDirection: "column",
						justifyContent: "center",
						alignItems: "center"
					}}
				>
					<Avatar
						sx={{
							display: { xs: "none", md: "flex" },
							mr: 0.5,
							width: "300px",
							marginBottom: "20px",
							justifyContent: "center",
							height: "80px"
						}}
						variant="square"
						src={Warehouse}
					/>
				</Box>
				<ContentStyle>
					<LoginForm />
				</ContentStyle>
			</Container>
		</RootStyle>
	);
};

export default Login;
