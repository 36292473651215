import CryptoJS from "crypto-js";

export function getUrlWithParams(url, params) {
	if (params && isObjectNotEmpty(params)) {
		const queryString = new URLSearchParams(params).toString();
		return `${url}?${queryString}`;
	}
	return url;
}

export function isObjectNotEmpty(variable) {
	return (
		typeof variable === "object" &&
		variable !== null &&
		Object.keys(variable).length > 0
	);
}

export function encrypt(text) {
	try {
		const secretKey = CryptoJS.enc.Hex.parse(
			process.env.REACT_APP_ENCRYPTION_SECRET_KEY
		);
		// Generating a random IV
		const iv = CryptoJS.lib.WordArray.random(128 / 8);

		const encrypted = CryptoJS.AES.encrypt(text, secretKey, {
			iv: iv,
			mode: CryptoJS.mode.CBC,
			padding: CryptoJS.pad.Pkcs7
		});

		// Prepending IV to the encrypted data and converting to hex
		return (
			iv.toString(CryptoJS.enc.Hex) +
			encrypted.ciphertext.toString(CryptoJS.enc.Hex)
		);
	} catch (e) {
		console.error(e);
		return null;
	}
}

export function decrypt(encryptedDataWithIV) {
	try {
		const secretKey = process.env.REACT_APP_ENCRYPTION_SECRET_KEY; // Ensure you're using the correct environment variable name
		const ivHex = encryptedDataWithIV.substring(0, 32);
		const encryptedData = encryptedDataWithIV.substring(32);
		const iv = CryptoJS.enc.Hex.parse(ivHex);
		const key = CryptoJS.enc.Hex.parse(secretKey);

		// Decrypting the data
		const decrypted = CryptoJS.AES.decrypt(
			{ ciphertext: CryptoJS.enc.Hex.parse(encryptedData) },
			key,
			{ iv: iv, mode: CryptoJS.mode.CBC, padding: CryptoJS.pad.Pkcs7 }
		);

		return decrypted.toString(CryptoJS.enc.Utf8);
	} catch (e) {
		console.error(e);
		return null;
	}
}
