import {
	API_ADMIN_MODEL_ADD_ROUTE,
	API_ADMIN_MODEL_DELETE_ROUTE,
	API_ADMIN_MODEL_EARNINGS_ROUTE,
	API_ADMIN_MODEL_ROUTE,
	API_ADMIN_MODEL_UPDATE_ROUTE,
	API_ADMIN_MODELS_EARNINGS_ROUTE,
	API_ADMIN_MODELS_ORDER_ROUTE,
	API_ADMIN_MODELS_ROUTE
} from "../../utils/consts";
import {
	DELETE_MODEL,
	DELETE_MODEL_MEDIA,
	MODEL,
	MODEL_EARNINGS,
	MODEL_MEDIA,
	MODELS,
	MODELS_EARNINGS,
	MODELS_ORDER,
	UPDATE_MODEL
} from "../types";
import { setError, setLoading, setMessage } from "../reducers/rootReducer";
import { userLogout } from "./userAction";
import axios from "../../utils/axios";
import { setMessages } from "./messageAction";

const allModels = (data) => {
	return {
		type: MODELS,
		payload: { data }
	};
};
const ModelsOrder = (data) => {
	return {
		type: MODELS_ORDER,
		payload: { data }
	};
};

const allModelsEarnings = (data) => {
	return {
		type: MODELS_EARNINGS,
		payload: { data }
	};
};
export const modelMedia = (data) => {
	return {
		type: MODEL_MEDIA,
		payload: { data }
	};
};

const oneModel = (data) => {
	return {
		type: MODEL,
		payload: { data }
	};
};
const oneModelEarnings = (data) => {
	return {
		type: MODEL_EARNINGS,
		payload: { data }
	};
};

const patchModel = (data) => {
	return {
		type: UPDATE_MODEL,
		payload: { data }
	};
};
export const delete_Model = (data) => {
	return {
		type: DELETE_MODEL,
		payload: { data }
	};
};
export const delete_Model_Media = (data) => {
	return {
		type: DELETE_MODEL_MEDIA,
		payload: { data }
	};
};

export const getModel = (id) => async (dispatch) => {
	dispatch(setLoading({ camera: "model", type: true }));
	try {
		const response = await fetch(
			process.env.REACT_APP_BACKEND_URL + API_ADMIN_MODEL_ROUTE + id,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
					Authorization: `Bearer ${sessionStorage.getItem("token")}`
				}
				//credentials: 'include',
			}
		);
		//response.data
		const data = await response.json();
		if (response.status >= 200 && response.status <= 299) {
			if (data.performer) {
				dispatch(oneModel(data));
				dispatch(setLoading({ camera: "model", type: false }));
			}
		} else {
			console.log("logMeOut", response.status, data);
			dispatch(setLoading({ camera: "model", type: false }));
			//dispatch(userLogout());
			//sessionStorage.removeItem("token");
		}
	} catch (error) {
		console.log(error);
		dispatch(setLoading({ camera: "model", type: false }));
	}
};
export const getModelEarnings = (id, month, year) => async (dispatch) => {
	dispatch(setLoading({ camera: "model", type: true }));
	let response = "";
	try {
		if ((month || year) == 0) {
			response = await fetch(
				process.env.REACT_APP_BACKEND_URL + API_ADMIN_MODEL_EARNINGS_ROUTE + id,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Accept: "application/json",
						Authorization: `Bearer ${sessionStorage.getItem("token")}`
					}
					//credentials: 'include',
				}
			);
		} else {
			response = await fetch(
				process.env.REACT_APP_BACKEND_URL +
					API_ADMIN_MODEL_EARNINGS_ROUTE +
					id +
					`?month=${month}&year=${year}`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Accept: "application/json",
						Authorization: `Bearer ${sessionStorage.getItem("token")}`
					}
					//credentials: 'include',
				}
			);
		}
		//response.data
		const data = await response.json();
		if (response.status >= 200 && response.status <= 299) {
			dispatch(oneModelEarnings(data.data));
			dispatch(setLoading({ camera: "model", type: false }));
		} else {
			console.log("logMeOut", response.status, data);
			dispatch(setLoading({ camera: "model", type: false }));
			//dispatch(userLogout());
			//sessionStorage.removeItem("token");
		}
	} catch (error) {
		console.log(error);
		dispatch(setLoading({ camera: "model", type: false }));
	}
};

export const getModels = () => async (dispatch) => {
	dispatch(setLoading({ camera: "model", type: true }));
	try {
		const options = {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: `Bearer ${sessionStorage.getItem("token")}`
			}
		};

		const response = await fetch(
			process.env.REACT_APP_BACKEND_URL + API_ADMIN_MODELS_ROUTE,
			options
		);

		const data = await response.json();
		if (response.status >= 200 && response.status <= 299) {
			dispatch(allModels(data.models));
			dispatch(setLoading({ camera: "model", type: false }));
		} else {
			dispatch(userLogout());
			sessionStorage.removeItem("token");
		}
	} catch (error) {
		console.log(error);
		dispatch(setLoading({ camera: "model", type: false }));
	}
};

export const updateModel = (item, id) => async (dispatch) => {
	dispatch(setLoading({ camera: "model", type: true }));
	try {
		let formData = new FormData();
		Object.keys(item).forEach((key) => formData.append(key, item[key]));
		/*     const options = {
              method: "POST",
              headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                'Authorization': `Bearer ${sessionStorage.getItem("token")}`,
            },
              body: JSON.stringify(item)
            }
            const response = await fetch('https://whxlbo-fr.warehouse-x.io' + API_ADMIN_MODEL_UPDATE_ROUTE + id, options) */
		const res = await axios.post(
			process.env.REACT_APP_BACKEND_URL + API_ADMIN_MODEL_UPDATE_ROUTE + id,
			formData,
			{
				withCredentials: true,
				headers: {
					Authorization: `Bearer ${sessionStorage.getItem("token")}`,
					accept: "application/json",
					"Content-Type": `multipart/form-data; boundary=${formData._boundary}`
				}
			}
		);
		/* const data = await response.json(); */
		/*  if (response.status >= 200 && response.status <= 299) {
          console.log(res.data.data); */
		if (res.status >= 200 && res.status <= 299) {
			const data = res.data;
			dispatch(patchModel(data));
			// dispatch(getModelsEarnings());
			dispatch(setMessage(data.message, "success"));
			/*          history.back();*/
			dispatch(setLoading({ camera: "model", type: false }));
		}
		/*  } else {
          dispatch(userLogout());
          sessionStorage.removeItem("token");
        } */
	} catch (error) {
		dispatch(setError(error));
		dispatch(setLoading({ camera: "model", type: false }));
	}
};

export const deleteModel = (id) => async (dispatch) => {
	dispatch(setLoading({ camera: "model", type: true }));
	try {
		const response = await fetch(
			process.env.REACT_APP_BACKEND_URL + API_ADMIN_MODEL_DELETE_ROUTE + id,
			{
				method: "DELETE",
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
					Authorization: `Bearer ${sessionStorage.getItem("token")}`
				}
				//credentials: 'include',
			}
		);
		//response.data
		const data = await response.json();
		if (response.status >= 200 && response.status <= 299) {
			dispatch(delete_Model());
			dispatch(getModels());
			dispatch(setMessage(data.message, data.type));
			history.back();
			dispatch(setLoading({ camera: "model", type: false }));
		} else {
			dispatch(setError(data.message));
			dispatch(setLoading({ camera: "model", type: false }));
			/* dispatch(userLogout());
            sessionStorage.removeItem("token"); */
		}
	} catch (error) {
		setMessage(error, "error");
		dispatch(setLoading({ camera: "model", type: false }));
	}
};

export const deleteMedia = (id, item) => async (dispatch) => {
	try {
		const response = await fetch(
			`${process.env.REACT_APP_BACKEND_URL}/api/v1/models/media/` +
				item +
				"/delete/" +
				id,
			{
				method: "DELETE",
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
					Authorization: `Bearer ${sessionStorage.getItem("token")}`
				}
				//credentials: 'include',
			}
		);
		//response.data
		const data = await response.json();
		console.log(data);
		dispatch(setMessage(data.message, data.type));
		dispatch(allModels(data.models));
		getModels();
	} catch (error) {
		setMessage(error, "error");
	}
};

export const addModel = (item) => async (dispatch) => {
	dispatch(setLoading({ camera: "model", type: true }));
	try {
		let formData = new FormData();
		Object.keys(item).forEach((key) => formData.append(key, item[key]));
		/*     const options = {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: `Bearer ${sessionStorage.getItem("token")}`,
              },
              //credentials: 'include',
              body: JSON.stringify(item),
            }; */
		const res = await axios.post(
			process.env.REACT_APP_BACKEND_URL + API_ADMIN_MODEL_ADD_ROUTE,
			formData,
			{
				withCredentials: true,
				headers: {
					Authorization: `Bearer ${sessionStorage.getItem("token")}`,
					Accept: "application/json",
					"Content-Type": `multipart/form-data; boundary=${formData._boundary}`
				}
			}
		);

		/* const response = await fetch(
          "https://whxlbo-fr.warehouse-x.io" + API_ADMIN_MODEL_ADD_ROUTE,
          options
        ); */
		if (res.status >= 200 && res.status <= 299) {
			const data = res.data;
			dispatch(oneModel(data));
			// dispatch(getModelsEarnings());
			dispatch(setMessage(res.data.message, res.data.type));
			history.back();
			dispatch(setLoading({ camera: "model", type: false }));
		} else {
			if (res.data.length > 1) {
				dispatch(setMessages(res.data));
			} else {
				dispatch(setMessage(res.data, "error"));
			}
		}
	} catch (error) {
		setMessage(error, "error");
		dispatch(setLoading({ camera: "model", type: false }));
	}
};

export const getModelMedia = (id) => async (dispatch) => {
	try {
		const response = await fetch(
			process.env.REACT_APP_BACKEND_URL + "/api/v1/models/" + id + "/media",
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
					Authorization: `Bearer ${sessionStorage.getItem("token")}`
				}
				//credentials: 'include',
			}
		);
		//response.data
		const data = await response.json();
		if (data) {
			dispatch(modelMedia(data));
		}
	} catch (error) {
		console.log(error);
	}
};

export const getModelsEarnings = () => async (dispatch) => {
	dispatch(setLoading({ camera: "model", type: true }));
	try {
		const options = {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: `Bearer ${sessionStorage.getItem("token")}`
			}
		};

		const response = await fetch(
			process.env.REACT_APP_BACKEND_URL + API_ADMIN_MODELS_EARNINGS_ROUTE,
			options
		);

		const data = await response.json();
		if (response.status >= 200 && response.status <= 299) {
			dispatch(allModelsEarnings(data.data.earnings));
			dispatch(setLoading({ camera: "model", type: false }));
		} else {
			dispatch(userLogout());
			sessionStorage.removeItem("token");
		}
	} catch (error) {
		console.log(error);
		dispatch(setLoading({ camera: "model", type: false }));
	}
};
export const getModelsOrder = () => async (dispatch) => {
	dispatch(setLoading({ camera: "model", type: true }));
	try {
		const options = {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: `Bearer ${sessionStorage.getItem("token")}`
			}
		};
		const response = await fetch(
			process.env.REACT_APP_BACKEND_URL + API_ADMIN_MODELS_ORDER_ROUTE,
			options
		);
		//credentials: 'include',

		const data = await response.json();
		if (response.status >= 200 && response.status <= 299) {
			dispatch(ModelsOrder(data.data));
			dispatch(setLoading({ camera: "model", type: false }));
		}
	} catch (error) {
		console.log(error);
		dispatch(setLoading({ camera: "model", type: false }));
	}
};
export const postModelsOrder = (item) => async (dispatch) => {
	dispatch(setLoading({ camera: "model", type: true }));
	try {
		const options = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: `Bearer ${sessionStorage.getItem("token")}`
			},
			body: JSON.stringify(item)
		};
		const response = await fetch(
			process.env.REACT_APP_BACKEND_URL + API_ADMIN_MODELS_ORDER_ROUTE,
			options
		);
		//credentials: 'include',

		const data = await response.json();
		if (response.status >= 200 && response.status <= 299) {
			dispatch(setMessage(data.message, data.type));
			dispatch(setLoading({ camera: "model", type: false }));
		}
	} catch (error) {
		console.log(error);
		dispatch(setLoading({ camera: "model", type: false }));
	}
};

export const getActiveModels = () => async (dispatch) => {
	dispatch(setLoading({ camera: "model", type: true }));
	try {
		const options = {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: `Bearer ${sessionStorage.getItem("token")}`
			}
		};

		const response = await fetch(
			process.env.REACT_APP_BACKEND_URL + API_ADMIN_MODELS_ROUTE + "/active",
			options
		);

		const data = await response.json();
		if (response.status >= 200 && response.status <= 299) {
			dispatch(allModels(data?.data?.models));
			dispatch(setLoading({ camera: "model", type: false }));
		} else {
			dispatch(userLogout());
			sessionStorage.removeItem("token");
		}
	} catch (error) {
		console.log(error);
		dispatch(setLoading({ camera: "model", type: false }));
	}
};
