import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import "../../App";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useNavigate, useParams } from "react-router-dom";
import { Container, Input } from "@mui/material";
import { connect, useDispatch, useSelector } from "react-redux";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import {
	delete_Promo,
	GetPromo,
	TerminatePromo
} from "../../redux/actions/PromosActions";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import axios from "axios";
import fileDownload from "js-file-download";
import { API_FETCH_PROMO_ROUTE } from "../../utils/consts";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 400,
	bgcolor: "background.paper",
	border: "2px solid #000",
	textAlign: "center",
	margin: "0 auto",
	p: 4
};
const style_confirm = {
	position: "absolute",
	top: "10%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 400,
	bgcolor: "background.paper",
	border: "2px solid #000",
	textAlign: "center",
	margin: "0 auto",
	p: 4
};

const mapStateToProps = (state) => {
	return {
		promo: state.promo
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		GetPromo: (id) => dispatch(GetPromo(id)),
		RemovePromo: (id) => dispatch(delete_Promo(id))
	};
};

function Promo({ promo, GetPromo, RemovePromo, admin }) {
	const dispatch = useDispatch();
	const param = useParams();
	const navigate = useNavigate();

	React.useEffect(() => {
		RemovePromo(param.id);
		GetPromo(param.id);
	}, []);
	const [selectedOptions, setSelectedOptions] = React.useState([]);

	const handleAutocompleteChange = (event, value) => {
		event.preventDefault();
		setSelectedOptions(value.title);
		const newFormData = { ...addFormData };
		newFormData["role"] = value.title;
		setAddFormData(newFormData);
	};
	const [inputList, setInputList] = React.useState({
		title: "",
		description: "",
		issued_count: "",
		credits: "",
		days_access: "",
		startDate: "",
		endDate: ""
	});

	React.useEffect(() => {
		setInputList({
			title: promo && promo.title,
			description: promo && promo.description,
			issued_count: promo && promo.issued_count,
			credits: promo && promo.bonusData && promo.bonusData.credits,
			days_access: promo && promo.bonusData && promo.bonusData.days_access,
			startDate: promo && promo.valid_from,
			endDate: promo && promo.valid_to
		});
	}, [promo]);

	const theme = createTheme({
		palette: {
			success: {
				// Purple and green play nicely together.
				main: "#78F73B"
			},
			error: {
				// This is green.A700 as hex.
				main: "#F73B3B"
			}
		}
	});

	function returnToUsers(event) {
		event.preventDefault();
		navigate("/promos");
	}

	const handleDownload = (url, filename) => {
		axios
			.get(url, {
				responseType: "blob",
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
					Authorization: `Bearer ${sessionStorage.getItem("token")}`
				}
			})
			.then((res) => {
				fileDownload(res.data, filename);
			});
	};

	const handleInputChange = (event) => {
		event.preventDefault();
		const fieldName = event.target.getAttribute("name");
		const fieldValue = event.target.value;
		const newFormData = { ...inputList };
		newFormData[fieldName] = fieldValue;
		setInputList(newFormData);
	};
	const [characterLimit] = React.useState(70);

	const handleSubmit = (e, index) => {
		e.preventDefault();
		dispatch(TerminatePromo(param.id));
		navigate("/promos");
	};
	const loading = useSelector((state) => state.loading);
	return (
		<>
			<Box
				spacing={3}
				sx={{ width: 1300, marginLeft: "290px", marginTop: "40px" }}
			>
				<Grid
					container
					spacing={0}
					style={{ display: "flex", flexDirection: "column" }}
				>
					{loading && loading.isLoading == true ? (
						<div className="loader-container">
							<div className="spinner"></div>
						</div>
					) : (
						""
					)}
					<Grid item xs={4} md={2} spacing={0} className="Pools">
						<Typography
							variant="h4"
							gutterBottom
							component="div"
							style={{ color: "white", marginBottom: "unset" }}
						>
							Ongoing Promo {promo && promo.title}
						</Typography>
						<Button
							/*  size="small" */
							variant="contained"
							style={{
								color: "black",
								backgroundColor: "#78F73B",
								maxWidth: "150px",
								maxHeight: "40px",
								minWidth: "150px",
								minHeight: "40px",
								borderRadius: "5px",
								textTransform: "unset",
								marginLeft: "20px",
								fontSize: "16px"
							}}
							onClick={() => {
								handleDownload(
									process.env.REACT_APP_BACKEND_URL +
										API_FETCH_PROMO_ROUTE +
										"/" +
										param.id +
										"/csv",
									promo && promo.title + ".txt"
								);
							}}
							className="create"
						>
							Download CSV
						</Button>
					</Grid>
					<Button
						startIcon={<ArrowBackIosIcon />}
						className="PromoPage_arrow"
						variant="outlined"
						onClick={returnToUsers}
					>
						All promos
					</Button>

					<Box
						component="div"
						sx={{
							flexGrow: 1,
							maxWidth: "650px",
							margin: "0",
							height: "100%"

							/* minHeight: "500px", */
						}}
					>
						<Container
							maxWidth="xl"
							style={{
								color: "#ffffff",
								width: "100%",
								height: "100%",
								backgroundColor: "#000000",
								borderRadius: "5px"
							}}
							sx={{ my: 3 }}
							className="PoolContainer"
						>
							<Grid spacing={1} className="Pool_wrap">
								<Grid item xs={12}>
									<Box component="div" className="Pool_main">
										<Stack direction="row" className="Pools_row">
											<Input
												name="title"
												onChange={handleInputChange}
												placeholder="Promo title"
												className="Pools_input_text"
												defaultValue={promo && promo.title}
												key={promo && promo.title}
												disableUnderline="true"
												inputProps={{ maxLength: 70 }}
											></Input>
											<Typography
												color={
													inputList &&
													inputList.title &&
													inputList.title.length >= characterLimit
														? "red"
														: "white"
												}
												style={{ position: "absolute", left: "890px" }}
											>
												{inputList &&
												inputList.title &&
												inputList.title.length > 0
													? inputList.title.length
													: 0}
												/{characterLimit}
											</Typography>
										</Stack>
										<Stack className="Pools_row">
											<Input
												name="description"
												onChange={handleInputChange}
												placeholder="Promo Text"
												className="Promos_input_text"
												defaultValue={promo && promo.description}
												key={promo && promo.description}
												disableUnderline="true"
												multiline
												/*    inputProps={{ maxLength: 70 }} */
											></Input>
											{/*   <Typography color={inputList.text.length >= characterLimit ? 'red' : 'white'} style={{ position: 'absolute', left: '890px' }}>
                                                            {inputList.text.length}/{characterLimit}
                                                        </Typography> */}
										</Stack>
										<Stack direction="row" className="Pools_row">
											<Typography width={"100px"}>Issue Amount</Typography>
											<Input
												name="issued_count"
												placeholder="Number"
												onChange={handleInputChange}
												className="Pools_input_credits"
												disableUnderline="true"
												defaultValue={promo && promo.issued_count}
												key={promo && promo.issued_count}
												inputProps={{ maxLength: 70 }}
											></Input>

											<Typography width={"60px"} marginLeft={"10px"}>
												Credits*
											</Typography>
											<Input
												name="credits"
												placeholder="Amount"
												onChange={handleInputChange}
												className="Pools_input_credits"
												disableUnderline="true"
												defaultValue={
													promo && promo.bonusData && promo.bonusData.credits
												}
												key={
													promo && promo.bonusData && promo.bonusData.credits
												}
												inputProps={{ maxLength: 70 }}
											></Input>
											<Typography width={"120px"} marginLeft={"10px"}>
												Camera Access*
											</Typography>
											<Input
												name="days_access"
												placeholder="Days"
												onChange={handleInputChange}
												className="Pools_input_credits"
												disableUnderline="true"
												defaultValue={
													promo &&
													promo.bonusData &&
													promo.bonusData.days_access
												}
												key={
													promo &&
													promo.bonusData &&
													promo.bonusData.days_access
												}
												inputProps={{ maxLength: 70 }}
											></Input>
										</Stack>

										<Stack
											direction="row"
											className="Pools_row"
											style={{ justifyContent: "space-between" }}
										>
											<div className="calendarWrap">
												<span
													style={{
														color: "white",
														paddingRight: "10px",
														paddingLeft: "10px"
													}}
												>
													From
												</span>
												<input
													name="startDate"
													defaultValue={promo && promo.valid_from}
													key={promo && promo.valid_from}
													readOnly
													className="inputBox"
												/>
												<span
													style={{
														color: "white",
														paddingRight: "10px",
														paddingLeft: "10px"
													}}
												>
													To
												</span>
												<input
													name="endDate"
													defaultValue={promo && promo.valid_to}
													key={promo && promo.valid_to}
													readOnly
													className="inputBox"
												/>
											</div>
											<ThemeProvider theme={theme}>
												<Button
													size="medium"
													variant="outlined"
													/* color="success" */
													style={{
														paddingLeft: "15px",
														marginLeft: "10px",
														maxWidth: "90px"
													}}
													color={
														promo && promo.is_active == 1 ? "error" : "success"
													}
													onClick={handleSubmit}
												>
													{promo && promo.is_active == 1
														? "Terminate"
														: "Enable"}
												</Button>
											</ThemeProvider>
										</Stack>
									</Box>
								</Grid>
							</Grid>
						</Container>
					</Box>
				</Grid>
			</Box>
		</>
	);
}

export default connect(mapStateToProps, mapDispatchToProps)(Promo);
