import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid";
import { useDispatch } from "react-redux";
import { hideModal, showModal } from "../../redux/reducers/rootReducer";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { Paper } from "@mui/material";
import { changeGalleryItemsOrder } from "../../redux/actions/GalleriesActions";
import GalleryCardModal from "./GalleryCardModal";
export default function GalleryReorderModal({ modal, items, type, id }) {
	const dispatch = useDispatch();

	const handleOpenPass = () => {
		{
			modal && modal.name == "sorder"
				? dispatch(hideModal("sorder"))
				: dispatch(showModal("sorder"));
		}
	};

	const style = {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		width: 400,
		bgcolor: "background.paper",
		border: "2px solid #000",
		textAlign: "center",
		margin: "0 auto",
		p: 4
	};
	const [itemList, setItemList] = React.useState(items && items);
	React.useEffect(() => {
		setItemList(items);
	}, [items]);

	const handleDrop = (droppedItem) => {
		// Ignore drop outside droppable container
		if (!droppedItem.destination) return;
		var updatedList = [...itemList];
		// Remove dragged item
		const [reorderedItem] = updatedList.splice(droppedItem.source.index, 1);
		// Add dropped item
		updatedList.splice(droppedItem.destination.index, 0, reorderedItem);
		// Update State
		setItemList(updatedList);
	};

	function changeHandle(value) {
		let finalArray = [];
		value.forEach((a) => {
			finalArray.push({
				id: a.id,
				sorder: a.sorder
			});
		});
		dispatch(
			changeGalleryItemsOrder(finalArray, type == "image" ? true : false, id)
		);
	}
	return (
		<Modal
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
			open={modal && modal.name == "sorder"}
			onClose={handleOpenPass}
		>
			<Box sx={style} className="modal18">
				<DragDropContext onDragEnd={handleDrop}>
					<Droppable droppableId="vertical-list">
						{(provided) => (
							<Paper
								style={{ width: "95%", minHeight: "400px", overflow: "auto" }}
							>
								<ul
									className="AllRoomBlock_list_modal"
									{...provided.droppableProps}
									ref={provided.innerRef}
								>
									{itemList &&
										itemList &&
										itemList.map((item, index) => {
											return (
												<div>
													<Draggable
														key={item.id.toString()}
														draggableId={item.id.toString()}
														index={index}
													>
														{(provided) => (
															<li
																className="draggable"
																{...provided.draggableProps}
																ref={provided.innerRef}
																{...provided.dragHandleProps}
															>
																<GalleryCardModal item={item} type={type} />
															</li>
														)}
													</Draggable>
												</div>
											);
										})}
								</ul>
								{provided.placeholder}
							</Paper>
						)}
					</Droppable>
				</DragDropContext>

				<Grid container spacing={0} className="modal18_grid">
					<Grid item xs={12} className="modal18_terms_grid">
						<Button
							size="small"
							variant="contained"
							type="submit"
							onClick={() => {
								changeHandle(itemList);
							}}
							style={{
								color: "black",
								backgroundColor: "#FFD800",
								maxWidth: "100px",
								maxHeight: "33px",
								minWidth: "100px",
								minHeight: "33px",
								borderRadius: "4px"
							}}
							className="create"
						>
							Save
						</Button>
					</Grid>
				</Grid>
			</Box>
		</Modal>
	);
}
