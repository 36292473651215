import {
	MODEL,
	UPDATE_MODEL,
	DELETE_MODEL,
	MODEL_MEDIA,
	DELETE_MODEL_MEDIA,
	MODEL_EARNINGS,
	MODELS_ORDER
} from "../types";

export const modelsOrderReducer = (state = {}, action) => {
	const { type, payload } = action;

	switch (type) {
		case MODELS_ORDER: {
			const { data } = payload;
			return data;
		}
		default: {
			return state;
		}
	}
};
