import {
	EVENTS,
	FETCH_MAGICS,
	FETCH_MAGICS_STATUS,
	MAGICS,
	PROMOS
} from "../types";

export const MagicsReducer = (state = [], action) => {
	const { type, payload } = action;

	switch (type) {
		case MAGICS: {
			const { data } = payload;
			state = [];
			return data;
		}
		case FETCH_MAGICS: {
			const { data } = payload;
			const newState = Object.assign([], state);
			let obj = newState.stages[data.magicx_stage_id - 1].tasks.find(
				(item) => item.id == data.id
			);
			const idx = newState.stages[data.magicx_stage_id - 1].tasks.indexOf(obj);
			newState.stages[data.magicx_stage_id - 1].tasks[idx] = data;
			return newState;
		}

		case FETCH_MAGICS_STATUS: {
			const { data } = payload;
			const newState = Object.assign([], state);
			let obj = newState.stages.find((item) => item.id == data.id);
			const idx = newState.stages.indexOf(obj);
			newState.stages[idx] = data;
			return newState;
		}
		default: {
			return state;
		}
	}
};
