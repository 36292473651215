import axios from "axios";
import {
	API_ADMIN_LOGIN_ROUTE,
	API_ADMIN_LOGOUT_ROUTE,
	API_ADMIN_USER_ADD_ROUTE,
	API_ADMIN_USER_DELETE_ROUTE,
	API_ADMIN_USER_ROUTE,
	API_ADMIN_USER_SUBSCRIPTION_CANCEL_ROUTE,
	API_ADMIN_USER_UNBAN_ROUTE,
	API_ADMIN_USER_UPDATE_ROUTE,
	API_ADMIN_USER_WARN_ROUTE,
	API_ADMIN_USERS_ROUTE,
	API_ADMIN_USERS_TRANSACTIONS_ROUTE
} from "../../utils/consts";
import { headers } from "../../utils/header";
import {
	AUTH_ADMIN_LOGIN,
	AUTH_ADMIN_LOGOUT,
	DELETE_TRANSACTION,
	DELETE_USER,
	DELETE_USER_SUBSCRIPTIONS,
	DELETE_USER_TRANSACTIONS,
	TIME_PICKER,
	UPDATE_USER,
	USER,
	USER_SUBSCRIPTIONS,
	USER_TRANSACTION,
	USER_TRANSACTIONS,
	USERS,
	USERS_TRANSACTIONS
} from "../types";
import {
	setError,
	setErrors,
	setLoading,
	setMessage
} from "../reducers/rootReducer";

const allUsers = (data) => {
	return {
		type: USERS,
		payload: { data }
	};
};
const allUsersTransactions = (data) => {
	return {
		type: USERS_TRANSACTIONS,
		payload: { data }
	};
};
const UserTransactions = (data) => {
	return {
		type: USER_TRANSACTIONS,
		payload: { data }
	};
};
const UserSubscriptions = (data) => {
	return {
		type: USER_SUBSCRIPTIONS,
		payload: { data }
	};
};
const userLogin = (data) => {
	return {
		type: AUTH_ADMIN_LOGIN,
		payload: { data }
	};
};

export const userLogout = (data) => {
	return {
		type: AUTH_ADMIN_LOGOUT,
		payload: { data }
	};
};

const oneUser = (data) => {
	return {
		type: USER,
		payload: { data }
	};
};
const oneTransaction = (data) => {
	return {
		type: USER_TRANSACTION,
		payload: { data }
	};
};
export const delete_transaction = (data) => {
	return {
		type: DELETE_TRANSACTION,
		payload: { data }
	};
};
const patchUser = (data) => {
	return {
		type: UPDATE_USER,
		payload: { data }
	};
};
export const User_date = (data) => {
	return {
		type: TIME_PICKER,
		payload: { data }
	};
};
export const delete_User = (data) => {
	return {
		type: DELETE_USER,
		payload: { data }
	};
};
export const deleteUserSubscriptions = (data) => {
	return {
		type: DELETE_USER_SUBSCRIPTIONS,
		payload: { data }
	};
};
export const deleteUserTransactions = (data) => {
	return {
		type: DELETE_USER_TRANSACTIONS,
		payload: { data }
	};
};

export const getUser = (id) => async (dispatch) => {
	dispatch(setLoading({ camera: "user", type: true }));
	try {
		const response = await fetch(
			process.env.REACT_APP_BACKEND_URL + API_ADMIN_USER_ROUTE + id,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
					Authorization: `Bearer ${sessionStorage.getItem("token")}`
				}
				//credentials: 'include',
			}
		);

		const data = await response.json();
		if (response.status >= 200 && response.status <= 299) {
			if (data.user) {
				dispatch(oneUser(data));
				dispatch(setLoading({ camera: "user", type: false }));
			}
		} else {
			if (response?.status == 401) {
				dispatch(userLogout());
				sessionStorage.removeItem("token");
			}
		}
	} catch (error) {
		console.log(error);
		dispatch(setLoading({ camera: "user", type: false }));
	}
};

export const getUsers =
	(
		page = null,
		limit = null,
		search_by = null,
		search = null,
		order_by = null,
		order_method = null,
		role = null
	) =>
	async (dispatch) => {
		dispatch(setLoading({ camera: "user", type: true }));
		try {
			const options = {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
					Authorization: `Bearer ${sessionStorage.getItem("token")}`
				}
			};
			const response = await fetch(
				process.env.REACT_APP_BACKEND_URL +
					API_ADMIN_USERS_ROUTE +
					"?page=" +
					page +
					"&limit=" +
					limit +
					"&search_by=" +
					search_by +
					"&search=" +
					search +
					"&order_by=" +
					order_by +
					"&order_method=" +
					order_method +
					"&role=" +
					role,
				options
			);

			//credentials: 'include')

			/* const data = await response.json() */
			if (response.status >= 200 && response.status <= 299) {
				const data = await response.json();
				dispatch(allUsers(data.data));
				dispatch(setLoading({ camera: "user", type: false }));
			}
		} catch (error) {
			console.log(error);
			dispatch(setLoading({ camera: "user", type: false }));
		}
	};

export const loginUser = (user) => async (dispatch) => {
	try {
		const options = {
			method: "POST",
			headers: headers,
			//credentials: 'include',
			body: JSON.stringify({
				email: user.email,
				password: user.password
			})
		};
		// sessionStorage.removeItem("token");
		// sessionStorage.removeItem("chat-data");
		// localStorage.removeItem("persist:root");
		const response = await fetch(
			process.env.REACT_APP_BACKEND_URL + API_ADMIN_LOGIN_ROUTE,
			options,
			{ withCredentials: true }
		);
		const data = await response.json();
		if (data.access_token) {
			dispatch(userLogin(data));
			sessionStorage.setItem("token", data.access_token);
			sessionStorage.setItem("chat-data", data.chat_data.kewb_access_token);
			setTimeout(() => {
				window.location.href = "/";
			}, 100);
		} else {
			dispatch(setMessage(data.message, "error"));
		}
	} catch (error) {
		setMessage(error, "error");
	}
};

export const logoutUser = (admin) => async (dispatch) => {
	const options = {
		method: "POST",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: `Bearer ${sessionStorage.getItem("token")}`
		}
	};
	dispatch(userLogout());
	const response = await fetch(
		process.env.REACT_APP_BACKEND_URL + API_ADMIN_LOGOUT_ROUTE,
		options
	);
	const data = await response.json();
	sessionStorage.removeItem("token");
	sessionStorage.removeItem("chat-data");
	localStorage.removeItem("persist:root");
	window.location.reload(false);
};

export const addNewUser = (item) => async (dispatch) => {
	dispatch(setLoading({ camera: "user", type: true }));
	try {
		let formData = new FormData();
		Object.keys(item).forEach((key) => formData.append(key, item[key]));
		/*     const options = {
              method: "POST",
              headers: {'Content-Type': 'application/json',
              "Accept": "application/json",
              'Authorization': `Bearer ${sessionStorage.getItem("token")}`},
              //credentials: 'include',
              body: JSON.stringify(item)
            } */
		const res = await axios.post(
			process.env.REACT_APP_BACKEND_URL + API_ADMIN_USER_ADD_ROUTE,
			formData,
			{
				withCredentials: true,
				headers: {
					Authorization: `Bearer ${sessionStorage.getItem("token")}`,
					Accept: "application/json",
					"Content-Type": `multipart/form-data; boundary=${formData._boundary}`
				}
			}
		);
		/* const response = await fetch('https://whxlbo-fr.warehouse-x.io' + API_ADMIN_USER_ADD_ROUTE, options)
        const data = await response.json() */

		if (res.status >= 200 && res.status <= 299) {
			const data = res.data;

			dispatch(oneUser(data));
			history.back();
			dispatch(getUsers());
			dispatch(setMessage(res.data.message));
		} else {
			dispatch(setErrors(data.errors));
			dispatch(setLoading({ camera: "user", type: false }));
			dispatch(userLogout());
			sessionStorage.removeItem("token");
		}
	} catch (error) {
		setMessage(error, "error");
		dispatch(setLoading({ camera: "user", type: false }));
	}
};

export const updateUser = (item, id) => async (dispatch) => {
	dispatch(setLoading({ camera: "user", type: true }));
	try {
		let formData = new FormData();
		Object.keys(item).forEach((key) => formData.append(key, item[key]));
		/*  const options = {
           method: "POST",
           headers: {'Content-Type': 'application/json',
           "Accept": "application/json",
           'Authorization': `Bearer ${sessionStorage.getItem("token")}`},
           credentials: 'include',
           body: JSON.stringify(item)
         } */

		/*     const response = await fetch('https://whxlbo-fr.warehouse-x.io' + API_ADMIN_USER_UPDATE_ROUTE + id, options) */
		const res = await axios.post(
			process.env.REACT_APP_BACKEND_URL + API_ADMIN_USER_UPDATE_ROUTE + id,
			formData,
			{
				withCredentials: true,
				headers: {
					Authorization: `Bearer ${sessionStorage.getItem("token")}`,
					accept: "application/json",
					"Content-Type": `multipart/form-data; boundary=${formData._boundary}`
				}
			}
		);
		if (res.status == 200) {
			const data = res.data;
			dispatch(patchUser(data));
			dispatch(setMessage(res.data.message, "success"));
			/* history.back();*/
			dispatch(setLoading({ camera: "user", type: false }));
			/* alert("user updated sucessfully");
            return true */
		} else {
			const data = await res.json();
			dispatch(setMessage(Object.values(data.errors)[0], "error"));
			dispatch(setLoading({ camera: "user", type: false }));
			/* dispatch(userLogout());
            sessionStorage.removeItem("token"); */
		}
	} catch (error) {
		dispatch(setError(error));
		dispatch(setLoading({ camera: "user", type: false }));
	}
};
export const deleteUser = (id) => async (dispatch) => {
	try {
		const response = await fetch(
			process.env.REACT_APP_BACKEND_URL + API_ADMIN_USER_DELETE_ROUTE + id,
			{
				method: "DELETE",
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
					Authorization: `Bearer ${sessionStorage.getItem("token")}`
				}
				//credentials: 'include',
			}
		);
		//response.data
		const data = await response.json();
		if (response.status >= 200 && response.status <= 299) {
			dispatch(delete_User());
			dispatch(getUsers());
			dispatch(setMessage(data.message, data.type));
		} else {
			dispatch(setError(data));
			/*  dispatch(userLogout());
             sessionStorage.removeItem("token"); */
		}
	} catch (error) {
		setMessage(error, "error");
	}
};
export const unBanUser = (id) => async (dispatch) => {
	try {
		const response = await fetch(
			process.env.REACT_APP_BACKEND_URL +
				API_ADMIN_USER_UNBAN_ROUTE +
				id +
				"/unban",
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
					Authorization: `Bearer ${sessionStorage.getItem("token")}`
				}
				//credentials: 'include',
			}
		);
		//response.data
		const data = await response.json();
		if (response.status >= 200 && response.status <= 299) {
			dispatch(getUsers());
			dispatch(setMessage(data.message, data.type));
		} else {
			dispatch(setError(data));
			/*  dispatch(userLogout());
             sessionStorage.removeItem("token"); */
		}
	} catch (error) {
		setMessage(error, "error");
	}
};

export const UserWarn = (item) => async (dispatch) => {
	try {
		const response = await fetch(
			process.env.REACT_APP_BACKEND_URL +
				API_ADMIN_USER_WARN_ROUTE +
				"warn-or-ban",
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
					Authorization: `Bearer ${sessionStorage.getItem("token")}`
				},
				body: JSON.stringify({
					text: item.text.text,
					chatThreadId: item.chatThreadId,
					type: item.type,
					guestIP: item.guestIP,
					userId: item.userId
				})
				//credentials: 'include',
			}
		);
		//response.data

		const data = await response.json();
		dispatch(setMessage(data.message, data.type));
		/*       if (response.status >= 200 && response.status <= 299) {
                dispatch(delete_User())
                dispatch(getUsers())
              } else {
                dispatch(userLogout());
                sessionStorage.removeItem("token");
              } */
	} catch (error) {
		console.log(error);
	}
};

export const UserBan = (item) => async (dispatch) => {
	try {
		const response = await fetch(
			process.env.REACT_APP_BACKEND_URL +
				API_ADMIN_USER_WARN_ROUTE +
				"warn-or-ban",
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
					Authorization: `Bearer ${sessionStorage.getItem("token")}`
				},
				body: JSON.stringify({
					text: item.text.text,
					chatThreadId: item.chatThreadId,
					type: item.type,
					guestIP: item.guestIP,
					userId: item.userId,
					duration: item.duration
				})
				//credentials: 'include',
			}
		);
		//response.data

		const data = await response.json();
		dispatch(setMessage(data.message, data.type));
		/*       if (response.status >= 200 && response.status <= 299) {
                dispatch(delete_User())
                dispatch(getUsers())
              } else {
                dispatch(userLogout());
                sessionStorage.removeItem("token");
              } */
	} catch (error) {
		console.log(error);
	}
};

export const getUsersTransactions =
	(page = null, limit = null, search = null) =>
	async (dispatch) => {
		dispatch(setLoading({ camera: "user", type: true }));
		try {
			const options = {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
					Authorization: `Bearer ${sessionStorage.getItem("token")}`
				}
			};
			const response = await fetch(
				process.env.REACT_APP_BACKEND_URL +
					API_ADMIN_USERS_TRANSACTIONS_ROUTE +
					"?page=" +
					page +
					"&limit=" +
					limit +
					"&search=" +
					search,
				options
			);

			//credentials: 'include')

			/* const data = await response.json() */
			const data = await response.json();
			if (response.status >= 200 && response.status <= 299) {
				dispatch(allUsersTransactions(data.data));
				dispatch(setLoading({ camera: "user", type: false }));
			} else {
				dispatch(setLoading({ camera: "user", type: false }));
				dispatch(userLogout());
				sessionStorage.removeItem("token");
			}
		} catch (error) {
			console.log(error);
			dispatch(setLoading({ camera: "user", type: false }));
		}
	};
export const getUserTransactions = (id, month, year) => async (dispatch) => {
	let response = "";
	dispatch(setLoading({ camera: "user", type: true }));
	try {
		if ((month || year) == 0) {
			response = await fetch(
				process.env.REACT_APP_BACKEND_URL +
					API_ADMIN_USERS_ROUTE +
					"/" +
					id +
					"/transactions",
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Accept: "application/json",
						Authorization: `Bearer ${sessionStorage.getItem("token")}`
					}
					//credentials: 'include',
				}
			);
		} else {
			response = await fetch(
				process.env.REACT_APP_BACKEND_URL +
					API_ADMIN_USERS_ROUTE +
					"/" +
					id +
					"/transactions" +
					`?month=${month}&year=${year}`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Accept: "application/json",
						Authorization: `Bearer ${sessionStorage.getItem("token")}`
					}
					//credentials: 'include',
				}
			);
		}
		const data = await response.json();
		if (response.status >= 200 && response.status <= 299) {
			dispatch(UserTransactions(data.data));
			dispatch(setLoading({ camera: "user", type: false }));
		} else {
			dispatch(setLoading({ camera: "user", type: false }));
			// dispatch(userLogout());
			// sessionStorage.removeItem("token");
		}
	} catch (error) {
		console.log(error);
		dispatch(setLoading({ camera: "user", type: false }));
	}
};
export const getUserSubscriptions = (id) => async (dispatch) => {
	let response = "";
	dispatch(setLoading({ camera: "user", type: true }));
	try {
		response = await fetch(
			process.env.REACT_APP_BACKEND_URL +
				API_ADMIN_USERS_ROUTE +
				"/" +
				id +
				"/subscriptions",
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
					Authorization: `Bearer ${sessionStorage.getItem("token")}`
				}
				//credentials: 'include',
			}
		);

		const data = await response.json();
		if (response.status >= 200 && response.status <= 299) {
			dispatch(UserSubscriptions(data.data.subscriptions));
			dispatch(setLoading({ camera: "user", type: false }));
		} else {
			dispatch(setLoading({ camera: "user", type: false }));
			// dispatch(userLogout());
			//sessionStorage.removeItem("token");
		}
	} catch (error) {
		console.log(error);
		dispatch(setLoading({ camera: "user", type: false }));
	}
};
export const cancelUserSubscription =
	(userId, subscriptionId) => async (dispatch) => {
		let response = "";
		try {
			response = await fetch(
				process.env.REACT_APP_BACKEND_URL +
					API_ADMIN_USER_SUBSCRIPTION_CANCEL_ROUTE +
					subscriptionId +
					"/cancel",
				{
					method: "DELETE",
					headers: {
						"Content-Type": "application/json",
						Accept: "application/json",
						Authorization: `Bearer ${sessionStorage.getItem("token")}`
					}
					//credentials: 'include',
				}
			);

			const data = await response.json();
			if (response.status >= 200 && response.status <= 299) {
				dispatch(getUserSubscriptions(userId));
				dispatch(setMessage(data.message, "success"));
			} else {
				dispatch(setMessage(data.message, "error"));
				// dispatch(userLogout());
				// sessionStorage.removeItem("token");
			}
		} catch (error) {
			console.log(error);
		}
	};

export const getTransaction = (id) => async (dispatch) => {
	dispatch(setLoading({ camera: "user", type: true }));
	try {
		const response = await fetch(
			process.env.REACT_APP_BACKEND_URL +
				API_ADMIN_USERS_TRANSACTIONS_ROUTE +
				"/" +
				id,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
					Authorization: `Bearer ${sessionStorage.getItem("token")}`
				}
				//credentials: 'include',
			}
		);

		const data = await response.json();
		if (response.status >= 200 && response.status <= 299) {
			dispatch(oneTransaction(data.data));
			dispatch(setLoading({ camera: "user", type: false }));
		} else {
			dispatch(setLoading({ camera: "user", type: false }));
			// dispatch(userLogout());
			// sessionStorage.removeItem("token");
		}
	} catch (error) {
		console.log(error);
		dispatch(setLoading({ camera: "user", type: false }));
	}
};

export const connect = () => async (dispatch) => {
	try {
		const result = axios.post("https://api.lovense.com/api/lan/getQrCode", {
			token: "mwdMlXbetIpm37KJZ-c9mZMw-2jqgo1UmvfU6hlHCpSYNgx9nqNxDy8Rn3Pdz055", // developer token
			uid: "b799e042b",
			uname: "test123" //
		});
		const data = result.json();
	} catch (error) {
		/* setMessage(error, 'error'); */
	}
};
