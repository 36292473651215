import * as React from "react";
import { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { Box, Input, Stack, Switch, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import { connect, useDispatch } from "react-redux";
import IconButton from "@mui/material/IconButton";
import { useNavigate } from "react-router-dom";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import { TabContext, TabList } from "@mui/lab";
import TabPanel from "@mui/lab/TabPanel";
import Tab from "@mui/material/Tab";
import {
	getEventStage,
	StudStageUpdate
} from "../../redux/actions/studActions";
import {
	API_ADMIN_STAGE_TASKS_ROUTE,
	API_ADMIN_STUD_STAGE_ROUTE,
	API_ADMIN_STUD_STAGES_ROUTE,
	API_ADMIN_TASK_ROUTE
} from "../../utils/consts";
import EventTasks from "../EventComponents/EventTasks";
import EventPerformers from "../EventComponents/EventPerformers";
import EventMedia from "../EventComponents/EventMedia";

const IOSSwitch = styled((props) => (
	<>
		<Switch
			focusVisibleClassName=".Mui-focusVisible"
			disableRipple
			{...props}
		/>
	</>
))(({ theme }) => ({
	width: 42,
	height: 26,
	padding: 0,
	"& .MuiSwitch-switchBase": {
		padding: 0,
		margin: 2,
		transitionDuration: "300ms",
		"&.Mui-checked": {
			transform: "translateX(16px)",
			color: "#fff",
			"& + .MuiSwitch-track": {
				backgroundColor: theme.palette.mode === "dark" ? "#55AC27" : "#65C466",
				opacity: 1,
				border: 0
			},
			"&.Mui-disabled + .MuiSwitch-track": {
				opacity: 0.5
			}
		},
		"&.Mui-focusVisible .MuiSwitch-thumb": {
			color: "#33cf4d",
			border: "6px solid #fff"
		},
		"&.Mui-disabled .MuiSwitch-thumb": {
			color:
				theme.palette.mode === "light"
					? theme.palette.grey[100]
					: theme.palette.grey[600]
		},
		"&.Mui-disabled + .MuiSwitch-track": {
			opacity: theme.palette.mode === "light" ? 0.7 : 0.3
		}
	},
	"& .MuiSwitch-thumb": {
		boxSizing: "border-box",
		width: 22,
		height: 22
	},
	"& .MuiSwitch-track": {
		borderRadius: 26 / 2,
		backgroundColor: theme.palette.mode === "light" ? "#55AC27" : "#D53131",
		opacity: 1,
		transition: theme.transitions.create(["background-color"], {
			duration: 500
		})
	}
}));
const StyledTab = styled(Tab)`
	border: 1px solid #78f73b;
	border-radius: 20px;
	font-size: 0.9rem !important;
	max-height: 35px !important;
	min-height: 35px !important;
	text-transform: none !important;
`;

const mapStateToProps = (state) => {
	return {
		whx_event: state.whx_event
	};
};

function Stud({ whx_event }) {
	const [studInfo, setStudInfo] = useState();
	const [index, setindex] = useState(0);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	useEffect(() => {
		const fetchData = async () => {
			try {
				let response = await fetch(
					process.env.REACT_APP_BACKEND_URL + API_ADMIN_STUD_STAGES_ROUTE,
					{
						method: "GET",
						headers: {
							"Content-Type": "application/json",
							Accept: "application/json",
							Authorization: `Bearer ${sessionStorage.getItem("token")}`
						}
						//credentials: 'include',
					}
				);
				const data = await response.json();
				if (response.status >= 200 && response.status <= 299) {
					setStudInfo(data?.data);
				}
			} catch (error) {
				console.log(error);
			}
		};

		fetchData();
	}, []);

	useEffect(() => {
		const activeStage = studInfo?.stages?.find(
			(stage) => stage?.is_active === 1
		);
		if (activeStage?.id) {
			dispatch(getEventStage(API_ADMIN_STUD_STAGE_ROUTE, activeStage?.id));
		}
		const activeStageIndex = studInfo?.stages.findIndex(
			(stage) => stage.is_active === 1
		);
		if (activeStageIndex !== -1) {
			setindex(activeStageIndex);
		} else {
			setindex(0);
		}
	}, [studInfo]);

	const handleChangeStage = (stage, i) => {
		dispatch(getEventStage(API_ADMIN_STUD_STAGE_ROUTE, stage?.id));
		setindex(i);
	};
	const [add, setadd] = useState(false);
	const handleClickAdd = (index) => {
		setadd(true);
	};

	const [selectedValue, setSelectedValue] = React.useState(
		whx_event?.stage?.name
	);
	useEffect(() => {
		if (whx_event) {
			setSelectedValue(whx_event?.stage?.name);
		}
	}, [whx_event]);
	const handleAddTrickName = (event) => {
		setSelectedValue(event.target.value);
	};

	const [tab, setTab] = useState("tasks");
	function handleChangeTab(event, value) {
		setTab(value);
	}

	return (
		<>
			<Stack
				spacing={3}
				sx={{ width: 1500, marginLeft: "290px", marginTop: "40px" }}
			>
				<Box>
					<Grid
						container
						spacing={0}
						style={{ display: "flex", flexDirection: "column" }}
					>
						<Grid item xs={4} md={2} spacing={0} className="Magicx_text">
							<Typography
								variant="h4"
								gutterBottom
								component="div"
								style={{ color: "white", marginBottom: "unset" }}
							>
								Current Stage
							</Typography>
						</Grid>

						<Box component="div" className="Pick_round">
							{studInfo?.stages?.map((item, i) => {
								return (
									<Button
										key={item?.id}
										onClick={() => {
											handleChangeStage(item, i);
										}}
										className="pick_round_button"
										style={{
											width: "68px",
											height: "68px",
											backgroundColor: index == i && "#78F73B",
											color: index == i && "black"
										}}
									>
										{i + 1}
									</Button>
								);
							})}
						</Box>

						<Grid
							item
							xs={4}
							md={2}
							spacing={0}
							className="Magicx_text"
							style={{ paddingTop: "25px" }}
						>
							<Typography
								variant="h4"
								gutterBottom
								component="div"
								style={{ color: "white", marginBottom: "unset" }}
							>
								Stage Settings
							</Typography>
						</Grid>
						<Box component="div" className="Voting_settings">
							{whx_event && whx_event.stage && (
								<>
									<Grid item className="Voting_switches" xs={4}>
										<Typography
											style={{
												color: "white",
												display: "flex",
												justifyContent: "flex-start"
											}}
										>
											Voting status
										</Typography>
										<div
											style={{
												display: "flex",
												justifyContent: "flex-start",
												alignItems: "center",
												gap: "10px"
											}}
										>
											<Typography style={{ color: "white" }}>Closed</Typography>
											<IOSSwitch
												checked={
													whx_event &&
													whx_event.stage &&
													whx_event.stage?.vote_enabled
														? true
														: false
												}
												onClick={() => {
													dispatch(
														StudStageUpdate(
															API_ADMIN_STUD_STAGE_ROUTE,
															whx_event.stage?.id,
															{
																vote_enabled: !whx_event.stage?.vote_enabled,
																name: selectedValue
															}
														)
													);
												}}
												inputProps={{ "aria-label": "ant design" }}
											/>
											<Typography style={{ color: "white" }}>Open</Typography>
										</div>
									</Grid>
									<Grid item className="Voting_switches" xs={4}>
										<Typography
											style={{
												color: "white",
												display: "flex",
												justifyContent: "flex-start"
											}}
										>
											Stage Name
										</Typography>
										<Box>
											<Input
												className="inputBoxTrick"
												disableUnderline="true"
												onClick={() => {
													handleClickAdd();
												}}
												onChange={handleAddTrickName}
												value={selectedValue}
												name="password"
												placeholder="Task Name"
											/>
											{add && (
												<IconButton
													onClick={() => {
														dispatch(
															StudStageUpdate(
																API_ADMIN_STUD_STAGE_ROUTE,
																whx_event.stage?.id,
																{
																	vote_enabled: whx_event.stage?.vote_enabled,
																	name: selectedValue
																}
															)
														);
														setadd(false);
													}}
													aria-label="delete"
												>
													<CheckCircleRoundedIcon
														style={{ color: "#78F73B" }}
													/>
												</IconButton>
											)}
										</Box>
									</Grid>
								</>
							)}
						</Box>
						<Grid
							item
							xs={4}
							md={2}
							spacing={0}
							className="Magicx_text"
							style={{ paddingTop: "25px" }}
						>
							<Typography
								variant="h4"
								gutterBottom
								component="div"
								style={{ color: "white", marginBottom: "unset" }}
							>
								Media
							</Typography>
						</Grid>
						<Box component="div" className="Voting_settings">
							{studInfo &&
								studInfo?.galleries &&
								Object?.entries(studInfo?.galleries).map(([key, value]) => (
									<Grid item className="Voting_switches" xs={4} key={key}>
										<Button
											component="a"
											href={`${window.origin}/gallery/${value}`}
											variant="outlined"
											style={{
												border: "1px solid #78f73b",
												borderRadius: "20px",
												fontSize: "0.9rem",
												maxHeight: "35px",
												minHeight: "35px",
												textTransform: "none",
												color: "white",
												whiteSpace: "nowrap",
												width: "250px"
											}}
										>
											Upload to{" "}
											{key === "is_highlight"
												? "Highlights"
												: key === "is_backstage"
													? "Behind the scenes"
													: "Queens Challenges"}
										</Button>
									</Grid>
								))}
						</Box>
						<Grid
							item
							xs={12}
							md={12}
							spacing={0}
							className="Magicx_text"
							style={{ paddingTop: "25px" }}
						>
							<TabContext value={tab}>
								<Box
									sx={{
										width: "100%"
									}}
								>
									<TabList
										className="flex"
										// onChange={handleChange}
										aria-label="Pages"
										sx={{
											".MuiTabs-flexContainer": {
												gap: 2,
												justifyContent: "flex-start"
											},
											".MuiTabs-indicator": {
												display: "none"
											}
										}}
										onChange={handleChangeTab}
									>
										<StyledTab
											label={"Tasks"}
											className="ProfileTab"
											value="tasks"
											sx={{
												backgroundColor: tab === "tasks" ? "#78f73b" : "none",
												color: tab === "tasks" ? "#000 !important" : "#fff"
											}}
										/>

										<StyledTab
											label={"Performers"}
											className="ProfileTab"
											value="performers"
											sx={{
												backgroundColor:
													tab === "performers" ? "#78f73b" : "none",
												color: tab === "performers" ? "#000 !important" : "#fff"
											}}
										/>
										<StyledTab
											label={"Tasks Media"}
											className="ProfileTab"
											value="media"
											sx={{
												backgroundColor: tab === "media" ? "#78f73b" : "none",
												color: tab === "media" ? "#000 !important" : "#fff"
											}}
										/>
									</TabList>

									<TabPanel style={{ padding: 0 }} value="tasks">
										<EventTasks
											stage={whx_event?.stage}
											backend_url={API_ADMIN_TASK_ROUTE}
										/>
									</TabPanel>

									<TabPanel style={{ padding: 0 }} value="performers">
										<EventPerformers
											participants={whx_event?.stage?.participants}
											stage={whx_event?.stage}
											backend_url={API_ADMIN_STUD_STAGES_ROUTE}
										/>
									</TabPanel>
									<TabPanel style={{ padding: 0 }} value="media">
										<EventMedia
											stage={whx_event?.stage}
											galleryID={studInfo?.main_gallery?.id}
											backend_url={API_ADMIN_STAGE_TASKS_ROUTE}
										/>
									</TabPanel>
								</Box>
							</TabContext>
						</Grid>
					</Grid>
				</Box>
			</Stack>
		</>
	);
}
export default connect(mapStateToProps)(Stud);
