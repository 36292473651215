import { FETCH_CHATS, FILTERS, REMOVE_CHAT, REMOVE_CHATS } from "../types";
import { API_FETCH_CHATS_ROUTE } from "../../utils/consts";
import axios from "axios";
import { setLoadings } from "../reducers/rootReducer";

export const setPrivateChats = (data) => {
	return {
		type: FETCH_CHATS,
		payload: { data }
	};
};

export const removeChats = () => {
	return {
		type: REMOVE_CHATS
	};
};
export const filterChats = (data) => {
	return {
		type: FILTERS,
		payload: { data }
	};
};
export const removeChat = (data) => {
	return {
		type: REMOVE_CHAT,
		payload: { data }
	};
};

export const getPrivateChats = () => async (dispatch) => {
	try {
		dispatch(setLoadings("test"));
		const res = await axios.get(
			process.env.REACT_APP_BACKEND_URL + API_FETCH_CHATS_ROUTE,

			{
				withCredentials: true,
				headers: {
					Authorization: `Bearer ${sessionStorage.getItem("token")}`,
					Accept: "application/json",
					"Content-Type": "application/json"
				}
			}
		);

		if (res.status == 200) {
			dispatch(setPrivateChats(res.data.data));
		}
	} catch (error) {
		console.log(error);
	}
};
