import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import "../../App";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useNavigate, useParams } from "react-router-dom";
import {
	Autocomplete,
	Backdrop,
	CardActionArea,
	Container,
	Input,
	MenuItem,
	Select,
	TextField
} from "@mui/material";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import Modal from "@mui/material/Modal";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import FormControl from "@mui/material/FormControl";
import { connect, useDispatch, useSelector } from "react-redux";
import {
	delete_User,
	deleteUser,
	getUser,
	getUsers,
	unBanUser,
	updateUser,
	UserBan,
	UserWarn
} from "../../redux/actions/userAction";
import Skeleton from "@mui/material/Skeleton";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import UserTransactions from "../UserTransactions/UserTransactions";
import { TabContext, TabList } from "@mui/lab";
import { styled } from "@mui/system";
import TabPanel from "@mui/lab/TabPanel";
import Tab from "@mui/material/Tab";
import UserSubscriptions from "../UserSubscriptions/UserSubscriptions";
import { removeErrors } from "../../redux/reducers/rootReducer";
import { useSnackbar } from "notistack";
import useVaporUpload from "../Upload/VaporUpload";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 400,
	bgcolor: "background.paper",
	border: "2px solid #000",
	textAlign: "center",
	margin: "0 auto",
	p: 4
};
const style_confirm = {
	position: "absolute",
	top: "10%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 400,
	bgcolor: "background.paper",
	border: "2px solid #000",
	textAlign: "center",
	margin: "0 auto",
	p: 4
};
const StyledTab = styled(Tab)`
	border: 1px solid #78f73b;
	border-radius: 20px;
	font-size: 0.9rem !important;
	max-height: 35px !important;
	min-height: 35px !important;
	text-transform: none !important;
`;

const roles = [{ id: 1, title: "member" }];
const mapStateToProps = (state) => {
	return {
		user: state.user
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		getUser: (id) => dispatch(getUser(id)),
		removeUser: (id) => dispatch(delete_User(id))
	};
};

function UserProfile({ user, getUser, removeUser, admin }) {
	const dispatch = useDispatch();
	const param = useParams();
	const navigate = useNavigate();

	React.useEffect(() => {
		removeUser(param.id);
		getUser(param.id);
	}, []);

	const [selectedOptions, setSelectedOptions] = React.useState([]);

	const handleAutocompleteChange = (event, value) => {
		event.preventDefault();
		setSelectedOptions(value.title);
		const newFormData = { ...addFormData };
		newFormData["role"] = value.title;
		setAddFormData(newFormData);
	};
	const [addFormData, setAddFormData] = React.useState({
		username: "",
		password: "",
		email: "",
		password_confirmation: "",
		role: "",
		tokens: "",
		createdAt: ""
	});

	React.useEffect(() => {
		setAddFormData({
			username: user.user && user.user.username,
			password: user.user && user.user.password,
			password_confirmation: user.user && user.user.password_confirmation,
			role: user.user && user.user.role,
			tokens: user.user && user.user.credits,
			email: user.user && user.user.email,
			createdAt: user.user && user.user.createdAt
		});
	}, [user]);

	const handleAddForm = (event) => {
		event.preventDefault();
		const fieldName = event.target.getAttribute("name");
		const fieldValue = event.target.value;
		const newFormData = { ...addFormData };
		newFormData[fieldName] = fieldValue;
		setAddFormData(newFormData);
	};
	const handleAddFormSubmit = (event) => {
		event.preventDefault();
		if (addFormData.password_confirmation != addFormData.password) {
			alert("passwords not the same");
			return false;
		} else {
			dispatch(updateUser(addFormData, param.id));
			// navigate("/users");
		}
	};

	const { enqueueSnackbar } = useSnackbar();
	const errorsSubmit = useSelector((state) => state.errors);

	React.useEffect(() => {
		if (errorsSubmit && errorsSubmit.username) {
			enqueueSnackbar(errorsSubmit.username[0], { variant: "error" });
		}
		if (errorsSubmit && errorsSubmit.email) {
			enqueueSnackbar(errorsSubmit.email[0], { variant: "error" });
		}
		if (errorsSubmit && errorsSubmit.password) {
			enqueueSnackbar(errorsSubmit.password[0], { variant: "error" });
		}
		if (errorsSubmit && errorsSubmit.password_confirmation) {
			enqueueSnackbar(errorsSubmit.password_confirmation[0], {
				variant: "error"
			});
		}
		setTimeout(() => {
			dispatch(removeErrors());
		}, 5000);
	}, [errorsSubmit]);
	const handleDeleteSubmit = (id) => {
		dispatch(deleteUser(id));
		navigate("/users");
		dispatch(getUsers());
	};
	const handleUnbanSubmit = (event) => {
		event.preventDefault();
		dispatch(unBanUser(param.id));
		navigate("/users");
		dispatch(getUsers());
	};

	const [open, setOpen] = React.useState(false);
	const handleOpen = () => {
		setOpen(true);
	};
	const handleClose = () => setOpen(false);

	React.useEffect(() => {
		setImageUrl(user.user && user.user.smallAvatar);
	}, [user.user]);
	const [selectedImage, setSelectedImage] = React.useState(null);
	const [imageUrl, setImageUrl] = React.useState(null);

	const { uploadFile, uploadProgress } = useVaporUpload();
	React.useEffect(() => {
		if (selectedImage) {
			const updateImage = async () => {
				setImageUrl(URL.createObjectURL(selectedImage));
				const newFormData = { ...addFormData };
				const { vaporResponse } = await uploadFile(selectedImage);
				newFormData["myFiles"] = vaporResponse.key;

				setAddFormData(newFormData);
			};
			updateImage();
		}
	}, [selectedImage]);

	const [NameData, setNameData] = React.useState([]);
	const handleModalForm = (event) => {
		event.preventDefault();
		const fieldName = event.target.getAttribute("name");
		const fieldValue = event.target.value;
		const newNameData = { ...NameData };
		newNameData[fieldName] = fieldValue;

		setNameData(newNameData);
	};

	function userWarn(id) {
		event.preventDefault();
		let obj = {
			text: NameData,
			ip: null,
			guestIP: null,
			chatThreadId: null,
			userId: id,
			type: "warning"
		};
		let newLiveshow = dispatch(UserWarn(obj));
		handleClose();
	}

	const [time, setTime] = React.useState("");
	const handleChange = (event) => {
		setTime(event.target.value);
	};

	function userBan(id) {
		event.preventDefault();
		let obj = {
			text: NameData,
			ip: null,
			guestIP: null,
			chatThreadId: null,
			userId: id,
			type: "ban",

			duration: time
		};
		let newLiveshow = dispatch(UserBan(obj));
		handleClose();
		navigate("/users");
		dispatch(getUsers());
	}

	function returnToUsers(event) {
		event.preventDefault();
		navigate("/users");
	}

	const [show, setShow] = React.useState(false);

	const handleCloseConfirm = () => {
		setShow(false);
	};
	const handleShow = () => {
		setShow(true);
		handleClose();
	};
	const loading = useSelector((state) => state.loading);

	const [tab, settab] = React.useState("transactions");

	const handleChangeTab = (event, newValue) => {
		settab(newValue);
	};
	return (
		<>
			<Box
				component="div"
				className="page-container"
				sx={{
					flexGrow: 1,
					maxWidth: "900px",
					margin: "0",
					height: "100%",
					minHeight: "1000px",
					marginLeft: "290px"
				}}
			>
				<Grid container spacing={0}>
					<Grid item xs={4} md={2} spacing={0} className="LiveShow">
						{!user.user ? (
							<Skeleton width={330} height={55} />
						) : (
							<Typography
								variant="h4"
								gutterBottom
								component="div"
								style={{ color: "white" }}
							>
								{user.user && user.user.username}'s Profile
							</Typography>
						)}
					</Grid>
				</Grid>
				<Button
					startIcon={<ArrowBackIosIcon />}
					className="ModelProfilePage_arrow"
					variant="outlined"
					onClick={returnToUsers}
				>
					All users
				</Button>
				<Container
					maxWidth="xl"
					style={{
						color: "#ffffff",
						width: "100%",
						height: "100%",
						backgroundColor: "#000000",
						position: "relative"
					}}
					sx={{ my: 3 }}
					className="ProvileContainer"
				>
					{loading && loading.isLoading == true ? (
						<div className="loader-container">
							<div className="spinner"></div>
						</div>
					) : (
						""
					)}
					<Grid spacing={1} className="ModelProfilePage_wrap">
						<Grid item xs={6} md={4}>
							<Box component="div" className="ModelProfilePage_main">
								<Stack
									direction="row"
									className="ModelProfilePage_row"
									spacing={1}
									style={{ flexGrow: "0.6" }}
								>
									<Typography
										gutterBottom
										component="div"
										className="ModelProfilePage_elements"
									>
										Profile image
									</Typography>
									<Box
										style={{
											display: "flex",
											flexDirection: "column",
											alignItems: "center",
											width: "100%"
										}}
									>
										<Box
											mt={2}
											textAlign="center"
											style={{
												width: "100px",
												height: "100px",
												backgroundColor: "rgb(41, 41, 41)",
												borderRadius: "50%"
											}}
										>
											{imageUrl && (
												<img
													src={imageUrl}
													height="100px"
													width="100px"
													accept="image/x-png,image/gif,image/jpeg"
													style={{ borderRadius: "50%" }}
												/>
											)}
										</Box>

										<input
											accept="image/*"
											style={{ display: "none" }}
											id="select-image"
											multiple
											type="file"
											name="myFiles"
											onChange={(e) => {
												setSelectedImage(e.target.files[0]);
											}}
										/>
										<label htmlFor="select-image" style={{ marginTop: "10px" }}>
											<Button variant="raised" component="span">
												Upload
											</Button>
										</label>
									</Box>
								</Stack>
								<Stack
									direction="row"
									className="ModelProfilePage_row"
									spacing={1}
								>
									<Typography
										gutterBottom
										component="div"
										className="ModelProfilePage_elements"
									>
										Name
									</Typography>
									<Input
										className="inputBoxProfile"
										name="username"
										defaultValue={user.user && user.user.username}
										key={user.user && user.user.username}
										disableUnderline="true"
										style={{ backgroundColor: "#292929" }}
										onChange={handleAddForm}
									></Input>
								</Stack>
								<Stack
									direction="row"
									className="ModelProfilePage_row"
									spacing={1}
								>
									<Typography
										gutterBottom
										component="div"
										className="ModelProfilePage_elements"
									>
										Email
									</Typography>
									<Input
										className="inputBoxProfile"
										name="email"
										defaultValue={user.user && user.user.email}
										key={user.user && user.user.email}
										disableUnderline="true"
										style={{ backgroundColor: "#292929" }}
										onChange={handleAddForm}
									></Input>
								</Stack>
								<Stack
									direction="row"
									className="ModelProfilePage_row"
									spacing={1}
								>
									<Typography
										gutterBottom
										component="div"
										className="ModelProfilePage_elements"
									>
										Credits
									</Typography>
									<Typography
										gutterBottom
										defaultValue={
											user.user &&
											user.user.balance &&
											user.user.balance.credits.total
										}
										key={
											user.user &&
											user.user.balance &&
											user.user.balance.credits.total
										}
										component="div"
										className="UserProfilePage_elements"
										style={{
											color: "yellow",
											display: "flex",
											justifyContent: "start"
										}}
									>
										{user.user &&
											user.user.balance &&
											user.user.balance.credits.total}
									</Typography>
								</Stack>
								<Stack
									direction="row"
									className="ModelProfilePage_row"
									spacing={1}
								>
									<Typography
										gutterBottom
										component="div"
										className="ModelProfilePage_elements"
									>
										Camera Access
									</Typography>
									<Typography
										gutterBottom
										defaultValue={
											user.user &&
											user.user.balance &&
											user.user.balance.package &&
											user.user.balance.package.is_valid
										}
										key={
											user.user &&
											user.user.balance &&
											user.user.balance.package &&
											user.user.balance.package.is_valid
										}
										component="div"
										className="UserProfilePage_elements"
										style={{
											color: "yellow",
											display: "flex",
											justifyContent: "start"
										}}
									>
										{user.user &&
										user.user.balance &&
										user.user.balance.package &&
										user.user.balance.package.is_valid == true
											? user.user.balance.package.expires_at +
												" days left" +
												` - ${user?.user?.balance?.package?.package_type?.charAt(0)?.toUpperCase() + user?.user?.balance?.package?.package_type?.slice(1)}`
											: "no access"}
									</Typography>
								</Stack>
								<Stack
									direction="row"
									className="ModelProfilePage_row"
									spacing={1}
								>
									<Typography
										gutterBottom
										component="div"
										className="ModelProfilePage_elements"
									>
										New Password
									</Typography>
									<Input
										name="password"
										className="inputBoxProfile"
										disableUnderline="true"
										style={{ backgroundColor: "#292929" }}
										onChange={handleAddForm}
									></Input>
								</Stack>
								<Stack
									direction="row"
									className="ModelProfilePage_row"
									spacing={1}
								>
									<Typography
										gutterBottom
										component="div"
										className="ModelProfilePage_elements"
									>
										Repeat Password
									</Typography>
									<Input
										name="password_confirmation"
										className="inputBoxProfile"
										disableUnderline="true"
										style={{ backgroundColor: "#292929" }}
										onChange={handleAddForm}
									></Input>
								</Stack>
							</Box>
						</Grid>
						<Grid
							item
							xs={6}
							md={8}
							style={{
								margin: "0 auto",
								textAlign: "center",
								display: "flex",
								flexDirection: "row",
								alignItems: "center"
							}}
						>
							<Box component="div" className="ModelProfilePage_main">
								<Stack
									direction="row"
									className="ModelProfilePage_row"
									spacing={1}
								>
									<Typography
										gutterBottom
										component="div"
										className="ModelProfilePage_elements"
									>
										Role
									</Typography>
									<Autocomplete
										className="ProfileRoleInput"
										disablePortal
										id="combo-box-demo"
										options={roles}
										defaultValue={
											user.user && roles.find((v) => v.title === user.user.role)
										}
										key={
											user.user && roles.find((v) => v.title === user.user.role)
										}
										getOptionLabel={(option) => option.title}
										renderInput={(params) => (
											<TextField
												{...params}
												disabled
												variant="standard"
												name="role"
											/>
										)}
										onChange={handleAutocompleteChange}
									/>
								</Stack>
								<Stack
									direction="row"
									className="ModelProfilePage_row"
									spacing={1}
								>
									<Typography
										gutterBottom
										component="div"
										className="ModelProfilePage_elements"
									>
										User since
									</Typography>
									<Typography
										gutterBottom
										component="div"
										className="UserProfilePage_elements"
										style={{ display: "flex", justifyContent: "start" }}
									>
										{user.user && user.user.createdAt}
									</Typography>
								</Stack>
								<Stack
									direction="row"
									className="ModelProfilePage_row"
									spacing={1}
								>
									<Typography
										gutterBottom
										component="div"
										className="ModelProfilePage_elements"
									>
										Last online
									</Typography>
									<Typography
										gutterBottom
										component="div"
										className="UserProfilePage_elements"
										style={{
											color: "yellow",
											display: "flex",
											justifyContent: "start"
										}}
									>
										{user.user && user.user.last_seen_at}
									</Typography>
								</Stack>
								<Stack
									direction="row"
									className="ModelProfilePage_row"
									spacing={1}
								>
									<Typography
										gutterBottom
										component="div"
										className="ModelProfilePage_elements"
									>
										Banned
									</Typography>
									<Typography
										gutterBottom
										component="div"
										className="UserProfilePage_elements"
										style={{
											color: `${
												user.user && user.user.is_banned == 0 ? "green" : "red"
											}`,
											display: "flex",
											justifyContent: "start"
										}}
									>
										{user.user && user.user.is_banned == 0
											? "not banned"
											: "banned"}
									</Typography>
								</Stack>
								<Stack
									direction="row"
									className="ModelProfilePage_row"
									spacing={1}
								>
									<Typography
										gutterBottom
										component="div"
										className="ModelProfilePage_elements"
									>
										IP
									</Typography>
									<Typography
										gutterBottom
										component="div"
										className="UserProfilePage_elements"
										style={{
											color: "white",
											display: "flex",
											justifyContent: "start"
										}}
									>
										{user.user && user.user.lastIP}
									</Typography>
								</Stack>
							</Box>
						</Grid>
					</Grid>
					<Grid spacing={1} className="userProfile_grid">
						<Grid
							item
							xs={6}
							style={{
								width: "100%",
								display: "flex",
								flexDirection: "row",
								justifyContent: "center"
							}}
						>
							<Button
								onClick={handleAddFormSubmit}
								size="small"
								variant="contained"
								style={{
									color: "black",
									backgroundColor: "#FFD800",
									maxWidth: "100px",
									maxHeight: "33px",
									minWidth: "100px",
									minHeight: "33px",
									borderRadius: "4px"
								}}
								className="create"
							>
								Save
							</Button>
						</Grid>
						<Grid
							item
							xs={6}
							style={{
								display: "flex",
								flexDirection: "row",
								justifyContent: "flex-end"
							}}
						>
							<CardActionArea onClick={handleOpen}>
								<Button
									size="small"
									variant="contained"
									style={{
										color: "white",
										backgroundColor: "#2E2E2E",
										maxWidth: "100px",
										maxHeight: "33px",
										minWidth: "100px",
										minHeight: "33px",
										borderRadius: "4px"
									}}
									className="create"
								>
									<ManageAccountsIcon />
								</Button>
							</CardActionArea>
							<Modal
								aria-labelledby="transition-modal-title"
								aria-describedby="transition-modal-description"
								open={open}
								onClose={handleClose}
								closeAfterTransition
								BackdropComponent={Backdrop}
								BackdropProps={{
									timeout: 500
								}}
								className="camera_details"
							>
								<Box
									sx={style}
									style={{
										marginTop: "50px"
									}}
								>
									<IconButton className="BackButton" onClick={handleClose}>
										<ClearIcon />
									</IconButton>
									<Container component="main" maxWidth="xs">
										<Box
											sx={{
												marginTop: 2,
												display: "flex",
												flexDirection: "column",
												alignItems: "center"
											}}
										>
											{user.user && user.user.is_banned == 0 ? (
												<>
													<Box
														sx={{
															marginTop: 2,
															display: "flex",
															flexDirection: "row",
															alignItems: "center"
														}}
														md={3}
													>
														<ul className="ChatListElem_wrap_live">
															<Input
																className="msg"
																name="text"
																disableUnderline="true"
																style={{ color: "#FFD800" }}
																onChange={handleModalForm}
															></Input>
														</ul>
													</Box>
													<Box component="form" noValidate sx={{ mt: 3 }}>
														<Grid container spacing={2}>
															<Button
																fullWidth
																onClick={() => {
																	userWarn(user.user && user.user.id);
																}}
																variant="outlined"
																sx={{ mt: 3, mb: 2 }}
																color="success"
																style={{
																	margin: "0",
																	marginBottom: "10px",
																	borderColor: "#FFD800",
																	color: "#FFD800"
																}}
															>
																Send Warning
															</Button>
															<Box
																style={{
																	display: "flex",
																	flexDirection: "row",
																	alignItems: "center",
																	width: "100%"
																}}
															>
																<Typography
																	component="h4"
																	variant="h8"
																	style={{ width: "40%", color: "red" }}
																>
																	Ban for
																</Typography>
																<FormControl
																	sx={{
																		m: 1,
																		minWidth: 120,
																		width: "33%",
																		color: "red",
																		backgroundColor: "#2C2C2C",
																		paddingTop: "4px"
																	}}
																	size="small"
																>
																	<Select
																		value={time}
																		onChange={handleChange}
																		style={{ color: "red", fontSize: "14px" }}
																	>
																		<MenuItem value={1440}>1 day</MenuItem>
																		<MenuItem value={10080}>7 days</MenuItem>
																		<MenuItem value={43200}>30 days</MenuItem>
																		<MenuItem value={44200}>Forever</MenuItem>
																	</Select>
																</FormControl>
																<Button
																	fullWidth
																	variant="outlined"
																	onClick={() => {
																		userBan(user.user && user.user.id);
																	}}
																	sx={{ mt: 3, mb: 2 }}
																	color="error"
																	style={{
																		margin: "0",
																		marginBottom: "10px",
																		width: "33%"
																	}}
																>
																	Ban
																</Button>
															</Box>
															<Box
																style={{
																	display: "flex",
																	flexDirection: "row",
																	alignItems: "center",
																	width: "100%",
																	justifyContent: "flex-end"
																}}
															>
																<Button
																	onClick={handleShow}
																	style={{
																		color: "#ED2779",
																		width: "140px",
																		textTransform: "unset"
																	}}
																	type="button"
																>
																	Delete this user
																</Button>
															</Box>
														</Grid>
													</Box>
												</>
											) : (
												<Grid container spacing={2}>
													<Box
														style={{
															display: "flex",
															flexDirection: "row",
															alignItems: "center",
															width: "100%",
															justifyContent: "flex-end"
														}}
													>
														<Button
															onClick={handleUnbanSubmit}
															style={{
																color: "green",
																width: "140px",
																textTransform: "unset"
															}}
															type="button"
														>
															UnBan user
														</Button>
														<Button
															onClick={handleShow}
															style={{
																color: "#ED2779",
																width: "140px",
																textTransform: "unset"
															}}
															type="button"
														>
															Delete this user
														</Button>
													</Box>
												</Grid>
											)}
										</Box>
									</Container>
								</Box>
							</Modal>

							<Modal
								open={show}
								onClose={handleCloseConfirm}
								aria-labelledby="transition-modal-title"
								aria-describedby="transition-modal-description"
								closeAfterTransition
								BackdropComponent={Backdrop}
								BackdropProps={{
									timeout: 500
								}}
								className="camera_details"
							>
								<Box sx={style_confirm}>
									<Typography color={"white"}>
										Are you sure you want to delete this user?
									</Typography>
									<Button
										variant="secondary"
										onClick={handleCloseConfirm}
										style={{
											color: "#ED2779",
											width: "140px",
											textTransform: "unset"
										}}
										type="button"
									>
										No
									</Button>
									<Button
										variant="primary"
										onClick={() => {
											handleCloseConfirm();
											handleDeleteSubmit(user.user.id);
										}}
										onSubmit={handleDeleteSubmit}
										style={{
											color: "green",
											width: "140px",
											textTransform: "unset"
										}}
										type="button"
									>
										Yes
									</Button>
								</Box>
							</Modal>
						</Grid>
					</Grid>
				</Container>
				<TabContext value={tab}>
					<Box
						sx={{
							width: "100%"
						}}
					>
						<TabList
							className="flex"
							// onChange={handleChange}
							aria-label="Pages"
							sx={{
								".MuiTabs-flexContainer": {
									gap: 2,
									justifyContent: "flex-start"
								},
								".MuiTabs-indicator": {
									display: "none"
								}
							}}
							onChange={handleChangeTab}
						>
							<StyledTab
								label={"User Transactions"}
								className="ProfileTab"
								value="transactions"
								sx={{
									backgroundColor: tab === "transactions" ? "#78f73b" : "none",
									color: tab === "transactions" ? "#000 !important" : "#fff"
								}}
							/>
							{admin &&
								admin?.available_permissions?.includes(
									"panel_user_subscriptions"
								) && (
									<StyledTab
										label={"User Subscriptions"}
										className="ProfileTab"
										value="subscriptions"
										sx={{
											backgroundColor:
												tab === "subscriptions" ? "#78f73b" : "none",
											color:
												tab === "subscriptions" ? "#000 !important" : "#fff"
										}}
									/>
								)}
						</TabList>
						<TabPanel style={{ padding: 0 }} value="transactions">
							<UserTransactions />
						</TabPanel>
						{admin &&
							admin?.available_permissions?.includes(
								"panel_user_subscriptions"
							) && (
								<TabPanel style={{ padding: 0 }} value="subscriptions">
									<UserSubscriptions />
								</TabPanel>
							)}
					</Box>
				</TabContext>
				{/* {admin && admin.user.role == "superadmin" &&  */}

				{/* } */}
			</Box>
		</>
	);
}

export default connect(mapStateToProps, mapDispatchToProps)(UserProfile);
