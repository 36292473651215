import {
	GEOBLOCK_EXCEPTIONS,
	GEOBLOCK_RESTRICTIONS,
	GEOBLOCK_SETTINGS
} from "../types";
import {
	API_ADMIN_GEOBLOCK_EXCEPTIONS_ADD_ROUTE,
	API_ADMIN_GEOBLOCK_EXCEPTIONS_DELETE_ROUTE,
	API_ADMIN_GEOBLOCK_RESTRICTIONS_ADD_ROUTE,
	API_ADMIN_GEOBLOCK_RESTRICTIONS_DELETE_ROUTE,
	API_FETCH_GEOBLOCK_EXCEPTIONS_ROUTE,
	API_FETCH_GEOBLOCK_RESTRICTIONS_ROUTE,
	API_FETCH_GEOBLOCK_SETTINGS_ROUTE
} from "../../utils/consts";
import {
	setError,
	setLoading,
	setLoadings,
	setMessage
} from "../reducers/rootReducer";
import axios from "../../utils/axios";

export const setGeoblockExceptions = (data) => {
	return {
		type: GEOBLOCK_EXCEPTIONS,
		payload: { data }
	};
};

export const setGeoblockRestrictions = (data) => {
	return {
		type: GEOBLOCK_RESTRICTIONS,
		payload: { data }
	};
};
export const setGeoblockSettings = (data) => {
	return {
		type: GEOBLOCK_SETTINGS,
		payload: { data }
	};
};

export const getGeoblockExceptions = () => async (dispatch) => {
	dispatch(setLoading({ camera: "geoblock", type: true }));
	try {
		dispatch(setLoadings("test"));
		const res = await axios.get(
			process.env.REACT_APP_BACKEND_URL + API_FETCH_GEOBLOCK_EXCEPTIONS_ROUTE,

			{
				withCredentials: true,
				headers: {
					Authorization: `Bearer ${sessionStorage.getItem("token")}`,
					Accept: "application/json",
					"Content-Type": "application/json"
				}
			}
		);

		if (res.status == 200) {
			dispatch(setGeoblockExceptions(res.data.data));
			dispatch(setLoading({ camera: "geoblock", type: false }));
		}
	} catch (error) {
		console.log(error);
		dispatch(setLoading({ camera: "geoblock", type: false }));
	}
};

export const getGeoblockRestrictions = () => async (dispatch) => {
	dispatch(setLoading({ camera: "geoblock", type: true }));
	try {
		dispatch(setLoadings("test"));
		const res = await axios.get(
			process.env.REACT_APP_BACKEND_URL + API_FETCH_GEOBLOCK_RESTRICTIONS_ROUTE,

			{
				withCredentials: true,
				headers: {
					Authorization: `Bearer ${sessionStorage.getItem("token")}`,
					Accept: "application/json",
					"Content-Type": "application/json"
				}
			}
		);

		if (res.status == 200) {
			dispatch(setGeoblockRestrictions(res.data.data));
			dispatch(setLoading({ camera: "geoblock", type: false }));
		}
	} catch (error) {
		console.log(error);
		dispatch(setLoading({ camera: "geoblock", type: false }));
	}
};

export const GeoblockExceptionsAdd = (item) => async (dispatch) => {
	try {
		/*     const options = {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: `Bearer ${sessionStorage.getItem("token")}`,
              },
              //credentials: 'include',
              body: JSON.stringify(item),
            }; */
		const res = await axios.post(
			process.env.REACT_APP_BACKEND_URL +
				API_ADMIN_GEOBLOCK_EXCEPTIONS_ADD_ROUTE,
			item,
			{
				withCredentials: true,
				headers: {
					Authorization: `Bearer ${sessionStorage.getItem("token")}`,
					Accept: "application/json",
					"Content-Type": "application/json"
				}
			}
		);

		/* const response = await fetch(
          "https://whxlbo-fr.warehouse-x.io" + API_ADMIN_MODEL_ADD_ROUTE,
          options
        ); */
		if (res.status >= 200 && res.status <= 299) {
			const data = res.data;
			dispatch(getGeoblockExceptions());
			dispatch(setMessage(res.data.message, res.data.type));
		}
		/* } else { */
		/*   dispatch(userLogout());
          sessionStorage.removeItem("token"); */
	} catch (error) {
		setMessage(error, "error");
	}
};

export const GeoblockRestrictionsAdd = (item) => async (dispatch) => {
	try {
		/*       let formData = new FormData();
              Object.keys(item).forEach((key) => formData.append(key, item[key])); */
		/*     const options = {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: `Bearer ${sessionStorage.getItem("token")}`,
              },
              //credentials: 'include',
              body: JSON.stringify(item),
            }; */
		const res = await axios.post(
			process.env.REACT_APP_BACKEND_URL +
				API_ADMIN_GEOBLOCK_RESTRICTIONS_ADD_ROUTE,
			item,
			{
				withCredentials: true,
				headers: {
					Authorization: `Bearer ${sessionStorage.getItem("token")}`,
					Accept: "application/json",
					"Content-Type": "application/json"
				}
			}
		);

		/* const response = await fetch(
          "https://whxlbo-fr.warehouse-x.io" + API_ADMIN_MODEL_ADD_ROUTE,
          options
        ); */
		if (res.status >= 200 && res.status <= 299) {
			const data = res.data;
			dispatch(getGeoblockRestrictions());
			dispatch(setMessage(res.data.message, res.data.type));
		}
		/* } else { */
		/*   dispatch(userLogout());
          sessionStorage.removeItem("token"); */
	} catch (error) {
		setMessage(error, "error");
	}
};

export const deleteException = (id) => async (dispatch) => {
	try {
		const response = await fetch(
			process.env.REACT_APP_BACKEND_URL +
				API_ADMIN_GEOBLOCK_EXCEPTIONS_DELETE_ROUTE +
				id +
				"/delete",
			{
				method: "DELETE",
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
					Authorization: `Bearer ${sessionStorage.getItem("token")}`
				}
				//credentials: 'include',
			}
		);
		//response.data
		const data = await response.json();

		if (response.status >= 200 && response.status <= 299) {
			dispatch(getGeoblockExceptions());
			dispatch(setMessage(data.message, data.type));
		} else {
			dispatch(setError(data));
			/*  dispatch(userLogout());
             sessionStorage.removeItem("token"); */
		}
	} catch (error) {
		setMessage(error, "error");
	}
};

export const deleteRestriction = (id) => async (dispatch) => {
	try {
		const response = await fetch(
			process.env.REACT_APP_BACKEND_URL +
				API_ADMIN_GEOBLOCK_RESTRICTIONS_DELETE_ROUTE +
				id +
				"/delete",
			{
				method: "DELETE",
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
					Authorization: `Bearer ${sessionStorage.getItem("token")}`
				}
				//credentials: 'include',
			}
		);
		//response.data
		const data = await response.json();
		if (response.status >= 200 && response.status <= 299) {
			dispatch(getGeoblockRestrictions());
			dispatch(setMessage(data.message, data.type));
		} else {
			dispatch(setError(data));
			/*  dispatch(userLogout());
             sessionStorage.removeItem("token"); */
		}
	} catch (error) {
		setMessage(error, "error");
	}
};

export const GeoblockSettings = (item) => async (dispatch) => {
	try {
		/*     const options = {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: `Bearer ${sessionStorage.getItem("token")}`,
              },
              //credentials: 'include',
              body: JSON.stringify(item),
            }; */
		const res = await axios.post(
			process.env.REACT_APP_BACKEND_URL + API_FETCH_GEOBLOCK_SETTINGS_ROUTE,
			item,
			{
				withCredentials: true,
				headers: {
					Authorization: `Bearer ${sessionStorage.getItem("token")}`,
					Accept: "application/json",
					"Content-Type": "application/json"
				}
			}
		);

		/* const response = await fetch(
          "https://whxlbo-fr.warehouse-x.io" + API_ADMIN_MODEL_ADD_ROUTE,
          options
        ); */

		if (res.status >= 200 && res.status <= 299) {
			const data = res.data;
			/* dispatch(setGeoblockSettings(res.data.data)); */

			dispatch(setMessage(res.data.message, res.data.type));
			dispatch(getGeoblockSettings());
		}
		/* } else { */
		/*   dispatch(userLogout());
          sessionStorage.removeItem("token"); */
	} catch (error) {
		setMessage(error, "error");
	}
};

export const getGeoblockSettings = () => async (dispatch) => {
	try {
		dispatch(setLoadings("test"));
		const res = await axios.get(
			process.env.REACT_APP_BACKEND_URL + API_FETCH_GEOBLOCK_SETTINGS_ROUTE,

			{
				withCredentials: true,
				headers: {
					Authorization: `Bearer ${sessionStorage.getItem("token")}`,
					Accept: "application/json",
					"Content-Type": "application/json"
				}
			}
		);
		if (res.status == 200) {
			dispatch(setGeoblockSettings(res.data.data));
		}
	} catch (error) {
		console.log(error);
	}
};
