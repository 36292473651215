import * as React from "react";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import {
	Autocomplete,
	Backdrop,
	Box,
	Container,
	Icon,
	Input,
	Link,
	Modal,
	Stack,
	Table,
	TableBody,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Typography
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { createTheme, styled, ThemeProvider } from "@mui/material/styles";
import GirlIcon from "@material-ui/icons/Face";
import { connect, useDispatch, useSelector } from "react-redux";
import ActivePools from "./ActivePools";
import { CreatePool, GetPools } from "../../redux/actions/PoolsActions";
import ClearIcon from "@mui/icons-material/Clear";
import IconButton from "@mui/material/IconButton";
import Stars from "../../assets/perf.svg";
import moment from "moment";

const mapStateToProps = (state) => {
	return {
		performers: state.models,
		pools: state.pools
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		GetPools: () => dispatch(GetPools())
	};
};

function Pools({ pools, GetPools }) {
	React.useEffect(() => {
		GetPools();
	}, []);
	const dispatch = useDispatch();

	const theme = createTheme({
		palette: {
			success: {
				// Purple and green play nicely together.
				main: "#78F73B"
			},
			error: {
				// This is green.A700 as hex.
				main: "#F73B3B"
			}
		}
	});

	const [inputList, setInputList] = React.useState([
		{
			description: "",
			credits_target: "",
			minutes: "",
			hours: "",
			participants: []
		}
	]);
	const handleAddClick = () => {
		setInputList([
			...inputList,
			{
				description: "",
				credits_target: "",
				minutes: "",
				hours: "",
				participants: []
			}
		]);
	};
	const handleInputChange = (e, index) => {
		const { name, value } = e.target;
		const list = [...inputList];
		list[index][name] = value;
		setInputList(list);
	};
	const handleRemoveClick = (index) => {
		const list = [...inputList];
		list.splice(index, 1);
		setInputList(list);
	};

	const [characterLimit] = React.useState(70);
	const [selectedOptions, setSelectedOptions] = React.useState([]);
	const [selected, setSelected] = React.useState([]);
	const [addFormData, setAddFormData] = React.useState([]);

	const handleAutocompleteChange = (target) => {
		//event.preventDefault();
		setSelected(target.target.value);
		var task_names = target.target.value.map(function (task, index, array) {
			return task.id;
		});
		setSelectedOptions(task_names);
		const list = [...inputList];
		list[target.target.index]["participants"] = target.target.value;
		setInputList(list);
	};

	const handleSubmit = (e, index) => {
		e.preventDefault();
		const list = [...inputList];
		var task_names = list[index].participants.map(
			function (task, index, array) {
				return task.id;
			}
		);
		const obj = {
			description: inputList[index].description,
			credits_target: inputList[index].credits_target,
			minutes: inputList[index].minutes,
			hours: inputList[index].hours,
			participants: task_names
		};
		dispatch(CreatePool(obj));
		list.splice(index, 1);
		setInputList(list);
	};
	const [open, setOpen] = React.useState(false);

	const handleOpen = () => {
		setOpen(true);
	};
	const handleClose = () => setOpen(false);

	const style = {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		maxWidth: 1200,
		minWidth: 850,
		minHeight: 350,
		maxHeight: 500,
		/* width: '100%', */
		bgcolor: "background.paper",
		textAlign: "center",
		margin: "0 auto",
		p: 4
	};

	const StyledTableRow = styled(TableRow)(({ theme }) => ({
		"&:nth-of-type(odd)": {
			backgroundColor: "rgb(0 0 0)"
		},
		"&:nth-of-type(even)": {
			backgroundColor: "#ffffff14"
		},
		// hide last border
		"&:last-child td, &:last-child th": {
			border: 0
		},
		"&.MuiTableRow-root:hover": {
			backgroundColor: "#555555"
		}
	}));

	const StyledTableCell = styled(TableCell)(({ theme }) => ({
		[`&.${tableCellClasses.head}`]: {
			/*    backgroundColor: "#292929", */
			color: theme.palette.common.white
		},
		[`&.${tableCellClasses.body}`]: {
			fontSize: 14
		}
	}));

	function getTimeStamp(timeCreated, TimeEnded) {
		const endDate = moment(TimeEnded);
		const startDate = moment(timeCreated);
		const diff = endDate - startDate;
		var diffDuration = moment.duration(diff);
		const days = diffDuration.days();
		const hours = diffDuration.hours() + days * 24; // get hours with getHours method
		const minutes = diffDuration.minutes();
		const seconds = diffDuration.seconds(); // get minutes with getMinutes method // get seconds with getSeconds method
		const timeString = `${pad(hours)}h ${pad(minutes)}m ${pad(seconds)}s`;
		return timeString; // finally, join to a time string
	}

	function pad(num) {
		return ("0" + num).slice(-2);
	}

	const loading = useSelector((state) => state.loading);
	return (
		<>
			<Stack
				spacing={3}
				sx={{ width: 1300, marginLeft: "290px", marginTop: "40px" }}
			>
				{loading && loading.isLoading == true ? (
					<div className="loader-container">
						<div className="spinner"></div>
					</div>
				) : (
					""
				)}
				<Box
					style={{ marginTop: loading && loading.isLoading == true && "0px" }}
				>
					<Grid
						container
						spacing={0}
						style={{ display: "flex", flexDirection: "column" }}
					>
						<Grid item xs={4} md={2} spacing={0} className="Pools">
							<Typography
								variant="h4"
								gutterBottom
								component="div"
								style={{ color: "white", marginBottom: "unset" }}
							>
								Ongoing Pools
							</Typography>
							<Button
								/*  size="small" */
								variant="contained"
								style={{
									color: "black",
									backgroundColor: "#78F73B",
									maxWidth: "140px",
									maxHeight: "40px",
									minWidth: "140px",
									minHeight: "40px",
									borderRadius: "5px",
									textTransform: "unset",
									marginLeft: "20px",
									fontSize: "16px"
								}}
								onClick={handleAddClick}
								className="create"
							>
								Create New
							</Button>
							<Typography className="modal18_terms">
								<Link
									onClick={(e) => {
										handleOpen(e);
									}}
									color="inherit"
									style={{
										marginLeft: "20px",
										width: "160%",
										display: "flex",
										justifyContent: "flex-end"
									}}
								>
									See Archived Pools
								</Link>
							</Typography>
						</Grid>
						{inputList.map((x, i) => {
							return (
								<Box
									component="div"
									sx={{
										flexGrow: 1,
										maxWidth: "650px",
										margin: "0",
										height: "100%"
										/* minHeight: "500px", */
									}}
								>
									<Container
										maxWidth="xl"
										style={{
											color: "#ffffff",
											width: "100%",
											height: "100%",
											backgroundColor: "#000000",
											borderRadius: "5px"
										}}
										sx={{ my: 3 }}
										className="PoolContainer"
									>
										<Grid spacing={1} className="Pool_wrap">
											<Grid item xs={12}>
												<Box component="div" className="Pool_main">
													<Stack direction="row" className="Pools_row">
														<Input
															value={x.description}
															name="description"
															onChange={(e) => {
																handleInputChange(e, i);
															}}
															placeholder="Perfomance description"
															className="Pools_input_text"
															disableUnderline="true"
															inputProps={{ maxLength: 70 }}
														></Input>
														<Typography
															color={
																x.description.length >= characterLimit
																	? "red"
																	: "white"
															}
															style={{ position: "absolute", left: "890px" }}
														>
															{x.description.length}/{characterLimit}
														</Typography>
													</Stack>
													<Stack className="Pools_row">
														<Autocomplete
															value={x.participants}
															underlineStyle={{ display: "none" }}
															name="participants"
															className="PoolLazyInput"
															multiple
															id="combo-box-demo"
															onChange={(event, value) => {
																handleAutocompleteChange({
																	target: {
																		value: value,
																		index: i
																	}
																});
																handleInputChange(event, i);
															}}
															options={pools.models ? pools.models : []}
															getOptionLabel={(option) =>
																option.inHouseName || ""
															}
															renderInput={(params) => (
																<TextField
																	{...params}
																	disabled
																	placeholder="Housemates"
																	name="participants"
																	variant="standard"
																/>
															)}
														/>
													</Stack>
													<Stack direction="row" className="Pools_row">
														<Typography width={"50px"}>Target</Typography>
														<Input
															value={x.credits_target}
															name="credits_target"
															placeholder="Credits"
															onChange={(e) => {
																handleInputChange(e, i);
															}}
															className="Pools_input_credits"
															disableUnderline="true"
															inputProps={{ maxLength: 70 }}
														></Input>
													</Stack>
													<Stack direction="row" className="Pools_row">
														<Typography width={"50px"}>Time</Typography>
														<Input
															value={x.hours}
															name="hours"
															onChange={(e) => {
																handleInputChange(e, i);
															}}
															placeholder="HH"
															className="Pools_input_time"
															disableUnderline="true"
															inputProps={{ maxLength: 70 }}
														></Input>
														<Input
															value={x.minutes}
															name="minutes"
															onChange={(e) => {
																handleInputChange(e, i);
															}}
															placeholder="MM"
															className="Pools_input_time"
															disableUnderline="true"
															inputProps={{ maxLength: 70 }}
														></Input>
														<ThemeProvider theme={theme}>
															<Button
																size="medium"
																variant="outlined"
																/* color="success" */
																style={{
																	paddingLeft: "15px",
																	marginLeft: "10px",
																	maxWidth: "90px"
																}}
																color={"success"}
																onClick={(e) => {
																	handleSubmit(e, i);
																}}
															>
																{"Create"}
															</Button>
														</ThemeProvider>
														<Grid
															style={{
																width: "65%",
																display: "flex",
																justifyContent: "flex-end"
															}}
														>
															<ThemeProvider theme={theme}>
																<Button
																	onClick={() => handleRemoveClick(i)}
																	size="medium"
																	variant="outlined"
																	/* color="success" */
																	style={{
																		paddingLeft: "15px",
																		marginLeft: "10px",
																		maxWidth: "90px"
																	}}
																	color={"error"}
																>
																	{"Cancel"}
																</Button>
															</ThemeProvider>
														</Grid>
													</Stack>
												</Box>
											</Grid>
										</Grid>
									</Container>
								</Box>
							);
						})}
						{pools &&
							pools.pools &&
							pools.pools.progress &&
							pools.pools.progress.map((pool) => {
								return <ActivePools models={pools.models} pool={pool} />;
							})}
					</Grid>
				</Box>
			</Stack>
			<Modal
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
				open={open}
				onClose={handleClose}
				closeAfterTransition
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500
				}}
				/*    className="camera_details" */

				//onClose={handleClose}
			>
				<Box sx={style} className="PoolModal">
					<IconButton className="BackButton" onClick={handleClose}>
						<ClearIcon />
					</IconButton>
					<TableContainer className="SchrollBar ModalTable" component={Paper}>
						<Table aria-label="simple table" stickyHeader>
							<TableHead>
								<StyledTableRow>
									<StyledTableCell
										style={{ borderBottom: "none" }}
										align="left"
									>
										Pool & Users
									</StyledTableCell>
									<StyledTableCell
										style={{ borderBottom: "none" }}
										align="left"
									>
										Time
									</StyledTableCell>
									<StyledTableCell
										style={{ borderBottom: "none" }}
										align="left"
									>
										Target
									</StyledTableCell>
									<StyledTableCell
										style={{ borderBottom: "none" }}
										align="right"
									>
										Status
									</StyledTableCell>
								</StyledTableRow>
							</TableHead>
							<TableBody>
								{pools &&
									pools.pools &&
									pools.pools.archived &&
									pools.pools.archived.map((row) => (
										<StyledTableRow
											key={row.id}
											sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
										>
											<TableCell
												align="left"
												className="Pool_Table_info"
												style={{ borderBottom: "none", color: "#FFD800" }}
											>
												<div
													className="Pool_Table_icons"
													style={{ paddingBottom: "10px", color: "white" }}
												>
													<Icon
														style={{
															display: "flex",
															justifycontent: "center",
															alignItems: "center",
															marginRight: "10px"
														}}
													>
														<img src={Stars} color="white" />
													</Icon>
													{row.description}
												</div>
												<div
													className="Pool_Table_icons"
													style={{ color: "#78F73B" }}
												>
													<GirlIcon
														style={{ marginRight: "10px", color: "white" }}
													/>
													{[
														row.performers.map(function (task, index, array) {
															return (index ? ", " : "") + task.inHouseName;
														})
													]}
												</div>
											</TableCell>
											<TableCell
												align="left"
												style={{ borderBottom: "none", color: "#FFD800" }}
											>
												<div
													className="Pool_Table_icons"
													style={{ marginBottom: "17px", color: "white" }}
												>
													{getTimeStamp(row.createdAt, row.updated)}
												</div>
												<div className="Pool_Table_icons">
													<p></p>
												</div>
											</TableCell>
											<TableCell
												align="left"
												style={{ borderBottom: "none", color: "#FFD800" }}
											>
												<div
													className="Pool_Table_icons"
													style={{ marginBottom: "17px", color: "#78F73B" }}
												>
													{row.credits_target}
												</div>
												<div className="Pool_Table_icons">
													<p></p>
												</div>
											</TableCell>
											<TableCell
												align="right"
												style={{ borderBottom: "none", color: "#FFD800" }}
											>
												<div
													className="Pool_Table_icons"
													style={{ marginBottom: "17px", color: "#78F73B" }}
												>
													{row.status}
												</div>
												<div className="Pool_Table_icons">
													<p></p>
												</div>
											</TableCell>
										</StyledTableRow>
									))}
							</TableBody>
						</Table>
					</TableContainer>
				</Box>
			</Modal>
		</>
	);
}

export default connect(mapStateToProps, mapDispatchToProps)(Pools);
