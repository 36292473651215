import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import {
	CardActionArea,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	TextareaAutosize
} from "@mui/material";
import { Backdrop } from "@mui/material";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import CssBaseline from "@mui/material/CssBaseline";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import Logo from "../../assets/Logo.svg";
import {
	assignCameraName,
	changeCameraRecord,
	changeCameraSDN,
	changeCameraStatus,
	setCameraActivity
} from "../../redux/actions/cameraAction";
import { useDispatch, useSelector } from "react-redux";
import { Input } from "@material-ui/core";
import CheckIcon from "@mui/icons-material/Check";
import { assignLiveShowName } from "../../redux/actions/liveshowAction";
import Skeleton from "@mui/material/Skeleton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { setLoading } from "../../redux/reducers/rootReducer";
import ReactHlsPlayer from "react-hls-player/dist";
import VideoCameraBackIcon from "@mui/icons-material/VideoCameraBack";
import CloseIcon from "@mui/icons-material/Close";
import JWPlayer from "@jwplayer/jwplayer-react";
import CanvasJSReact from "./assets/canvasjs.react.js";
import { useRef } from "react";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: "fit-content",
	bgcolor: "background.paper",
	border: "2px solid #000",
	textAlign: "center",
	margin: "0 auto",
	p: 4
};
const theme = createTheme({
	palette: {
		success: {
			// Purple and green play nicely together.
			main: "#78F73B"
		},
		error: {
			// This is green.A700 as hex.
			main: "#F73B3B"
		},
		off: {
			main: "#800000"
		},
		on: {
			main: "#7CFC00"
		}
	}
});
export default function CameraCardActivity({ camera }) {
	const dispatch = useDispatch();
	const [open, setOpen] = React.useState(false);
	const mainVideoScreen = React.useRef();
	const [mute, setMute] = React.useState(true);
	const [volume, setVolume] = React.useState(50);
	const [prevent, setPrevent] = React.useState(true);
	let newHeight;
	const handleOpen = () => {
		setOpen(true);
	};
	const handleClose = () => setOpen(false);

	const [addFormData, setAddFormData] = React.useState({});
	const [log, setLog] = React.useState("");
	const [check, setcheck] = React.useState(false);

	React.useEffect(() => {
		if (camera && camera.hls_url) {
			var fragmentExtension = ".ts";
			var originalOpen = XMLHttpRequest.prototype.open;
			const url = new URL(camera.hls_url);

			const queryParams = new URLSearchParams(url.search);
			const subscriberCode = queryParams.get("subscriberCode");
			const subscriberId = queryParams.get("subscriberId");

			window.XMLHttpRequest.prototype.open = function () {
				if (arguments[1].endsWith(fragmentExtension)) {
					arguments[1] =
						arguments[1] +
						"?" +
						`subscriberId=${subscriberId}&subscriberCode=${subscriberCode}`;
				}
				originalOpen.apply(this, arguments);
			};
		}
	}, [camera]);

	function submitStatus(item, text) {
		setLog(item);
		const newFormData = { ...addFormData };
		if (item == 0) {
			if (text == "showInHome_logged") {
				camera.showInHome_logged = 1;
			} else {
				camera.showInHome_nonlogged = 1;
			}
			newFormData[text] = 1;
		} else {
			if (text == "showInHome_logged") {
				camera.showInHome_logged = 0;
				camera.showInHome_nonlogged = 0;
			} else {
				camera.showInHome_logged = 0;
				camera.showInHome_nonlogged = 0;
			}
			newFormData[text] = 0;
		}
		setAddFormData(camera);
		dispatch(changeCameraStatus(camera.id, camera));
		setcheck(true);
	}
	const loading = useSelector((state) => state.loading);

	React.useEffect(() => {
		if (loading.isLoading == false && check == true) {
			handleClose();
			setcheck(false);
		}
	}, [loading]);
	const heartBeat = camera.recording_status == true ? "record" : "";

	const arr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

	var dataPoints = [];

	//   // exportEnabled: true,

	//   charts: [
	//     {
	//       backgroundColor: "black",
	//       axisX: {
	//         crosshair: {
	//           enabled: true,
	//           snapToDataPoint: true,
	//           valueFormatString: "MMM YYYY",
	//         },
	//       },
	//       axisY: {
	//         crosshair: {
	//           enabled: true,
	//           snapToDataPoint: true,
	//           valueFormatString: "$#,###.00M",
	//         },
	//       },
	//       data: [
	//         {
	//           type: "splineArea",
	//           color: "#77F73B",
	//           xValueFormatString: "MMM YYYY",
	//           yValueFormatString: "$#,###.##M",
	//           dataPoints: dataPoints,
	//         },
	//       ],
	//     },
	//   ],
	//   // navigator: {
	//   //   slider: {
	//   //     minimum: new Date(2010, 00, 01),
	//   //     maximum: new Date(2018, 00, 01),
	//   //   },
	//   // },
	// });

	$.getJSON(
		"https://canvasjs.com/data/gallery/stock-chart/grocery-sales.json",
		function (data) {
			for (var i = 0; i < data.length; i++) {
				dataPoints.push({
					x: new Date(data[i].date),
					y: Number(data[i].sale)
				});
			}
		}
	);

	const options = {
		animationEnabled: true,
		backgroundColor: "black",
		color: "white",
		toolTip: {
			cornerRadius: 10,
			fontColor: "black"
		},
		stripLines: {
			cornerRadius: 10
		},
		axisX: {
			crosshair: {
				enabled: true,
				snapToDataPoint: false,
				valueFormatString: "MMM YYYY",
				labelFontColor: "white"
			},
			labelFontColor: "white",
			lineThickness: 0
			// tickThickness: 0,
		},
		axisY: {
			crosshair: {
				enabled: true,
				snapToDataPoint: false
			},
			labelFontColor: "white",
			lineThickness: 0,
			gridThickness: 0,
			tickLength: 0,
			maximum: 6000
			// stripLines: [
			//   {
			//     value: 0,
			//     showOnTop: true,
			//     color: "gray",
			//     thickness: 2,
			//   },
			// ],
		},
		data: [
			{
				type: "splineArea",
				color: "#77F73B",
				indexLabelFontColor: "white",
				// xValueFormatString: "YYYY",
				// yValueFormatString: "#,##0.## bn kW⋅h",
				click: function (e) {
					// alert(  e.dataSeries.type+ ", dataPoint { x:" + e.dataPoint.x + ", y: "+ e.dataPoint.y + " }" );
					e?.chart?.axisX[0]?.stripLines[0]?.remove();
					e.chart.axisX[0].addTo("stripLines", {
						value: e.dataPoint.x,
						showOnTop: true
					});
					e.chart.axisX[0].stripLines[0].set("color", "#40e0d0", false);
					e.chart.axisX[0].stripLines[0].set("thickness", 2);

					this.disabled = true;
					SeekTo(e.dataPoint.y);
					// alert("Index:" + e.dataPointIndex + " Pixel Values: {x:" +e.x+", y:"+e.y+"}");
				},
				// showInLegend: true,
				dataPoints: [
					{ x: new Date(2008, 0), y: 600 },
					{ x: new Date(2009, 0), y: 1200 },
					{ x: new Date(2010, 0), y: 1800 },
					{ x: new Date(2011, 0), y: 2400 },
					{ x: new Date(2012, 0), y: 3000 },
					{ x: new Date(2013, 0), y: 3600 },
					{ x: new Date(2014, 0), y: 4200 },
					{ x: new Date(2015, 0), y: 4800 },
					{ x: new Date(2016, 0), y: 5400 },
					{ x: new Date(2017, 0), y: 6000 }
				]
			}
		]
	};

	var CanvasJS = CanvasJSReact.CanvasJS;
	var CanvasJSChart = CanvasJSReact.CanvasJSChart;

	return (
		<>
			<Box
				style={{
					flexBasis: "30%",

					borderRadius: "10px",
					padding: "15px",
					backgroundColor: "black"
				}}
			>
				<Grid
					style={{
						display: "flex",
						flexDirection: "column",
						gap: "5px"
						// position: "relative",
						// width: "100%",
					}}
				>
					<div style={{ position: "relative" }}>
						<Typography
							gutterBottom
							variant="h6"
							component="span"
							style={{
								position: "absolute",
								padding: "0px 5px",
								bottom: "5px",
								right: "5px",
								textAlign: "right"
							}}
						>
							{camera.name}
						</Typography>
						<ReactHlsPlayer
							id="main_video"
							src={camera.hls_url}
							controls={false}
							style={{
								width: "100%",
								aspectRatio: "16/9",
								borderRadius: "5px"
							}}
							muted={true}
							autoPlay
						/>
					</div>

					<Typography>Rate the activity</Typography>
					<Grid
						style={{
							display: "flex",
							flexDirection: "row",
							border: "1px solid #707070",
							borderRadius: "10px"
						}}
					>
						{arr.map((item, index) => {
							return (
								<Button
									className="create"
									onClick={() => {
										dispatch(setCameraActivity(camera.id, item));
									}}
									style={{
										width: "-webkit-fill-available",
										minWidth: "unset",
										height: "-webkit-fill-available",
										color: "white",
										background: index % 2 === 0 ? "black" : "#212121",
										borderRadius:
											index == 0
												? "10px 0px 0px 10px"
												: index == arr.length - 1
													? "0px 10px 10px 0px"
													: "0px",
										padding: "10px"
									}}
								>
									{item}
								</Button>
							);
						})}
					</Grid>
				</Grid>
			</Box>
		</>
	);
}
