import thunk from "redux-thunk";
import { applyMiddleware, createStore } from "redux";
import { rootReducer } from "./reducers/rootReducer";
import { initialState } from "./state";
import { composeWithDevTools } from "redux-devtools-extension";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";

const persistConfig = {
	key: "root",
	storage,
	whitelist: [
		"session",
		"admin",
		"models",
		"model",
		"blog",
		"blog_comments",
		"blogs",
		"loading",
		"model_media",
		"liveshows",
		"liveshowShedule",
		"liveshow",
		"cameras",
		"time_picker",
		"chat",
		"messages",
		"filters",
		"chats",
		"models_earnings",
		"users_transactions",
		"message",
		"chat_messages",
		"modal",
		"model_earnings",
		"pools",
		"user_transactions",
		"dashboard",
		"promos",
		"promo",
		"events",
		"galleries",
		"gallery",
		"models_order",
		"errors",
		"magics",
		"sidebar",
		"user_subscriptions",
		"liveshow_tab",
		"environment",
		"tube",
		"microphones",
		"whx_event"
	]
};

const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = createStore(
	persistedReducer,
	initialState,
	composeWithDevTools(applyMiddleware(thunk))
);
const Persistor = persistStore(store);

export { store };
export { Persistor };
