import { Box, Button, CircularProgress } from "@mui/material";
import React from "react";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import ExpandLessRoundedIcon from "@mui/icons-material/ExpandLessRounded";

export default function ShowMoreButton({
	showMore,
	onClick,
	loading = false,
	withIcon = false
}) {
	return (
		<Box className="show-more-button">
			<Button
				variant="text"
				disabled={loading}
				onClick={onClick}
				sx={{ textTransform: "unset" }}
			>
				{showMore ? "Show more" : "Show less"}
				{withIcon ? (
					<>
						{loading ? (
							<CircularProgress
								color="white"
								sx={{
									width: "14px !important",
									height: "14px !important",
									ml: 1
								}}
							/>
						) : showMore ? (
							<ExpandMoreRoundedIcon />
						) : (
							<ExpandLessRoundedIcon />
						)}
					</>
				) : (
					loading && (
						<CircularProgress
							color="white"
							sx={{
								width: "20px !important",
								height: "20px !important",
								ml: 1
							}}
						/>
					)
				)}
			</Button>
		</Box>
	);
}
