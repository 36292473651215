import * as React from "react";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import VisibilityIcon from "@mui/icons-material/Visibility";
export default function RoomCardModal({ camera }) {
	const cardClass = "cameraCardModal";

	return (
		<Card className={cardClass}>
			<CardMedia
				component="img"
				height="80"
				image={camera.thumbnail_url}
				title={camera.name}
			/>
			<Typography
				gutterBottom
				variant="h7"
				component="span"
				className="BadgeTextModal"
			>
				{camera.name}
			</Typography>
			<Typography
				gutterBottom
				variant="h7"
				component="span"
				className="CurrentOnline_Modal"
			>
				<VisibilityIcon fontSize="small" style={{ marginRight: "3px" }} />{" "}
				{camera.viewers_count}
			</Typography>
		</Card>
	);
}
