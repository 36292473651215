import * as React from "react";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import {
	Backdrop,
	Box,
	Checkbox,
	Container,
	FormControlLabel,
	FormGroup,
	Input,
	Modal,
	Stack,
	Table,
	TableBody,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Typography
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { createTheme, styled } from "@mui/material/styles";
import { connect, useDispatch, useSelector } from "react-redux";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useParams } from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import {
	LocalizationProvider,
	MobileDateTimePicker
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import {
	CreateEvent,
	deleteEvent,
	getEvents,
	UpdateEvent
} from "../../redux/actions/EventsActions";
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";

const mapStateToProps = (state) => {
	return {
		events: state.events
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		getEvents: (month, year) => dispatch(getEvents(month, year))
	};
};

function Events({ events, getEvents }) {
	React.useEffect(() => {
		getEvents(0, 0);
	}, []);
	const dispatch = useDispatch();
	const param = useParams();
	const theme = createTheme({
		palette: {
			success: {
				// Purple and green play nicely together.
				main: "#78F73B"
			},
			error: {
				// This is green.A700 as hex.
				main: "#FF0000"
			}
		}
	});

	const data = [
		{ id: 0, name: "January" },
		{ id: 1, name: "February" },
		{ id: 2, name: "March" },
		{ id: 3, name: "April" },
		{ id: 4, name: "May" },
		{ id: 5, name: "June" },
		{ id: 6, name: "July" },
		{ id: 7, name: "August" },
		{ id: 8, name: "September" },
		{ id: 9, name: "October" },
		{ id: 10, name: "November" },
		{ id: 11, name: "December" }
	];
	const [month, setMonth] = React.useState(undefined);
	const [year, setYear] = React.useState(undefined);

	function getIndex(name) {
		return data.findIndex((obj) => obj.name == name);
	}

	function cameraChangeButtonForward() {
		var index = getIndex(events.current_month);
		var year = events.current_year;
		var nextid = index + 1;
		if (nextid == data.length) {
			nextid = 0;
			events.current_year += 1;
			setYear(events.current_year);
		}
		events.current_month = data[nextid].name;
		setYear(events.current_year);
		setMonth(data[nextid].name);
	}

	function cameraChangeButtonBack() {
		var index = getIndex(events.current_month);
		var year = events.current_year;
		var nextid = index - 1;
		if (nextid < 0) {
			nextid = data.length - 1;
			events.current_year -= 1;
			setYear(events.current_year);
		}
		events.current_month = data[nextid].name;
		setYear(events.current_year);
		setMonth(data[nextid].name);
	}

	React.useEffect(() => {
		if (month != undefined || year != undefined) {
			getEvents(month, year);
		}
	}, [month, year]);

	const [inputList, setInputList] = React.useState({
		description: "",
		videoUrl: "",
		hlsVideoUrl: "",
		highlight_thumbnail: ""
	});
	const [create, setCreate] = React.useState(false);
	const handleAddClick = () => {
		setInputList([
			{
				description: "",
				credits_target: "",
				minutes: "",
				hours: "",
				participants: []
			}
		]);
		setOpen(true);
	};
	const handleInputChange = (e) => {
		e.preventDefault();
		const fieldName = e.target.getAttribute("name");
		const fieldValue = e.target.value;
		const newFormData = { ...inputList };
		newFormData[fieldName] = fieldValue;
		setInputList(newFormData);
	};
	const handleRemoveClick = (index) => {
		const list = [...inputList];
		list.splice(index, 1);
		setInputList(list);
	};

	const [characterLimit] = React.useState(70);
	const [selectedOptions, setSelectedOptions] = React.useState([]);
	const [selected, setSelected] = React.useState([]);
	const [addFormData, setAddFormData] = React.useState([]);

	const handleAutocompleteChange = (target) => {
		//event.preventDefault();
		setSelected(target.target.value);
		var task_names = target.target.value.map(function (task, index, array) {
			return task.id;
		});
		setSelectedOptions(task_names);
		const list = [...inputList];
		list[target.target.index]["participants"] = target.target.value;
		setInputList(list);
	};
	const [events_details, setEventsDetails] = React.useState({});
	const [event_visible, setEventVisible] = React.useState(
		events_details && events_details.events_visible
			? events_details.events_visible
			: true
	);
	const [highlights_visible, setHighlightsVisible] = React.useState(
		events_details && events_details.highlights_visible
	);
	const [dateWithNoInitialValue, setDateWithNoInitialValue] =
		React.useState(null);
	const [dateWithInitialValue, setDateWithInitialValue] = React.useState(
		dayjs(new Date())
	);
	const handleSubmit = (e) => {
		e.preventDefault();
		let obj;
		if (dateWithInitialValue.$d == undefined) {
			const date = new Date(dateWithInitialValue);
			obj = {
				description: inputList.description,
				event_time: new Date(date - date.getTimezoneOffset() * 60 * 1000),
				event_global_time: date,
				highlight: inputList.videoUrl,
				highlight_hls_url: inputList.hlsVideoUrl,
				highlights_visible: highlights_visible ? highlights_visible : null,
				events_visible: event_visible,
				highlight_thumbnail: inputList.highlight_thumbnail
			};
		} else {
			obj = {
				description: inputList.description,
				event_time: new Date(
					dateWithInitialValue.$d.getTime() -
						dateWithInitialValue.$d.getTimezoneOffset() * 60 * 1000
				),
				event_global_time: dateWithInitialValue.$d,
				highlight: inputList.videoUrl,
				highlight_hls_url: inputList.hlsVideoUrl,
				highlights_visible: highlights_visible ? highlights_visible : null,
				events_visible: event_visible,
				highlight_thumbnail: inputList.highlight_thumbnail
			};
		}
		/*     dispatch(CreatePool(obj)); */
		if (create == true) {
			dispatch(CreateEvent(obj));
			setCreate(false);
			handleClose();
		} else {
			dispatch(UpdateEvent(obj, events_details.id));
			handleClose();
		}
		setInputList({ description: "" });
	};
	const handleDelete = (e) => {
		e.preventDefault();

		dispatch(deleteEvent(events_details.id));
		handleClose();
		setInputList({ description: "" });
	};

	const [open, setOpen] = React.useState(false);

	const handleOpen = () => {
		setOpen(true);
	};
	const handleClose = () => setOpen(false);

	const style = {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		maxWidth: 508,
		minWidth: 508,
		minHeight: "fit-content",
		maxHeight: "fit-content",
		/* width: '100%', */
		background: "#000000 0% 0% no-repeat padding-box",
		boxShadow: "0px 0px 30px #000000",
		border: "1px solid #333333",
		borderRadius: "5px",
		textAlign: "center",
		margin: "0 auto",
		p: 4
	};

	const StyledTableRow = styled(TableRow)(({ theme }) => ({
		"&:nth-of-type(odd)": {
			backgroundColor: "rgb(0 0 0)",
			height: 30,
			padding: "0px 16px"
		},
		"&:nth-of-type(even)": {
			backgroundColor: "#ffffff14",
			height: 30,
			padding: "0px 16px"
		},
		// hide last border
		"&:last-child td, &:last-child th": {
			border: 0
		},
		"&.MuiTableRow-root:hover": {
			backgroundColor: "#555555"
		}
	}));

	const StyledTableCell = styled(TableCell)(({ theme }) => ({
		[`&.${tableCellClasses.head}`]: {
			backgroundColor: "#292929",
			color: theme.palette.common.white
		},
		[`&.${tableCellClasses.body}`]: {
			fontSize: 14
		}
	}));

	const rows = events && events.events;

	const loading = useSelector((state) => state.loading);

	return (
		<>
			<Stack
				spacing={3}
				sx={{ width: 1300, marginLeft: "290px", marginTop: "40px" }}
			>
				{loading && loading.isLoading == true ? (
					<div className="loader-container">
						<div className="spinner"></div>
					</div>
				) : (
					""
				)}
				<Box
					style={{ marginTop: loading && loading.isLoading == true && "0px" }}
				>
					<Grid
						container
						spacing={0}
						style={{ display: "flex", flexDirection: "column" }}
					>
						<Grid item xs={4} md={2} spacing={0} className="Pools">
							<Typography
								variant="h4"
								gutterBottom
								component="div"
								style={{ color: "white", marginBottom: "unset" }}
							>
								Events Schedule
							</Typography>
							<Button
								/*  size="small" */
								variant="contained"
								style={{
									color: "black",
									backgroundColor: "#78F73B",
									maxWidth: "140px",
									maxHeight: "40px",
									minWidth: "140px",
									minHeight: "40px",
									borderRadius: "5px",
									textTransform: "unset",
									marginLeft: "20px",
									fontSize: "16px"
								}}
								onClick={(e) => {
									handleOpen(true);
									setEventVisible(true);
									setHighlightsVisible(false);
									setDateWithInitialValue(dayjs(new Date()));
									setEventsDetails();
									setCreate(true);
								}}
								className="create"
							>
								Create New
							</Button>
						</Grid>
						<Box
							style={{
								display: "flex",
								justifyContent: "flex-start",
								marginTop: "50px"
							}}
						>
							<Box className="DashBoard_box" style={{ paddingRight: "20px" }}>
								<Button
									onClick={() => cameraChangeButtonBack()}
									startIcon={<ArrowBackIosIcon />}
									className="ModelProfilePage_arrow_back"
									variant="contained"
								>
									Previous
								</Button>
							</Box>
							<Box className="DashBoard_box" style={{ paddingRight: "20px" }}>
								<Box className="Date">
									<Typography
										variant="h6"
										component="div"
										style={{
											color: "white",
											minWidth: "170px",
											display: "flex",
											alignItems: "center",
											justifyContent: "center"
										}}
									>
										{events && events.current_month}
										&nbsp;
										{events && events.current_year}
									</Typography>
								</Box>
							</Box>
							<Box className="DashBoard_box" style={{ paddingRight: "20px" }}>
								<Button
									onClick={() => cameraChangeButtonForward()}
									endIcon={<ArrowForwardIosIcon />}
									className="ModelProfilePage_arrow_next"
									variant="contained"
								>
									Next
								</Button>
							</Box>
						</Box>
						<TableContainer
							className="SchrollBar_Geo"
							component={Paper}
							style={{
								overflow: "auto",
								marginRight: "40px",
								maxWidth: "1000px",
								marginTop: "20px",
								borderRadius: "5px",
								boxShadow: "0px 0px 20px #00000029"
							}}
							sx={{ maxHeight: "500px" }}
						>
							<Table aria-label="simple table" stickyHeader>
								<TableHead>
									<StyledTableRow>
										<StyledTableCell
											style={{ borderBottom: "none" }}
											align="left"
										>
											Date
										</StyledTableCell>
										<StyledTableCell
											style={{ borderBottom: "none" }}
											align="left"
										>
											Local Time
										</StyledTableCell>
										<StyledTableCell
											style={{ borderBottom: "none" }}
											align="left"
										>
											UTC Time
										</StyledTableCell>
										<StyledTableCell
											style={{ borderBottom: "none" }}
											align="left"
										>
											Event
										</StyledTableCell>
										<StyledTableCell
											style={{ borderBottom: "none" }}
											align="left"
										>
											Views
										</StyledTableCell>
										<StyledTableCell
											style={{ borderBottom: "none" }}
											align="right"
										></StyledTableCell>
										<StyledTableCell
											style={{ borderBottom: "none" }}
											align="right"
										></StyledTableCell>
									</StyledTableRow>
								</TableHead>
								<TableBody>
									{rows &&
										rows.map((row) => (
											<StyledTableRow
												key={row.id}
												sx={{
													"&:last-child td, &:last-child th": { border: 0 }
												}}
												style={{ height: 50 }}
											>
												<TableCell
													align="left"
													style={{
														borderBottom: "none",
														color: "#FFD800"
													}}
												>
													{row.date}
												</TableCell>
												<TableCell
													align="left"
													style={{ borderBottom: "none", color: "white" }}
												>
													{row.localTime}
												</TableCell>
												<TableCell
													align="left"
													style={{ borderBottom: "none", color: "white" }}
												>
													{row.globalTime} (UTC)
												</TableCell>
												<TableCell
													align="left"
													style={{ borderBottom: "none", color: "#77F73B" }}
												>
													{row.event}
												</TableCell>
												<TableCell
													align="left"
													style={{ borderBottom: "none", color: "#77F73B" }}
												>
													{row.highlight_views_count}
												</TableCell>
												<TableCell
													align="right"
													style={{ borderBottom: "none", color: "white" }}
												>
													{row.highlight && (
														<VideoLibraryIcon
															fontSize="small"
															style={{ color: "#77F73B" }}
														/>
													)}
												</TableCell>
												<TableCell
													align="right"
													style={{ borderBottom: "none", color: "white" }}
												>
													<Button
														style={{ color: "#77F73B", width: "10px" }}
														type="button"
														onClick={(e) => {
															handleOpen(e);
															setEventsDetails(row);
															setEventVisible(row.events_visible);
															setHighlightsVisible(row.highlights_visible);
															setInputList({ description: row.event });
															setDateWithInitialValue(row.event_time);
														}}
													>
														<EditIcon fontSize="small" />
													</Button>
												</TableCell>
											</StyledTableRow>
										))}
								</TableBody>
							</Table>
						</TableContainer>
					</Grid>
				</Box>
				<Modal
					aria-labelledby="transition-modal-title"
					aria-describedby="transition-modal-description"
					open={open}
					onClose={handleClose}
					closeAfterTransition
					BackdropComponent={Backdrop}
					BackdropProps={{
						timeout: 500
					}}
					className="event_details"
				>
					<Box
						sx={style}
						style={{
							marginTop: "50px"
						}}
					>
						{loading && loading.isLoading == true ? (
							<div className="loader-container">
								<div className="spinner"></div>
							</div>
						) : (
							""
						)}
						<IconButton className="BackButton" onClick={handleClose}>
							<ClearIcon />
						</IconButton>
						<Container
							component="main"
							maxWidth="xs"
							style={{
								paddingLeft: "0px",
								paddingRight: "0px"
							}}
						>
							<Box
								sx={{
									marginTop: 2,
									display: "flex",
									flexDirection: "row",
									alignItems: "baseline"
								}}
								md={3}
							>
								<Typography style={{ color: "white", marginRight: "10px" }}>
									Event
								</Typography>
								<ul className="ChatListElem_wrap_live">
									<Input
										className="msg"
										name="description"
										onChange={(e) => {
											handleInputChange(e);
										}}
										defaultValue={events_details && events_details.event}
										key={events_details && events_details.event}
										disableUnderline="true"
										style={{ color: "#78F73B" }}
									></Input>
								</ul>
							</Box>
							<Box
								sx={{
									marginTop: "0px",
									display: "flex",
									flexDirection: "row",
									alignItems: "baseline"
								}}
								md={3}
							>
								<Typography style={{ color: "white", marginRight: "14px" }}>
									Time
								</Typography>
								<LocalizationProvider dateAdapter={AdapterDayjs}>
									<MobileDateTimePicker
										value={dateWithInitialValue && dateWithInitialValue}
										onChange={(newValue) => {
											setDateWithInitialValue(newValue);
										}}
										inputFormat="YYYY/MM/DD hh:mm a"
										mask="____/__/__ __:__ _M"
										renderInput={(params) => <TextField {...params} />}
									/>
								</LocalizationProvider>
								<Typography style={{ color: "white", marginLeft: "10px" }}>
									{"UTC 11:00AM"}
								</Typography>
							</Box>
							<Box
								sx={{
									marginTop: 2,
									display: "flex",
									flexDirection: "row",
									alignItems: "baseline"
								}}
								md={3}
							>
								<Typography style={{ color: "white", marginRight: "10px" }}>
									Video
								</Typography>
								<ul className="ChatListElem_wrap_live">
									<Input
										className="msg"
										name="videoUrl"
										onChange={(e) => {
											handleInputChange(e);
										}}
										defaultValue={events_details && events_details.highlight}
										key={events_details && events_details.highlight}
										disableUnderline="true"
										style={{ color: "#78F73B" }}
									></Input>
								</ul>
							</Box>
							<Box
								sx={{
									marginTop: 2,
									display: "flex",
									flexDirection: "row",
									alignItems: "baseline"
								}}
								md={3}
							>
								<Typography
									style={{
										color: "white",
										marginRight: "10px",
										whiteSpace: "nowrap"
									}}
								>
									Hls Video
								</Typography>
								<ul className="ChatListElem_wrap_live">
									<Input
										className="msg"
										name="hlsVideoUrl"
										onChange={(e) => {
											handleInputChange(e);
										}}
										defaultValue={
											events_details && events_details.highlight_hls_url
										}
										key={events_details && events_details.highlight_hls_url}
										disableUnderline="true"
										style={{ color: "#78F73B" }}
									></Input>
								</ul>
							</Box>
							<Box
								sx={{
									marginTop: 2,
									display: "flex",
									flexDirection: "row",
									alignItems: "baseline"
								}}
								md={3}
							>
								<Typography style={{ color: "white", marginRight: "10px" }}>
									Thumbnail
								</Typography>
								<ul className="ChatListElem_wrap_live">
									<Input
										className="msg"
										name="highlight_thumbnail"
										onChange={(e) => {
											handleInputChange(e);
										}}
										defaultValue={
											events_details && events_details.highlight_thumbnail
										}
										key={events_details && events_details.highlight_thumbnail}
										disableUnderline="true"
										style={{ color: "#78F73B" }}
									></Input>
								</ul>
							</Box>
							<Box
								sx={{
									marginTop: 2,
									display: "flex",
									flexDirection: "row",
									alignItems: "baseline"
								}}
								md={3}
							>
								<FormGroup style={{ display: "flex", flexDirection: "row" }}>
									<FormControlLabel
										value={"first"}
										className="Form"
										style={{ color: "white", marginRight: "40px" }}
										control={
											<Checkbox
												checked={highlights_visible && true}
												style={{
													color: "white",
													"&.Mui-checked": {
														color: "white"
													}
												}}
												onClick={() => {
													setHighlightsVisible(!highlights_visible);
												}}
											/>
										}
										label="Highlights visible"
									/>
									<FormControlLabel
										value={"first"}
										className="Form"
										style={{ color: "white", marginRight: "40px" }}
										control={
											<Checkbox
												checked={event_visible && true}
												style={{
													color: "white",
													"&.Mui-checked": {
														color: "white"
													}
												}}
												onClick={() => {
													setEventVisible(!event_visible);
												}}
											/>
										}
										label="Event visible"
									/>
								</FormGroup>
							</Box>

							<Box
								sx={{
									marginTop: 2,
									display: "flex",
									flexDirection: "column",
									alignItems: "center"
								}}
							>
								<Grid
									container
									spacing={2}
									style={{
										display: "flex",
										justifyContent: "space-between",
										alignItems: "center",
										marginTop: "5px"
									}}
								>
									<Button
										/* fullWidth */
										variant="outlined"
										sx={{ mt: 3, mb: 2 }}
										color="error"
										onClick={(e) => handleDelete(e)}
										style={{
											margin: "0",
											marginBottom: "10px",
											color: "red",
											borderColor: "red",
											textDecoration: "none"
										}}
									>
										Delete
									</Button>
									<Button
										/* fullWidth */
										variant="contained"
										sx={{ mt: 3, mb: 2 }}
										onClick={(e) => handleSubmit(e)}
										color="success"
										style={{
											margin: "0",
											marginBottom: "10px",
											color: "black",
											borderColor: "#78F73B",
											textDecoration: "none",
											backgroundColor: "#78F73B"
										}}
									>
										Save & Publish
									</Button>
								</Grid>
							</Box>
						</Container>
					</Box>
				</Modal>
			</Stack>
		</>
	);
}

export default connect(mapStateToProps, mapDispatchToProps)(Events);
