import * as React from "react";
import Stack from "@mui/material/Stack";
import { Box, Typography } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { connect, useDispatch } from "react-redux";
import { getLiveshows } from "../../redux/actions/liveshowAction";
import { setLiveshowTab, setMessage } from "../../redux/reducers/rootReducer";
import { styled } from "@mui/system";
import Tab from "@mui/material/Tab";
import { TabContext, TabList } from "@mui/lab";
import TabPanel from "@mui/lab/TabPanel";
import Liveshows from "./Liveshows";

const theme = createTheme({
	palette: {
		success: {
			// Purple and green play nicely together.
			main: "#78F73B"
		},
		error: {
			// This is green.A700 as hex.
			main: "#F73B3B"
		}
	}
});

const StyledTab = styled(Tab)`
	border: 1px solid #78f73b;
	border-radius: 20px;
	font-size: 0.9rem !important;
	max-height: 35px !important;
	min-height: 35px !important;
	text-transform: none !important;
`;

const mapStateToProps = (state) => {
	return {
		liveshows: state.liveshows,
		liveshowShedule: state.liveshowShedule,
		liveshow: state.liveshow,
		tab: state.liveshow_tab
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		getLiveshows: () => dispatch(getLiveshows()),
		setMessage: (data, type) => dispatch(setMessage(data, type))
	};
};

function ManageLiveShows({ tab }) {
	const dispatch = useDispatch();
	function handleChangeTab(event, value) {
		dispatch(setLiveshowTab(value));
	}

	return (
		<>
			<Stack
				className="page-container"
				spacing={3}
				sx={{ width: 800, marginLeft: "290px" }}
			>
				<Box>
					<Typography
						variant="h4"
						gutterBottom
						component="div"
						style={{ color: "white" }}
					>
						Manage Live Show Rooms
					</Typography>

					<TabContext value={tab}>
						<Box
							sx={{
								width: "100%"
							}}
						>
							<TabList
								className="flex"
								// onChange={handleChange}
								aria-label="Pages"
								sx={{
									".MuiTabs-flexContainer": {
										gap: 2,
										justifyContent: "flex-start"
									},
									".MuiTabs-indicator": {
										display: "none"
									}
								}}
								onChange={handleChangeTab}
							>
								<StyledTab
									label={"Liveshows"}
									className="ProfileTab"
									value="liveshows"
									sx={{
										backgroundColor: tab === "liveshows" ? "#78f73b" : "none",
										color: tab === "liveshows" ? "#000 !important" : "#fff"
									}}
								/>

								<StyledTab
									label={"Couple liveshows"}
									className="ProfileTab"
									value="couples_liveshows"
									sx={{
										backgroundColor:
											tab === "couples_liveshows" ? "#78f73b" : "none",
										color:
											tab === "couples_liveshows" ? "#000 !important" : "#fff"
									}}
								/>
							</TabList>
							<TabPanel style={{ padding: 0 }} value="couples_liveshows">
								<Liveshows theme={theme} liveshowType={"couples_liveshows"} />
							</TabPanel>

							<TabPanel style={{ padding: 0 }} value="liveshows">
								<Liveshows theme={theme} liveshowType={"liveshows"} />
							</TabPanel>
						</Box>
					</TabContext>
				</Box>
			</Stack>
		</>
	);
}

export default connect(mapStateToProps, mapDispatchToProps)(ManageLiveShows);
