import { API_ADMIN_DASHBOARD_ACTIONS_ROUTE } from "../../utils/consts";

import { DASHBOARD_DATA } from "../types";
import { setLoading } from "../reducers/rootReducer";

const DashBoardData = (data) => {
	return {
		type: DASHBOARD_DATA,
		payload: { data }
	};
};

export const getDashBoardData = (month, year) => async (dispatch) => {
	dispatch(setLoading({ camera: "dashboard", type: true }));
	let response = "";
	try {
		if ((month || year) == 0) {
			response = await fetch(
				process.env.REACT_APP_BACKEND_URL + API_ADMIN_DASHBOARD_ACTIONS_ROUTE,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Accept: "application/json",
						Authorization: `Bearer ${sessionStorage.getItem("token")}`
					}
					//credentials: 'include',
				}
			);
		} else {
			response = await fetch(
				process.env.REACT_APP_BACKEND_URL +
					API_ADMIN_DASHBOARD_ACTIONS_ROUTE +
					`?month=${month}&year=${year}`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Accept: "application/json",
						Authorization: `Bearer ${sessionStorage.getItem("token")}`
					}
					//credentials: 'include',
				}
			);
		}
		const data = await response.json();
		if (response.status >= 200 && response.status <= 299) {
			dispatch(DashBoardData(data.data));
			dispatch(setLoading({ camera: "dashboard", type: false }));
		} else {
			dispatch(setLoading({ camera: "dashboard", type: false }));
			// dispatch(userLogout());
			// sessionStorage.removeItem("token");
		}
	} catch (error) {
		console.log(error);
		dispatch(setLoading({ camera: "dashboard", type: false }));
	}
};
