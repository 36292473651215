import * as React from "react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import {
	Autocomplete,
	Backdrop,
	Box,
	Button,
	CardActionArea,
	Container,
	Grid,
	IconButton,
	Input,
	Modal,
	Stack,
	TextField
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import ClearIcon from "@mui/icons-material/Clear";
import { LoadingButton } from "@mui/lab";
import CheckboxUI from "../../UI/CheckboxUI";
import {
	DeleteTubeImage,
	DeleteTubeVideo,
	UpdateTubeImage,
	UpdateTubeMediaItem,
	UpdateTubeVideo
} from "../../redux/actions/TubeActions";
import ReactHlsPlayer from "react-hls-player";
import RotateRightIcon from "@mui/icons-material/RotateRight";
import EditIcon from "@mui/icons-material/Edit";
import useVaporUpload from "../Upload/VaporUpload";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 400,
	bgcolor: "background.paper",
	border: "2px solid #000",
	textAlign: "center",
	margin: "0 auto",
	p: 4,
	maxHeight: "700px",
	overflow: "auto"
};
const style2 = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	maxWidth: "90vh",
	width: "fit-content",
	bgcolor: "background.paper",
	textAlign: "center",
	margin: "0 auto",
	p: 2,
	maxHeight: "90vh",
	overflow: "unset"
};
export default function TubeCard({ mediaItem, filters, isPhoto }) {
	const dispatch = useDispatch();
	const [selectedTags, setSelectedTags] = useState(
		mediaItem && isPhoto ? mediaItem?.metaTags : mediaItem?.tags
	);
	const [inputList, setInputList] = useState({
		metaDescription: mediaItem && mediaItem?.metaDescription,
		title: mediaItem && mediaItem?.title,
		description: mediaItem && mediaItem?.description
	});
	const [premium, setPremium] = useState(mediaItem && mediaItem?.is_premium);
	const [tube, setTube] = useState(mediaItem && mediaItem?.is_tube);
	const [tubeApproved, setTubeApproved] = useState(
		mediaItem && mediaItem?.tube_approved
	);
	const [trailerPath, setTrailerPath] = useState(
		mediaItem && mediaItem?.trailerPath
	);
	const [reel, setReel] = useState(mediaItem && mediaItem?.is_vertical);

	const [selectedOptions, setSelectedOptions] = useState([]);

	const [displayVerticalCenter, setDisplayVerticalCenter] = useState(
		mediaItem?.thumbnail_offset ?? 50
	);
	const [actualOffset, setActualOffset] = useState(mediaItem?.thumbnail_offset);
	const [selectedImage, setSelectedImage] = React.useState(null);
	const handleAutocompleteChange = (target) => {
		var task_names = target.target.value;
		setSelectedOptions(task_names);
	};
	useEffect(() => {
		if (mediaItem && mediaItem?.id) {
			setInputList({
				metaDescription: mediaItem && mediaItem?.metaDescription,
				title: mediaItem && mediaItem?.title,
				description: mediaItem && mediaItem?.description
			});
			setTube(mediaItem && mediaItem?.is_tube);
			setPremium(mediaItem && mediaItem?.is_premium);
			setTubeApproved(mediaItem && mediaItem?.tube_approved);
			setReel(mediaItem && mediaItem?.is_vertical);
			setTrailerPath(mediaItem && mediaItem?.trailerPath);
		}
		var task_names =
			mediaItem &&
			mediaItem?.performers.map(function (task, index, array) {
				return task.id;
			});
		setSelectedTags(isPhoto ? mediaItem?.metaTags : mediaItem?.tags);
		setSelectedOptions(task_names);
		setActualOffset(mediaItem?.thumbnail_offset);
	}, [mediaItem]);

	const cardClass = ["cameraCard"];

	const loading = useSelector((state) => state.loading);

	const [customModalVisible, setCustomModalVisible] = useState(false);
	const openCustomModal = () => {
		setCustomModalVisible(true);
	};

	const closeCustomModal = () => {
		setCustomModalVisible(false);
		setDisplayVerticalCenter(50);
		setRotation(0);
	};

	const changeHandle = () => {
		setPremium(!premium);
	};

	const changeHandleTubeIsReel = () => {
		setReel(!reel);
	};

	const handleInputChange = useCallback((e) => {
		const fieldName = e.target.getAttribute("name");
		const fieldValue = e.target.value;
		setInputList((prevState) => ({
			...prevState,
			[fieldName]: fieldValue
		}));
	}, []);

	const [rotation, setRotation] = useState(0);

	const calculateRotatedImageWidth = (width, height, angle) => {
		const radians = (angle * Math.PI) / 180;
		const sin = Math.abs(Math.sin(radians));
		const cos = Math.abs(Math.cos(radians));
		return width * cos + height * sin;
	};
	const [boxWidth, setBoxWidth] = useState("fit-content");
	const handleRotate = () => {
		setRotation((prevRotation) => {
			const newRotation = (prevRotation + 90) % 360;
			const img = imgRef.current;
			if (img) {
				const rect = img.getBoundingClientRect();
				const rotatedWidth = calculateRotatedImageWidth(
					rect.width,
					rect.height,
					newRotation
				);
				setBoxWidth(rotatedWidth);
			}
			return newRotation;
		});
	};

	const lineRef = useRef(null);
	const imgRef = useRef(null);

	const handleMouseDown = (event) => {
		document.addEventListener("mousemove", handleMouseMove);
		document.addEventListener("mouseup", handleMouseUp);
		document.body.style.userSelect = "none";
	};

	const handleMouseUp = () => {
		document.removeEventListener("mousemove", handleMouseMove);
		document.removeEventListener("mouseup", handleMouseUp);
		document.body.style.userSelect = "auto";
	};

	const handleMouseMove = (event) => {
		const img = imgRef?.current;
		const rect = img?.getBoundingClientRect();
		let newDisplayVerticalCenter =
			((event.clientY - rect.top) / rect.height) * 100;
		// Calculate the height of the gray box in percentage
		const grayBoxHeight = rect.width * (9 / 16); // Calculate the gray box height based on a 16:9 aspect ratio
		const grayBoxHeightPercent = (grayBoxHeight / rect.height) * 100;

		// Constrain the vertical center value so the gray box stays within the container boundaries
		if (newDisplayVerticalCenter - grayBoxHeightPercent / 2 < 0) {
			newDisplayVerticalCenter = grayBoxHeightPercent / 2;
		} else if (newDisplayVerticalCenter + grayBoxHeightPercent / 2 > 100) {
			newDisplayVerticalCenter = 100 - grayBoxHeightPercent / 2;
		}
		const normalizedCenter =
			(newDisplayVerticalCenter - grayBoxHeightPercent / 2) /
			(100 - grayBoxHeightPercent);
		let offset = normalizedCenter * 100;
		offset = Math.round(offset);
		setDisplayVerticalCenter(newDisplayVerticalCenter);
		setActualOffset(offset);
	};

	const handleSave = () => {
		if (!inputList.title) {
			alert("Please fill out all required fields: Title");
			return;
		}

		if (isPhoto && (!selectedTags || selectedTags.length === 0)) {
			alert("Please select at least one tag for the photo.");
			return;
		}
		if (!isPhoto && (!selectedTags || selectedTags.length === 0)) {
			alert("Please select at least one tag for the video.");
			return;
		}

		let obj = {
			participants: selectedOptions,
			is_premium: premium,
			is_tube: tube,
			tube_approved: true,
			metaDescription: inputList.metaDescription,
			title: inputList.title
			/*	description: inputList.description*/
		};

		if (isPhoto) {
			obj.metaTags = selectedTags;
			obj.thumbnail_offset = actualOffset !== 50 ? actualOffset : null;
			obj.rotate = rotation;
		} else {
			obj.tags = selectedTags;
			obj.is_vertical = reel;
		}
		if (isPhoto) {
			dispatch(UpdateTubeImage(mediaItem?.id, obj));
		} else {
			dispatch(UpdateTubeVideo(mediaItem?.id, obj));
		}
		setDisplayVerticalCenter(50);
		setRotation(0);
		closeCustomModal();
	};

	const handleSaveMediaItem = () => {
		let obj = {
			thumbnail_offset: actualOffset !== 50 ? actualOffset : null,
			rotate: rotation,
			participants: selectedOptions,
			metaDescription: inputList.metaDescription,
			title: inputList.title,
			metaTags: selectedTags
		};

		if (isPhoto) {
			dispatch(UpdateTubeMediaItem(mediaItem?.id, obj));

			/*	setFullScreen(false);*/
		}
	};
	useEffect(() => {
		if (loading.isLoading == false) {
			setFullScreen(false);
			setDisplayVerticalCenter(50);
			setRotation(0);
		}
	}, [loading]);
	const handleDelete = () => {
		if (isPhoto) {
			dispatch(DeleteTubeImage(mediaItem?.id));
		} else {
			dispatch(DeleteTubeVideo(mediaItem?.id));
		}

		closeCustomModal();
	};
	const videoRef = useRef();
	/*	const handleMouseEnter = () => {
		if (!isPhoto && videoRef.current && videoRef.current.src) {
			videoRef.current.play().catch((error) => {
				console.error("Error attempting to play:", error);
			});
			videoRef.current.muted = true;
		}
	};

	const handleMouseLeave = () => {
		if (!isPhoto && videoRef.current) {
			videoRef.current.pause();
			videoRef.current.currentTime = 0;
		}
	};*/
	const [fullScreen, setFullScreen] = useState(false);

	const handleFullScreenToggle = () => {
		setFullScreen(!fullScreen);
		// Calculate grayBoxHeightPercent based on the initial image dimensions
		setTimeout(() => {
			const img = imgRef?.current;
			if (img) {
				const rect = img?.getBoundingClientRect();
				const grayBoxHeight = rect.width * (9 / 16); // Calculate the gray box height based on a 16:9 aspect ratio
				const grayBoxHeightPercent = (grayBoxHeight / rect.height) * 100;
				let initialOffset = mediaItem?.thumbnail_offset ?? 50;
				if (initialOffset === 50) {
					setDisplayVerticalCenter(50);
					return;
				}
				// Calculate the normalized initial offset
				const normalizedCenter = initialOffset / 100;
				let initialDisplayVerticalCenter =
					normalizedCenter * (100 - grayBoxHeightPercent) +
					grayBoxHeightPercent / 2;

				setDisplayVerticalCenter(initialDisplayVerticalCenter);
			}
		}, 1);
	};
	const [currentTime, setCurrentTime] = useState(0);

	const handleTimeUpdate = (event) => {
		setCurrentTime(event?.target?.currentTime);
	};
	const videoPlayer = useMemo(() => {
		return (
			<ReactHlsPlayer
				className="VideoInput_video"
				src={mediaItem?.fullMovie}
				controls={true}
				style={{
					minWidth: "300px",
					width: "100%",
					height: "300px",
					objectFit: "contain"
				}}
				muted={false}
				autoPlay
				onTimeUpdate={handleTimeUpdate}
			/>
		);
	}, [mediaItem?.fullMovie, mediaItem]);
	const { uploadFile } = useVaporUpload();
	useEffect(() => {
		const handleUpload = async () => {
			if (selectedImage) {
				try {
					const { vaporResponse } = await uploadFile(selectedImage);
					const formData = new FormData();
					formData.append("poster_image", vaporResponse.key);
					formData.append("title", inputList.title);
					formData.append("participants", selectedOptions ?? []);
					dispatch(UpdateTubeVideo(mediaItem?.id, formData, true));
					setSelectedImage(null);
				} catch (error) {
					console.error("Error uploading file: ", error);
					setSelectedImage(null);
				}
			}
		};

		handleUpload();
	}, [selectedImage]);
	const captureFrame = (video, currentTime) => {
		return new Promise((resolve, reject) => {
			const canvas = document.createElement("canvas");
			const context = canvas.getContext("2d");
			video.currentTime = currentTime;

			video.onseeked = () => {
				canvas.width = video.videoWidth;
				canvas.height = video.videoHeight;
				context.drawImage(video, 0, 0, canvas.width, canvas.height);
				canvas.toBlob((blob) => {
					if (blob) {
						resolve(blob);
					} else {
						reject(new Error("Failed to create Blob"));
					}
				}, "image/jpeg");
			};

			video.onerror = (error) => {
				reject(error);
			};
		});
	};
	const handleChoose = async () => {
		const video = document.querySelector("video.VideoInput_video");
		try {
			const imageBlob = await captureFrame(video, currentTime);

			// Create a File from the Blob
			const file = new File([imageBlob], `frame_${currentTime}.jpeg`, {
				type: "image/jpeg",
				lastModified: Date.now()
			});
			setSelectedImage(file);
		} catch (error) {
			console.error("Error capturing frame: ", error);
		}
	};
	const formatTime = (time) => {
		const minutes = Math.floor(time / 60);
		const seconds = Math.floor(time % 60);
		const milliseconds = Math.floor((time % 1) * 1000);
		return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}:${String(milliseconds).padStart(3, "0")}`;
	};
	return (
		<>
			<Card
				id={mediaItem?.id + "-" + mediaItem?.updatedAt}
				className={cardClass}
				onClick={openCustomModal}
				style={{
					borderRadius: "10px",
					maxWidth: "150px",
					position: "relative"
				}}
				/*		onMouseEnter={handleMouseEnter}
				onMouseLeave={handleMouseLeave}*/
			>
				<CardActionArea
					id={mediaItem?.id + "-" + mediaItem?.createdAt}
					style={{
						filter:
							!mediaItem?.is_tube &&
							!mediaItem?.tube_approved &&
							"grayscale(100%)"
					}}
				>
					{!isPhoto ? (
						<>
							<CardMedia
								ref={videoRef}
								component="img"
								style={{
									height: "80px",
									width: "100%",
									objectFit: "cover",
									objectPosition: actualOffset >= 0 && `center ${actualOffset}%`
								}}
								src={mediaItem?.posterMediaMeta?.thumbnail230}
							/>

							{mediaItem?.status === "processing" && (
								<div
									className="loader-container"
									style={{
										padding: "24% 0"
									}}
								>
									<div className="spinner"></div>
								</div>
							)}
						</>
					) : (
						<>
							<CardMedia
								ref={videoRef}
								component="img"
								style={{
									height: "80px",
									width: "100%",
									objectFit: "cover",
									objectPosition: actualOffset >= 0 && `center ${actualOffset}%`
								}}
								src={mediaItem?.mediaMeta?.thumbnail230}
							/>

							{mediaItem?.status === "processing" && (
								<div
									className="loader-container"
									style={{
										padding: "24% 0"
									}}
								>
									<div className="spinner"></div>
								</div>
							)}
						</>
					)}
					{!mediaItem?.tube_approved && (
						<Typography
							gutterBottom
							variant="h7"
							component="span"
							className={"BadgeButton"}
						>
							Waiting approval
						</Typography>
					)}
				</CardActionArea>
			</Card>
			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				closeAfterTransition
				open={customModalVisible}
				onCancel={closeCustomModal}
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
					onClick: closeCustomModal
				}}
				className="camera_details"
			>
				<Box
					sx={style}
					style={{
						marginTop: "50px"
					}}
				>
					{isPhoto && (
						<IconButton
							className="FullScreenButton"
							onClick={handleFullScreenToggle}
							style={{
								position: "absolute",
								top: 0,
								left: 0,
								zIndex: 1000,
								color: "white",
								display: fullScreen && "none"
							}}
						>
							<EditIcon />
						</IconButton>
					)}
					<IconButton className="BackButton" onClick={closeCustomModal}>
						<ClearIcon />
					</IconButton>
					<Container
						component="main"
						maxWidth="xs"
						style={{
							padding: "0px"
						}}
					>
						{!isPhoto ? (
							<div className="VideoInput">
								{mediaItem?.status === "active" ? (
									videoPlayer
								) : (
									<div
										className="loader-container"
										style={{ position: "relative" }}
									>
										<div className="spinner"></div>
									</div>
								)}
							</div>
						) : (
							<div className="VideoInput">
								{mediaItem?.status === "active" ? (
									<CardMedia
										ref={videoRef}
										component="img"
										style={{
											height: "165px",
											width: "100%",
											maxWidth: "290px",
											borderRadius: "10px",
											objectPosition:
												mediaItem?.thumbnail_offset >= 0
													? `center ${mediaItem?.thumbnail_offset}%`
													: mediaItem?.is_vertical && "top"
										}}
										src={mediaItem?.path && mediaItem?.path}
									/>
								) : (
									<div
										className="loader-container"
										style={{ position: "relative" }}
									>
										<div className="spinner"></div>
									</div>
								)}
							</div>
						)}
						{!isPhoto && (
							<Box
								style={{
									display: "flex",
									flexDirection: "column",
									alignItems: "center",
									width: "100%",
									marginTop: "10px"
								}}
							>
								<Typography style={{ color: "#78F73B", fontSize: "1rem" }}>
									{formatTime(currentTime)}
								</Typography>
								<Button
									variant="contained"
									style={{
										width: "fit-content",
										marginTop: "10px",
										color: "black",
										backgroundColor: "#78F73B"
									}}
									onClick={handleChoose}
								>
									Update poster image
								</Button>
							</Box>
						)}
						<Box
							sx={{
								marginTop: 2,
								display: "flex",
								flexDirection: "column",
								alignItems: "center"
							}}
						>
							<Box component="form" noValidate sx={{ mt: 3 }}>
								<Grid item xs={12}>
									<CheckboxUI
										style={{ color: "white" }}
										title={"Non Nude"}
										name="freeUnlocked"
										value="allowExtraEmails"
										color="primary"
										onChange={changeHandle}
										checked={premium == 0 ? true : false}
									/>

									{!isPhoto && (
										<CheckboxUI
											style={{ color: "white" }}
											title={"Reel"}
											name="freeUnlocked"
											value="allowExtraEmails"
											color="primary"
											onChange={changeHandleTubeIsReel}
											checked={reel == 0 ? false : true}
										/>
									)}
								</Grid>
							</Box>
						</Box>
					</Container>

					<Autocomplete
						className="LazyInput"
						multiple
						name="participants"
						id="id"
						style={{ width: "100%", marginBottom: "15px", maxWidth: "100%" }}
						onChange={(event, value) => {
							handleAutocompleteChange({
								target: {
									value: value
								}
							});
						}}
						options={
							filters && filters.models ? Object.keys(filters.models) : []
						}
						defaultValue={selectedOptions && selectedOptions}
						key={selectedOptions && selectedOptions}
						getOptionLabel={(option) => filters.models[parseInt(option)] || ""}
						renderInput={(params) => (
							<TextField
								{...params}
								placeholder={"Participants"}
								variant="standard"
								name="participants"
								sx={{
									borderRadius: "4px",
									padding: "0px 10px 0px 5px",
									textAlign: "left",
									fontSize: "14px"
								}}
							/>
						)}
					/>
					<Stack
						direction="row"
						style={{ paddingBottom: "15px" }}
						className="Pools_row"
					>
						<Input
							value={inputList.title}
							name="title"
							multiline
							onChange={(e) => {
								handleInputChange(e);
							}}
							style={{ padding: "5px", height: "unset" }}
							placeholder="Title"
							className="Pools_input_text"
							disableUnderline="true"
						></Input>
					</Stack>
					{/*	<Stack
						direction="row"
						style={{ paddingBottom: "15px" }}
						className="Pools_row"
					>
						<Input
							value={inputList.description}
							name="description"
							multiline
							onChange={(e) => {
								handleInputChange(e);
							}}
							style={{ padding: "5px", height: "unset" }}
							placeholder="Description"
							className="Pools_input_text"
							disableUnderline="true"
						></Input>
					</Stack>*/}
					<Stack
						direction="row"
						className="Pools_row"
						style={{ paddingBottom: "15px" }}
						spacing={1}
					>
						<Autocomplete
							multiple
							id="tags-standard"
							freeSolo={true}
							fullWidth
							onChange={(event, value) => setSelectedTags(value)}
							options={[]}
							defaultValue={selectedTags && selectedTags}
							key={selectedTags && selectedTags}
							sx={{
								backgroundColor: "#1d1d1d",
								borderRadius: "4px",
								textAlign: "left",
								fontSize: "14px"
							}}
							className="tags-input"
							renderInput={(params) => (
								<TextField
									{...params}
									variant="standard"
									placeholder="Input tags"
									placeholderStyle={{ color: "red" }}
									sx={{
										borderRadius: "4px",
										padding: "0px 10px 0px 5px",
										textAlign: "left",
										fontSize: "14px"
									}}
								/>
							)}
						/>
					</Stack>
					{/*<Stack
						direction="row"
						style={{ paddingBottom: "15px" }}
						className="Pools_row"
					>
						<Input
							value={inputList.metaDescription}
							name="metaDescription"
							multiline
							onChange={(e) => {
								handleInputChange(e);
							}}
							style={{ padding: "5px", height: "unset" }}
							placeholder="Meta Description"
							className="Pools_input_text"
							disableUnderline="true"
						></Input>
					</Stack>*/}

					{/*<Button*/}
					{/*    onClick={handleSave}*/}
					{/*    variant="contained"*/}
					{/*    loading={uploading}*/}
					{/*    disabled={source == null}*/}
					{/*    style={{*/}
					{/*        width: "100%",*/}
					{/*        marginTop: "10px",*/}
					{/*        color: "black",*/}
					{/*        backgroundColor: "#78F73B",*/}
					{/*        opacity: source == null && "0.5"*/}
					{/*    }}*/}
					{/*>*/}
					{/*    Save*/}
					{/*</Button>*/}
					<LoadingButton
						type="submit"
						onClick={handleSave}
						variant="contained"
						sx={{
							mt: 3,
							mb: 3,
							py: 1.5,
							borderRadius: "10px",
							textTransform: "unset",
							fontSize: "1.1rem",
							".MuiCircularProgress-root": {
								width: "23px !important",
								height: "23px !important"
							},
							".MuiLoadingButton-loadingIndicator": {
								color: "black"
							}
						}}
						style={{
							width: "100%",
							marginTop: "10px",
							color: "black",
							backgroundColor: "#78F73B"
						}}
					>
						{"Approve"}
					</LoadingButton>
					<LoadingButton
						type="error"
						onClick={handleDelete}
						variant="contained"
						sx={{
							mt: 3,
							mb: 3,
							py: 1.5,
							borderRadius: "10px",
							textTransform: "unset",
							fontSize: "1.1rem",
							".MuiCircularProgress-root": {
								width: "23px !important",
								height: "23px !important"
							},
							".MuiLoadingButton-loadingIndicator": {
								color: "white"
							}
						}}
						style={{
							width: "100%",
							marginTop: "10px",
							color: "white",
							backgroundColor: "red"
						}}
					>
						{"Delete"}
					</LoadingButton>
				</Box>
			</Modal>
			{fullScreen && (
				<>
					<Modal
						visible={fullScreen}
						open={fullScreen}
						footer={null}
						onClose={() => setFullScreen(false)}
						aria-labelledby="image-editor-modal"
						aria-describedby="image-editor-modal-description"
					>
						<Box sx={style2}>
							{loading && loading.isLoading == true ? (
								<div className="loader-container">
									<div className="spinner"></div>
								</div>
							) : (
								""
							)}
							<IconButton
								className="CloseButton"
								onClick={() => setFullScreen(false)}
								style={{
									position: "absolute",
									top: 0,
									right: 0,
									zIndex: 1001,
									color: "white"
								}}
							>
								<ClearIcon />
							</IconButton>
							<IconButton
								className="RotateButton"
								onClick={handleRotate}
								style={{
									position: "absolute",
									top: 0,
									right: 50,
									zIndex: 1001,
									color: "white"
								}}
							>
								<RotateRightIcon />
							</IconButton>
							<div
								style={{
									position: "relative",
									width: "100%",
									height: "100%",
									maxHeight: "84vh"
								}}
							>
								<img
									src={mediaItem?.path && mediaItem?.path}
									alt="Modal Image"
									ref={imgRef}
									style={{
										width: rotation % 180 === 0 ? "100%" : `${boxWidth}px`,
										height: rotation % 180 === 0 ? "100%" : `${boxWidth}px`,
										maxHeight: "84vh",
										objectFit: "contain",
										transform: `rotate(${rotation}deg)`,
										transition: "transform 0.3s ease" // Smooth transition
									}}
								/>
								{/* Thumbnail Visualization Box */}
								<div
									onMouseDown={handleMouseDown}
									style={{
										position: "absolute",
										top: `${displayVerticalCenter}%`,
										left: "50%",
										width: `100%`,
										aspectRatio: "16/9",
										transform: "translate(-50%, -50%)",
										backgroundColor: "rgba(128, 128, 128, 0.5)",
										cursor: "pointer",
										zIndex: 1000
									}}
								/>
								{/*<div
									ref={lineRef}
									style={{
										position: "absolute",
										top: `${displayVerticalCenter}%`,
										left: 0,
										right: 0,
										height: "2px",
										backgroundColor: "yellow",
										pointerEvents: "none",
										zIndex: 1000
									}}
								/>*/}
							</div>
							<LoadingButton
								type="submit"
								onClick={handleSaveMediaItem}
								variant="contained"
								sx={{
									mt: 3,
									mb: 3,
									py: 1.5,
									borderRadius: "10px",
									textTransform: "unset",
									fontSize: "1.1rem",
									".MuiCircularProgress-root": {
										width: "23px !important",
										height: "23px !important"
									},
									".MuiLoadingButton-loadingIndicator": {
										color: "black"
									}
								}}
								style={{
									width: "fit-content",
									color: "black",
									backgroundColor: "#78F73B"
								}}
							>
								{"Save"}
							</LoadingButton>
						</Box>
					</Modal>
				</>
			)}
		</>
	);
}
