import { CAMERAS } from "../types";

export const camerasReducer = (state = {}, action) => {
	const { type, payload } = action;

	switch (type) {
		case CAMERAS: {
			const { data } = payload;
			state = {};
			return data;
		}
		default: {
			return state;
		}
	}
};
