import * as React from "react";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";

export default function GalleryCardModal({ item, type }) {
	const cardClass = ["cameraCardModal"];
	return (
		<Card className={cardClass}>
			<CardMedia
				component="img"
				height="80"
				image={
					type == "image"
						? item?.path
						: item?.poster_image?.mediaMeta?.imageLarge
				}
				title={item && item.title}
			/>

			{/*   ((camera && camera.type == 'common') && (admin.user && admin.user.role !== 'superadmin')) ? "BadgeText_common" : */}
			<Typography
				gutterBottom
				variant="h7"
				component="span"
				className={"BadgeText"}
			>
				{item.title}
			</Typography>
		</Card>
	);
}
