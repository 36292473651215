import {
	API_FETCH_LIVESHOW_ASSIGN_ROUTE,
	API_FETCH_LIVESHOW_LOGOUT_ROUTE,
	API_FETCH_LIVESHOW_RENAME_ROUTE,
	API_FETCH_LIVESHOW_ROUTE,
	API_FETCH_LIVESHOWS_ROUTE
} from "../../utils/consts";
import { LIVESHOW, LIVESHOW_SCHEDULES, LIVESHOWS, UPDATE } from "../types";
import { userLogout } from "./userAction";
import { setLoading, setMessage } from "../reducers/rootReducer";
import { getUrlWithParams } from "../../utils/helpers";

const allLiveshowsRooms = (data) => {
	return {
		type: LIVESHOWS,
		payload: { data }
	};
};
const oneLiveshowRoom = (data) => {
	return {
		type: LIVESHOW,
		payload: { data }
	};
};

const patchLiveshow = (data) => {
	return {
		type: UPDATE,
		payload: { data }
	};
};
export const liveShowSchedule = (data) => {
	return {
		type: LIVESHOW_SCHEDULES,
		payload: { data }
	};
};

export const getLiveshow = (id) => async (dispatch) => {
	try {
		const response = await fetch(
			process.env.REACT_APP_BACKEND_URL + API_FETCH_LIVESHOW_ROUTE + id,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
					Authorization: `Bearer ${sessionStorage.getItem("token")}`
				}
				//credentials: 'include',
			}
		);
		//response.data
		const data = await response.json();
		console.log("got liveshow : " + data.title);
		dispatch(oneLiveshow(data));
	} catch (error) {
		console.log(error);
	}
};

export const getLiveshows =
	(onlyCouple = null) =>
	async (dispatch) => {
		dispatch(setLoading({ camera: "liveshow", type: true }));
		try {
			const options = {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
					Authorization: `Bearer ${sessionStorage.getItem("token")}`
				}
				//credentials: 'include',
			};
			let params = {};

			if (onlyCouple) {
				params = {
					onlyCouple: 1
				};
			}

			const requestUrl =
				process.env.REACT_APP_BACKEND_URL + API_FETCH_LIVESHOWS_ROUTE;
			const requestUrlWithParams = getUrlWithParams(requestUrl, params);

			const response = await fetch(requestUrlWithParams, options);

			const data = await response.json();

			if (response.status >= 200 && response.status <= 299) {
				dispatch(allLiveshowsRooms(data.data));
				dispatch(setLoading({ camera: "liveshow", type: false }));
			} else {
				dispatch(userLogout());
				sessionStorage.removeItem("token");
			}
		} catch (error) {
			console.log(error);
			dispatch(setLoading({ camera: "liveshow", type: false }));
		}
	};

export const stopLiveShow =
	(id, onlyCouple = false) =>
	async (dispatch) => {
		dispatch(setLoading({ camera: "liveshow", type: true }));
		try {
			const options = {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
					Authorization: `Bearer ${sessionStorage.getItem("token")}`
				}
				//credentials: 'include',
			};
			const response = await fetch(
				process.env.REACT_APP_BACKEND_URL +
					API_FETCH_LIVESHOW_LOGOUT_ROUTE +
					id +
					"/logout",
				options
			);

			const data = await response.json();
			if (response.status >= 200 && response.status <= 299) {
				if ((data.type = "success")) {
					//dispatch(liveShowSchedule([]));
					dispatch(oneLiveshowRoom(data.data.room));
					dispatch(getLiveshows(onlyCouple));
					dispatch(setMessage(data.message, data.type));
					dispatch(setLoading({ camera: "liveshow", type: false }));
				}
			} else {
				dispatch(setMessage(data.message, data.type));
				dispatch(setLoading({ camera: "liveshow", type: false }));
			}
		} catch (error) {
			setMessage(error, "error");
			dispatch(setLoading({ camera: "liveshow", type: false }));
		}
	};

export const assignLiveShow =
	(item, id, onlyCouple = false) =>
	async (dispatch) => {
		let response = "";
		dispatch(setLoading({ camera: "liveshow", type: true }));
		try {
			const options = {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
					Authorization: `Bearer ${sessionStorage.getItem("token")}`
				},
				body: JSON.stringify(item)
			};
			const scheduleId = item.schedule == null ? false : true;
			if (scheduleId == true) {
				response = await fetch(
					process.env.REACT_APP_BACKEND_URL +
						API_FETCH_LIVESHOW_ASSIGN_ROUTE +
						id +
						"/assign/" +
						item.schedule,
					options
				);
			} else {
				response = await fetch(
					process.env.REACT_APP_BACKEND_URL +
						API_FETCH_LIVESHOW_ASSIGN_ROUTE +
						id +
						"/assign",
					options
				);
			}
			const data = await response.json();
			if (response.status >= 200 && response.status <= 299) {
				if ((data.type = "success")) {
					dispatch(liveShowSchedule([]));
					dispatch(oneLiveshowRoom(data.data.room));
					dispatch(getLiveshows(onlyCouple));

					dispatch(setMessage(data.message, data.type));
				}
			} else {
				dispatch(setMessage(data.message, data.type));
				dispatch(setLoading({ camera: "liveshow", type: false }));
			}
		} catch (error) {
			setMessage(error, "error");
			dispatch(setLoading({ camera: "liveshow", type: false }));
		}
	};
export const UpdateLiveshow = (item, id) => async (dispatch) => {
	let response = "";
	dispatch(setLoading({ camera: "liveshow", type: true }));
	try {
		const options = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: `Bearer ${sessionStorage.getItem("token")}`
			},
			body: JSON.stringify(item)
		};

		response = await fetch(
			process.env.REACT_APP_BACKEND_URL + API_FETCH_LIVESHOW_ASSIGN_ROUTE + id,
			options
		);
		const data = await response.json();
		if (response.status >= 200 && response.status <= 299) {
			if ((data.type = "success")) {
				//dispatch(liveShowSchedule([]));
				dispatch(oneLiveshowRoom(data.data.room));
				dispatch(getLiveshows());
				dispatch(setMessage(data.message, data.type));
			}
		} else {
			dispatch(setMessage(data.message, data.type));
			dispatch(setLoading({ camera: "liveshow", type: false }));
		}
	} catch (error) {
		setMessage(error, "error");
		dispatch(setLoading({ camera: "liveshow", type: false }));
	}
};

/*   export const getLiveshowSchedule = () => async (dispatch) => {
    try {
      const options = {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        "Accept": "application/json",
        'Authorization': `Bearer ${sessionStorage.getItem("token")}`,
    },
      //credentials: 'include',
      }
      const response = await fetch('https://whxlbo-fr.warehouse-x.io' + API_FETCH_LIVESHOW_SCHEDULE_ROUTE,options)

      const data = await response.json()
      dispatch(allLiveshowsSchedules(data))

    } catch (error) {
      console.log(error)
    }
  } */
export const assignLiveShowName =
	(item, id, onlyCouple = false) =>
	async (dispatch) => {
		dispatch(setLoading({ camera: "liveshow", type: true }));
		try {
			const options = {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
					Authorization: `Bearer ${sessionStorage.getItem("token")}`
				},
				body: JSON.stringify(item)
				//credentials: 'include',
			};
			const response = await fetch(
				process.env.REACT_APP_BACKEND_URL +
					API_FETCH_LIVESHOW_RENAME_ROUTE +
					id +
					"/update",
				options
			);

			const data = await response.json();

			if (response.status >= 200 && response.status <= 299) {
				if ((data.type = "success")) {
					//dispatch(liveShowSchedule([]));
					dispatch(oneLiveshowRoom(data.data.room));
					dispatch(getLiveshows(onlyCouple));
					dispatch(setMessage(data.message, data.type));
				}
			} else {
				dispatch(userLogout());
				sessionStorage.removeItem("token");
			}
		} catch (error) {
			setMessage(error, "error");
			dispatch(setLoading({ camera: "liveshow", type: false }));
		}
	};
