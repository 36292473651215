import { DELETE_USER_TRANSACTIONS, USER_TRANSACTIONS } from "../types";

export const User_TransactionsReducer = (state = {}, action) => {
	const { type, payload } = action;

	switch (type) {
		case USER_TRANSACTIONS: {
			const { data } = payload;
			return data;
		}
		case DELETE_USER_TRANSACTIONS: {
			return {};
		}
		default: {
			return state;
		}
	}
};
