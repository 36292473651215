import { LIVESHOW, UPDATE } from "../types";

export const liveshowReducer = (state = {}, action) => {
	const { type, payload } = action;

	switch (type) {
		case LIVESHOW: {
			const { data } = payload;
			return data;
		}

		case UPDATE: {
			const { data } = payload;
			return data;
		}

		default: {
			return state;
		}
	}
};
