import React, { useState } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import { getBlogComments } from "../../redux/actions/blogActions";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";

function SearchComponent() {
	const [searchText, setSearchText] = useState("");
	const param = useParams();
	const dispatch = useDispatch();
	const [isClearing, setClearing] = useState(false);
	React.useEffect(() => {
		const timeoutID = setTimeout(() => {
			if (searchText.length > 0) {
				dispatch(getBlogComments(param.slug, null, 20, searchText));
				setClearing(false);
			}
			if (isClearing && searchText.length == 0) {
				dispatch(getBlogComments(param.slug, null, 20, searchText));
				setClearing(false);
			}
		}, 500);
		return () => clearTimeout(timeoutID);
	}, [searchText]);

	const requestSearch = (value) => {
		setSearchText(value);
	};

	const clearSearch = () => {
		setSearchText("");
		setClearing(false);
		dispatch(getBlogComments(param.slug, null, 20, ""));
	};

	React.useEffect(() => {
		const handleBackspace = (event) => {
			if (event.keyCode == 8) {
				setClearing(true);
			}
		};
		document.addEventListener("keydown", handleBackspace);
		return () => {
			document.removeEventListener("keydown", handleBackspace);
		};
	}, [searchText]);
	return (
		<Box>
			<TextField
				fullWidth
				value={searchText}
				className="searchComment"
				onChange={(event) => requestSearch(event.target.value)}
				placeholder="Search Comment"
				InputProps={{
					endAdornment: (
						<IconButton
							title="Clear"
							aria-label="Clear"
							size="small"
							style={{ visibility: searchText ? "visible" : "hidden" }}
							onClick={clearSearch}
						>
							<ClearIcon fontSize="small" />
						</IconButton>
					)
				}}
			/>
		</Box>
	);
}

export default SearchComponent;
