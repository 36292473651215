import {
	WHX_EVENT,
	WHX_EVENT_PARTICIPANT_UPDATE,
	WHX_EVENT_TASK_CREATE,
	WHX_EVENT_TASK_REMOVE,
	WHX_EVENT_TASK_UPDATE
} from "../types";
import { setMessage } from "../reducers/rootReducer";

const Whx_Event = (data) => {
	return {
		type: WHX_EVENT,
		payload: { data }
	};
};

const Whx_Event_Task_Create = (data) => {
	return {
		type: WHX_EVENT_TASK_CREATE,
		payload: { data }
	};
};
const Whx_Event_Task_Update = (data) => {
	return {
		type: WHX_EVENT_TASK_UPDATE,
		payload: { data }
	};
};
const Whx_Event_Task_Remove = (data) => {
	return {
		type: WHX_EVENT_TASK_REMOVE,
		payload: { data }
	};
};

const Whx_Event_Participant_Update = (data) => {
	return {
		type: WHX_EVENT_PARTICIPANT_UPDATE,
		payload: { data }
	};
};

export const getEventStage = (backend_url, id) => async (dispatch) => {
	let response = "";
	try {
		response = await fetch(
			process.env.REACT_APP_BACKEND_URL + backend_url + "/" + id,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
					Authorization: `Bearer ${sessionStorage.getItem("token")}`
				}
				//credentials: 'include',
			}
		);
		const data = await response.json();
		if (response.status >= 200 && response.status <= 299) {
			dispatch(Whx_Event(data.data));
		}
	} catch (error) {
		console.log(error);
	}
};
export const StudStageUpdate =
	(backend_url, id, object) => async (dispatch) => {
		let response = "";
		try {
			response = await fetch(
				process.env.REACT_APP_BACKEND_URL + backend_url + "/" + id,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Accept: "application/json",
						Authorization: `Bearer ${sessionStorage.getItem("token")}`
					},
					body: JSON.stringify(object),
					credentials: "include"
				}
			);
			const data = await response.json();
			if (response.status >= 200 && response.status <= 299) {
				dispatch(Whx_Event(data.data));
				dispatch(setMessage(data.message, data.type));
			}
		} catch (error) {
			console.log(error);
		}
	};

export const CreateTask =
	(backend_url, stage_id, participantsId, name, start_time) =>
	async (dispatch) => {
		try {
			const options = {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
					Authorization: `Bearer ${sessionStorage.getItem("token")}`
				},
				body: JSON.stringify({
					stage_id: stage_id,
					participants: participantsId,
					name: name,
					start_time: start_time
				}),
				credentials: "include"
			};
			const response = await fetch(
				process.env.REACT_APP_BACKEND_URL + backend_url,
				options
			);
			//response.data
			const data = await response.json();

			console.log(data);
			if (data.type == "success") {
				dispatch(Whx_Event_Task_Create(data.data.task));
				dispatch(setMessage(data.message, data.type));
			}
			if (data.type == "false") {
			}
		} catch (error) {
			console.log(error);
		}
	};
export const UpdateTask = (backend_url, taskId, body) => async (dispatch) => {
	try {
		const options = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: `Bearer ${sessionStorage.getItem("token")}`
			},
			body
			//credentials: 'include',
		};
		const response = await fetch(
			process.env.REACT_APP_BACKEND_URL + backend_url + "/" + taskId,
			options
		);
		//response.data
		const data = await response.json();

		if (data.type == "success") {
			dispatch(Whx_Event_Task_Update(data.data.task));
			dispatch(setMessage(data.message, data.type));
		}
		if (data.type == "false") {
		}
	} catch (error) {
		console.log(error);
	}
};
export const UpdateStageTaskMedia =
	(backend_url, taskId, body) => async (dispatch) => {
		try {
			const options = {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
					Authorization: `Bearer ${sessionStorage.getItem("token")}`
				},
				body
				//credentials: 'include',
			};
			const response = await fetch(
				process.env.REACT_APP_BACKEND_URL + backend_url + "/" + taskId,
				options
			);
			//response.data
			const data = await response.json();

			if (data.type == "success") {
				dispatch(Whx_Event_Task_Update(data.data.task));
				dispatch(setMessage(data.message, data.type));
			}
			if (data.type == "false") {
			}
		} catch (error) {
			console.log(error);
		}
	};
export const DeleteStageTaskMedia =
	(backend_url, taskId) => async (dispatch) => {
		try {
			const options = {
				method: "DELETE",
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
					Authorization: `Bearer ${sessionStorage.getItem("token")}`
				}
				//credentials: 'include',
			};
			const response = await fetch(
				process.env.REACT_APP_BACKEND_URL + backend_url + "/" + taskId,
				options
			);
			//response.data
			const data = await response.json();

			if (data.type == "success") {
				dispatch(Whx_Event_Task_Update(data.data.task));
				dispatch(setMessage(data.message, data.type));
			}
			if (data.type == "false") {
			}
		} catch (error) {
			console.log(error);
		}
	};
export const UpdateTaskStatus =
	(backend_url, taskId, is_live) => async (dispatch) => {
		try {
			const options = {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
					Authorization: `Bearer ${sessionStorage.getItem("token")}`
				},
				body: JSON.stringify({
					is_live: is_live
				})
				//credentials: 'include',
			};
			const response = await fetch(
				process.env.REACT_APP_BACKEND_URL +
					backend_url +
					"/" +
					taskId +
					"/status",
				options
			);
			//response.data
			const data = await response.json();

			if (data.type == "success") {
				dispatch(Whx_Event_Task_Update(data.data.task));
				dispatch(setMessage(data.message, data.type));
			}
			if (data.type == "error") {
				dispatch(setMessage(data.message, data.type));
			}
		} catch (error) {
			console.log(error);
		}
	};
export const DeleteTask = (backend_url, task_id) => async (dispatch) => {
	try {
		const options = {
			method: "DELETE",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: `Bearer ${sessionStorage.getItem("token")}`
			}
			//credentials: 'include',
		};
		const response = await fetch(
			process.env.REACT_APP_BACKEND_URL + backend_url + "/" + task_id,
			options
		);
		//response.data
		const data = await response.json();

		if (data.type == "success") {
			dispatch(Whx_Event_Task_Remove(task_id));
			dispatch(setMessage(data.message, data.type));
		}
		if (data.type == "error") {
			dispatch(setMessage(data.message, data.type));
		}
	} catch (error) {
		console.log(error);
	}
};
export const EventModelsProceed =
	(backend_url, stage_id, participantsId) => async (dispatch) => {
		try {
			const options = {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
					Authorization: `Bearer ${sessionStorage.getItem("token")}`
				},
				body: JSON.stringify({
					participants: participantsId
				}),
				credentials: "include"
			};
			const response = await fetch(
				process.env.REACT_APP_BACKEND_URL +
					backend_url +
					"/" +
					stage_id +
					"/proceed",
				options
			);
			//response.data
			const data = await response.json();

			console.log(data);
			if (data.type == "success") {
				dispatch(setMessage(data.message, data.type));
				dispatch(getEventStage(stage_id));
			}
			if (data.type == "error") {
				dispatch(setMessage(data.message, data.type));
			}
		} catch (error) {
			console.log(error);
		}
	};
export const UpdateParticipantVotes =
	(backend_url, stage_id, participant_id, votes_count) => async (dispatch) => {
		try {
			const options = {
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
					Authorization: `Bearer ${sessionStorage.getItem("token")}`
				},
				body: JSON.stringify({
					participant_id: participant_id,
					votes_count: votes_count
				})
				//credentials: 'include',
			};
			const response = await fetch(
				process.env.REACT_APP_BACKEND_URL +
					backend_url +
					"/" +
					stage_id +
					"/participant/votes",
				options
			);
			//response.data
			const data = await response.json();

			if (data.type == "success") {
				dispatch(Whx_Event_Participant_Update(data.data.task));
				dispatch(setMessage(data.message, data.type));
			}
			if (data.type == "error") {
				dispatch(setMessage(data.message, data.type));
			}
		} catch (error) {
			console.log(error);
		}
	};
