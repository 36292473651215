import { GEOBLOCK_EXCEPTIONS } from "../types";

export const geoblockExceptionsReducer = (state = {}, action) => {
	const { type, payload } = action;
	switch (type) {
		case GEOBLOCK_EXCEPTIONS: {
			const { data } = payload;
			state = {};
			return { data };
		}
		default: {
			return state;
		}
	}
};
