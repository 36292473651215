import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import "../../App";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { Container, Input } from "@mui/material";
import { connect, useDispatch, useSelector } from "react-redux";
import { addModel, getModelsEarnings } from "../../redux/actions/modelAction";
import { removeErrors, setMessage } from "../../redux/reducers/rootReducer";
import { useSnackbar } from "notistack";
import useVaporUpload from "../Upload/VaporUpload";

const mapStateToProps = (state) => {
	return {
		models_earnings: state.models_earnings
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		getModels: () => dispatch(getModelsEarnings()),
		addModel: (data) => dispatch(addModel(data)),
		setMessage: (data, type) => dispatch(setMessage(data, type))
	};
};

function AddModelProfile({ models, getModels, addModel, setMessage, admin }) {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [addFormData, setAddFormData] = React.useState({
		inHouseName: "",
		username: "",
		age: "",
		languages: "",
		favouriteSexPosition: "",
		analThoughts: "",
		oralThoughts: "",
		fantasies: "",
		groupSexThoughts: "",
		email: "",
		mobilePhone: "",
		model_blocked_countries: [],
		pageTitle: "",
		metaDescription: "",
		about_me: "",
		commissionPercent: 0,
		bestTimeForSex: "",
		sizeMatter: "",
		password: "",
		password_confirmation: ""
	});

	const handleAddForm = (event) => {
		const fieldName = event.target.getAttribute("name");
		const fieldValue = event.target.value;
		const newFormData = { ...addFormData };
		newFormData[fieldName] = fieldValue;
		setAddFormData(newFormData);
		event.preventDefault();
	};

	const handleAddFormSubmit = (event) => {
		event.preventDefault();
		if (addFormData.username.length == 0) {
			setMessage("username is required", "error");
			return false;
		}
		if (addFormData.inHouseName.length == 0) {
			setMessage("inHouseName is required", "error");
			return false;
		}
		if (addFormData.password.length == 0) {
			setMessage("password is required", "error");
			return false;
		}
		if (addFormData.password_confirmation.length == 0) {
			setMessage("password confirmation is required", "error");
			return false;
		}
		if (addFormData.password !== addFormData.password_confirmation) {
			setMessage("passwords not the same", "error");
			return false;
		}
		if (
			addFormData.password.length < 6 ||
			addFormData.password_confirmation.length < 6
		) {
			setMessage("passwords lenght must be minimum 6 characters", "error");
			return false;
		} else {
			addModel(addFormData);
			// navigate("/girlManagement");
			setImageUrl(null);
			return true;
		}
	};
	const { enqueueSnackbar } = useSnackbar();
	const errorsSubmit = useSelector((state) => state.errors);
	React.useEffect(() => {
		if (errorsSubmit && errorsSubmit.username) {
			enqueueSnackbar(errorsSubmit.username[0], { variant: "error" });
		}
		if (errorsSubmit && errorsSubmit.inHouseName) {
			enqueueSnackbar(errorsSubmit.inHouseName[0], { variant: "error" });
		}
		if (errorsSubmit && errorsSubmit.email) {
			enqueueSnackbar(errorsSubmit.email[0], { variant: "error" });
		}
		if (errorsSubmit && errorsSubmit.password) {
			enqueueSnackbar(errorsSubmit.password[0], { variant: "error" });
		}
		if (errorsSubmit && errorsSubmit.password_confirmation) {
			enqueueSnackbar(errorsSubmit.password_confirmation[0], {
				variant: "error"
			});
		}
		if (errorsSubmit && errorsSubmit.mobilePhone) {
			enqueueSnackbar(errorsSubmit.mobilePhone[0], {
				variant: "error"
			});
		}

		setTimeout(() => {
			dispatch(removeErrors());
		}, 5000);
	}, [errorsSubmit]);

	const [selectedImage, setSelectedImage] = React.useState(null);
	const [imageUrl, setImageUrl] = React.useState(null);
	const { uploadFile, uploadProgress } = useVaporUpload();
	React.useEffect(() => {
		if (selectedImage) {
			const updateImage = async () => {
				setImageUrl(URL.createObjectURL(selectedImage));
				const newFormData = { ...addFormData };
				const { vaporResponse } = await uploadFile(selectedImage);
				newFormData["myFiles"] = vaporResponse.key;

				setAddFormData(newFormData);
			};
			updateImage();
		}
	}, [selectedImage]);

	function returnToModels(event) {
		// event.preventDefault();
		navigate("/girlManagement");
	}

	const loading = useSelector((state) => state.loading);
	return (
		<>
			<Box
				component="div"
				className="page-container"
				sx={{
					flexGrow: 1,
					maxWidth: "1100px",
					margin: "0 auto",
					height: "100%",
					minHeight: "700px",
					marginLeft: "290px"
				}}
			>
				<Grid container spacing={0}>
					<Grid item xs={4} md={2} spacing={0} className="LiveShow">
						<Typography
							variant="h4"
							gutterBottom
							component="div"
							style={{ color: "white" }}
						>
							Add new model Profile
						</Typography>
					</Grid>
				</Grid>
				<Button
					startIcon={<ArrowBackIosIcon />}
					className="ModelProfilePage_arrow"
					variant="outlined"
					onClick={returnToModels}
				>
					All models
				</Button>
				<Container
					maxWidth="xl"
					style={{
						color: "#ffffff",
						width: "100%",
						height: "100%",
						backgroundColor: "#000000",
						position: "relative"
					}}
					sx={{ my: 3 }}
					className="ProvileContainer"
				>
					{loading && loading.isLoading == true ? (
						<div className="loader-container">
							<div className="spinner"></div>
						</div>
					) : (
						""
					)}
					<Grid spacing={1} className="ModelProfilePage_wrap">
						<Grid item xs={6} md={4}>
							<Box component="div" className="ModelProfilePage_main">
								<Stack
									direction="row"
									className="ModelProfilePage_row"
									spacing={1}
									style={{ flexGrow: "0.6" }}
								>
									<Typography
										gutterBottom
										component="div"
										className="ModelProfilePage_elements"
									>
										Profile image
									</Typography>
									<Box
										style={{
											display: "flex",
											flexDirection: "column",
											alignItems: "center",
											width: "100%"
										}}
									>
										<Box
											mt={2}
											textAlign="center"
											style={{
												width: "100px",
												height: "100px",
												backgroundColor: "rgb(41, 41, 41)",
												borderRadius: "50%"
											}}
										>
											{imageUrl && selectedImage && (
												<img
													src={imageUrl}
													alt={selectedImage.name}
													height="100px"
													width="100px"
													accept="image/x-png,image/gif,image/jpeg"
													style={{ borderRadius: "50%" }}
												/>
											)}
										</Box>

										<input
											accept="image/*"
											style={{ display: "none" }}
											id="select-image"
											multiple
											type="file"
											name="myFiles"
											onChange={(e) => {
												setSelectedImage(e.target.files[0]);
											}}
										/>
										<label htmlFor="select-image" style={{ marginTop: "10px" }}>
											<Button variant="raised" component="span">
												Upload
											</Button>
										</label>
									</Box>
								</Stack>
								<Stack
									direction="row"
									className="ModelProfilePage_row"
									spacing={1}
								>
									<Typography
										gutterBottom
										component="div"
										className="ModelProfilePage_elements"
									>
										Username *
									</Typography>
									<Input
										className="inputBoxProfile"
										name="username"
										disableUnderline="true"
										style={{ backgroundColor: "#292929" }}
										onChange={handleAddForm}
										required
									></Input>
								</Stack>
								<Stack
									direction="row"
									className="ModelProfilePage_row"
									spacing={1}
								>
									<Typography
										gutterBottom
										component="div"
										className="ModelProfilePage_elements"
									>
										Email
									</Typography>
									<Input
										className="inputBoxProfile"
										disableUnderline="true"
										name="email"
										type="email"
										style={{ backgroundColor: "#292929" }}
										onChange={handleAddForm}
										required
										onInvalid={(e) =>
											e.target.setCustomValidity("Provide correct email")
										}
									></Input>
								</Stack>
								<Stack
									direction="row"
									className="ModelProfilePage_row"
									spacing={1}
								>
									<Typography
										gutterBottom
										component="div"
										className="ModelProfilePage_elements"
									>
										Display Name *
									</Typography>
									<Input
										className="inputBoxProfile"
										name="inHouseName"
										disableUnderline="true"
										style={{ backgroundColor: "#292929" }}
										onChange={handleAddForm}
										required
									></Input>
								</Stack>
								<Stack
									direction="row"
									className="ModelProfilePage_row"
									spacing={1}
								>
									<Typography
										gutterBottom
										component="div"
										className="ModelProfilePage_elements"
									>
										Password *
									</Typography>
									<Input
										className="inputBoxProfile"
										disableUnderline="true"
										name="password"
										style={{ backgroundColor: "#292929" }}
										onChange={handleAddForm}
										required
									></Input>
								</Stack>
								<Stack
									direction="row"
									className="ModelProfilePage_row"
									spacing={1}
								>
									<Typography
										gutterBottom
										component="div"
										className="ModelProfilePage_elements"
									>
										Password_confirmation *
									</Typography>
									<Input
										className="inputBoxProfile"
										disableUnderline="true"
										name="password_confirmation"
										style={{ backgroundColor: "#292929" }}
										onChange={handleAddForm}
										required
									></Input>
								</Stack>
								<Stack
									direction="row"
									className="ModelProfilePage_row"
									spacing={1}
								>
									<Typography
										gutterBottom
										component="div"
										className="ModelProfilePage_elements"
									>
										Mobile Number
									</Typography>
									<Input
										name="mobilePhone"
										className="inputBoxProfile"
										disableUnderline="true"
										style={{ backgroundColor: "#292929" }}
										onChange={handleAddForm}
										required
									></Input>
								</Stack>
								{admin && admin.user.role == "superadmin" && (
									<Stack
										direction="row"
										className="ModelProfilePage_row"
										spacing={1}
									>
										<Typography
											gutterBottom
											component="div"
											className="ModelProfilePage_elements"
										>
											Commission %
										</Typography>
										<Input
											name="commissionPercent"
											onChange={handleAddForm}
											className="inputBoxProfile"
											disableUnderline="true"
											style={{ backgroundColor: "#292929" }}
										></Input>
									</Stack>
								)}
							</Box>
						</Grid>
						<Grid
							item
							xs={6}
							md={8}
							style={{ margin: "0 auto", textAlign: "center" }}
						>
							<Box component="div" className="ModelProfilePage_main">
								<Stack
									direction="row"
									className="ModelProfilePage_row"
									spacing={1}
								>
									<Typography
										gutterBottom
										component="div"
										className="ModelProfilePage_elements"
									>
										Age
									</Typography>
									<Input
										className="inputBoxProfile"
										name="age"
										disableUnderline="true"
										style={{ backgroundColor: "#292929" }}
										onChange={handleAddForm}
									></Input>
								</Stack>
								<Stack
									direction="row"
									className="ModelProfilePage_row"
									spacing={1}
								>
									<Typography
										gutterBottom
										component="div"
										className="ModelProfilePage_elements"
									>
										Language
									</Typography>
									<Input
										className="inputBoxProfile"
										name="languages"
										disableUnderline="true"
										style={{ backgroundColor: "#292929" }}
										onChange={handleAddForm}
									></Input>
								</Stack>
								<Stack
									direction="row"
									className="ModelProfilePage_row"
									spacing={1}
								>
									<Typography
										gutterBottom
										component="div"
										className="ModelProfilePage_elements"
									>
										Favourite Sex Pos.
									</Typography>
									<Input
										className="inputBoxProfile"
										name="favouriteSexPosition"
										disableUnderline="true"
										style={{ backgroundColor: "#292929" }}
										onChange={handleAddForm}
									></Input>
								</Stack>
								<Stack
									direction="row"
									className="ModelProfilePage_row"
									spacing={1}
								>
									<Typography
										gutterBottom
										component="div"
										sx={{ fontSze: "10px" }}
										className="ModelProfilePage_elements"
									>
										Best time for Sex?
									</Typography>
									<Input
										name="bestTimeForSex"
										className="inputBoxProfile"
										disableUnderline="true"
										style={{ backgroundColor: "#292929" }}
										onChange={handleAddForm}
									></Input>
								</Stack>
								<Stack
									direction="row"
									className="ModelProfilePage_row"
									spacing={1}
								>
									<Typography
										gutterBottom
										component="div"
										className="ModelProfilePage_elements"
									>
										Does size matter?
									</Typography>
									<Input
										name="sizeMatter"
										className="inputBoxProfile"
										disableUnderline="true"
										style={{ backgroundColor: "#292929" }}
										onChange={handleAddForm}
									></Input>
								</Stack>
								<Stack
									direction="row"
									className="ModelProfilePage_row"
									spacing={1}
								>
									<Typography
										gutterBottom
										component="div"
										className="ModelProfilePage_elements"
									>
										Group sex?
									</Typography>
									<Input
										name="groupSexThoughts"
										className="inputBoxProfile"
										disableUnderline="true"
										style={{ backgroundColor: "#292929" }}
										onChange={handleAddForm}
									></Input>
								</Stack>
								<Stack
									direction="row"
									className="ModelProfilePage_row"
									spacing={1}
								>
									<Typography
										gutterBottom
										component="div"
										className="ModelProfilePage_elements"
									>
										Anal?
									</Typography>
									<Input
										className="inputBoxProfile"
										name="analThoughts"
										disableUnderline="true"
										style={{ backgroundColor: "#292929" }}
										onChange={handleAddForm}
									></Input>
								</Stack>
								<Stack
									direction="row"
									className="ModelProfilePage_row"
									spacing={1}
								>
									<Typography
										gutterBottom
										component="div"
										className="ModelProfilePage_elements"
									>
										Oral?
									</Typography>
									<Input
										className="inputBoxProfile"
										name="oralThoughts"
										disableUnderline="true"
										style={{ backgroundColor: "#292929" }}
										onChange={handleAddForm}
									></Input>
								</Stack>
								<Stack
									direction="row"
									className="ModelProfilePage_row"
									spacing={1}
								>
									<Typography
										gutterBottom
										component="div"
										className="ModelProfilePage_elements"
									>
										Page title?
									</Typography>
									<Input
										name="pageTitle"
										className="inputBoxProfile"
										disableUnderline="true"
										style={{ backgroundColor: "#292929" }}
										onChange={handleAddForm}
									></Input>
								</Stack>
								<Stack
									direction="row"
									className="ModelProfilePage_row"
									spacing={1}
								>
									<Typography
										gutterBottom
										component="div"
										className="ModelProfilePage_elements"
									>
										Meta description
									</Typography>
									<Input
										name="metaDescription"
										className="inputBoxProfile"
										disableUnderline="true"
										style={{ backgroundColor: "#292929" }}
										onChange={handleAddForm}
									></Input>
								</Stack>
								<Stack
									direction="row"
									className="ModelProfilePage_row"
									spacing={1}
								>
									<Typography
										gutterBottom
										component="div"
										className="ModelProfilePage_elements"
									>
										Fantasy?
									</Typography>
									<Input
										className="inputBoxProfile"
										name="fantasies"
										disableUnderline="true"
										style={{ backgroundColor: "#292929" }}
										onChange={handleAddForm}
									></Input>
								</Stack>
								<Stack
									direction="row"
									className="ModelProfilePage_row"
									spacing={1}
								>
									<Typography
										gutterBottom
										component="div"
										className="ModelProfilePage_elements"
									>
										Bio Text
									</Typography>
									<Input
										name="about_me"
										className="inputBoxProfile"
										disableUnderline="true"
										style={{
											backgroundColor: "#292929",
											minHeight: "100px",
											maxHeight: "200px",
											minWidth: "300px",
											overflow: "hidden"
										}}
										multiline="true"
										rows={5}
										onChange={handleAddForm}
									></Input>
								</Stack>
								<Stack
									direction="row"
									className="ModelProfilePage_row"
									spacing={1}
								>
									<Typography
										gutterBottom
										component="div"
										className="ModelProfilePage_elements"
									>
										Status text
									</Typography>
									<Input
										className="inputBoxProfile"
										name="status"
										disableUnderline="true"
										style={{ backgroundColor: "#292929" }}
										onChange={handleAddForm}
									></Input>
								</Stack>
							</Box>
						</Grid>
					</Grid>
					<Grid spacing={1} className="userProfile_grid">
						<Grid
							item
							xs={6}
							style={{
								width: "100%",
								display: "flex",
								flexDirection: "row",
								justifyContent: "center"
							}}
						>
							<Button
								onClick={handleAddFormSubmit}
								size="small"
								variant="contained"
								style={{
									color: "black",
									backgroundColor: "#78F73B",
									maxWidth: "100px",
									maxHeight: "33px",
									minWidth: "100px",
									minHeight: "33px",
									borderRadius: "4px",
									textTransform: "unset"
								}}
								className="create"
							>
								Add
							</Button>
						</Grid>
						<Grid
							item
							xs={6}
							style={{
								display: "flex",
								flexDirection: "row",
								justifyContent: "flex-end"
							}}
						></Grid>
					</Grid>
				</Container>
			</Box>
		</>
	);
}

export default connect(mapStateToProps, mapDispatchToProps)(AddModelProfile);
