import * as React from "react";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import {
	Stack,
	Table,
	TableBody,
	TableContainer,
	TableHead,
	TableRow
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import GirlIcon from "@material-ui/icons/Face";
import { connect, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
	cancelUserSubscription,
	deleteUserSubscriptions,
	getUserSubscriptions
} from "../../redux/actions/userAction";

const mapStateToProps = (state) => {
	return {
		user_subscriptions: state.user_subscriptions,
		users: state.users
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getUserSubscriptions: (id) => dispatch(getUserSubscriptions(id)),
		deleteUserSubscriptions: (id) => dispatch(deleteUserSubscriptions(id))
	};
};

function UserSubscriptions({
	getUserSubscriptions,
	deleteUserSubscriptions,
	user_subscriptions,
	users
}) {
	const dispatch = useDispatch();
	const param = useParams();
	const navigate = useNavigate();
	React.useEffect(() => {
		deleteUserSubscriptions(param.id);
		getUserSubscriptions(param.id);
	}, []);

	const StyledTableRow = styled(TableRow)(({ theme }) => ({
		"&:nth-of-type(odd)": {
			backgroundColor: "rgb(0 0 0)"
		},
		"&:nth-of-type(even)": {
			backgroundColor: "#ffffff14"
		},
		// hide last border
		"&:last-child td, &:last-child th": {
			border: 0
		},
		"&.MuiTableRow-root:hover": {
			backgroundColor: "#555555"
		}
	}));

	const StyledTableCell = styled(TableCell)(({ theme }) => ({
		[`&.${tableCellClasses.head}`]: {
			backgroundColor: "#292929",
			color: theme.palette.common.white
		},
		[`&.${tableCellClasses.body}`]: {
			fontSize: 14
		}
	}));

	const data = [
		{ id: 0, name: "January" },
		{ id: 1, name: "February" },
		{ id: 2, name: "March" },
		{ id: 3, name: "April" },
		{ id: 4, name: "May" },
		{ id: 5, name: "June" },
		{ id: 6, name: "July" },
		{ id: 7, name: "August" },
		{ id: 8, name: "September" },
		{ id: 9, name: "October" },
		{ id: 10, name: "November" },
		{ id: 11, name: "December" }
	];

	const [month, setMonth] = React.useState(undefined);
	const [year, setYear] = React.useState(undefined);

	function getIndex(name) {
		return data.findIndex((obj) => obj.name == name);
	}

	function getIndex2(id) {
		return users?.users?.findIndex((obj) => obj.id == id);
	}

	let check2 = getIndex2(param.id);

	return (
		<>
			<Stack spacing={3} sx={{ width: 600 }}>
				<Stack spacing={3} sx={{ width: 900 }}>
					<TableContainer
						className="SchrollBar"
						component={Paper}
						style={{ overflow: "auto", marginRight: "40px" }}
						sx={{ maxHeight: "800px" }}
					>
						<Table aria-label="simple table" stickyHeader>
							<TableHead>
								<StyledTableRow>
									<StyledTableCell
										style={{ borderBottom: "none" }}
										align="left"
									>
										Valid from
									</StyledTableCell>
									<StyledTableCell
										style={{ borderBottom: "none" }}
										align="left"
									>
										Valid to
									</StyledTableCell>
									<StyledTableCell
										style={{ borderBottom: "none" }}
										align="left"
									>
										Package type
									</StyledTableCell>
									<StyledTableCell
										style={{ borderBottom: "none" }}
										align="right"
									>
										Recurring Billing
									</StyledTableCell>
								</StyledTableRow>
							</TableHead>
							<TableBody>
								{user_subscriptions &&
									user_subscriptions.length > 0 &&
									user_subscriptions.map((row) => (
										<StyledTableRow
											key={row?.id}
											sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
										>
											<TableCell
												align="left"
												style={{
													borderBottom: "none",
													color: "#78F73B"
												}}
											>
												<GirlIcon
													style={{ color: "white", marginRight: "10px" }}
												/>
												{row?.valid_from}
											</TableCell>
											<TableCell
												align="left"
												style={{ borderBottom: "none", color: "#FFD800" }}
											>
												{row?.valid_to}
											</TableCell>
											<TableCell
												align="left"
												style={{ borderBottom: "none", color: "#FFD800" }}
											>
												{row?.package_name}
											</TableCell>
											<TableCell
												align="right"
												style={{ borderBottom: "none", color: "#FFD800" }}
											>
												{row?.is_recurring == 1 ? (
													<Button
														onClick={() => {
															dispatch(
																cancelUserSubscription(param?.id, row?.id)
															);
														}}
														style={{
															color: "black",
															backgroundColor: "red",
															borderRadius: "10px"
														}}
													>
														Cancel
													</Button>
												) : (
													"Not recurring"
												)}
											</TableCell>
										</StyledTableRow>
									))}
							</TableBody>
						</Table>
					</TableContainer>
				</Stack>
			</Stack>
		</>
	);
}

export default connect(mapStateToProps, mapDispatchToProps)(UserSubscriptions);
