import * as React from "react";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import {
	Autocomplete,
	Box,
	Container,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	Input,
	List,
	Select,
	Skeleton,
	Stack,
	Tab,
	TableRow,
	TextField,
	Typography
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { createTheme, styled, ThemeProvider } from "@mui/material/styles";
import { connect, useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { MenuItem } from "@material-ui/core";
import { useNavigate, useParams } from "react-router-dom";
import {
	DeleteGallery,
	GetGalleryAction,
	UpdateGallery
} from "../../redux/actions/GalleriesActions";
import UploadGallery from "../Upload/UploadGallery";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import UploadGalleryVideos from "../Upload/UploadGalleryVideos";
import Cameras_Reorder_Modal from "../AllCameras/Cameras_Reorder_Modal";
import GalleryReorderModal from "./GalleryReorderModal";
import { hideModal, showModal } from "../../redux/reducers/rootReducer";

const mapStateToProps = (state) => {
	return {
		performers: state.models,
		galleries: state.galleries,
		gallery: state.gallery,
		model: state.model,
		modal: state.modal
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		GetGalleries: () => dispatch(GetGalleries())
	};
};

function EditGallery({ galleries, gallery, model, modal }) {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [test, settest] = React.useState();
	const param = useParams();
	React.useEffect(() => {
		dispatch(GetGalleryAction(param.id));
		setSelectedTags(null);
	}, []);
	React.useEffect(() => {
		return () => {
			setSelectedTags(null);
		};
	}, []);
	const theme = createTheme({
		palette: {
			success: {
				// Purple and green play nicely together.
				main: "#78F73B"
			},
			error: {
				// This is green.A700 as hex.
				main: "#F73B3B"
			}
		}
	});

	const [selectedTags, setSelectedTags] = React.useState(
		gallery && gallery.gallery && gallery.gallery.metaTags
	);
	React.useEffect(() => {
		setSelectedTags(gallery && gallery.gallery && gallery.gallery.metaTags);
	}, [gallery]);
	// const [check, setCheck] = React.useState(false);
	// React.useEffect(() => {
	//   console.log("here");
	//   console.log(gallery && gallery.gallery && gallery.gallery.metaTags);
	//   setCheck(true);
	//   setSelectedTags(gallery && gallery.gallery && gallery.gallery.metaTags);
	//   inputList.metaDescription =
	//     gallery && gallery.gallery && gallery.gallery.metaDescription;
	//   setCheck(false);
	// }, [gallery]);

	const [inputList, setInputList] = React.useState({
		description: "",
		metaDescription: ""
	});
	React.useEffect(() => {
		setInputList({
			description: gallery && gallery.gallery && gallery.gallery.name,
			metaDescription:
				gallery && gallery.gallery && gallery.gallery.metaDescription
					? gallery.gallery.metaDescription
					: ""
		});
	}, [gallery]);

	const handleInputChange = (e) => {
		const fieldName = event.target.getAttribute("name");
		const fieldValue = event.target.value;
		const newFormData = { ...inputList };
		newFormData[fieldName] = fieldValue;
		setInputList(newFormData);
	};

	const [characterLimit] = React.useState(70);
	const all_events = galleries && galleries.filters && galleries.filters.events;
	const all_models = galleries && galleries.filters && galleries.filters.models;

	const [selected, setSelected] = React.useState(
		gallery && gallery.gallery && gallery.gallery.selected_type
	);
	const handleChangeCampaign = (event) => {
		setSelected(event.target.value);
	};
	const [selectedEvents, setSelectedEvents] = React.useState("");
	const [selectedHousemate, setSelectedHousemate] = React.useState("");

	React.useEffect(() => {
		if (selected == "Event") {
			setSelectedEvents(
				gallery && gallery.gallery && gallery.gallery.selected_id
			);
			setSelectedHousemate("");
		}
		if (selected == "Housemate") {
			setSelectedEvents("");
			setSelectedHousemate(
				gallery && gallery.gallery && gallery.gallery.selected_id
			);
		}
	}, [selected, gallery]);

	const [addFormData, setAddFormData] = React.useState([]);

	const handleAutocompleteChange = (target) => {
		var task_names = target.target.value;
		setSelectedEvents(task_names);
	};
	const handleChangeHousemate = (target) => {
		var task_names = target.target.value;
		setSelectedHousemate(task_names);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		let obj;
		if (selected && selected === "Event") {
			obj = {
				name: inputList.description,
				selected_type: selected,
				selected_id: selectedEvents,
				metaDescription: inputList.metaDescription,
				metaTags: selectedTags
			};
		}
		if (selected && selected === "Housemate") {
			obj = {
				name: inputList.description,
				selected_type: selected,
				selected_id: selectedHousemate,
				metaDescription: inputList.metaDescription,
				metaTags: selectedTags
			};
		}
		if (selected && selected === "Main") {
			obj = {
				name: inputList.description,
				selected_type: selected,
				metaDescription: inputList.metaDescription,
				metaTags: selectedTags
			};
		}
		dispatch(UpdateGallery(obj, param.id));
	};
	const [open, setOpen] = React.useState(false);

	const handleOpen = () => {
		setOpen(true);
	};
	const handleClose = () => setOpen(false);

	const style = {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		maxWidth: 1200,
		minWidth: 850,
		minHeight: 350,
		maxHeight: 500,
		/* width: '100%', */
		bgcolor: "background.paper",
		textAlign: "center",
		margin: "0 auto",
		p: 4
	};

	const StyledTableRow = styled(TableRow)(({ theme }) => ({
		"&:nth-of-type(odd)": {
			backgroundColor: "rgb(0 0 0)"
		},
		"&:nth-of-type(even)": {
			backgroundColor: "#ffffff14"
		},
		// hide last border
		"&:last-child td, &:last-child th": {
			border: 0
		},
		"&.MuiTableRow-root:hover": {
			backgroundColor: "#555555"
		}
	}));

	const StyledTableCell = styled(TableCell)(({ theme }) => ({
		[`&.${tableCellClasses.head}`]: {
			/*    backgroundColor: "#292929", */
			color: theme.palette.common.white
		},
		[`&.${tableCellClasses.body}`]: {
			fontSize: 14
		}
	}));

	function getTimeStamp(timeCreated, TimeEnded) {
		const endDate = moment(TimeEnded);
		const startDate = moment(timeCreated);
		const diff = endDate - startDate;
		var diffDuration = moment.duration(diff);
		const days = diffDuration.days();
		const hours = diffDuration.hours() + days * 24; // get hours with getHours method
		const minutes = diffDuration.minutes();
		const seconds = diffDuration.seconds(); // get minutes with getMinutes method // get seconds with getSeconds method
		const timeString = `${pad(hours)}h ${pad(minutes)}m ${pad(seconds)}s`;
		return timeString; // finally, join to a time string
	}

	function pad(num) {
		return ("0" + num).slice(-2);
	}

	const currencies = [
		{
			value: "Main",
			label: "$"
		},
		{
			value: "Event",
			label: "€"
		},
		{
			value: "Housemate",
			label: "฿"
		}
	];

	const handleChangeEvent = (event) => {
		setSelectedEvents(event.target.value);
	};
	const [value, setValue] = React.useState("1");
	const handleChange = (event, newValue) => {
		setValue(newValue);
		dispatch(GetGalleryAction(param.id));
	};
	const handleDelete = () => {
		setOpenConfirm(false);
		dispatch(DeleteGallery(param.id));
		navigate("/galleries");
	};

	const [openConfirm, setOpenConfirm] = React.useState(false);

	const handleClickOpen = () => {
		setOpenConfirm(true);
	};

	const handleCloseConfirm = () => {
		setOpenConfirm(false);
	};
	const handleOpenPass = () => {
		{
			modal && modal.name == "sorder"
				? dispatch(hideModal("sorder"))
				: dispatch(showModal("sorder"));
		}
	};
	const loading = useSelector((state) => state.loading);
	return (
		<>
			<Stack
				spacing={3}
				sx={{ width: 1300, marginLeft: "290px", marginTop: "40px" }}
			>
				{loading && loading.isLoading == true ? (
					<div className="loader-container">
						<div className="spinner"></div>
					</div>
				) : (
					""
				)}
				<Box
					style={{ marginTop: loading && loading.isLoading == true && "0px" }}
				>
					<Grid
						container
						spacing={0}
						style={{ display: "flex", flexDirection: "column" }}
					>
						<Grid item xs={4} md={2} spacing={0} className="Pools">
							{!gallery && !gallery.gallery ? (
								<Skeleton width={330} height={55} />
							) : (
								<>
									<Typography
										variant="h4"
										gutterBottom
										component="div"
										style={{ color: "white", marginBottom: "unset" }}
									>
										Gallery
									</Typography>
									<Typography
										variant="h4"
										gutterBottom
										component="div"
										style={{
											color: "#78f73b",
											marginBottom: "unset",
											paddingLeft: "10px"
										}}
									>
										{gallery && gallery.gallery && gallery.gallery.name}
									</Typography>
								</>
							)}
						</Grid>
						<Box
							component="div"
							sx={{
								flexGrow: 1,
								maxWidth: "650px",
								margin: "0",
								height: "100%"
								/* minHeight: "500px", */
							}}
						>
							<Container
								maxWidth="xl"
								style={{
									color: "#ffffff",
									width: "100%",
									height: "100%",
									backgroundColor: "#000000",
									borderRadius: "5px"
								}}
								sx={{ my: 3 }}
								className="PoolContainer"
							>
								<Grid spacing={1} className="Pool_wrap">
									<Grid item xs={12}>
										<Box component="div" className="Pool_main">
											<Stack direction="row" className="Pools_row">
												<Input
													value={inputList.description}
													name="description"
													onChange={(e) => {
														handleInputChange(e);
													}}
													placeholder="Tittle"
													className="Pools_input_text"
													disableUnderline="true"
													inputProps={{ maxLength: 70 }}
												></Input>
												<Typography
													color={
														inputList &&
														inputList.description &&
														inputList.description.length >= characterLimit
															? "red"
															: "white"
													}
													style={{ position: "absolute", left: "890px" }}
												>
													{inputList &&
														inputList.description &&
														inputList.description.length}
													/{characterLimit}
												</Typography>
											</Stack>
											<Stack className="Pools_row">
												<Select
													className="PayoutSelect"
													value={selected && selected}
													onChange={handleChangeCampaign}
													displayEmpty
													renderValue={(value) => {
														return (
															<Box sx={{ display: "flex", gap: 1 }}>
																<div
																	style={{
																		display: "flex",
																		width: "-webkit-fill-available",
																		justifyContent: "center"
																	}}
																>
																	{value}
																</div>
															</Box>
														);
													}}
												>
													{currencies.map((option) => (
														<MenuItem
															style={{ textAlign: "center" }}
															key={option.value}
															value={option.value}
														>
															{option.value}
														</MenuItem>
													))}
												</Select>
												{selected && selected == "Event" && (
													<Autocomplete
														className="GalleryInput"
														name="participants"
														id="id"
														value={selectedEvents && selectedEvents}
														style={{
															width: "40%",
															marginLeft: "10px",
															height: "35px"
														}}
														onChange={(event, value) => {
															handleAutocompleteChange({
																target: {
																	value: value
																}
															});
														}}
														options={Object.keys(all_events && all_events)}
														getOptionLabel={(option) =>
															all_events[option] || ""
														}
														renderInput={(params) => (
															<TextField
																{...params}
																InputProps={{
																	...params.InputProps,
																	disableUnderline: true
																}}
																variant="standard"
																name="participants"
															/>
														)}
													/>
												)}
												{selected && selected == "Housemate" && (
													<Autocomplete
														className="GalleryInput"
														name="participants"
														id="id"
														value={selectedHousemate && selectedHousemate}
														style={{
															width: "40%",
															marginLeft: "10px",
															height: "35px"
														}}
														onChange={(event, value) => {
															handleChangeHousemate({
																target: {
																	value: value
																}
															});
														}}
														options={Object.keys(all_models && all_models)}
														getOptionLabel={(option) =>
															all_models[option] || ""
														}
														renderInput={(params) => (
															<TextField
																{...params}
																InputProps={{
																	...params.InputProps,
																	disableUnderline: true
																}}
																variant="standard"
																name="participants"
															/>
														)}
													/>
												)}
											</Stack>
											<Stack direction="row" className="Pools_row" spacing={1}>
												{/* <TagsInput
                          value={selectedTags}
                          onChange={setSelectedTags}
                          name="tags"
                          placeHolder="enter tags"
                        /> */}
												<Autocomplete
													multiple
													id="tags-standard"
													freeSolo={true}
													fullWidth
													value={selectedTags}
													onChange={(event, value) => setSelectedTags(value)}
													options={[]}
													sx={{
														backgroundColor: "#1d1d1d",
														borderRadius: "4px",
														textAlign: "left",
														fontSize: "14px"
													}}
													className="tags-input"
													renderInput={(params) => (
														<TextField
															{...params}
															variant="standard"
															placeholder="Input tags"
															placeholderStyle={{ color: "red" }}
															sx={{
																borderRadius: "4px",
																padding: "0px 10px 0px 5px",
																textAlign: "left",
																fontSize: "14px"
															}}
														/>
													)}
												/>
											</Stack>
											<Stack direction="row" className="Pools_row">
												<Input
													value={inputList.metaDescription}
													name="metaDescription"
													multiline
													onChange={(e) => {
														handleInputChange(e);
													}}
													style={{ padding: "5px", height: "unset" }}
													placeholder="Meta Description"
													className="Pools_input_text"
													disableUnderline="true"
												></Input>
											</Stack>
											<Stack direction="row" className="Galleries_row">
												<Button
													/*  size="small" */
													variant="outlined"
													size="medium"
													style={{
														paddingLeft: "15px",
														marginLeft: "10px",
														maxWidth: "fit-content",
														border: "1px solid #F73B3B",
														color: "#F73B3B",
														padding: "5px 15px",
														borderRadius: "4px"
													}}
													onClick={handleClickOpen}
													className="delete"
												>
													Delete Gallery
												</Button>
												<ThemeProvider theme={theme}>
													<Button
														size="medium"
														variant="outlined"
														/* color="success" */
														style={{
															paddingLeft: "15px",
															marginLeft: "10px",
															maxWidth: "90px",
															border: "1px solid #78f73b80",
															color: "#78F73B",
															padding: "5px 15px",
															borderRadius: "4px"
														}}
														color={"success"}
														onClick={(e) => {
															handleSubmit(e);
														}}
													>
														{"Update"}
													</Button>
												</ThemeProvider>
											</Stack>
											<Dialog
												open={openConfirm}
												onClose={handleCloseConfirm}
												aria-labelledby="alert-dialog-title"
												aria-describedby="alert-dialog-description"
											>
												<DialogContent>
													<DialogContentText
														style={{ color: "white", fontSize: "1.3rem" }}
														id="alert-dialog-description"
													>
														Are use sure you want to delete this Gallery?
													</DialogContentText>
												</DialogContent>
												<DialogActions>
													<Button
														style={{ color: "#F73B3B" }}
														onClick={handleCloseConfirm}
													>
														Disagree
													</Button>
													<Button
														style={{ color: "#78F73B" }}
														onClick={handleDelete}
														autoFocus
													>
														Agree
													</Button>
												</DialogActions>
											</Dialog>
										</Box>
									</Grid>
								</Grid>
							</Container>
						</Box>
					</Grid>
				</Box>
				<Box>
					<Grid
						item
						xs={4}
						md={2}
						spacing={0}
						style={{
							display: "flex",
							flexDirection: "row",
							alignItems: "center"
						}}
					>
						<Typography
							variant="h5"
							gutterBottom
							component="div"
							style={{ color: "#FFFFFF" }}
						>
							Gallery media
						</Typography>
						<Button
							onClick={() => handleOpenPass()}
							className="Cameras_edit"
							style={{ marginLeft: "40px", height: "30px" }}
						>
							Edit order
						</Button>
					</Grid>
					<Box sx={{ width: "100%", typography: "body1", color: "#FFFFFF" }}>
						<TabContext value={value}>
							<Box
								sx={{
									borderBottom: 1,
									borderColor: "#FFFFFF",
									color: "#FFFFFF"
								}}
							>
								<TabList
									onChange={handleChange}
									aria-label="lab API tabs example"
								>
									<Tab style={{ color: "#FFFFFF" }} label="PHOTOS" value="1" />
									<Tab style={{ color: "#FFFFFF" }} label="VIDEOS" value="2" />
								</TabList>
							</Box>
							<TabPanel value="1">
								<Paper style={{ width: "95%", minHeight: "100px" }}>
									<List className="AllRoomBlock_list_images">
										<UploadGallery id={model.user && model.user.id} />
									</List>
								</Paper>
							</TabPanel>
							<TabPanel value="2">
								<Paper style={{ width: "95%", minHeight: "100px" }}>
									<List className="AllRoomBlock_list_images">
										<UploadGalleryVideos id={model.user && model.user.id} />
									</List>
								</Paper>
							</TabPanel>
						</TabContext>
					</Box>
				</Box>
			</Stack>
			<GalleryReorderModal
				modal={modal}
				items={value == 1 ? gallery?.gallery?.images : gallery?.gallery?.videos}
				type={value == 1 ? "image" : "video"}
				id={gallery?.gallery?.id}
			/>
		</>
	);
}

export default connect(mapStateToProps, mapDispatchToProps)(EditGallery);
