import { SIDEBAR_HIDE } from "../types";

const initialState = {};
export const sidebarReducer = (state = {}, action) => {
	const { type, payload } = action;

	switch (type) {
		case SIDEBAR_HIDE: {
			const { data } = payload;
			return data;
		}
		default: {
			return initialState;
		}
	}
};
