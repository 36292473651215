import * as React from "react";
import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import {
	Box,
	Checkbox,
	Input,
	MenuItem,
	Stack,
	Table,
	TableBody,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	Typography
} from "@mui/material";
import TableCell from "@mui/material/TableCell";
import { useDispatch } from "react-redux";
import IconButton from "@mui/material/IconButton";
import CircleIcon from "@mui/icons-material/Circle";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import MultiSelectUI from "../../UI/MultiSelectUI";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
	LocalizationProvider,
	MobileDateTimePicker
} from "@mui/x-date-pickers";
import dayjs from "dayjs";
import {
	CreateTask,
	DeleteTask,
	UpdateTask,
	UpdateTaskStatus
} from "../../redux/actions/studActions";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";

function EventTasks({ stage, backend_url }) {
	const dispatch = useDispatch();

	const [selected, setselected] = useState("none");
	const handleClickOpen = (index) => {
		setadd(false);
		setselected(index);
	};

	const [add, setadd] = useState(false);
	const handleClickAdd = (index) => {
		setselected("none");
		setadd(true);
	};
	const [dateWithInitialValues, setDateWithInitialValues] = React.useState(
		stage?.tasks?.map((task) => task?.start_time)
	); // Initialize array with default values
	const [dateWithInitialValuesCreate, setDateWithInitialValuesCreate] =
		React.useState(
			dayjs(new Date()) // Initialize array with default values
		);
	const [participants, setParticipants] = React.useState([]);
	const [task_participants, setTaskParticipants] = useState(() =>
		stage?.tasks?.map((task) =>
			task?.participants?.map((participant) => ({
				title: participant.inHouseName,
				value: participant.id
			}))
		)
	);

	useEffect(() => {
		if (stage) {
			setDateWithInitialValues(stage?.tasks?.map((task) => task?.start_time));
			setTaskParticipants(() =>
				stage?.tasks?.map((task) =>
					task?.participants?.map((participant) => ({
						title: participant.inHouseName,
						value: participant.id
					}))
				)
			);
		}
	}, [stage, stage?.tasks]);
	const [selectedValue, setSelectedValue] = React.useState("");
	const handleAddTrickName = (event) => {
		setSelectedValue(event.target.value);
	};

	const handleAdd = (id) => {
		var participantsId = participants?.map((participant) => {
			return participant?.value;
		});
		var name = selectedValue;
		let start_time = dateWithInitialValuesCreate?.$d?.toISOString();
		dispatch(CreateTask(backend_url, id, participantsId, name, start_time));
		setadd(false);
		setParticipants([]);
		setSelectedValue("");
	};

	const [selectedTaskName, setSelectedTaskValue] = React.useState({});
	const handleChangeTrickName = (event) => {
		setSelectedTaskValue({ index: selected, value: event.target.value });
	};
	const handleEdit = (id) => {
		var elem = document.getElementById(selected).value;
		var participantsId = task_participants[selected]?.map((participant) => {
			return participant?.value;
		});
		let selected_date;
		let formatted_date;
		if (dateWithInitialValues[selected]?.$d == undefined) {
			selected_date = new Date(dateWithInitialValues[selected]);
			formatted_date = selected_date.toISOString();
		} else {
			selected_date = dateWithInitialValues[selected].$d;
			formatted_date = selected_date.toISOString();
		}
		const body = JSON.stringify({
			participants: participantsId,
			name: elem,
			start_time: formatted_date
		});
		dispatch(UpdateTask(backend_url, id, body));
		setselected("none");
	};

	const handleChangeParticipants = (value) => {
		const selectedValues = Array.isArray(value) ? value : value?.target.value;

		const options = stage?.participants?.map((model, i) => {
			return {
				title: model?.inHouseName,
				value: model?.id
			};
		});
		const selectedParticipants = options.filter((option) =>
			selectedValues.some((selected) => selected.value === option.value)
		);
		setParticipants(selectedParticipants);
	};
	const handleChangeTaskParticipants = (value, taskIndex) => {
		const selectedValues = Array.isArray(value) ? value : value?.target.value;

		const options = stage?.participants?.map((model, i) => {
			return {
				title: model?.inHouseName,
				value: model?.id
			};
		});
		const selectedParticipants = options.filter((option) =>
			selectedValues.some((selected) => selected.value === option.value)
		);
		setTaskParticipants((prevParticipants) => {
			const newParticipants = [...prevParticipants];
			newParticipants[taskIndex] = selectedParticipants;
			return newParticipants;
		});
	};
	return (
		<>
			<Stack spacing={3} sx={{ width: 1200 }}>
				<Box>
					<Grid
						container
						spacing={0}
						style={{ display: "flex", flexDirection: "column" }}
					>
						<Grid
							item
							xs={4}
							md={2}
							spacing={0}
							className="Magicx_text"
							style={{ paddingTop: "25px" }}
						>
							<Typography
								variant="h4"
								gutterBottom
								component="div"
								style={{ color: "white", marginBottom: "unset" }}
							>
								Tasks - ${stage?.name}
							</Typography>
						</Grid>
						<Box component="div" className="Voting_tricks">
							<TableContainer className="Magics_table">
								<Table sx={{ minWidth: 650 }} aria-label="simple table">
									<TableHead>
										<TableRow>
											<TableCell>Housemate</TableCell>
											<TableCell
												style={{
													maxWidth: "250px"
												}}
												align="left"
											>
												Task
											</TableCell>
											<TableCell align="center">Time</TableCell>
											<TableCell align="left"></TableCell>
											<TableCell align="left">Status</TableCell>
											<TableCell align="right"></TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										<TableRow
											key={"0"}
											sx={{
												"&:last-child td, &:last-child th": { border: 0 }
											}}
										>
											<TableCell align="left" style={{ maxWidth: "250px" }}>
												<MultiSelectUI
													MenuItem={MenuItem}
													sx={{ minWidth: "200px", maxWidth: "350px" }}
													multiple={true}
													title={"Participants"}
													onChange={(e) => handleChangeParticipants(e)}
													value={participants}
													items={stage?.participants?.map((model, i) => {
														return {
															title: model?.inHouseName,
															value: model?.id
														};
													})}
												/>
											</TableCell>
											<TableCell align="left">
												{" "}
												<Input
													className="inputBoxTrick"
													disableUnderline="true"
													onClick={() => {
														handleClickAdd();
													}}
													onChange={handleAddTrickName}
													value={selectedValue}
													name="password"
													placeholder="Task Name"
												></Input>
											</TableCell>
											<TableCell align="center">
												{" "}
												<LocalizationProvider dateAdapter={AdapterDayjs}>
													<MobileDateTimePicker
														value={
															dateWithInitialValuesCreate &&
															dateWithInitialValuesCreate
														}
														onChange={(newValue) => {
															setDateWithInitialValuesCreate(newValue);
															setadd(true);
														}}
														inputFormat="YYYY/MM/DD hh:mm a"
														mask="____/__/__ __:__ _M"
														renderInput={(params) => <TextField {...params} />}
													/>
												</LocalizationProvider>
											</TableCell>

											<TableCell align="left">
												{add && (
													<IconButton
														onClick={() => {
															handleAdd(stage?.id);
														}}
														aria-label="delete"
													>
														<CheckCircleRoundedIcon
															style={{ color: "#78F73B" }}
														/>
													</IconButton>
												)}
											</TableCell>
										</TableRow>
										{stage &&
											stage?.tasks &&
											stage?.tasks?.map((task, index) => (
												<TableRow
													key={task.id}
													sx={{
														"&:last-child td, &:last-child th": { border: 0 }
													}}
												>
													<TableCell align="left">
														<MultiSelectUI
															MenuItem={MenuItem}
															sx={{
																minWidth: "200px",
																maxWidth: "350px"
															}}
															multiple={true}
															title={"Participants"}
															onChange={(e) => {
																handleClickOpen(index);
																handleChangeTaskParticipants(e, index);
															}} // Pass the index of the task
															value={task_participants?.[index] ?? []} // Use the participants state corresponding to the task index
															items={stage?.participants?.map((model, i) => {
																return {
																	title: model?.inHouseName,
																	value: model?.id
																};
															})}
														/>
													</TableCell>
													<TableCell align="left">
														{" "}
														<Input
															key={task.id}
															id={index}
															className="inputBoxTrick"
															disableUnderline="true"
															name="password"
															onClick={() => {
																handleClickOpen(index);
															}}
															onChange={handleChangeTrickName}
															placeholder="Trick Name"
															defaultValue={task?.name}
														></Input>
													</TableCell>
													<TableCell align="center">
														{" "}
														<LocalizationProvider dateAdapter={AdapterDayjs}>
															<MobileDateTimePicker
																value={dateWithInitialValues?.[index]} // Use value corresponding to current row
																onChange={(newValue) => {
																	const updatedValues = [
																		...dateWithInitialValues
																	]; // Create a copy of the state array
																	updatedValues[index] = newValue; // Update value at the selected index
																	setDateWithInitialValues(updatedValues); // Update the state with new array
																	setselected(index);
																}}
																inputFormat="YYYY/MM/DD hh:mm a"
																mask="____/__/__ __:__ _M"
																renderInput={(params) => (
																	<TextField {...params} />
																)}
															/>
														</LocalizationProvider>
													</TableCell>
													<TableCell align="left">
														{selected == index && (
															<>
																<IconButton
																	onClick={() => {
																		handleEdit(task.id);
																	}}
																	aria-label="delete"
																>
																	<CheckCircleRoundedIcon
																		style={{ color: "#78F73B" }}
																	/>
																</IconButton>
															</>
														)}
													</TableCell>
													<TableCell align="left">
														{" "}
														<div className="Trick_status">
															<Checkbox
																key={task.id}
																checked={task.is_live ? true : false}
																onClick={() => {
																	dispatch(
																		UpdateTaskStatus(
																			backend_url,
																			task.id,
																			!task?.is_live
																		)
																	);
																}}
																icon={
																	<CircleIcon style={{ color: "#1D1D1D" }} />
																}
																checkedIcon={
																	<CircleIcon style={{ color: "#FF0000" }} />
																}
															/>
															<Typography style={{ color: "white" }}>
																Live
															</Typography>
														</div>
													</TableCell>
													<TableCell align="right">
														<IconButton
															onClick={() => {
																dispatch(DeleteTask(backend_url, task.id));
															}}
															aria-label="delete"
														>
															<RemoveCircleIcon style={{ color: "red" }} />
														</IconButton>
													</TableCell>
												</TableRow>
											))}
									</TableBody>
								</Table>
							</TableContainer>
						</Box>
					</Grid>
				</Box>
			</Stack>
		</>
	);
}
export default EventTasks;
