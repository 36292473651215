export const initialState = {
	session: [],
	user: {},
	users: [],
	users_transactions: [],
	user_transactions: [],
	user_subscriptions: [],
	admin: {},
	onlineModels: [],
	notification: {},
	notifications: [],
	model: [],
	model_earnings: [],
	model_media: [],
	models: [],
	blog: [],
	blog_comments: {},
	blogs: [],
	models_earnings: [],
	loading: {},
	camera: {},
	cameras: {},
	liveshow: {},
	liveshows: [],
	liveshowShedule: [],
	time_picker: [],
	chats: [],
	message: {},
	messages: [],
	modal: {},
	chat: [],
	filters: [],
	error: {},
	errors: [],
	chat_messages: [],
	geoblock_exceptions: [],
	geoblock_restrictions: [],
	geoblock_settings: {},
	transaction: {},
	pools: [],
	promos: [],
	promo: [],
	dashboard: [],
	events: [],
	liveshow_tab: "liveshows",
	environment: {},
	magics: [],
	galleries: [],
	gallery: {},
	models_order: [],
	sidebar: {},
	tube: {},
	microphones: [],
	whx_event: {}
};
