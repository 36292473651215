import * as React from "react";
import { useState } from "react";
import Grid from "@mui/material/Grid";
import { Box, Button, Container, Typography } from "@mui/material";
import ChatBlock from "../ChatBlock.jsx/ChatBlock";
import { connect, useDispatch } from "react-redux";
import { filterChats, getPrivateChats } from "../../redux/actions/chatActions";
import io from "socket.io-client";

var socket = null;
const mapStateToProps = (state) => {
	return {
		chats: state.chats,
		filters: state.filters
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		getPrivateChats: () => dispatch(getPrivateChats())
	};
};

function ChatModeration({ chats, getPrivateChats, filters }) {
	React.useEffect(() => {
		getPrivateChats();
	}, []);
	const dispatch = useDispatch();
	const [filtersCheck, setFilters] = React.useState(filters);

	function handleHide() {
		const newArr = [];
		dispatch(filterChats(newArr));
		setFilters(filters);
	}

	var token = sessionStorage.getItem("chat-data");
	const [sockets, setsockets] = useState([]);

	if (!sessionStorage.getItem("chat-data")) {
		fetch(process.env.REACT_APP_BACKEND_URL + "/api/v1/admin/environment", {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: `Bearer ${sessionStorage.getItem("token")}`
			}
		})
			.then((response) => response.json())
			.then((data) => {
				let token = data.data.chat_data.kewb_access_token;
				sessionStorage.setItem("chat-data", token);
			});
	}
	if (!token) token = sessionStorage.getItem("chat-data");
	React.useEffect(() => {
		chats &&
			chats.map((chat) => {
				var socket = io.connect(process.env.REACT_APP_CHAT_URL, {
					transports: ["websocket", "polling"],
					query: { token },
					reconnection: true,
					reconnectionDelay: 3000
				});
				setsockets((sockets) => [
					...sockets,
					{
						chat: chat,
						socket: socket
					}
				]);

				// sockets.push({
				//   chat: chat,
				//   socket: socket,
				// });
				socket &&
					socket.on("connect", () => {
						console.log("### Connected");
						if (chat && chat.chat.id) {
							socket &&
								socket.emit(
									"join_room",
									chat && chat.chat.id ? chat.chat.id : "room-id-first"
								);
							console.log(
								"### Connected to chat" +
									(chat && chat.chat.id ? chat.chat.id : " room-id-first")
							);
						} else {
							socket && socket.emit("join_room", "room-id-first");
							console.log("### Connected to chat" + " room-id-first");
						}
					});
			});
	}, [chats.length]);
	const [chatsState, setchatsState] = useState(null);
	React.useEffect(() => {
		setchatsState(chats);
	}, [chats, sockets]);
	return (
		<>
			<Container
				maxWidth="xl"
				className="page-container"
				style={{
					color: "#ffffff",
					width: "100%",
					height: "100%",
					marginLeft: "260px"
				}}
			>
				<Box>
					<Grid item xs={12} md={9} spacing={0} className="chatModeration">
						<Typography
							variant="h4"
							gutterBottom
							component="div"
							style={{ color: "white" }}
						>
							Chat moderation
						</Typography>
						<Box style={{ paddingLeft: "15px", paddingBottom: "8px" }}>
							<Button
								size="small"
								variant="contained"
								onClick={() => {
									handleHide();
								}}
								style={{
									color: "black",
									backgroundColor: "#78F73B",
									maxWidth: "150px",
									maxHeight: "20px",
									minWidth: "150px",
									minHeight: "20px",
									borderRadius: "4px"
								}}
								className="create"
							>
								Clear filters
							</Button>
						</Box>
					</Grid>
					<Grid className="parendCont MainStreamScreen" container spacing={2}>
						{chatsState &&
							sockets.length == chatsState.length &&
							chatsState.map((chat) => {
								if (!filters.includes(chat.chat.id)) {
									return (
										<Grid
											item
											xs={2}
											md={3}
											style={{
												maxWidth: "300px",
												marginRight: "20px",
												marginTop: ""
											}}
										>
											<ChatBlock
												chat={chat}
												isLiveshow={true}
												key={chat.chat.id}
												sockets={sockets}
											/>
										</Grid>
									);
								}
							})}
					</Grid>
				</Box>
			</Container>
		</>
	);
}

export default connect(mapStateToProps, mapDispatchToProps)(ChatModeration);
