// ConfirmModal.js
import React from "react";
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle
} from "@mui/material";

const ConfirmModal = ({ open, onClose, onConfirm, text, color }) => {
	return (
		<Dialog open={open} onClose={onClose}>
			<DialogTitle>Are you sure?</DialogTitle>
			<DialogContent>
				<DialogContentText>Are you sure you want to {text}?</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={onClose} color="primary">
					Cancel
				</Button>
				<Button
					size="big"
					variant="contained"
					onClick={onConfirm}
					color={color}
					autoFocus
				>
					Confirm
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default ConfirmModal;
