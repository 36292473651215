const token = sessionStorage.getItem("token");

export const headers_auth = {
	"Content-Type": "application/json",
	Accept: "application/json",
	Authorization: `Bearer ${sessionStorage.getItem("token")}`
};
export const headers = {
	"Content-Type": "application/json",
	Accept: "application/json"
};
