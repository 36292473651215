import { GEOBLOCK_EXCEPTIONS, GEOBLOCK_RESTRICTIONS } from "../types";

export const geoblockRestrictionsReducer = (state = {}, action) => {
	const { type, payload } = action;
	switch (type) {
		case GEOBLOCK_RESTRICTIONS: {
			const { data } = payload;
			state = {};
			return { data };
		}
		default: {
			return state;
		}
	}
};
