import * as React from "react";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import {
	Box,
	Stack,
	Table,
	TableBody,
	TableContainer,
	TableHead,
	TableRow,
	Typography
} from "@mui/material";
import SearchBar from "material-ui-search-bar";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import { getUsersTransactions } from "../../redux/actions/userAction";
import { connect, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import TablePagination from "@mui/material/TablePagination";

function FunctionClick() {
	function clickHandler() {}
}

const mapStateToProps = (state) => {
	return {
		users_transactions: state.users_transactions,
		time_picker: state.time_picker
	};
};

function Transactions({ users_transactions, time_picker }) {
	const dispatch = useDispatch();
	React.useEffect(() => {
		dispatch(getUsersTransactions());
	}, []);

	let navigate = useNavigate();
	const [selected, setSelected] = React.useState(true);

	const StyledTableRow = styled(TableRow)(({ theme }) => ({
		"&:nth-of-type(odd)": {
			backgroundColor: "rgb(0 0 0)"
		},
		"&:nth-of-type(even)": {
			backgroundColor: "#ffffff14"
		},
		// hide last border
		"&:last-child td, &:last-child th": {
			border: 0
		},
		"&.MuiTableRow-root:hover": {
			backgroundColor: "#555555"
		}
	}));

	const StyledTableCell = styled(TableCell)(({ theme }) => ({
		[`&.${tableCellClasses.head}`]: {
			backgroundColor: "#292929",
			color: theme.palette.common.white
		},
		[`&.${tableCellClasses.body}`]: {
			fontSize: 14
		}
	}));
	const [rows, setRows] = React.useState(
		users_transactions && users_transactions?.payments
	);

	React.useEffect(() => {
		setRows(users_transactions?.payments);
	}, [users_transactions?.payments]);

	const [searched, setSearched] = React.useState("");

	const requestSearch = (searchVal) => {
		setPage(0);
		setSearched(searchVal);
	};
	const cancelSearch = () => {
		setSearched("");
		if (page == 0) {
			dispatch(getUsersTransactions(page + 1, rowsPerPage, null));
		}

		setPage(0);
	};

	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(20);

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	};

	React.useEffect(() => {
		dispatch(getUsersTransactions(page + 1, rowsPerPage, searched));
	}, [page, rowsPerPage]);
	React.useEffect(() => {
		const timeoutID = setTimeout(() => {
			if (searched.length != 0) {
				dispatch(getUsersTransactions(page + 1, rowsPerPage, searched));
			}
		}, 500);
		return () => clearTimeout(timeoutID);
	}, [searched]);

	const loading = useSelector((state) => state.loading);
	return (
		<>
			<Stack spacing={3} sx={{ width: 1000, marginLeft: "290px" }}>
				{loading && loading.isLoading == true ? (
					<div className="loader-container">
						<div className="spinner"></div>
					</div>
				) : (
					""
				)}
				<Box
					style={{ marginTop: loading && loading.isLoading == true && "0px" }}
				>
					<Grid container spacing={0}>
						<Grid item xs={4} md={2} spacing={0} className="LiveShow">
							<Typography
								variant="h4"
								gutterBottom
								component="div"
								style={{ color: "white" }}
							>
								Transactions
							</Typography>
						</Grid>
					</Grid>
				</Box>
				<Box className="Search_box">
					<Typography
						variant="h6"
						gutterBottom
						component="div"
						style={{ color: "white", marginRight: "63px" }}
					>
						Find user
					</Typography>
					<SearchBar
						style={{ height: "35px" }}
						className="SearchBar"
						placeholder=""
						value={searched}
						onChange={(searchVal) => requestSearch(searchVal)}
						onCancelSearch={() => cancelSearch()}
					/>
				</Box>
				<TableContainer
					className="SchrollBar"
					component={Paper}
					style={{ overflow: "auto", marginRight: "40px" }}
					sx={{ maxHeight: "800px" }}
				>
					<Table aria-label="simple table" stickyHeader>
						<TableHead>
							<StyledTableRow>
								<StyledTableCell style={{ borderBottom: "none" }} align="left">
									referenceID
								</StyledTableCell>
								<StyledTableCell style={{ borderBottom: "none" }} align="left">
									username
								</StyledTableCell>
								<StyledTableCell style={{ borderBottom: "none" }} align="right">
									description
								</StyledTableCell>
								<StyledTableCell style={{ borderBottom: "none" }} align="right">
									vendor
								</StyledTableCell>
								<StyledTableCell style={{ borderBottom: "none" }} align="right">
									status
								</StyledTableCell>
								<StyledTableCell style={{ borderBottom: "none" }} align="right">
									createdAt
								</StyledTableCell>
								<StyledTableCell style={{ borderBottom: "none" }} align="right">
									IP address
								</StyledTableCell>
							</StyledTableRow>
						</TableHead>
						<TableBody>
							{rows &&
								rows.map((row) => (
									<StyledTableRow
										hover={true}
										onClick={() => {
											navigate("/transactionDetails/" + row.id);
										}}
										key={row.id}
										sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
									>
										<TableCell
											align="left"
											style={{ borderBottom: "none", color: "#FFD800" }}
										>
											{row.referenceID}
										</TableCell>
										<TableCell
											align="left"
											style={{
												borderBottom: "none",
												color: "#78F73B"
											}}
										>
											{row.username}
										</TableCell>
										<TableCell
											align="right"
											style={{ borderBottom: "none", color: "#FFD800" }}
										>
											{row.description}
										</TableCell>
										<TableCell
											align="right"
											style={{ borderBottom: "none", color: "#FFD800" }}
										>
											{row.vendor}
										</TableCell>
										<TableCell
											align="right"
											style={{ borderBottom: "none", color: "#FFD800" }}
										>
											{row.status}
										</TableCell>
										<TableCell
											align="right"
											style={{ borderBottom: "none", color: "#FFD800" }}
										>
											{row.createdAt}
										</TableCell>
										<TableCell
											align="right"
											style={{ borderBottom: "none", color: "#FFD800" }}
										>
											{row.last_ip}
										</TableCell>
									</StyledTableRow>
								))}
						</TableBody>
					</Table>
				</TableContainer>
				<TablePagination
					style={{ background: "#292929", color: "white" }}
					rowsPerPageOptions={[20, 50, 100]}
					component="div"
					count={
						users_transactions && users_transactions?.pagination?.total_count
					}
					rowsPerPage={rowsPerPage}
					page={page}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>
			</Stack>
		</>
	);
}

export default connect(mapStateToProps)(Transactions);
