import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import "../../App";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import { Autocomplete, Container, Input, TextField } from "@mui/material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { addNewUser } from "../../redux/actions/userAction";
import { useDispatch, useSelector } from "react-redux";
import { removeErrors } from "../../redux/reducers/rootReducer";
import { useSnackbar } from "notistack";
import useVaporUpload from "../Upload/VaporUpload";

const superRoles = [
	{ id: 1, title: "member" },
	{ id: 2, title: "admin" }
];
const roles = [{ id: 1, title: "member" }];

export default function AddUserProfile({ admin }) {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [selectedOptions, setSelectedOptions] = React.useState([]);

	const handleAutocompleteChange = (event, value) => {
		event.preventDefault();
		setSelectedOptions(value.title);
		const newFormData = { ...addFormData };
		newFormData["role"] = value.title;
		setAddFormData(newFormData);
	};
	const [addFormData, setAddFormData] = React.useState({
		username: "",
		password: "",
		email: "",
		password_confirmation: "",
		role: roles[0].title,
		tokens: "",
		myFiles: ""
	});

	const handleAddForm = (event) => {
		event.preventDefault();
		const fieldName = event.target.getAttribute("name");
		const fieldValue = event.target.value;
		const newFormData = { ...addFormData };
		newFormData[fieldName] = fieldValue;
		setAddFormData(newFormData);
	};
	const handleAddFormSubmit = (event) => {
		event.preventDefault();
		if (addFormData.email.length == 0) {
			alert("email is required");
			return false;
		}
		if (addFormData.password.length == 0) {
			alert("password is required");
			return false;
		}
		if (addFormData.password_confirmation.length == 0) {
			alert("password confirmation is required");
			return false;
		}
		if (addFormData.password_confirmation != addFormData.password) {
			alert("passwords not the same");
			return false;
		} else {
			dispatch(addNewUser(addFormData));
			/*    navigate("/users");*/
			/*      dispatch(getUsers());*/
			return true;
		}
	};
	const { enqueueSnackbar } = useSnackbar();
	const errorsSubmit = useSelector((state) => state.errors);
	React.useEffect(() => {
		if (errorsSubmit && errorsSubmit.email) {
			enqueueSnackbar(errorsSubmit.email[0], { variant: "error" });
		}
		if (errorsSubmit && errorsSubmit.password) {
			enqueueSnackbar(errorsSubmit.password[0], { variant: "error" });
		}
		if (errorsSubmit && errorsSubmit.password_confirmation) {
			enqueueSnackbar(errorsSubmit.password_confirmation[0], {
				variant: "error"
			});
		}
		setTimeout(() => {
			dispatch(removeErrors());
		}, 5000);
	}, [errorsSubmit]);
	const [selectedImage, setSelectedImage] = React.useState(null);
	const [imageUrl, setImageUrl] = React.useState(null);

	const { uploadFile, uploadProgress } = useVaporUpload();
	React.useEffect(() => {
		if (selectedImage) {
			const updateImage = async () => {
				setImageUrl(URL.createObjectURL(selectedImage));
				const newFormData = { ...addFormData };
				const { vaporResponse } = await uploadFile(selectedImage);
				newFormData["myFiles"] = vaporResponse.key;

				setAddFormData(newFormData);
			};
			updateImage();
		}
	}, [selectedImage]);

	function returnToUsers(event) {
		event.preventDefault();
		navigate("/users");
	}

	return (
		<>
			<Box
				component="div"
				className="page-container"
				sx={{
					flexGrow: 1,
					maxWidth: "900px",
					margin: "0",
					height: "100%",
					minHeight: "1000px",
					marginLeft: "290px"
				}}
			>
				<Grid container spacing={0}>
					<Grid item xs={4} md={2} spacing={0} className="LiveShow">
						<Typography
							variant="h4"
							gutterBottom
							component="div"
							style={{ color: "white" }}
						>
							Add new User
						</Typography>
					</Grid>
				</Grid>
				<Button
					startIcon={<ArrowBackIosIcon />}
					className="ModelProfilePage_arrow"
					variant="outlined"
					onClick={returnToUsers}
				>
					All users
				</Button>
				<Container
					maxWidth="xl"
					style={{
						color: "#ffffff",
						width: "100%",
						height: "100%",
						backgroundColor: "#000000"
					}}
					sx={{ my: 3 }}
					className="ProvileContainer"
				>
					<Grid spacing={1} className="ModelProfilePage_wrap">
						<Grid item xs={6} md={4}>
							<Box component="div" className="ModelProfilePage_main">
								<Stack
									direction="row"
									className="ModelProfilePage_row"
									spacing={1}
									style={{ flexGrow: "0.6" }}
								>
									<Typography
										gutterBottom
										component="div"
										className="ModelProfilePage_elements"
									>
										Profile image
									</Typography>
									<Box
										style={{
											display: "flex",
											flexDirection: "column",
											alignItems: "center",
											width: "100%"
										}}
									>
										<Box
											mt={2}
											textAlign="center"
											style={{
												width: "100px",
												height: "100px",
												backgroundColor: "rgb(41, 41, 41)",
												borderRadius: "50%"
											}}
										>
											{imageUrl && selectedImage && (
												<img
													src={imageUrl}
													alt={selectedImage.name}
													height="100px"
													width="100px"
													accept="image/x-png,image/gif,image/jpeg"
													style={{ borderRadius: "50%" }}
												/>
											)}
										</Box>

										<input
											accept="image/*"
											style={{ display: "none" }}
											id="select-image"
											multiple
											type="file"
											name="myFiles"
											onChange={(e) => {
												setSelectedImage(e.target.files[0]);
											}}
										/>
										<label htmlFor="select-image" style={{ marginTop: "10px" }}>
											<Button variant="raised" component="span">
												Upload
											</Button>
										</label>
									</Box>
								</Stack>
								<Stack
									direction="row"
									className="ModelProfilePage_row"
									spacing={1}
								>
									<Typography
										gutterBottom
										component="div"
										className="ModelProfilePage_elements"
									>
										Name
									</Typography>
									<Input
										className="inputBoxProfile"
										name="username"
										disableUnderline="true"
										style={{ backgroundColor: "#292929" }}
										onChange={handleAddForm}
									></Input>
								</Stack>
								<Stack
									direction="row"
									className="ModelProfilePage_row"
									spacing={1}
								>
									<Typography
										gutterBottom
										component="div"
										className="ModelProfilePage_elements"
									>
										Email
									</Typography>
									<Input
										className="inputBoxProfile"
										name="email"
										disableUnderline="true"
										style={{ backgroundColor: "#292929" }}
										onChange={handleAddForm}
									></Input>
								</Stack>
								<Stack
									direction="row"
									className="ModelProfilePage_row"
									spacing={1}
								>
									<Typography
										gutterBottom
										component="div"
										className="ModelProfilePage_elements"
									>
										Credits
									</Typography>
									<Typography
										gutterBottom
										component="div"
										className="UserProfilePage_elements"
										style={{
											color: "yellow",
											display: "flex",
											justifyContent: "start"
										}}
									></Typography>
								</Stack>
								<Stack
									direction="row"
									className="ModelProfilePage_row"
									spacing={1}
								>
									<Typography
										gutterBottom
										component="div"
										className="ModelProfilePage_elements"
									>
										New Password
									</Typography>
									<Input
										name="password"
										className="inputBoxProfile"
										disableUnderline="true"
										style={{ backgroundColor: "#292929" }}
										onChange={handleAddForm}
									></Input>
								</Stack>
								<Stack
									direction="row"
									className="ModelProfilePage_row"
									spacing={1}
								>
									<Typography
										gutterBottom
										component="div"
										className="ModelProfilePage_elements"
									>
										Repeat Password
									</Typography>
									<Input
										name="password_confirmation"
										className="inputBoxProfile"
										disableUnderline="true"
										style={{ backgroundColor: "#292929" }}
										onChange={handleAddForm}
									></Input>
								</Stack>
							</Box>
						</Grid>
						<Grid
							item
							xs={6}
							md={8}
							style={{
								margin: "0 auto",
								textAlign: "center",
								display: "flex",
								flexDirection: "row",
								alignItems: "center"
							}}
						>
							<Box component="div" className="ModelProfilePage_main">
								<Stack
									direction="row"
									className="ModelProfilePage_row"
									spacing={1}
								>
									<Typography
										gutterBottom
										component="div"
										className="ModelProfilePage_elements"
									>
										Role
									</Typography>
									<Autocomplete
										className="ProfileRoleInput"
										disablePortal
										id="combo-box-demo"
										defaultValue={
											admin && admin.user && admin.user.role == "superadmin"
												? superRoles[0]
												: roles[0]
										}
										key={
											admin && admin.user && admin.user.role == "superadmin"
												? superRoles[0]
												: roles[0]
										}
										options={
											admin && admin.user && admin.user.role == "superadmin"
												? superRoles
												: roles
										}
										getOptionLabel={(option) => option.title}
										renderInput={(params) => (
											<TextField
												{...params}
												disabled
												variant="standard"
												name="role"
											/>
										)}
										onChange={handleAutocompleteChange}
									/>
								</Stack>
								<Stack
									direction="row"
									className="ModelProfilePage_row"
									spacing={1}
								>
									<Typography
										gutterBottom
										component="div"
										className="ModelProfilePage_elements"
									>
										User since
									</Typography>
									<Typography
										gutterBottom
										component="div"
										className="UserProfilePage_elements"
										style={{ display: "flex", justifyContent: "start" }}
									></Typography>
								</Stack>
								<Stack
									direction="row"
									className="ModelProfilePage_row"
									spacing={1}
								>
									<Typography
										gutterBottom
										component="div"
										className="ModelProfilePage_elements"
									>
										Last online
									</Typography>
									<Typography
										gutterBottom
										component="div"
										className="UserProfilePage_elements"
										style={{
											color: "yellow",
											display: "flex",
											justifyContent: "start"
										}}
									></Typography>
								</Stack>
							</Box>
						</Grid>
					</Grid>
					<Grid spacing={1} className="userProfile_grid">
						<Grid
							item
							xs={6}
							style={{
								width: "100%",
								display: "flex",
								flexDirection: "row",
								justifyContent: "center"
							}}
						>
							<Button
								onClick={handleAddFormSubmit}
								size="small"
								variant="contained"
								style={{
									color: "black",
									backgroundColor: "#FFD800",
									maxWidth: "100px",
									maxHeight: "33px",
									minWidth: "100px",
									minHeight: "33px",
									borderRadius: "4px"
								}}
								className="create"
							>
								Save
							</Button>
						</Grid>
					</Grid>
				</Container>
			</Box>
		</>
	);
}
