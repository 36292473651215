import {
	API_ADMIN_BLOG_ACTIONS_ROUTE,
	API_ADMIN_BLOG_CATEGORIES_ROUTE,
	API_ADMIN_BLOGS_ACTIONS_ROUTE
} from "../../utils/consts";
import { userLogout } from "./userAction";
import axios from "../../utils/axios";
import { setError, setLoading, setMessage } from "../reducers/rootReducer";
import {
	BLOG,
	BLOG_COMMENT_UPDATE,
	BLOG_COMMENTS,
	BLOG_COMMENTS_PAGINATION,
	BLOGS,
	DELETE_BLOG,
	UPDATE_BLOG
} from "../types";

const allBlogs = (data) => {
	return {
		type: BLOGS,
		payload: { data }
	};
};

const oneBlog = (data) => {
	return {
		type: BLOG,
		payload: { data }
	};
};
const BlogComments = (data) => {
	return {
		type: BLOG_COMMENTS,
		payload: { data }
	};
};
const BlogCommentUpdate = (data) => {
	return {
		type: BLOG_COMMENT_UPDATE,
		payload: { data }
	};
};
const BlogCommentsPagination = (data) => {
	return {
		type: BLOG_COMMENTS_PAGINATION,
		payload: { data }
	};
};

const patchBlog = (data) => {
	return {
		type: UPDATE_BLOG,
		payload: { data }
	};
};
export const delete_Blog = (data) => {
	return {
		type: DELETE_BLOG,
		payload: { data }
	};
};

export const getBlog = (slug) => async (dispatch) => {
	dispatch(setLoading({ camera: "blog", type: true }));
	try {
		const response = await fetch(
			process.env.REACT_APP_BACKEND_URL +
				API_ADMIN_BLOGS_ACTIONS_ROUTE +
				"/" +
				slug +
				"/edit",
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
					Authorization: `Bearer ${sessionStorage.getItem("token")}`
				}
				//credentials: 'include',
			}
		);
		//response.data
		const data = await response.json();
		if (response.status >= 200 && response.status <= 299) {
			dispatch(setLoading({ camera: "blog", type: false }));
			if (data.data) {
				dispatch(oneBlog(data.data));
			}
		} else {
			console.log("logMeOut", response.status, data);
			dispatch(setLoading({ camera: "blog", type: false }));
			//dispatch(userLogout());
			//sessionStorage.removeItem("token");
		}
	} catch (error) {
		dispatch(setLoading({ camera: "blog", type: false }));
	}
};
export const getBlogCategory = () => async (dispatch) => {
	try {
		const response = await fetch(
			process.env.REACT_APP_BACKEND_URL + API_ADMIN_BLOG_CATEGORIES_ROUTE,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
					Authorization: `Bearer ${sessionStorage.getItem("token")}`
				}
				//credentials: 'include',
			}
		);
		//response.data
		const data = await response.json();
		if (response.status >= 200 && response.status <= 299) {
			if (data.data) {
				dispatch(oneBlog(data.data));
			}
		} else {
			console.log("logMeOut", response.status, data);
			//dispatch(userLogout());
			//sessionStorage.removeItem("token");
		}
	} catch (error) {
		console.log(error);
	}
};

export const getBlogs = () => async (dispatch) => {
	dispatch(setLoading({ camera: "blog", type: true }));
	try {
		const options = {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: `Bearer ${sessionStorage.getItem("token")}`
			}
		};

		const response = await fetch(
			process.env.REACT_APP_BACKEND_URL + API_ADMIN_BLOGS_ACTIONS_ROUTE,
			options
		);

		const data = await response.json();
		if (response.status >= 200 && response.status <= 299) {
			dispatch(allBlogs(data.data.blogs));
			dispatch(setLoading({ camera: "blog", type: false }));
		} else {
			dispatch(userLogout());
			sessionStorage.removeItem("token");
		}
	} catch (error) {
		console.log(error);
		dispatch(setLoading({ camera: "blog", type: false }));
	}
};

export const updateBlog = (item, slug) => async (dispatch) => {
	dispatch(setLoading({ camera: "blog", type: true }));
	try {
		let formData = new FormData();
		Object.keys(item).forEach((key) => formData.append(key, item[key]));

		/*     const options = {
              method: "POST",
              headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                'Authorization': `Bearer ${sessionStorage.getItem("token")}`,
            },
              body: JSON.stringify(item)
            }
            const response = await fetch('https://whxlbo-fr.warehouse-x.io' + API_ADMIN_MODEL_UPDATE_ROUTE + id, options) */
		const res = await axios.post(
			process.env.REACT_APP_BACKEND_URL +
				API_ADMIN_BLOGS_ACTIONS_ROUTE +
				"/" +
				slug,
			formData,
			{
				withCredentials: true,
				headers: {
					Authorization: `Bearer ${sessionStorage.getItem("token")}`,
					accept: "application/json",
					"Content-Type": `multipart/form-data; boundary=${formData._boundary}`
				}
			}
		);
		/* const data = await response.json(); */
		/*  if (response.status >= 200 && response.status <= 299) {
          console.log(res.data.data); */
		if (res.status >= 200 && res.status <= 299) {
			const data = res.data;
			dispatch(patchBlog(data));
			dispatch(getBlogs());
			dispatch(setLoading({ camera: "blog", type: false }));
			history.back();
			dispatch(setMessage(data.message, "success"));
		}
		/*  } else {
          dispatch(userLogout());
          sessionStorage.removeItem("token");
        } */
	} catch (error) {
		dispatch(setError(error));
		dispatch(setLoading({ camera: "blog", type: false }));
	}
};

export const deleteBlog = (slug) => async (dispatch) => {
	try {
		const response = await fetch(
			process.env.REACT_APP_BACKEND_URL +
				API_ADMIN_BLOGS_ACTIONS_ROUTE +
				"/" +
				slug,
			{
				method: "DELETE",
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
					Authorization: `Bearer ${sessionStorage.getItem("token")}`
				}
				//credentials: 'include',
			}
		);
		//response.data
		const data = await response.json();
		if (response.status >= 200 && response.status <= 299) {
			dispatch(delete_Blog());
			dispatch(getBlogs());
			dispatch(setMessage(data.message, "success"));
		} else {
			dispatch(setError(data.message, "error"));
			/* dispatch(userLogout());
            sessionStorage.removeItem("token"); */
		}
	} catch (error) {
		setMessage(error, "error");
	}
};

export const addBlog = (item) => async (dispatch) => {
	dispatch(setLoading({ camera: "blog", type: true }));
	try {
		let formData = new FormData();
		Object.keys(item).forEach((key) => formData.append(key, item[key]));
		/*     const options = {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: `Bearer ${sessionStorage.getItem("token")}`,
              },
              //credentials: 'include',
              body: JSON.stringify(item),
            }; */
		const res = await axios.post(
			process.env.REACT_APP_BACKEND_URL + API_ADMIN_BLOGS_ACTIONS_ROUTE,
			formData,
			{
				withCredentials: true,
				headers: {
					Authorization: `Bearer ${sessionStorage.getItem("token")}`,
					Accept: "application/json",
					"Content-Type": `multipart/form-data; boundary=${formData._boundary}`
				}
			}
		);

		/* const response = await fetch(
          "https://whxlbo-fr.warehouse-x.io" + API_ADMIN_MODEL_ADD_ROUTE,
          options
        ); */
		if (res.status >= 200 && res.status <= 299) {
			const data = res.data;
			dispatch(oneBlog(data));
			dispatch(getBlogs());
			dispatch(setLoading({ camera: "blog", type: false }));
			history.back();
			dispatch(setMessage(res.data.message, res.data.type));
		}
	} catch (error) {
		dispatch(setError(error));
		dispatch(setLoading({ camera: "blog", type: false }));
	}
};

export const getBlogComments =
	(slug, page = null, limit = null, search = null) =>
	async (dispatch) => {
		dispatch(setLoading({ camera: "blog", type: true }));
		try {
			const response = await fetch(
				process.env.REACT_APP_BACKEND_URL +
					API_ADMIN_BLOG_ACTIONS_ROUTE +
					"/" +
					slug +
					"/comments" +
					`?page=${page}` +
					`&limit=${limit}` +
					`&search=${search}`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Accept: "application/json",
						Authorization: `Bearer ${sessionStorage.getItem("token")}`
					}
					//credentials: 'include',
				}
			);
			//response.data
			const data = await response.json();
			if (response.status >= 200 && response.status <= 299) {
				dispatch(setLoading({ camera: "blog", type: false }));
				if (page == null) {
					dispatch(BlogComments(data?.data));
				}
				if (page) {
					dispatch(BlogCommentsPagination(data.data));
				}
			} else {
				console.log("logMeOut", response.status, data);
				dispatch(setLoading({ camera: "blog", type: false }));
				//dispatch(userLogout());
				//sessionStorage.removeItem("token");
			}
		} catch (error) {
			console.log(error);
			dispatch(setLoading({ camera: "blog", type: false }));
		}
	};

export const DeleteBlogComment = (slug, commentId) => async (dispatch) => {
	dispatch(setLoading({ camera: "blog", type: true }));
	try {
		const response = await fetch(
			process.env.REACT_APP_BACKEND_URL +
				API_ADMIN_BLOG_ACTIONS_ROUTE +
				"/" +
				slug +
				"/comment/" +
				commentId,
			{
				method: "DELETE",
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
					Authorization: `Bearer ${sessionStorage.getItem("token")}`
				}
				//credentials: 'include',
			}
		);
		//response.data
		const data = await response.json();
		if (response.status >= 200 && response.status <= 299) {
			dispatch(setLoading({ camera: "blog", type: false }));

			dispatch(BlogCommentUpdate(data.data));
			dispatch(setMessage(data.message, data.type));
		} else {
			console.log("logMeOut", response.status, data);
			dispatch(setLoading({ camera: "blog", type: false }));
			//dispatch(userLogout());
			//sessionStorage.removeItem("token");
		}
	} catch (error) {
		console.log(error);
		dispatch(setLoading({ camera: "blog", type: false }));
	}
};
