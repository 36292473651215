import * as React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import { getCameras, offCamerasSDN } from "../../redux/actions/cameraAction";
import { connect, useDispatch, useSelector } from "react-redux";
import Paper from "@mui/material/Paper";
import List from "@mui/material/List";
import CameraCard from "../CameraCard/CameraCard";
import Button from "@mui/material/Button";
import Skeleton from "@mui/material/Skeleton";
import { hideModal, showModal } from "../../redux/reducers/rootReducer";
import Cameras_Reorder_Modal from "./Cameras_Reorder_Modal";

const mapStateToProps = (state) => {
	return {
		cameras: state.cameras,
		modal: state.modal
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		getCameras: () => dispatch(getCameras())
	};
};

function AllRooms({ cameras, getCameras, modal, admin }) {
	const dispatch = useDispatch();

	React.useEffect(() => {
		getCameras();
	}, []);

	function changeHandleSDN() {
		dispatch(offCamerasSDN());
	}

	const [selected, setSelected] = React.useState("");
	const handleOpenPass = (item) => {
		setSelected(item);
		{
			modal && modal.name == "password"
				? dispatch(hideModal("password"))
				: dispatch(showModal("password"));
		}
	};
	const [sidebar, setSidebar] = useState(false);
	const loading = useSelector((state) => state.loading);

	const groupCamerasBySubscription = (cameras, subscriptionPackages) => {
		const groupedCameras = {};

		if (cameras && cameras.all_rooms) {
			cameras.all_rooms.forEach((camera) => {
				const packageIndex = camera?.subscription_package;
				const packageName = subscriptionPackages[packageIndex];

				if (!groupedCameras[packageName]) {
					groupedCameras[packageName] = [];
				}

				groupedCameras[packageName].push(camera);
			});
		}

		return groupedCameras;
	};

	// Prepare the data
	const subscriptionPackages = cameras?.subscription_packages || {};
	const groupedCameras = groupCamerasBySubscription(
		cameras,
		subscriptionPackages
	);
	// Create an ordered list of package names based on the subscriptionPackages values
	const orderedPackages = Object.values(subscriptionPackages);

	// Sort grouped cameras based on the order of package names
	const sortedGroupedCameras = orderedPackages.reduce((acc, packageName) => {
		if (groupedCameras[packageName]) {
			acc[packageName] = groupedCameras[packageName];
		}
		return acc;
	}, {});
	return (
		<>
			<Container
				maxWidth="xl"
				className="page-container page-cameras"
				style={{
					color: "#ffffff",
					width: "-webkit-fill-available",
					height: "100%!important",
					marginLeft: sidebar ? "0px" : "230px",
					paddingLeft: "0px",
					paddingRight: "0px"
				}}
			>
				{loading && loading.isLoading == true ? (
					<div className="loader-container">
						<div className="spinner"></div>
					</div>
				) : (
					""
				)}
				<Box
					style={{
						marginLeft: "5%",
						marginTop: loading && loading.isLoading == true && "0px"
					}}
				>
					<Typography variant="h4" gutterBottom component="div">
						Manage Cameras
					</Typography>
					<Grid container spacing={0}>
						<Grid item xs={4} md={2} spacing={0} className="AllRoomGrid">
							<Typography variant="h7" gutterBottom component="div">
								All Cameras
							</Typography>
							<Button
								onClick={() => handleOpenPass(cameras.all_rooms)}
								className="Cameras_edit"
								style={{ marginLeft: "40px", height: "30px" }}
							>
								Edit
							</Button>
						</Grid>
					</Grid>

					<Paper style={{ width: "95%", minHeight: "100px" }}>
						<List className="AllRoomBlock_list">
							{!(cameras && cameras.all_rooms) &&
								[...Array(8)].map((e, i) => (
									<Skeleton
										key={"skelet-l-" + i}
										width={150}
										height={90}
										style={{ margin: "5px" }}
									/>
								))}
							{Object.keys(sortedGroupedCameras).map((packageName) => (
								<Box
									key={packageName}
									style={{
										display: "flex",
										width: "100%",
										flexDirection: "column",
										flexWrap: "wrap",
										marginBottom: "20px" // Add margin between groups
									}}
								>
									<Typography
										variant="h6"
										style={{
											marginLeft: "10px",
											color: "white"
										}}
									>
										{packageName}
									</Typography>
									<Box
										style={{
											display: "flex",
											width: "100%",
											flexWrap: "wrap"
										}}
									>
										{groupedCameras[packageName].map((camera) => (
											<CameraCard
												key={camera.id}
												camera={camera}
												admin={admin}
												event_keys={cameras?.subscription_packages}
											/>
										))}
									</Box>
								</Box>
							))}
						</List>
					</Paper>
					{/* <Grid container spacing={0}>
            <Grid item xs={4} md={2} spacing={0} className="AllRoomGrid">
              <Typography variant="h7" gutterBottom component="div">
                Live Shows
              </Typography>
            </Grid>
          </Grid>
          <Paper style={{ width: "95%", minHeight: "100px" }}>
            <List className="AllRoomBlock_list">
              {!(cameras && cameras.liveShow) &&
                [...Array(8)].map((e, i) => (
                  <Skeleton
                    key={"skelet-l-" + i}
                    width={150}
                    height={90}
                    style={{ margin: "5px" }}
                  />
                ))}
              {cameras &&
                cameras.liveShow &&
                cameras.liveShow.map((camera) => {
                  return (
                    <CameraCard key={camera.id} camera={camera} admin={admin} />
                  );
                })}
            </List>
          </Paper> */}
					<Grid
						style={{
							padding: "20px 0px",
							width: "95%",
							display: "flex",
							justifyContent: "space-between"
						}}
					>
						{admin && admin.user && admin.user.role == "superadmin" && (
							<></>
							// <Button
							//   size="big"
							//   variant="contained"
							//   /* color="success" */
							//   style={{
							//     maxWidth: "160px",
							//     minWidth: "160px",
							//   }}
							//   onClick={() => changeHandleSDN()}
							//   color={"error"}
							// >
							//   {"FLUSH ALL CDN's"}
							// </Button>
						)}

						{/*  <Button
                            size="big"
                            variant="contained"
                             color="success"
                            style={{
                                maxWidth: "200px",
                                minWidth: "160px",
                            }}
                            onClick={() => changeHandleCheck()}
                            color={check ? "success" : "error"}
                        >
                            {check ? "Enable all streams" : "Disable all streams"}
                        </Button>*/}
					</Grid>
				</Box>
			</Container>
			<Cameras_Reorder_Modal modal={modal} items={selected} />
		</>
	);
}

export default connect(mapStateToProps, mapDispatchToProps)(AllRooms);
