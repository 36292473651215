import { MODEL_MEDIA, DELETE_MODEL_MEDIA } from "../types";

export const modelMediaReducer = (state = [], action) => {
	const { type, payload } = action;

	switch (type) {
		case MODEL_MEDIA: {
			const { data } = payload;
			state = [];
			return data;
		}
		case DELETE_MODEL_MEDIA: {
			return [];
		}
		default: {
			return state;
		}
	}
};
