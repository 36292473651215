import { MODELS, MODELS_EARNINGS } from "../types";

export const modelsReducer = (state = [], action) => {
	const { type, payload } = action;

	switch (type) {
		case MODELS: {
			const { data } = payload;
			state = [];
			return [...state, ...data];
		}

		default: {
			return state;
		}
	}
};
