import * as React from "react";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import {
	Box,
	Checkbox,
	Icon,
	Link,
	MenuItem,
	Modal,
	Radio,
	Select,
	setRef,
	SvgIcon,
	Switch,
	TablePagination
} from "@mui/material";
import { Container } from "@mui/material";
import {
	TableContainer,
	Table,
	TableHead,
	TableBody,
	TableRow
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { Autocomplete } from "@mui/material";
import { TextField } from "@mui/material";
import { Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import GirlIcon from "@material-ui/icons/Face";
import { Typography } from "@mui/material";
import { Input } from "@mui/material";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { connect, useDispatch } from "react-redux";
import { CreatePool, GetPools } from "../../redux/actions/PoolsActions";
import ClearIcon from "@mui/icons-material/Clear";
import { Backdrop } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Stars from "../../assets/perf.svg";
import moment from "moment";
import DateRangePickerComp from "../DateRangePickerComp/DateRangePickerComp";
import { format } from "date-fns";
import { User_date } from "../../redux/actions/userAction";
import { CreatePromo, GetPromos } from "../../redux/actions/PromosActions";
import { setMessage } from "../../redux/reducers/rootReducer";
import { useNavigate } from "react-router-dom";
import {
	CreateTrick,
	getMagics,
	StageSettingsUpdate,
	TrickDone,
	TrickProceed,
	UpdateTrick,
	UpdateTrickStatus
} from "../../redux/actions/EventsActions";
import { useState } from "react";
import CircleOutlinedIcon from "@mui/icons-material/CircleOutlined";
import CircleIcon from "@mui/icons-material/Circle";
import ArrowRightRoundedIcon from "@mui/icons-material/ArrowRightRounded";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
const mapStateToProps = (state) => {
	return {
		magics: state.magics,
		time_picker: state.time_picker
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		getMagics: () => dispatch(getMagics())
	};
};

function MagicX({ getMagics, time_picker, magics }) {
	React.useEffect(() => {
		getMagics();
	}, []);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const theme = createTheme({
		palette: {
			success: {
				// Purple and green play nicely together.
				main: "#78F73B"
			},
			error: {
				// This is green.A700 as hex.
				main: "#F73B3B"
			}
		}
	});

	const [inputList, setInputList] = React.useState([
		{
			title: "",
			description: "",
			issued_count: "",
			credits: "",
			days_access: "",
			period: {}
		}
	]);

	const handleInputChange = (e, index) => {
		const { name, value } = e.target;
		const list = [...inputList];
		list[index][name] = value;
		setInputList(list);
	};

	const [characterLimit] = React.useState(70);

	const handleSubmit = (e, index) => {
		e.preventDefault();
		const list = [...inputList];
		const obj = {
			title: inputList[index].title,
			description: inputList[index].description,
			issued_count: inputList[index].issued_count,
			credits: inputList[index].credits,
			days_access: inputList[index].days_access,
			period: {
				startDate:
					time_picker[0].startDate == undefined
						? ""
						: format(time_picker[0].startDate, "dd.MM.yyy"),
				endDate:
					time_picker[0].endDate == undefined
						? ""
						: format(time_picker[0].endDate, "dd.MM.yyy")
			}
		};
		if (obj.title.length == 0) {
			dispatch(setMessage("The title is required.", "error"));
			return;
		}
		if (obj.issued_count.length == 0) {
			dispatch(setMessage("The issued count is required.", "error"));
			return;
		}
		if (obj.credits.length == 0) {
			dispatch(setMessage("The credits is required.", "error"));
			return;
		}
		if (obj.days_access.length == 0) {
			dispatch(setMessage("The days access is required.", "error"));
			return;
		}
		if (obj.period.endDate.length == 0 || obj.period.startDate.length == 0) {
			dispatch(setMessage("The date is required.", "error"));
			return;
		}
		dispatch(CreatePromo(obj));
		setInputList([
			{
				title: "",
				description: "",
				issued_count: "",
				credits: "",
				days_access: "",
				period: {}
			}
		]);
	};

	const style = {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		maxWidth: 1200,
		minWidth: 850,
		minHeight: 350,
		maxHeight: 500,
		/* width: '100%', */
		bgcolor: "background.paper",
		textAlign: "center",
		margin: "0 auto",
		p: 4
	};

	const IOSSwitch = styled((props) => (
		<>
			<Switch
				focusVisibleClassName=".Mui-focusVisible"
				disableRipple
				{...props}
			/>
		</>
	))(({ theme }) => ({
		width: 42,
		height: 26,
		padding: 0,
		"& .MuiSwitch-switchBase": {
			padding: 0,
			margin: 2,
			transitionDuration: "300ms",
			"&.Mui-checked": {
				transform: "translateX(16px)",
				color: "#fff",
				"& + .MuiSwitch-track": {
					backgroundColor:
						theme.palette.mode === "dark" ? "#55AC27" : "#65C466",
					opacity: 1,
					border: 0
				},
				"&.Mui-disabled + .MuiSwitch-track": {
					opacity: 0.5
				}
			},
			"&.Mui-focusVisible .MuiSwitch-thumb": {
				color: "#33cf4d",
				border: "6px solid #fff"
			},
			"&.Mui-disabled .MuiSwitch-thumb": {
				color:
					theme.palette.mode === "light"
						? theme.palette.grey[100]
						: theme.palette.grey[600]
			},
			"&.Mui-disabled + .MuiSwitch-track": {
				opacity: theme.palette.mode === "light" ? 0.7 : 0.3
			}
		},
		"& .MuiSwitch-thumb": {
			boxSizing: "border-box",
			width: 22,
			height: 22
		},
		"& .MuiSwitch-track": {
			borderRadius: 26 / 2,
			backgroundColor: theme.palette.mode === "light" ? "#55AC27" : "#D53131",
			opacity: 1,
			transition: theme.transitions.create(["background-color"], {
				duration: 500
			})
		}
	}));

	var count = [1, 2, 3];
	const [view_type, setview_type] = useState("");

	const [rows, setrows] = useState(null);

	const [index, setindex] = useState(0);
	const handleChangeStage = (i) => {
		setindex(i);
		setTimeout(() => {
			setrows(magics?.stages?.[i]?.tasks);
		}, 100);
		setselected("none");
	};

	React.useEffect(() => {
		setTimeout(() => {
			const sorder =
				magics?.stages?.[index]?.tasks &&
				[]
					.concat(magics?.stages?.[index]?.tasks)
					?.sort((a, b) => (a?.sorder > b?.sorder ? 1 : -1));

			setrows(sorder && sorder);
		}, 100);
	}, [magics?.stages?.[index]?.tasks]);

	const [selected, setselected] = useState("none");
	const handleClickOpen = (index) => {
		setadd(false);
		setselected(index);
	};

	const [add, setadd] = useState(false);
	const handleClickAdd = (index) => {
		setselected("none");
		setadd(true);
	};
	const [model, setmodel] = useState("");
	const handleChangeModel = (event) => {
		setadd(true);
		setselected("none");
		setmodel(event.target.value);
	};

	const [selectedValue, setSelectedValue] = React.useState("");
	const handleAddTrickName = (event) => {
		setSelectedValue(event.target.value);
	};
	const [models, setmodels] = useState(magics && magics?.models);
	React.useEffect(() => {
		setmodels(magics?.models);
	}, [magics, magics.models]);
	const handleAdd = (id) => {
		var performerId = models?.find((element) => {
			return element.inHouseName == model;
		}).id;
		var name = selectedValue;
		dispatch(CreateTrick(name, performerId, id));
		setadd(false);
		setmodel("");
		setSelectedValue("");
	};

	const [selectedTrickName, setSelectedTrickValue] = React.useState({});
	const handleChangeTrickName = (event) => {
		setSelectedTrickValue({ index: selected, value: event.target.value });
	};
	const handleEdit = (id) => {
		var elem = document.getElementById(selected).value;
		var url = document.getElementById(selected + 99).value;
		dispatch(UpdateTrick(elem, url, id));
		setselected("none");
	};
	const handleStatus = (is_live, id) => {
		dispatch(UpdateTrickStatus(!is_live, id));
		setselected("none");
	};
	//   const [IsLiked, setIsLiked] = useState(null)
	//   const handleCheckbox = (is_live) => {
	//     setIsLiked(!is_live);
	//   };
	const handleClickOpenUrl = (index) => {
		setadd(false);
		setselected(index);
	};
	const [selectedTrickReplay, setSelectedTrickReplay] = React.useState({});
	const handleChangeTrickReplay = (event) => {
		setSelectedTrickReplay({ index: selected, value: event.target.value });
	};

	return (
		<>
			<Stack
				spacing={3}
				sx={{ width: 1500, marginLeft: "290px", marginTop: "40px" }}
			>
				<Box>
					<Grid
						container
						spacing={0}
						style={{ display: "flex", flexDirection: "column" }}
					>
						<Grid item xs={4} md={2} spacing={0} className="Magicx_text">
							<Typography
								variant="h4"
								gutterBottom
								component="div"
								style={{ color: "white", marginBottom: "unset" }}
							>
								Current Round
							</Typography>
						</Grid>

						<Box component="div" className="Pick_round">
							{magics?.stages?.map((item, i) => {
								return (
									<Button
										key={item?.id}
										onClick={() => {
											handleChangeStage(i);
										}}
										className="pick_round_button"
										style={{
											width: "68px",
											height: "68px",
											backgroundColor: index == i && "#78F73B",
											color: index == i && "black"
										}}
									>
										{i + 1}
									</Button>
								);
							})}
						</Box>

						<Grid
							item
							xs={4}
							md={2}
							spacing={0}
							className="Magicx_text"
							style={{ paddingTop: "25px" }}
						>
							<Typography
								variant="h4"
								gutterBottom
								component="div"
								style={{ color: "white", marginBottom: "unset" }}
							>
								Voting
							</Typography>
						</Grid>
						<Box component="div" className="Voting_settings">
							{magics && magics.stages && magics?.stages[index] && (
								<>
									<Grid item className="Voting_switches" xs={4}>
										<Typography
											style={{
												color: "white",
												display: "flex",
												justifyContent: "flex-start"
											}}
										>
											Voting status
										</Typography>
										<div
											style={{
												display: "flex",
												justifyContent: "flex-start",
												alignItems: "center",
												gap: "10px"
											}}
										>
											<Typography style={{ color: "white" }}>Closed</Typography>
											<IOSSwitch
												checked={
													magics &&
													magics.stages &&
													magics.stages[index] &&
													magics?.stages[index]?.vote_enabled
														? true
														: false
												}
												onClick={() => {
													dispatch(
														StageSettingsUpdate(
															{
																vote_enabled:
																	!magics?.stages[index]?.vote_enabled
															},
															magics?.stages[index].id
														)
													);
												}}
												inputProps={{ "aria-label": "ant design" }}
											/>
											<Typography style={{ color: "white" }}>Open</Typography>
										</div>
									</Grid>
									<Grid item xs={4} className="Voting_switches">
										<Typography
											style={{
												color: "white",
												display: "flex",
												justifyContent: "start"
											}}
										>
											Votes visibility to Users
										</Typography>
										<div
											style={{
												display: "flex",
												justifyContent: "flex-start",
												alignItems: "center",
												gap: "10px"
											}}
										>
											<Typography style={{ color: "white" }}>Hidden</Typography>
											<IOSSwitch
												checked={
													magics &&
													magics.stages &&
													magics.stages[index] &&
													magics?.stages[index]?.vote_visible
														? true
														: false
												}
												onClick={() => {
													dispatch(
														StageSettingsUpdate(
															{
																vote_visible:
																	!magics?.stages[index]?.vote_visible
															},
															magics?.stages[index].id
														)
													);
												}}
												inputProps={{ "aria-label": "ant design" }}
											/>
											<Typography style={{ color: "white" }}>
												Visible
											</Typography>
										</div>
									</Grid>
									<Grid item xs={4} className="Voting_switches">
										<Typography
											style={{
												color: "white",
												display: "flex",
												justifyContent: "flex-start"
											}}
										>
											Shows replaced with Magic X
										</Typography>
										<div
											style={{
												display: "flex",
												justifyContent: "flex-start",
												alignItems: "center",
												gap: "10px"
											}}
										>
											<Typography style={{ color: "white" }}>No</Typography>
											<IOSSwitch
												checked={
													magics &&
													magics.stages &&
													magics.stages[index] &&
													magics?.stages[index]?.is_active
														? true
														: false
												}
												onClick={() => {
													dispatch(
														StageSettingsUpdate(
															{ is_active: !magics?.stages[index]?.is_active },
															magics?.stages[index].id
														)
													);
												}}
												inputProps={{ "aria-label": "ant design" }}
											/>
											<Typography style={{ color: "white" }}>Yes</Typography>
										</div>
									</Grid>
								</>
							)}
						</Box>
						<Grid
							item
							xs={4}
							md={2}
							spacing={0}
							className="Magicx_text"
							style={{ paddingTop: "25px" }}
						>
							<Typography
								variant="h4"
								gutterBottom
								component="div"
								style={{ color: "white", marginBottom: "unset" }}
							>
								Performers and tricks
							</Typography>
						</Grid>
						<Box component="div" className="Voting_tricks">
							<TableContainer className="Magics_table">
								<Table sx={{ minWidth: 650 }} aria-label="simple table">
									<TableHead>
										<TableRow>
											<TableCell>Housemate</TableCell>
											<TableCell align="left">Trick</TableCell>
											<TableCell align="left">Replay</TableCell>
											<TableCell align="left"></TableCell>
											<TableCell align="left">Status</TableCell>
											<TableCell align="center">Votes</TableCell>
											<TableCell align="right"></TableCell>
											<TableCell align="right"></TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										<TableRow
											key={"0"}
											sx={{
												"&:last-child td, &:last-child th": { border: 0 }
											}}
										>
											<TableCell align="left">
												<Select
													className="LandingSelect"
													MenuProps={{
														PaperProps: {
															sx: {
																backgroundColor: "#1a1a1a",
																backgroundImage: "unset",
																".MuiMenuItem-root": {
																	padding: "8px 15px",
																	minHeight: "unset"
																}
															}
														}
													}}
													sx={{
														color: "white",
														fontSize: "16px",
														padding: "0",
														width: "80%",
														minWidth: "250px",
														height: "41px",
														backgroundColor: "#1a1a1a",
														fieldset: {
															border: "0"
														},
														".MuiSelect-select": {
															textAlign: "initial"
														}
													}}
													//   onChange={handleChangeCampaign}
													disableUnderline
													onChange={handleChangeModel}
													value={model ? model : ""}
													renderValue={(value) => {
														return (
															<Box sx={{ display: "flex", gap: 1 }}>
																<div
																	style={{
																		display: "flex",
																		width: "-webkit-fill-available",
																		justifyContent: "flex-start"
																	}}
																>
																	{value && value}
																</div>
															</Box>
														);
													}}
												>
													{models &&
														models.map((option) => (
															<MenuItem
																style={{ textAlign: "center" }}
																key={option.id}
																value={option.inHouseName}
															>
																{option.inHouseName}
															</MenuItem>
														))}
												</Select>
											</TableCell>
											<TableCell align="left">
												{" "}
												<Input
													className="inputBoxTrick"
													disableUnderline="true"
													onClick={() => {
														handleClickAdd();
													}}
													onChange={handleAddTrickName}
													value={selectedValue}
													name="password"
													placeholder="Trick Name"
												></Input>
											</TableCell>
											<TableCell align="left">
												{add && (
													<IconButton
														onClick={() => {
															handleAdd(magics?.stages[index].id);
														}}
														aria-label="delete"
													>
														<CheckCircleRoundedIcon
															style={{ color: "#78F73B" }}
														/>
													</IconButton>
												)}
											</TableCell>
											<TableCell align="left"></TableCell>
											<TableCell align="center"></TableCell>
											<TableCell
												style={{ display: "flex" }}
												align="right"
											></TableCell>
										</TableRow>
										{rows &&
											rows.map((row, index) => (
												<TableRow
													key={row.id}
													sx={{
														"&:last-child td, &:last-child th": { border: 0 }
													}}
												>
													<TableCell align="left">
														<Input
															key={row.id}
															className="inputBoxTrick"
															disableUnderline="true"
															name="password"
															disabled
															defaultValue={row?.performer?.inHouseName}
														></Input>
													</TableCell>
													<TableCell align="left">
														{" "}
														<Input
															key={row.id}
															id={index}
															className="inputBoxTrick"
															disableUnderline="true"
															name="password"
															onClick={() => {
																handleClickOpen(index);
															}}
															onChange={handleChangeTrickName}
															placeholder="Trick Name"
															defaultValue={row?.name}
														></Input>
													</TableCell>
													<TableCell align="left">
														{" "}
														<Input
															key={index}
															id={index + 99}
															className="inputBoxTrick"
															disableUnderline="true"
															name="password"
															onClick={() => {
																handleClickOpenUrl(index);
															}}
															onChange={handleChangeTrickReplay}
															placeholder="Trick Video"
															defaultValue={row?.video_url}
														></Input>
													</TableCell>
													<TableCell align="left">
														{selected == index && (
															<>
																<IconButton
																	onClick={() => {
																		handleEdit(row.id);
																	}}
																	aria-label="delete"
																>
																	<CheckCircleRoundedIcon
																		style={{ color: "#78F73B" }}
																	/>
																</IconButton>
															</>
														)}
													</TableCell>
													<TableCell align="left">
														{" "}
														<div className="Trick_status">
															<Checkbox
																key={row.id}
																checked={row.is_live ? true : false}
																onChange={() => {
																	handleCheckbox(row.is_live);
																}}
																onClick={() => {
																	handleStatus(row?.is_live, row.id);
																}}
																icon={
																	<CircleIcon style={{ color: "#1D1D1D" }} />
																}
																checkedIcon={
																	<CircleIcon style={{ color: "#FF0000" }} />
																}
															/>
															<Typography style={{ color: "white" }}>
																Live
															</Typography>
														</div>
													</TableCell>
													<TableCell align="center">
														{row?.votes_count}
													</TableCell>
													<TableCell align="right">
														{row.is_done == 0 && (
															<Button
																size="small"
																variant="contained"
																style={{
																	color: "black",
																	backgroundColor: "#78F73B",
																	maxHeight: "33px",
																	minHeight: "33px",
																	borderRadius: "4px",
																	textTransform: "unset",
																	whiteSpace: "nowrap"
																}}
																onClick={() => {
																	dispatch(TrickDone(1, row.id));
																}}
																className="create"
															>
																set done
															</Button>
														)}
													</TableCell>
													<TableCell style={{ display: "flex" }} align="right">
														{" "}
														<Button
															className="Tricks_proceed"
															onClick={() => {
																dispatch(TrickProceed(row.id));
															}}
															endIcon={
																<ArrowRightRoundedIcon
																	style={{
																		color: "white",
																		width: "1.5em",
																		height: "1.5em"
																	}}
																/>
															}
														>
															Proceed to next round
														</Button>
													</TableCell>
												</TableRow>
											))}
									</TableBody>
								</Table>
							</TableContainer>
						</Box>
					</Grid>
				</Box>
			</Stack>
		</>
	);
}
export default connect(mapStateToProps, mapDispatchToProps)(MagicX);
