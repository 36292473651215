import { useState } from "react";
import { useDispatch } from "react-redux";
import axios from "axios";

function useVaporUpload() {
	const [uploadProgress, setUploadProgress] = useState(0);
	const dispatch = useDispatch();
	const CHUNK_SIZE = 100 * 1024 * 1024; // 100MB, adjust this based on requirements
	const uploadFile = async (file) => {
		// Determine if the file is a video or an image
		const isVideo = file.type.startsWith("video/");
		if (isVideo) {
			// Handle video file upload with chunking
			return await uploadVideoInChunks(file);
		} else {
			// Handle image upload with single request
			return await uploadImage(file);
		}
	};
	// Upload image with single PUT request (existing logic for images)
	const uploadImage = async (file) => {
		try {
			const vaporResponse = await store(file, {
				signedStorageUrl:
					process.env.REACT_APP_BACKEND_URL +
					"/api/v1/vapor/signed-storage-url",
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
					Authorization: `Bearer ${sessionStorage.getItem("token")}`
				},
				progress: (progress) => {
					let uploadProgress = Math.round(progress * 100);
					setUploadProgress(uploadProgress);
				}
			});

			return { vaporResponse, uploadProgress };
		} catch (error) {
			console.error("Error uploading image:", error);
			throw error;
		}
	};
	// Upload video with chunking (using async for parallel upload)
	const uploadVideoInChunks = async (file) => {
		try {
			// Step 1: Initiate multipart upload and get uploadId and URLs for each part
			const { key, uuid, uploadId, urls } = await initiateMultipartUpload(
				file.size
			);

			const totalChunks = urls.length;
			let uploadedParts = [];
			let promises = [];

			for (let i = 0; i < totalChunks; i++) {
				// Slice the file to get the chunk
				const start = i * CHUNK_SIZE;
				const end = (i + 1) * CHUNK_SIZE;
				const chunk = file.slice(start, end);

				// Step 2: Upload the chunk asynchronously
				const urlData = urls[i];
				const partNumber = urlData?.partNumber; // Correct part number starting from 1
				promises.push(
					uploadChunk(chunk, urlData, partNumber).then((ETag) => {
						uploadedParts.push({ PartNumber: partNumber, ETag });
					})
				);
			}

			// Wait for all chunk uploads to complete
			await Promise.all(promises);

			// Step 3: Complete multipart upload after all chunks are uploaded
			const complete = await completeMultipartUpload(
				uploadId,
				key,
				uploadedParts
			);
			const vaporResponse = {
				key, // This is the key you received
				uuid // This is the uuid you received
			};

			return { vaporResponse, uploadProgress: 100 };
		} catch (error) {
			console.error("Error uploading video:", error);
			throw error;
		}
	};
	// Step 1: Initiate multipart upload and get UploadId and PartsCount
	const initiateMultipartUpload = async (fileSize) => {
		const response = await axios.post(
			process.env.REACT_APP_BACKEND_URL +
				"/api/v1/vapor/signed-storage-url/multipart",
			{
				partsCount: Math.ceil(fileSize / CHUNK_SIZE)
			},
			{
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
					Authorization: `Bearer ${sessionStorage.getItem("token")}`
				}
			}
		);
		return response.data; // Assuming response contains { uploadId, urls[] }
	};
	// Step 2: Upload a single chunk using the URL provided for the part
	const uploadChunk = async (chunk, urlData, uploadId, partNumber) => {
		const { url } = urlData; // Extract the URL from the data
		try {
			const response = await axios.put(url, chunk, {
				headers: {
					"Content-Type": chunk.type // Make sure the Content-Type matches the chunk
				},
				onUploadProgress: (progressEvent) => {
					let uploadProgress = Math.round(
						(progressEvent.loaded / progressEvent.total) * 100
					);
					setUploadProgress(uploadProgress);
				}
			});

			// Return the ETag from the response header
			return response.headers.etag;
		} catch (error) {
			console.error(`Error uploading part ${partNumber}:`, error);
			throw error;
		}
	};
	// Step 3: Complete multipart upload
	const completeMultipartUpload = async (uploadId, key, parts) => {
		try {
			const response = await axios.post(
				process.env.REACT_APP_BACKEND_URL +
					"/api/v1/vapor/signed-storage-url/multipart/complete",
				{
					key,
					uploadId,
					parts // [{ PartNumber: int, ETag: string }]
				},
				{
					headers: {
						"Content-Type": "application/json",
						Accept: "application/json",
						Authorization: `Bearer ${sessionStorage.getItem("token")}`
					}
				}
			);

			// Check if the response has a success status
			if (response?.status === 200 || response?.status === 201) {
				return response?.data; // Assuming response contains { key, uuid }
			} else {
				// Handle unexpected response status
				throw new Error(`Unexpected response status: ${response?.status}`);
			}
		} catch (error) {
			// Handle network or request errors
			console.error("Error completing multipart upload:", error);

			// You can throw the error or handle it gracefully
			throw new Error(
				error?.response && error?.response?.data
					? `Upload failed: ${error?.response?.data?.message || error?.response?.data}`
					: `Upload failed: ${error?.message}`
			);
		}
	};

	const store = async (file, options = {}) => {
		const response = await axios.post(
			options.signedStorageUrl
				? options.signedStorageUrl
				: "/vapor/signed-storage-url",
			{
				bucket: options.bucket || "",
				content_type: options.contentType || file.type,
				expires: options.expires || "",
				visibility: options.visibility || "",
				...options.data
			},
			{
				baseURL: options.baseURL || null,
				headers: options.headers || {},
				...options.options
			}
		);

		let headers = response.data.headers;

		if ("Host" in headers) {
			delete headers.Host;
		}

		if (typeof options.progress === "undefined") {
			options.progress = () => {};
		}

		const cancelToken = options.cancelToken || "";

		await axios.put(response.data.url, file, {
			cancelToken: cancelToken,
			headers: headers,
			onUploadProgress: (progressEvent) => {
				options.progress(progressEvent.loaded / progressEvent.total);
			}
		});

		response.data.extension = file.name.split(".").pop();

		return response.data;
	};

	return { uploadFile, uploadProgress };
}

export default useVaporUpload;
