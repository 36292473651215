import * as React from "react";
import { useState } from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import InputEmoji from "react-input-emoji";
import "../../App";
import {
	Backdrop,
	CardActionArea,
	Input,
	MenuItem,
	Select,
	Skeleton,
	Typography
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import Modal from "@mui/material/Modal";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import { connect, useDispatch, useSelector } from "react-redux";
import {
	appendMessageToState,
	DeleteMessage,
	getMessages,
	sendGlobalTips,
	sendMessage
} from "../../redux/actions/messageAction";
import AdjustIcon from "@mui/icons-material/Adjust";
import Message from "./Message";
import {
	filterChats,
	removeChat,
	removeChats
} from "../../redux/actions/chatActions";
import { UserBan, UserWarn } from "../../redux/actions/userAction";
import moment from "moment";
import { decrypt } from "../../utils/helpers";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 400,
	bgcolor: "background.paper",
	border: "2px solid #000",
	textAlign: "center",
	margin: "0 auto",
	p: 4
};

const mapStateToProps = (state) => {
	return {
		messages: state.messages,
		admin: state.admin,
		chats: state.chats,
		filters: state.filters
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		getMessages: (chatId, socket, callback, offset) =>
			dispatch(getMessages(chatId, socket, callback, offset)),
		sendMessage: (messg, chatId) => dispatch(sendMessage(messg, chatId)),
		sendGlobalTips: (amount) => dispatch(sendGlobalTips(amount)),
		appendMessageToState: (message, chat) =>
			dispatch(appendMessageToState(message, chat)),
		removeChats: () => dispatch(removeChats())
	};
};
var token;
let offset = 0;

function ChatBlock({
	getMessages,
	appendMessageToState,
	chat,
	admin,
	chats,
	filters,
	sockets,
	messages
}) {
	const dispatch = useDispatch();
	const [message_details, setMessageDetails] = React.useState({});

	const [open, setOpen] = React.useState(false);

	const handleOpen = () => {
		setOpen(true);
	};
	const handleClose = () => setOpen(false);

	const inputMessage = React.useRef();

	const [text, setText] = React.useState("");
	let initialized = false;
	React.useEffect(() => {
		if (chat) {
			if (chat !== undefined && chat.chat !== undefined) {
				initializer();

				scrollToBottom();
			}
		}
	}, [socket]);

	React.useEffect(() => {
		var elms = document.getElementsByClassName("react-input-emoji--button");
		for (let index = 0; index < elms.length; index++) {
			const element = elms[index];
			if (element)
				element.innerHTML =
					'<span style="width: 24px;height: 24px;display: inline-block;background-image: url(https://unpkg.com/emoji-datasource-apple@5.0.1/img/apple/sheets-256/64.png);background-size: 5700% 5700%;background-position: 53.5714% 62.5%;"/>';
		}
	}, []);
	const [reload, setReload] = React.useState("");
	var index =
		sockets && sockets.findIndex((obj) => obj.chat.chat.id === chat.chat.id);
	var socket = sockets && sockets[index].socket;
	const [heart, setHeart] = React.useState(false);

	function initializer() {
		console.log("initializing socket");
		if (!sessionStorage.getItem("chat-data")) {
			fetch(process.env.REACT_APP_BACKEND_URL + "/api/v1/admin/environment", {
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
					Authorization: `Bearer ${sessionStorage.getItem("token")}`
				}
				//credentials: 'include',
			})
				.then((response) => response.json())
				.then((data) => {
					let token = data.data.chat_data.kewb_access_token;
					sessionStorage.setItem("chat-data", token);
				});
		}
		if (!token) token = sessionStorage.getItem("chat-data");
		if (token /* && chats_connect == 0  */) {
			/*       socket.on('connect',()=>{
                    console.log('reconected')
                    window.location.reload()
                  }) */
			socket &&
				socket.on("connect", () => {
					console.log("### Connected"); // true
					setHeart(true);
					console.log("heart");
					getMessages(chat.chat.id, chat, token);
					scrollToBottom();
					setTimeout(() => {
						scrollToBottom();
					}, 1000);
				});
			socket &&
				socket.on("disconnect", () => {
					setHeart(false);
				});
			socket.onAny((event, data, ...args) => {
				let dataVal = JSON.stringify(data);
				console.log(`Event received: ${event} data: ${dataVal}`);
			});

			socket &&
				socket.on("message_sent", async (data) => {
					const message = {
						id: parseInt(data.id),
						message: data.message,
						type: data.type,
						banned: false,
						deleted: false,
						senderIp: data.senderIp ? decrypt(data.senderIp) : null,
						data: {
							avatar: data.data.avatar ? data.data.avatar : "",
							name: data.data.name,
							userId: data.data.userId,
							time: getTime(data.createdAt)
						}
					};
					appendMessage(message);
				});

			//setChats(1)
		}
	}

	function getTime(time) {
		var date = new Date(time);

		const hours = date.getHours(); // get hours with getHours method
		const minutes = date.getMinutes(); // get minutes with getMinutes method // get seconds with getSeconds method
		const timeString = `${pad(hours)}:${pad(minutes)}`;
		return timeString; // finally, join to a time string
	}

	function pad(num) {
		return ("0" + num).slice(-2);
	}

	function isScrolledToBottom() {
		const chatElem = document.getElementById(`ChatList-${chat.chat.id}`);
		if (!chatElem) return;
		if (
			chatElem.scrollHeight - chatElem.scrollTop <=
			chatElem.clientHeight + 300
		) {
			return true;
		}
		return false;
	}

	function scrollToBottom() {
		const chatElem = document.getElementById(
			`ChatList-${chat && chat.chat && chat.chat.id}`
		);
		if (!chatElem) return;
		setTimeout(() => {
			chatElem.scrollTop = chatElem.scrollHeight;
		}, 100);
		setTimeout(() => {
			chatElem.scrollTop = chatElem.scrollHeight;
		}, 200);
		setTimeout(() => {
			chatElem.scrollTop = chatElem.scrollHeight;
		}, 300);
		setTimeout(() => {
			chatElem.scrollTop = chatElem.scrollHeight;
		}, 400);
		setTimeout(() => {
			chatElem.scrollTop = chatElem.scrollHeight;
		}, 500);
	}

	function handleOnEnter() {
		var time = moment().format("HH:mm");
		const newMessage = {
			chatId: chat && chat.chat.id ? chat.chat.id : 1,
			message: text,
			time: time,
			data: {
				name: admin && admin.user.username,
				userId: admin && admin.user.id ? admin.user.id : 0,
				time: time
			},
			type: "text",
			banned: false,
			deleted: false
		};

		if (socket) {
			socket.emit("send_message", newMessage);
			console.log("sending message");
			console.log(
				"### Sending message to chat: #" +
					(chat && chat.chat.id ? chat.chat.id : 1)
			);
		}

		scrollToBottom();
	}

	function appendMessage(message) {
		if (isScrolledToBottom()) {
			appendMessageToState(message, chat);

			scrollToBottom();
		} else {
			appendMessageToState(message, chat);
		}
	}

	const [NameData, setNameData] = React.useState([]);
	const handleAddForm = (event) => {
		const fieldName = event.target.getAttribute("name");
		const fieldValue = event.target.value;
		const newNameData = { ...NameData };
		newNameData[fieldName] = fieldValue;

		setNameData(newNameData);
	};

	function userWarn(message, id) {
		event.preventDefault();
		let obj = {
			text: NameData,
			chatThreadId: id,
			ip: message,
			type: "warning",
			guestIP: message.senderIp
				? message.senderIp.toString().replace("::ffff:", "")
				: null,
			userId: message.data.userId ? message.data.userId : null
		};
		dispatch(UserWarn(obj));
		handleClose();
	}

	const [time, setTime] = React.useState("");
	const handleChange = (event) => {
		setTime(event.target.value);
	};

	function userBan(message, id) {
		event.preventDefault();
		let obj = {
			text: NameData,
			chatThreadId: id,
			type: "ban",
			guestIP: message.senderIp
				? message.senderIp.toString().replace("::ffff:", "")
				: null,
			duration: time,
			userId: message.data.userId ? message.data.userId : null
		};
		dispatch(UserBan(obj));
		handleClose();
	}

	function handleHide(id) {
		const newArr = filters;
		newArr.push(id);
		dispatch(filterChats(newArr));
		dispatch(removeChat(id));
	}

	function handleDeleteSubmit(msg, chatId) {
		msg.deleted = true;
		let obj = {
			messageId: msg.id
		};
		dispatch(DeleteMessage(obj, chatId));
		handleClose();
	}

	const [isLoaded, setisLoaded] = React.useState(false);
	const [chatMessages, setchatMessages] = useState(null);
	React.useEffect(() => {
		setchatMessages(chat?.messages);
	}, [chat?.messages]);

	const [toScrollBottom, setToScrollBottom] = React.useState(false);
	React.useEffect(() => {
		if (toScrollBottom) {
			setTimeout(() => {
				scrollToBottom();
			}, 300);
		}
	}, [toScrollBottom]);

	const [scroll, setScroll] = useState(false);
	React.useEffect(() => {
		if (isLoaded && !scroll) {
			scrollToBottom();
		}
		if (isLoaded && scroll) {
			setTimeout(() => {
				chatMessages &&
					document?.getElementById(chatMessages[100]?.id)?.scrollIntoView();
			}, 100);

			setScroll(false);
			setloading(false);
		}
	}, [isLoaded]);

	const handleScroll = async (event) => {
		if (isLoaded) {
			const { scrollHeight, scrollTop, clientHeight } = event.target;
			if (scrollTop <= 100) {
				offset += 100;
				getMessages(chat.chat.id, chat, token, offset);
				setisLoaded(false);
				setScroll(true);
				setloading(true);
				//document.getElementById(`ChatList-${chat && chat.chat && chat.chat.id}`).scrollTop = 600;
			}
		}
	};
	const [loadingSCroll, setloading] = useState(false);
	const loading = useSelector((state) => state.loading);
	return (
		<Container
			className="ChatContainer"
			maxWidth="xl"
			style={{ margin: "0", padding: "0", minHeight: "200px" }}
		>
			<Box
				className="ChatWrapper"
				sx={{
					bgcolor: "#212121"
				}}
				md={3}
				style={{ maxWidth: "300px", position: "relative" }}
			>
				{loading && loading.isLoading == true ? (
					<div className="loader-container" style={{ position: "absolute" }}>
						<div className="spinner"></div>
					</div>
				) : (
					""
				)}
				<Box>
					<Typography
						variant="h7"
						component="div"
						style={{
							color: "#000000",
							minWidth: "150px",
							backgroundColor: "#78F73B",
							borderRadius: "5px",
							borderBottomRightRadius: "0",
							borderBottomLeftRadius: "0",
							padding: "4px 10px",
							display: "flex",
							flexDirection: "row",
							alignItems: "center"
						}}
					>
						{chat && chat.room_name ? chat.room_name : chat.liveshow_title}
						<AdjustIcon
							className={heart == true ? "online" : ""}
							fontSize="small"
						/>
						<IconButton
							size="small"
							className="HideButton"
							onClick={() => {
								handleHide(chat.chat.id);
							}}
						>
							<ClearIcon fontSize="small" />
						</IconButton>
					</Typography>
				</Box>
				<>
					<ul
						className="ChatList"
						id={`ChatList-${chat && chat.chat && chat.chat.id}`}
						style={{ maxWidth: "300px" }}
						onScroll={handleScroll}
					>
						{loadingSCroll && loadingSCroll == true ? (
							<div className="loader-container">
								<div className="spinner"></div>
							</div>
						) : (
							""
						)}

						{chat && chat.messages && chat.messages.length === 0
							? [...Array(4)].map((e, i) => (
									<Box sx={{ flexGrow: 1 }}>
										<Grid container spacing={2}>
											<Grid item xs={1.5} style={{ position: "relative" }}>
												<Skeleton
													variant="circular"
													sx={{ bgcolor: "grey.850" }}
													width={40}
													height={40}
													style={{ bottom: "0", position: "absolute" }}
												/>
											</Grid>
											<Grid item xs={10}>
												<Skeleton
													variant="rectangular"
													sx={{ bgcolor: "grey.850" }}
													style={
														i % 3 === 0
															? {
																	margin: "6px",
																	width: "40%",
																	height: "45px",
																	borderRadius: "10px"
																}
															: i % 2 === 0
																? {
																		margin: "6px",
																		width: "60%",
																		height: "95px",
																		borderRadius: "10px"
																	}
																: i % 4 === 0
																	? {
																			margin: "6px",
																			width: "100%",
																			height: "75px",
																			borderRadius: "10px"
																		}
																	: {
																			margin: "6px",
																			width: "70%",
																			height: "65px",
																			borderRadius: "10px"
																		}
													}
												/>
											</Grid>
										</Grid>
									</Box>
								))
							: chatMessages &&
								chatMessages.map((message, index) => {
									if (!isLoaded && chatMessages.length - 1 === index) {
										setTimeout(() => {
											setisLoaded(true);
										}, [1000]);
										if (!toScrollBottom) {
											setToScrollBottom(true);
										}
									}

									return (
										<CardActionArea
											onClick={(e) => {
												handleOpen(e);
												setMessageDetails(message);
											}}
										>
											<Message
												key={message.id}
												message={message}
												userId={admin && admin.user && admin.user.id}
											/>
										</CardActionArea>
									);
								})}
					</ul>

					<Modal
						aria-labelledby="transition-modal-title"
						aria-describedby="transition-modal-description"
						open={open}
						onClose={handleClose}
						closeAfterTransition
						BackdropComponent={Backdrop}
						BackdropProps={{
							timeout: 500
						}}
						className="camera_details"
					>
						<Box
							sx={style}
							style={{
								marginTop: "50px"
							}}
						>
							<IconButton className="BackButton" onClick={handleClose}>
								<ClearIcon />
							</IconButton>
							<Container component="main" maxWidth="xs">
								<Box
									sx={{
										marginTop: 2,
										display: "flex",
										flexDirection: "column",
										alignItems: "center"
									}}
								>
									<Typography
										component="h1"
										variant="h6"
										style={{ color: "#77F73B" }}
									>
										{message_details &&
										message_details.data &&
										message_details.data.name
											? message_details.data.name
											: "Guest"}
									</Typography>
									<Typography
										component="h2"
										variant="h6"
										style={{ color: "white" }}
									>
										{message_details &&
											message_details.senderIp &&
											message_details.senderIp
												.toString()
												.replace("::ffff:", "")}
									</Typography>

									<Box
										sx={{
											marginTop: 2,
											display: "flex",
											flexDirection: "row",
											alignItems: "center"
										}}
										md={3}
									>
										<ul className="ChatListElem_wrap_live">
											<Input
												className="msg"
												name="text"
												disableUnderline="true"
												style={{ color: "#FFD800" }}
												onChange={handleAddForm}
												placeholder="Input info for user"
											></Input>
										</ul>
										<Box
											sx={{
												bgcolor: "transparent",
												paddingRight: "10px",
												paddingBottom: "10px"
											}}
											md={3}
										></Box>
									</Box>
									<Box component="form" noValidate sx={{ mt: 3 }}>
										<Grid container spacing={2}>
											<Button
												type="submit"
												onClick={() => {
													userWarn(message_details, chat.chatthreadId);
												}}
												fullWidth
												variant="outlined"
												sx={{ mt: 3, mb: 2 }}
												color="success"
												style={{
													margin: "0",
													marginBottom: "10px",
													borderColor: "#FFD800",
													color: "#FFD800"
												}}
											>
												Send Warning
											</Button>
											<Box
												style={{
													display: "flex",
													flexDirection: "row",
													alignItems: "center",
													width: "100%"
												}}
											>
												<Typography
													component="h4"
													variant="h8"
													style={{ width: "40%", color: "red" }}
												>
													Ban for
												</Typography>
												<FormControl
													sx={{
														m: 1,
														minWidth: 120,
														width: "33%",
														color: "red",
														backgroundColor: "#2C2C2C",
														paddingTop: "4px"
													}}
													size="small"
												>
													<Select
														value={time}
														onChange={handleChange}
														style={{ color: "red", fontSize: "14px" }}
													>
														<MenuItem value={30}>30 min</MenuItem>
														<MenuItem value={60}>60 min</MenuItem>
														<MenuItem value={120}>120 min</MenuItem>
														<MenuItem value={1000}>forever</MenuItem>
													</Select>
												</FormControl>
												<Button
													fullWidth
													variant="outlined"
													onClick={() => {
														userBan(message_details, chat.chatthreadId);
													}}
													sx={{ mt: 3, mb: 2 }}
													color="error"
													style={{
														margin: "0px 0px 0px",
														width: "33%"
													}}
												>
													Ban
												</Button>
											</Box>
											<Box
												style={{
													display: "flex",
													flexDirection: "row",
													alignItems: "center",
													width: "100%",
													justifyContent: "flex-end"
												}}
											>
												<Button
													onClick={() => {
														handleDeleteSubmit(
															message_details && message_details,
															chat.chat.id
														);
													}}
													style={{
														color: "#ED2779",
														width: "150px",
														textTransform: "unset"
													}}
													type="button"
												>
													Delete this message
												</Button>
											</Box>
										</Grid>
									</Box>
								</Box>
							</Container>
						</Box>
					</Modal>
					<form
						style={{
							backgroundColor: "#ffffff",
							display: "flex",
							alignItems: "center",
							position: "relative",
							bottom: "0",
							left: "0",
							right: "0"
						}}
						//   onSubmit={newMessage}
					>
						{/* <input ref={inputMessage} style={styles.Input} type="text" /> */}
						{/* <Button onClick={testMessage}>Append new message</Button> */}
						<InputEmoji
							ref={inputMessage}
							value={text}
							onChange={setText}
							cleanOnEnter
							className="ChatInput"
							onEnter={handleOnEnter}
							placeholder="Type your message here."
							borderRadius="0px"
							borderColor="#FFFFFF"
							style={{ fontSize: "14px" }}
						/>
					</form>
				</>
			</Box>
		</Container>
	);
}

export default connect(mapStateToProps, mapDispatchToProps)(ChatBlock);
