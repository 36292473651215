import * as React from "react";
import { useEffect, useRef, useState } from "react";
import "antd/dist/antd.css";
import { PlusOutlined } from "@ant-design/icons";
import "video-react/dist/video-react.css";
import { connect, useDispatch, useSelector } from "react-redux";
import {
	Autocomplete,
	Backdrop,
	Box,
	Container,
	Grid,
	IconButton,
	Input,
	Modal,
	Stack,
	TextField
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import CheckboxUI from "../../UI/CheckboxUI";
import { LoadingButton } from "@mui/lab";
import {
	TubeVideoUpload,
	UpdateTubeImage
} from "../../redux/actions/TubeActions";
import { setMessage } from "../../redux/reducers/rootReducer";
import useVaporUpload from "./VaporUpload";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 700,
	bgcolor: "background.paper",
	border: "2px solid #000",
	textAlign: "center",
	margin: "0 auto",
	p: 4,
	maxHeight: "700px",
	overflow: "auto"
};

const mapStateToProps = (state) => {
	return {
		gallery: state.gallery
	};
};
export const defaultTags = [
	"sexy",
	"naked",
	"warehouse x",
	"whx",
	"porn",
	"porn content",
	"live show",
	"nudes",
	"porn model",
	"tube x",
	"xxx",
	"naked girl",
	"best porn models",
	"boobs",
	"ass"
];

const UploadTubePhotos = ({ filters }) => {
	const dispatch = useDispatch();
	const loading = useSelector((state) => state.loading);
	const [premium, setPremium] = useState(false);
	const [tube, setTube] = useState(true);
	const [selectedOptions, setSelectedOptions] = useState([]);
	const [selectedTags, setSelectedTags] = useState(defaultTags);
	const [metaDescription, setMetaDescription] = useState("");
	const [title, setTitle] = useState("");
	const [description, setDescription] = useState("");
	const handleAutocompleteChange = (target) => {
		var task_names = target.target.value;
		setSelectedOptions(task_names);
	};

	const changeHandle = () => {
		setPremium(!premium);
	};
	const [uploadedImage, setUploadedImage] = useState(null);

	const handleSave = () => {
		const obj = {
			participants: selectedOptions,
			is_premium: premium,
			is_tube: tube,
			metaDescription: inputList.metaDescription,
			metaTags: selectedTags,
			title: inputList.title,
			description: inputList.description
		};
		dispatch(UpdateTubeImage(uploadedImage.id, obj));
		setMetaDescription("");
		setTitle("");
		setDescription("");
		setSelectedOptions([]);
		closeCustomModal();
	};
	const [inputList, setInputList] = useState({
		metaDescription: metaDescription,
		title: title,
		description: description
	});
	useEffect(() => {
		setInputList({
			metaDescription: metaDescription,
			title: title,
			description: description
		});
	}, [metaDescription, title, description]);

	const handleInputChange = (e) => {
		const fieldName = event.target.getAttribute("name");
		const fieldValue = event.target.value;
		const newFormData = { ...inputList };
		newFormData[fieldName] = fieldValue;
		setInputList(newFormData);
	};

	const [customModalVisible, setCustomModalVisible] = useState(false);
	const openCustomModal = () => {
		setCustomModalVisible(true);
		setInputList({
			metaDescription: "",
			title: "",
			description: ""
		});
		setSelectedOptions([]);
	};

	const closeCustomModal = () => {
		if (uploading) {
			return dispatch(setMessage("Video Loading is in progress", "error"));
		}
		setCustomModalVisible(false);
		setSource(null);
		setMetaDescription("");
		setTitle("");
		setDescription("");
		setSelectedOptions([]);
	};
	const inputRef = useRef();

	const [source, setSource] = useState();
	const [uploading, setUploading] = useState(false);
	const [uploadingBULK, setUploadingBULK] = useState(false);
	const { uploadFile } = useVaporUpload();
	const handleFileChange = async (event) => {
		const file = event.target.files[0];
		setUploading(true);
		const { vaporResponse } = await uploadFile(file);
		const formData = new FormData();
		formData.append("images", vaporResponse.key);

		fetch(
			process.env.REACT_APP_BACKEND_URL +
				"/api/v1/admin/manager/tube/upload-image",
			{
				method: "POST",
				body: formData,
				headers: {
					Authorization: `Bearer ${sessionStorage.getItem("token")}`
				}
			}
		)
			.then((response) => {
				if (!response.ok) {
					setUploading(false);
					setSource(null);
					throw new Error(`Can't upload image, try another one`);
				}
				return response.json();
			})
			.then((data) => {
				if (data.type == "success") {
					dispatch(TubeVideoUpload(data.data.image));
					dispatch(setMessage(data.message, data.type));
					setUploadedImage(data.data.image);
					setUploading(false);
					if (file) {
						const reader = new FileReader();
						reader.onload = (e) => {
							setSource(e.target.result);
						};
						reader.readAsDataURL(file);
					}
					/*   setSource(url);*/
				}
				// Handle success response
			})
			.catch((error) => {
				// Handle error
				setUploading(false);
				setSource(null);
				dispatch(setMessage(error?.message, "error"));
			})
			.finally(() => {
				setTimeout(() => {
					setUploading(false);
				}, 2000);
			});
	};
	// New bulk upload handler
	const handleBulkFileChange = async (event) => {
		const files = Array.from(event.target.files);
		setUploadingBULK(true);
		let uploadedBulkImages = [];

		// Upload each file individually
		for (const file of files) {
			const { vaporResponse } = await uploadFile(file);
			const formData = new FormData();
			formData.append("images", vaporResponse.key);
			formData.append("is_bulk", 1);
			try {
				const response = await fetch(
					process.env.REACT_APP_BACKEND_URL +
						"/api/v1/admin/manager/tube/upload-image",
					{
						method: "POST",
						body: formData,
						headers: {
							Authorization: `Bearer ${sessionStorage.getItem("token")}`
						}
					}
				);

				if (!response.ok) {
					throw new Error(`Can't upload image, try another one`);
				}

				const data = await response.json();

				if (data.type === "success") {
					dispatch(TubeVideoUpload(data.data.image));
					dispatch(setMessage(data.message, data.type));
				}
			} catch (error) {
				dispatch(setMessage(error?.message, "error"));
			}
		}

		setUploadingBULK(false);
	};
	const handleChoose = (event) => {
		inputRef.current.click();
	};
	const bulkInputRef = useRef();
	// Handle bulk file input click
	const handleBulkChoose = () => {
		bulkInputRef.current.click();
	};
	return (
		<>
			<div
				style={{
					background: "grey ",
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					justifyContent: "center",
					borderRadius: "5px",
					padding: "15px",
					width: "100px",
					cursor: "pointer"
				}}
				onClick={openCustomModal}
			>
				<PlusOutlined style={{ color: "white" }} />
				<div
					style={{
						marginTop: 8,
						color: "white",
						textAlign: "center"
					}}
				>
					Upload single file
				</div>
			</div>
			{/* Bulk upload button */}
			<div
				style={{
					background: "#4CAF50",
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					justifyContent: "center",
					borderRadius: "5px",
					padding: "15px",
					width: "100px",
					cursor: "pointer",
					marginLeft: "10px"
				}}
				onClick={handleBulkChoose}
			>
				<PlusOutlined style={{ color: "white" }} />
				<div style={{ marginTop: 8, color: "white", textAlign: "center" }}>
					Bulk Upload
				</div>
				{/* Input field for bulk upload */}
				<input
					ref={bulkInputRef}
					type="file"
					multiple
					style={{ display: "none" }}
					accept="image/*"
					onChange={handleBulkFileChange}
				/>
			</div>
			{uploadingBULK && (
				<div
					className="loader-container"
					style={{
						padding: "24% 0"
					}}
				>
					<div className="spinner"></div>
				</div>
			)}
			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				closeAfterTransition
				open={customModalVisible}
				onCancel={closeCustomModal}
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
					onClick: closeCustomModal
				}}
				className="camera_details"
			>
				<Box
					sx={style}
					style={{
						marginTop: "50px"
					}}
				>
					{loading && loading.isLoading == true ? (
						<div className="loader-container">
							<div className="spinner"></div>
						</div>
					) : (
						""
					)}
					<IconButton className="BackButton" onClick={closeCustomModal}>
						<ClearIcon />
					</IconButton>
					<Container component="main" maxWidth="xs">
						<div className="VideoInput">
							<input
								ref={inputRef}
								className="VideoInput_input"
								type="file"
								onChange={handleFileChange}
								accept="image/*" // Limit to image files only
							/>
							{!source && (
								<LoadingButton
									loading={uploading}
									sx={{
										mt: 3,
										mb: 3,
										py: 1.5,
										borderRadius: "10px",
										textTransform: "unset",
										fontSize: "1.1rem",
										".MuiCircularProgress-root": {
											width: "23px !important",
											height: "23px !important"
										},
										".MuiLoadingButton-loadingIndicator": {
											color: "black"
										}
									}}
									style={{
										width: "100%",
										marginTop: "10px",
										color: uploading ? "#78F73B" : "black",
										backgroundColor: "#78F73B"
									}}
									onClick={handleChoose}
								>
									Upload Photo
								</LoadingButton>
							)}
							{source && (
								<img
									className="VideoInput_video"
									style={{
										minWidth: "300px",
										width: "100%",
										height: "300px",
										objectFit: "contain"
									}}
									src={source}
								/>
							)}
						</div>
						<Box
							sx={{
								marginTop: 2,
								display: "flex",
								flexDirection: "column",
								alignItems: "center"
							}}
						>
							<Box component="form" noValidate sx={{ mt: 3 }}>
								<Grid item xs={12}>
									<CheckboxUI
										style={{ color: "white" }}
										title={"Non Nude"}
										name="freeUnlocked"
										value="allowExtraEmails"
										color="primary"
										onChange={changeHandle}
										checked={premium == 0 ? true : false}
									/>
								</Grid>
							</Box>
						</Box>
					</Container>

					<Autocomplete
						className="LazyInput"
						multiple
						name="participants"
						id="id"
						style={{ width: "100%", marginBottom: "15px", maxWidth: "100%" }}
						onChange={(event, value) => {
							handleAutocompleteChange({
								target: {
									value: value
								}
							});
						}}
						options={
							filters && filters.models ? Object.keys(filters.models) : []
						}
						defaultValue={selectedOptions && selectedOptions}
						key={selectedOptions && selectedOptions}
						getOptionLabel={(option) => filters.models[option] || ""}
						renderInput={(params) => (
							<TextField
								{...params}
								placeholder={"Participants"}
								variant="standard"
								name="participants"
								sx={{
									borderRadius: "4px",
									padding: "0px 10px 0px 5px",
									textAlign: "left",
									fontSize: "14px"
								}}
							/>
						)}
					/>
					<Stack
						direction="row"
						style={{ paddingBottom: "15px" }}
						className="Pools_row"
					>
						<Input
							value={inputList.title}
							name="title"
							multiline
							onChange={(e) => {
								handleInputChange(e);
							}}
							style={{ padding: "5px", height: "unset" }}
							placeholder="Title"
							className="Pools_input_text"
							disableUnderline="true"
						></Input>
					</Stack>
					{/*	<Stack
						direction="row"
						style={{ paddingBottom: "15px" }}
						className="Pools_row"
					>
						<Input
							value={inputList.description}
							name="description"
							multiline
							onChange={(e) => {
								handleInputChange(e);
							}}
							style={{ padding: "5px", height: "unset" }}
							placeholder="Description"
							className="Pools_input_text"
							disableUnderline="true"
						></Input>
					</Stack>*/}
					<Stack
						direction="row"
						className="Pools_row"
						style={{ paddingBottom: "15px" }}
						spacing={1}
					>
						<Autocomplete
							multiple
							id="tags-standard"
							freeSolo={true}
							fullWidth
							onChange={(event, value) => setSelectedTags(value)}
							defaultValue={selectedTags}
							value={selectedTags}
							options={[]}
							sx={{
								backgroundColor: "#1d1d1d",
								borderRadius: "4px",
								textAlign: "left",
								fontSize: "14px"
							}}
							className="tags-input"
							renderInput={(params) => (
								<TextField
									{...params}
									variant="standard"
									placeholder="Input tags"
									placeholderStyle={{ color: "red" }}
									sx={{
										borderRadius: "4px",
										padding: "0px 10px 0px 5px",
										textAlign: "left",
										fontSize: "14px"
									}}
								/>
							)}
						/>
					</Stack>
					{/*				<Stack
						direction="row"
						style={{ paddingBottom: "15px" }}
						className="Pools_row"
					>
						<Input
							value={inputList.metaDescription}
							name="metaDescription"
							multiline
							onChange={(e) => {
								handleInputChange(e);
							}}
							style={{ padding: "5px", height: "unset" }}
							placeholder="Meta Description"
							className="Pools_input_text"
							disableUnderline="true"
						></Input>
					</Stack>*/}

					{/*<Button*/}
					{/*    onClick={handleSave}*/}
					{/*    variant="contained"*/}
					{/*    loading={uploading}*/}
					{/*    disabled={source == null}*/}
					{/*    style={{*/}
					{/*        width: "100%",*/}
					{/*        marginTop: "10px",*/}
					{/*        color: "black",*/}
					{/*        backgroundColor: "#78F73B",*/}
					{/*        opacity: source == null && "0.5"*/}
					{/*    }}*/}
					{/*>*/}
					{/*    Save*/}
					{/*</Button>*/}
					<LoadingButton
						type="submit"
						onClick={handleSave}
						variant="contained"
						disabled={source == null}
						sx={{
							mt: 3,
							mb: 3,
							py: 1.5,
							borderRadius: "10px",
							textTransform: "unset",
							fontSize: "1.1rem",
							".MuiCircularProgress-root": {
								width: "23px !important",
								height: "23px !important"
							},
							".MuiLoadingButton-loadingIndicator": {
								color: "black"
							}
						}}
						style={{
							width: "100%",
							marginTop: "10px",
							color: "black",
							backgroundColor: "#78F73B",
							opacity: source == null && "0.5"
						}}
					>
						{"Save"}
					</LoadingButton>
				</Box>
			</Modal>
		</>
	);
};

export default connect(mapStateToProps)(UploadTubePhotos);
