import * as React from "react";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import {
	Box,
	Stack,
	Table,
	TableBody,
	TableContainer,
	TableHead,
	TableRow,
	TableSortLabel,
	Typography
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import GirlIcon from "@material-ui/icons/Face";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { getModelEarnings } from "../../redux/actions/modelAction";
import { connect, useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import ModelChart from "../Chart/ModelChart";

const mapStateToProps = (state) => {
	return {
		model_earnings: state.model_earnings,
		models_earnings: state.models_earnings
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getModelEarnings: (id, month, year) =>
			dispatch(getModelEarnings(id, month, year))
	};
};

function ModelEarnings({ getModelEarnings, model_earnings, models_earnings }) {
	const dispatch = useDispatch();
	const param = useParams();
	const navigate = useNavigate();
	React.useEffect(() => {
		getModelEarnings(param.id, 0, 0);
	}, []);

	const data = [
		{ id: 0, name: "January" },
		{ id: 1, name: "February" },
		{ id: 2, name: "March" },
		{ id: 3, name: "April" },
		{ id: 4, name: "May" },
		{ id: 5, name: "June" },
		{ id: 6, name: "July" },
		{ id: 7, name: "August" },
		{ id: 8, name: "September" },
		{ id: 9, name: "October" },
		{ id: 10, name: "November" },
		{ id: 11, name: "December" }
	];
	const StyledTableRow = styled(TableRow)(({ theme }) => ({
		"&:nth-of-type(odd)": {
			backgroundColor: "rgb(0 0 0)"
		},
		"&:nth-of-type(even)": {
			backgroundColor: "#ffffff14"
		},
		// hide last border
		"&:last-child td, &:last-child th": {
			border: 0
		},
		"&.MuiTableRow-root:hover": {
			backgroundColor: "#555555"
		}
	}));

	const StyledTableCell = styled(TableCell)(({ theme }) => ({
		[`&.${tableCellClasses.head}`]: {
			backgroundColor: "#292929",
			color: theme.palette.common.white
		},
		[`&.${tableCellClasses.body}`]: {
			fontSize: 14
		}
	}));

	const [month, setMonth] = React.useState(undefined);
	const [year, setYear] = React.useState(undefined);

	function getIndex(name) {
		return data.findIndex((obj) => obj.name == name);
	}

	function getIndex2(id) {
		return models_earnings.findIndex((obj) => obj.id == id);
	}

	let check2 = getIndex2(param.id);

	function cameraChangeButtonForward() {
		var index = getIndex(model_earnings.current_month);
		var year = model_earnings.current_year;
		var nextid = index + 1;
		if (nextid == data.length) {
			nextid = 0;
			model_earnings.current_year += 1;
			setYear(model_earnings.current_year);
		}
		model_earnings.current_month = data[nextid].name;
		setYear(model_earnings.current_year);
		setMonth(data[nextid].name);
	}

	function cameraChangeButtonBack() {
		var index = getIndex(model_earnings.current_month);
		var year = model_earnings.current_year;
		var nextid = index - 1;
		if (nextid < 0) {
			nextid = data.length - 1;
			model_earnings.current_year -= 1;
			setYear(model_earnings.current_year);
		}
		model_earnings.current_month = data[nextid].name;
		setYear(model_earnings.current_year);
		setMonth(data[nextid].name);
	}

	React.useEffect(() => {
		if (month != undefined || year != undefined) {
			getModelEarnings(param.id, month, year);
		}
	}, [month]);
	const rows = model_earnings && model_earnings.earnings;

	const [orderDirection, setOrderDirection] = React.useState("asc");
	const [valueToOrderBy, setvalueToOrderBy] = React.useState("tokens");
	const [rowData, setRowData] = React.useState(rows && rows);

	React.useEffect(() => {
		setRowData(rows);
	}, [rows]);

	const sorting = (item) => {
		const isAscending = valueToOrderBy === item && orderDirection === "asc";
		let sorted;

		if (isAscending) {
			if (item == "tokens") {
				sorted = [...rowData].sort((a, b) => (a[item] > b[item] ? 1 : -1));
			}
			if (item == "last_ip") {
				sorted = [...rows].sort((a, b) =>
					(a[item]
						? Number(
								a[item]
									.split(".")
									.map((num) => `000${num}`.slice(-3))
									.join("")
							)
						: 0) >
					(b[item]
						? Number(
								b[item]
									.split(".")
									.map((num) => `000${num}`.slice(-3))
									.join("")
							)
						: 0)
						? 1
						: -1
				);
			}
			if (item != "tokens" && item != "last_ip") {
				sorted = [...rowData].sort((a, b) =>
					a[item].toLowerCase() > b[item].toLowerCase() ? 1 : -1
				);
			}
		}
		if (!isAscending) {
			if (item == "tokens") {
				sorted = [...rowData].sort((a, b) => (a[item] < b[item] ? 1 : -1));
			}
			if (item == "last_ip") {
				sorted = [...rows].sort((a, b) =>
					(a[item]
						? Number(
								a[item]
									.split(".")
									.map((num) => `000${num}`.slice(-3))
									.join("")
							)
						: 0) <
					(b[item]
						? Number(
								b[item]
									.split(".")
									.map((num) => `000${num}`.slice(-3))
									.join("")
							)
						: 0)
						? 1
						: -1
				);
			}
			if (item != "tokens" && item != "last_ip") {
				sorted = [...rowData].sort((a, b) =>
					a[item].toLowerCase() < b[item].toLowerCase() ? 1 : -1
				);
			}
		}

		setvalueToOrderBy(item);
		setRowData(sorted);
		setOrderDirection(isAscending ? "desc" : "asc");
	};

	const loading = useSelector((state) => state.loading);
	return (
		<>
			<Stack
				className="page-container"
				spacing={3}
				sx={{ width: 600, marginLeft: "290px" }}
			>
				{loading && loading.isLoading == true ? (
					<div className="loader-container">
						<div className="spinner"></div>
					</div>
				) : (
					""
				)}
				<Box
					style={{ marginTop: loading && loading.isLoading == true && "0px" }}
				>
					<Grid container spacing={0}>
						<Grid item xs={4} md={2} spacing={0} className="LiveShow">
							<Typography
								variant="h4"
								gutterBottom
								component="div"
								style={{ color: "white" }}
							>
								{models_earnings &&
									models_earnings[check2] &&
									models_earnings[check2].inHouseName}
								`s Earnings
							</Typography>
						</Grid>
					</Grid>
				</Box>
				<Box style={{ display: "flex", justifyContent: "flex-start" }}>
					<Box className="DashBoard_box" style={{ paddingRight: "20px" }}>
						<Typography
							variant="h7"
							gutterBottom
							component="div"
							style={{ color: "white", minWidth: "150px" }}
						>
							LifeTime
						</Typography>
						<Box className="Info_box">
							<Typography
								variant="h4"
								gutterBottom
								component="div"
								style={{
									color: "#78F73B",
									minWidth: "170px",
									display: "flex",
									alignItems: "center",
									justifyContent: "center"
								}}
							>
								{Math.floor(
									model_earnings &&
										model_earnings.earnings_summary_fixed &&
										model_earnings.earnings_summary_fixed.lifetime
								)}
							</Typography>
						</Box>
					</Box>
					<Box className="DashBoard_box" style={{ paddingRight: "20px" }}>
						<Typography
							variant="h7"
							gutterBottom
							component="div"
							style={{ color: "white", minWidth: "150px" }}
						>
							This month
						</Typography>
						<Box className="Info_box">
							<Typography
								variant="h4"
								gutterBottom
								component="div"
								style={{
									color: "#78F73B",
									minWidth: "170px",
									display: "flex",
									alignItems: "center",
									justifyContent: "center"
								}}
							>
								{Math.floor(
									model_earnings &&
										model_earnings.earnings_summary_fixed &&
										model_earnings.earnings_summary_fixed.this_month
								)}
							</Typography>
						</Box>
					</Box>
					<Box className="DashBoard_box" style={{ paddingRight: "20px" }}>
						<Typography
							variant="h7"
							gutterBottom
							component="div"
							style={{ color: "white", minWidth: "150px" }}
						>
							This week
						</Typography>
						<Box className="Info_box">
							<Typography
								variant="h4"
								gutterBottom
								component="div"
								style={{
									color: "#78F73B",
									minWidth: "170px",
									display: "flex",
									alignItems: "center",
									justifyContent: "center"
								}}
							>
								{Math.floor(
									model_earnings &&
										model_earnings.earnings_summary_fixed &&
										model_earnings.earnings_summary_fixed.this_week
								)}
							</Typography>
						</Box>
					</Box>
					<Box className="DashBoard_box" style={{ paddingRight: "20px" }}>
						<Typography
							variant="h7"
							gutterBottom
							component="div"
							style={{ color: "white", minWidth: "150px" }}
						>
							Today
						</Typography>
						<Box className="Info_box">
							<Typography
								variant="h4"
								gutterBottom
								component="div"
								style={{
									color: "#78F73B",
									minWidth: "170px",
									display: "flex",
									alignItems: "center",
									justifyContent: "center"
								}}
							>
								{Math.floor(
									model_earnings &&
										model_earnings.earnings_summary_fixed &&
										model_earnings.earnings_summary_fixed.today
								)}
							</Typography>
						</Box>
					</Box>
					<Box></Box>
				</Box>

				<Box style={{ display: "flex", justifyContent: "flex-start" }}>
					<Box className="DashBoard_box" style={{ paddingRight: "20px" }}>
						<Button
							onClick={() => cameraChangeButtonBack()}
							startIcon={<ArrowBackIosIcon />}
							className="ModelProfilePage_arrow_back"
							variant="contained"
						>
							Previous
						</Button>
					</Box>
					<Box className="DashBoard_box" style={{ paddingRight: "20px" }}>
						<Box className="Date">
							<Typography
								variant="h6"
								component="div"
								style={{
									color: "white",
									minWidth: "170px",
									display: "flex",
									alignItems: "center",
									justifyContent: "center"
								}}
							>
								{model_earnings && model_earnings.current_month}
								&nbsp;
								{model_earnings && model_earnings.current_year}
							</Typography>
						</Box>
					</Box>
					<Box className="DashBoard_box" style={{ paddingRight: "20px" }}>
						<Button
							onClick={() => cameraChangeButtonForward()}
							endIcon={<ArrowForwardIosIcon />}
							className="ModelProfilePage_arrow_next"
							variant="contained"
						>
							Next
						</Button>
					</Box>
				</Box>
				<ModelChart
					chartData={model_earnings && model_earnings.earnings_chart}
				/>
				<Box className="DashBoard_box" style={{ paddingRight: "20px" }}>
					<Typography
						variant="h7"
						gutterBottom
						component="div"
						style={{ color: "white", minWidth: "150px" }}
					>
						Selected Month total
					</Typography>
					<Box className="Info_box">
						<Typography
							variant="h4"
							gutterBottom
							component="div"
							style={{
								color: "#78F73B",
								minWidth: "170px",
								display: "flex",
								alignItems: "center",
								justifyContent: "center"
							}}
						>
							{Math.floor(model_earnings && model_earnings.earnings_summary)}
						</Typography>
					</Box>
				</Box>

				<Stack spacing={3} sx={{ width: 1000, marginLeft: "290px" }}>
					<Box>
						<Grid container spacing={0}>
							<Grid item xs={4} md={2} spacing={0} className="LiveShow">
								<Typography
									variant="h4"
									gutterBottom
									component="div"
									style={{ color: "white" }}
								>
									Earnings
								</Typography>
							</Grid>
						</Grid>
					</Box>
					<TableContainer
						className="SchrollBar"
						component={Paper}
						style={{ overflow: "auto", marginRight: "40px" }}
						sx={{ maxHeight: "800px" }}
					>
						<Table aria-label="simple table" stickyHeader>
							<TableHead>
								<StyledTableRow>
									<StyledTableCell
										style={{ borderBottom: "none" }}
										align="left"
									>
										<TableSortLabel
											active={valueToOrderBy === "item"}
											direction={
												valueToOrderBy === "item" ? orderDirection : "asc"
											}
											onClick={() => {
												sorting("item");
											}}
										>
											Type of tips
										</TableSortLabel>
									</StyledTableCell>
									<StyledTableCell
										style={{ borderBottom: "none" }}
										align="right"
									>
										<TableSortLabel
											active={valueToOrderBy === "tokens"}
											direction={
												valueToOrderBy === "tokens" ? orderDirection : "asc"
											}
											onClick={() => {
												sorting("tokens");
											}}
										>
											Tokens Amount
										</TableSortLabel>
									</StyledTableCell>
									<StyledTableCell
										style={{ borderBottom: "none" }}
										align="right"
									>
										<TableSortLabel
											active={valueToOrderBy === "payFrom"}
											direction={
												valueToOrderBy === "payFrom" ? orderDirection : "asc"
											}
											onClick={() => {
												sorting("payFrom");
											}}
										>
											Sender
										</TableSortLabel>
									</StyledTableCell>
									<StyledTableCell
										style={{ borderBottom: "none" }}
										align="right"
									>
										<TableSortLabel
											active={valueToOrderBy === "createdAt"}
											direction={
												valueToOrderBy === "createdAt" ? orderDirection : "asc"
											}
											onClick={() => {
												sorting("createdAt");
											}}
										>
											Time
										</TableSortLabel>
									</StyledTableCell>
									<StyledTableCell
										style={{ borderBottom: "none" }}
										align="right"
									>
										<TableSortLabel
											active={valueToOrderBy === "last_ip"}
											direction={
												valueToOrderBy === "last_ip" ? orderDirection : "asc"
											}
											onClick={() => {
												sorting("last_ip");
											}}
										>
											IP Address
										</TableSortLabel>
									</StyledTableCell>
								</StyledTableRow>
							</TableHead>
							<TableBody>
								{rowData &&
									rowData.map((row) => (
										<StyledTableRow
											key={row.id}
											sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
										>
											<TableCell
												align="left"
												style={{
													borderBottom: "none",
													color: "#78F73B"
												}}
											>
												<GirlIcon
													style={{ color: "white", marginRight: "10px" }}
												/>
												{row.item}
											</TableCell>
											<TableCell
												align="right"
												style={{ borderBottom: "none", color: "#FFD800" }}
											>
												{row.tokens == null ? 0 : row.tokens}
											</TableCell>
											<TableCell
												align="right"
												style={{ borderBottom: "none", color: "#FFD800" }}
											>
												{row.payFrom}
											</TableCell>
											<TableCell
												align="right"
												style={{ borderBottom: "none", color: "#FFD800" }}
											>
												{row.createdAt}
											</TableCell>
											<TableCell
												align="right"
												style={{ borderBottom: "none", color: "#FFD800" }}
											>
												{row.last_ip}
											</TableCell>
										</StyledTableRow>
									))}
							</TableBody>
						</Table>
					</TableContainer>
				</Stack>
			</Stack>
		</>
	);
}

export default connect(mapStateToProps, mapDispatchToProps)(ModelEarnings);
