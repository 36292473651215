import * as React from "react";
import { useState } from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import {
	Box,
	Input,
	Stack,
	Table,
	TableBody,
	TableContainer,
	TableHead,
	TableRow,
	Typography
} from "@mui/material";
import TableCell from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import { useDispatch } from "react-redux";
import IconButton from "@mui/material/IconButton";
import { useNavigate } from "react-router-dom";
import ArrowRightRoundedIcon from "@mui/icons-material/ArrowRightRounded";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import CheckboxUI from "../../UI/CheckboxUI";
import {
	EventModelsProceed,
	UpdateParticipantVotes
} from "../../redux/actions/studActions";

const InputUi = styled(Input)(({ theme }) => ({
	"& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
		display: "none"
	},
	"& input[type=number]": {
		MozAppearance: "textfield"
	}
}));

function EventPerformers({ participants, stage, backend_url }) {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const [selected, setselected] = useState("none");
	const handleClickOpen = (index) => {
		setselected(index);
	};

	const [models, setmodels] = useState(participants);
	React.useEffect(() => {
		setmodels(participants);
	}, [participants]);

	const [selectedVotesValue, setSelectedVotesValue] = React.useState({});
	const handleChangeVotesCount = (event) => {
		setSelectedVotesValue({ index: selected, value: event.target.value });
	};
	const handleEdit = (stage_id, participant_id) => {
		var votes_count = document.getElementById(selected).value;
		dispatch(
			UpdateParticipantVotes(backend_url, stage_id, participant_id, votes_count)
		);
		setselected("none");
	};

	const [selectedCheckboxes, setSelectedCheckboxes] = useState({});

	const handleCheckboxChange = (event, id) => {
		const isChecked = event.target.checked;
		setSelectedCheckboxes((prevState) => ({
			...prevState,
			[id]: isChecked
		}));
	};
	return (
		<>
			<Stack spacing={3} sx={{ width: 750 }}>
				<Grid
					container
					spacing={0}
					style={{ display: "flex", flexDirection: "column" }}
				>
					<Grid
						item
						xs={12}
						md={12}
						spacing={0}
						className="Magicx_text"
						style={{
							paddingTop: "25px",
							flexDirection: "row",
							justifyContent: "space-between"
						}}
					>
						<Typography
							variant="h4"
							gutterBottom
							component="div"
							style={{ color: "white", marginBottom: "unset" }}
						>
							Performers - ${stage?.name}
						</Typography>
						<Button
							type="submit"
							variant="contained"
							style={{
								width: "fit-content",
								color: "black",
								backgroundColor: "#78F73B",
								border: "unset",
								textTransform: "unset"
							}}
							onClick={() => {
								const selectedIds = Object.keys(selectedCheckboxes).filter(
									(id) => selectedCheckboxes[id] === true
								);
								dispatch(
									EventModelsProceed(backend_url, stage.id, selectedIds)
								);
							}}
							endIcon={
								<ArrowRightRoundedIcon
									style={{
										color: "black",
										width: "1.5em",
										height: "1.5em"
									}}
								/>
							}
						>
							Proceed to next round
						</Button>
					</Grid>
					<Box component="div" className="Voting_tricks">
						<TableContainer className="Magics_table">
							<Table sx={{ minWidth: 650 }} aria-label="simple table">
								<TableHead>
									<TableRow>
										<TableCell align="left">Housemate</TableCell>
										<TableCell align="left">Votes</TableCell>
										<TableCell align="center">Proceed</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{models &&
										models.map((model, index) => (
											<TableRow
												key={model.id}
												sx={{
													"&:last-child td, &:last-child th": { border: 0 }
												}}
											>
												<TableCell align="left">
													<Input
														key={model.id}
														className="inputBoxTrick"
														disableUnderline="true"
														name="password"
														disabled
														defaultValue={model?.inHouseName}
													></Input>
												</TableCell>
												<TableCell align="left">
													{" "}
													<InputUi
														id={index}
														className="inputBoxTrick"
														style={{
															minWidth: "100px"
														}}
														disableUnderline="true"
														type="number"
														onClick={() => {
															handleClickOpen(index);
														}}
														onChange={handleChangeVotesCount}
														placeholder="Trick Name"
														key={model?.votes_count}
														defaultValue={model?.votes_count}
													/>
													{selected == index && (
														<>
															<IconButton
																onClick={() => {
																	handleEdit(stage?.id, model?.id);
																}}
																aria-label="delete"
															>
																<CheckCircleRoundedIcon
																	style={{ color: "#78F73B" }}
																/>
															</IconButton>
														</>
													)}
												</TableCell>
												<TableCell align="center">
													<CheckboxUI
														style={{ color: "white", margin: "0px" }}
														title={""}
														name="freeUnlocked"
														value="allowExtraEmails"
														color="primary"
														onChange={(event) =>
															handleCheckboxChange(event, model?.id)
														}
														checked={selectedCheckboxes[model?.id] || false}
													/>
												</TableCell>
											</TableRow>
										))}
								</TableBody>
							</Table>
						</TableContainer>
					</Box>
				</Grid>
			</Stack>
		</>
	);
}
export default EventPerformers;
