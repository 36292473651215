import { setLoading, setMessage } from "../reducers/rootReducer";
import { API_ADMIN_MICROPHONES_ROUTE } from "../../utils/consts";
import { MICROPHONE_UPDATE, MICROPHONES } from "../types";

const allMicrophones = (data) => {
	return {
		type: MICROPHONES,
		payload: { data }
	};
};
const microphoneUpdate = (data) => {
	return {
		type: MICROPHONE_UPDATE,
		payload: { data }
	};
};
export const getMicrophones = () => async (dispatch) => {
	dispatch(setLoading({ camera: "micro", type: true }));
	console.log("Microphones");
	try {
		const response = await fetch(
			process.env.REACT_APP_BACKEND_URL + API_ADMIN_MICROPHONES_ROUTE,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
					Authorization: `Bearer ${sessionStorage.getItem("token")}`
				}
				//credentials: 'include',
			}
		);
		//response.data
		const data = await response.json();
		console.log(data, response);
		if (response.status >= 200 && response.status <= 299) {
			if (data?.data?.microphones) {
				dispatch(allMicrophones(data?.data?.microphones));
				dispatch(setLoading({ camera: "micro", type: false }));
			}
		} else {
			console.log("logMeOut", response.status, data);
			dispatch(setLoading({ camera: "micro", type: false }));
			//dispatch(userLogout());
			//sessionStorage.removeItem("token");
		}
	} catch (error) {
		console.log(error);
		dispatch(setLoading({ camera: "micro", type: false }));
	}
};

export const updateMicrophone = (microId, userId) => async (dispatch) => {
	dispatch(setLoading({ camera: "micro", type: true }));
	console.log("Microphones");
	try {
		const response = await fetch(
			process.env.REACT_APP_BACKEND_URL +
				API_ADMIN_MICROPHONES_ROUTE +
				"/" +
				microId +
				"/assign/" +
				userId,
			{
				method: "PUT",
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
					Authorization: `Bearer ${sessionStorage.getItem("token")}`
				}
				//credentials: 'include',
			}
		);
		//response.data
		const data = await response.json();
		if (response.status >= 200 && response.status <= 299) {
			console.log(data?.data);
			dispatch(microphoneUpdate(data?.data?.microphone));
			dispatch(setLoading({ camera: "micro", type: false }));
			dispatch(setMessage(data.message, data.type));
		} else {
			console.log("logMeOut", response.status, data);
			dispatch(setLoading({ camera: "micro", type: false }));
			//dispatch(userLogout());
			//sessionStorage.removeItem("token");
		}
	} catch (error) {
		console.log(error);
		dispatch(setLoading({ camera: "micro", type: false }));
	}
};
