import React, { useRef } from "react";
import {
	FormControl,
	InputLabel,
	Select,
	ListItemText,
	ListItemIcon,
	Checkbox
} from "@mui/material";

export default function MultiSelectUI({
	MenuItem,
	title,
	onChange,
	value,
	items,
	sx,
	style
}) {
	const selectRef = useRef();

	let randomId = Math.floor(Math.random(1, 1000) * 1000);

	const [open, setOpen] = React.useState(false);

	const [isAllSelected, setIsAllSelected] = React.useState(false);

	const handleClose = () => {
		setOpen(false);
	};

	const handleOpen = () => {
		setOpen(true);
	};

	React.useEffect(() => {
		setTimeout(() => {
			const el = document.querySelector('[data-meta="MuiButtonBase"]');
			if (el) el.remove();
		}, 1);
	}, [open]);

	React.useEffect(() => {
		setIsAllSelected(items?.length > 0 && value?.length === items?.length);
	}, [items, value]);

	if (!items) {
		return <></>;
	}
	if (items === []) {
		return <></>;
	}
	const handleCheckboxChange = (item) => {
		if (value.some((v) => v.value === item.value)) {
			// If the item is already selected, remove it from the selection
			const updatedValue = value.filter((v) => v.value !== item.value);
			onChange(updatedValue);
		} else {
			// If the item is not selected, add it to the selection
			const updatedValue = [...value, item];
			onChange(updatedValue);
		}
	};
	const handleSelectAll = () => {
		if (isAllSelected) {
			// If all items are already selected, deselect all
			onChange([]);
		} else {
			// If not all items are selected, select all
			onChange(items);
		}
	};
	return (
		<FormControl className="MultiSelectUI" sx={sx} style={style}>
			<InputLabel id="demo-simple-select-label">{title}</InputLabel>
			<Select
				ref={selectRef}
				labelId={"select-label-" + randomId}
				id={"select-" + randomId}
				label={title}
				/*	onChange={onChange}*/
				value={value}
				open={open}
				onClose={handleClose}
				onOpen={handleOpen}
				multiple={true}
				renderValue={(selected) =>
					selected.map((item) => item.title).join(", ")
				}
				className="select"
				MenuProps={{
					PaperProps: {
						sx: {
							bgcolor: "black",
							"& .MuiMenuItem-root": {
								padding: "4px 10px !important",
								border: "1px solid transparent"
							},
							"& .MuiButtonBase-root:hover": {
								bgcolor: "#222222"
							},
							"& .Mui-selected": {
								bgcolor: "rgb(37, 37, 37)"
							},
							"& .MuiSvgIcon-root": {
								color: "#77F73B"
							},
							"& .Mui-selected .MuiSvgIcon-root": {
								color: "#77F73B"
							},
							"& ul": {
								display: "flex",
								flexDirection: "column",
								maxHeight: "350px",
								maxWidth: "450px"
							},
							"& li": {
								justifyContent: "space-between",
								padding: "8px 10px !important",
								gap: "10px"
							},
							"& span": {
								whiteSpace: "pre-wrap"
							}
						}
					}
				}}
			>
				<MenuItem value="all" onClick={handleSelectAll}>
					<ListItemIcon>
						<Checkbox
							checked={isAllSelected}
							className="root-select"
							indeterminate={value.length > 0 && value.length < items.length}
						/>
					</ListItemIcon>
					<ListItemText primary="Select All" />
				</MenuItem>
				{items.map((item, i) => {
					return (
						<MenuItem
							onClick={() => handleCheckboxChange(item)}
							key={"sel-" + i}
							value={item}
						>
							<ListItemIcon>
								<Checkbox
									checked={value.some(
										(v) => v.title === item.title && v.value === item.value
									)}
								/>
							</ListItemIcon>
							<ListItemText primary={item.title} />
						</MenuItem>
					);
				})}
			</Select>
		</FormControl>
	);
}
