import React, { useState } from "react";
import "antd/dist/antd.css";
import { PlusOutlined } from "@ant-design/icons";
import { Upload } from "antd";
import ReactHlsPlayer from "react-hls-player";
import "video-react/dist/video-react.css";
import { deleteMedia, getModelMedia } from "../../redux/actions/modelAction";
import { useParams } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import axios from "axios";
import styled from "./StreamScreen.module.css";
import {
	Box,
	Button,
	Container,
	IconButton,
	Input,
	Modal,
	Stack
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { UpdateVideo } from "../../redux/actions/GalleriesActions";
import useVaporUpload from "./VaporUpload";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 400,
	bgcolor: "background.paper",
	border: "2px solid #000",
	textAlign: "center",
	margin: "0 auto",
	p: 4
};
const getBase64 = (file) =>
	new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);

		reader.onload = () => resolve(reader.result);

		reader.onerror = (error) => reject(error);
	});
const mapStateToProps = (state) => {
	return {
		model_media: state.model_media
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		getModelMedia: (id) => dispatch(getModelMedia(id))
	};
};

const UploaderVideo = ({ model_media, getModelMedia, id }) => {
	const dispatch = useDispatch();
	// React.useEffect(() => {
	//   getModelMedia(id);
	// }, []);
	const [previewVisible, setPreviewVisible] = useState(false);
	const [previewImage, setPreviewImage] = useState("");
	const [previewTitle, setPreviewTitle] = useState("");
	const [fileList, setFileList] = useState([]);
	const [title, setTitle] = React.useState("");
	const [description, setDescription] = React.useState("");
	const [fileId, setId] = React.useState("");
	const param = useParams();
	React.useEffect(() => {
		let newFileList = [];
		if (model_media && model_media.videos && model_media.videos.length == 0) {
			setFileList([]);
		}
		model_media &&
			model_media.videos &&
			model_media.videos.forEach((video) => {
				newFileList.push({
					uid: video.id,
					status: "done",
					title: video.title && video.title,
					description: video.description && video.description,
					url:
						video.poster_image &&
						video.poster_image.mediaMeta &&
						video.poster_image.mediaMeta.imageLarge,
					response: '{"status": "success"}', // response from server
					linkProps: '{"download": "video"}'
				});

				setFileList(newFileList);
			});
	}, [model_media]);

	const handleCancel = () => {
		setPreviewVisible(false);
		setTitle("");
		setDescription("");
	};

	const handlePreview = async (file) => {
		const res = await axios.get(
			`${process.env.REACT_APP_BACKEND_URL}/api/v1/models/media/video/` +
				file.uid,
			{
				withCredentials: true,
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
					Authorization: `Bearer ${sessionStorage.getItem("token")}`
				}
			}
		);
		if (res.data) {
			setPreviewImage(res.data.data.player);
			setId(file.uid);
			setDescription(file.description);
			setTitle(file.title);
			setPreviewVisible(true);
			setPreviewTitle(
				file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
			);
		}
	};

	const handleChange = ({ fileList: newFileList }) => {
		setFileList(newFileList);
	};

	const handleRemove = (info) => {
		dispatch(deleteMedia(info.uid, "video"));
		handleChange;
	};
	const { uploadFile } = useVaporUpload();
	const customRequest = async ({ file, onSuccess, onError, onProgress }) => {
		try {
			console.log(file);
			const { vaporResponse } = await uploadFile(file);

			const formData = new FormData();
			formData.append("title", vaporResponse.uuid);
			formData.append("fullMovie", vaporResponse.key);

			const response = await axios.post(
				`${process.env.REACT_APP_BACKEND_URL}/api/v1/models/media/video/store/${
					id && id
				}`,
				formData,
				{
					headers: {
						Authorization: `Bearer ${sessionStorage.getItem("token")}`
					},
					onUploadProgress: (progressEvent) => {
						const percentCompleted = Math.round(
							(progressEvent.loaded * 100) / progressEvent.total
						);
						onProgress({ percent: percentCompleted });
					}
				}
			);

			onSuccess(response.data, file);
			if (response.status === 200) {
				console.log(response);
				const newPhoto = {
					uid: response.data.data.id,
					status: "done",
					url:
						response.data.data &&
						response?.data?.data?.poster_image?.mediaMeta?.imageLarge &&
						response?.data?.data?.poster_image?.mediaMeta?.imageLarge,
					response: '{"status": "success"}',
					linkProps: '{"download": "video"}'
				};
				setFileList((prevList) => {
					const newList = [...prevList];
					newList.pop(); // Remove the last item
					newList.push(newPhoto);
					return newList;
				});
			}
		} catch (error) {
			onError(error);
		}
	};
	const uploadButton = (
		<div style={{ backgroundColor: "black !important" }}>
			<PlusOutlined style={{ color: "white" }} />
			<div
				style={{
					marginTop: 8,
					color: "white"
				}}
			>
				Upload
			</div>
		</div>
	);
	const props = {
		customRequest: customRequest,
		listType: "picture-card",
		name: "fullMovie"
	};
	const handleSave = () => {
		const obj = {
			title: inputList.title,
			description: inputList.description
		};

		dispatch(UpdateVideo(fileId, obj));
		setPreviewVisible(false);
		setTitle("");
		setDescription("");
		dispatch(getModelMedia(param.id));
	};
	const [inputList, setInputList] = React.useState({
		title: title && title,
		description: description && description
	});
	React.useEffect(() => {
		setInputList({
			title: title && title,
			description: description && description
		});
	}, [title, description]);
	const handleInputChange = (e) => {
		const fieldName = event.target.getAttribute("name");
		const fieldValue = event.target.value;
		const newFormData = { ...inputList };
		newFormData[fieldName] = fieldValue;
		setInputList(newFormData);
	};
	return (
		<>
			<Upload
				{...props}
				fileList={fileList}
				data={{ mediaType: "video" }}
				onPreview={handlePreview}
				onChange={handleChange}
				onRemove={handleRemove}
				defaultFileList={setFileList}
				key={setFileList}
				style={{ backgroundColor: "black", color: "white" }}
			>
				{fileList.length >= 20 ? null : uploadButton}
			</Upload>
			<Modal
				visible={previewVisible}
				open={previewVisible}
				footer={null}
				onClose={handleCancel}
			>
				<Box
					sx={style}
					style={{
						marginTop: "50px"
					}}
				>
					{/* {loading && loading.isLoading == true ? (
            <div className="loader-container">
              <div className="spinner"></div>
            </div>
          ) : (
            ""
          )} */}
					<IconButton className="BackButton" onClick={handleCancel}>
						<ClearIcon />
					</IconButton>
					<Container component="main" maxWidth="xs">
						<Box
							sx={{
								marginTop: 2,
								display: "flex",
								flexDirection: "row",
								alignItems: "center"
							}}
							md={3}
						>
							<Box
								sx={{
									bgcolor: "transparent",
									paddingRight: "10px",
									paddingBottom: "10px"
								}}
								md={3}
							>
								<ReactHlsPlayer
									id="main_video"
									src={previewImage && previewImage}
									controls={true}
									className={styled.VideoScreen}
									style={{
										maxWidth: "1350px",
										minWidth: "300px",
										width: "100%"
									}}
									muted={true}
									autoPlay
								/>
							</Box>
						</Box>
					</Container>

					<Stack
						direction="row"
						style={{ paddingBottom: "15px" }}
						className="Pools_row"
					>
						<Input
							value={inputList.title}
							name="title"
							multiline
							onChange={(e) => {
								handleInputChange(e);
							}}
							style={{ padding: "5px", height: "unset" }}
							placeholder="Title"
							className="Pools_input_text"
							disableUnderline="true"
						></Input>
					</Stack>
					<Stack
						direction="row"
						style={{ paddingBottom: "15px" }}
						className="Pools_row"
					>
						<Input
							value={inputList.description}
							name="description"
							multiline
							onChange={(e) => {
								handleInputChange(e);
							}}
							style={{ padding: "5px", height: "unset" }}
							placeholder="Description"
							className="Pools_input_text"
							disableUnderline="true"
						></Input>
					</Stack>
					<Button
						onClick={handleSave}
						variant="contained"
						style={{
							width: "100%",
							marginTop: "10px",
							color: "black",
							backgroundColor: "#78F73B"
						}}
					>
						Save
					</Button>
				</Box>
			</Modal>
		</>
	);
};

export default connect(mapStateToProps, mapDispatchToProps)(UploaderVideo);
