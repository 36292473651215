import { Grow } from "@mui/material";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import * as React from "react";
import CommentMessage from "./CommentMessage";
import { useParams } from "react-router-dom";
import { getBlogComments } from "../../redux/actions/blogActions";
import { useDispatch } from "react-redux";
import SearchComponent from "./SearchMessage";

export default function BlogComments({ blog_comments }) {
	const param = useParams();
	const dispatch = useDispatch();
	return (
		<Grow in timeout={400} delay={600}>
			<Grid
				style={{
					display: "flex",
					flexDirection: "column",
					alignItems: "flex-start",
					gap: "10px",
					width: "100%",
					// minHeight: "700px",
					maxHeight: "700px"
				}}
			>
				<Grid
					style={{
						display: "flex",
						flexDirection: "row",
						gap: "10px",
						alignItems: "center"
					}}
				>
					<Typography variant="h4">{"Comments"}</Typography>
					<SearchComponent />
				</Grid>

				<ul
					id="CommentList"
					// onScroll={handleScroll}
					style={{
						height: "fit-content",
						maxHeight: "600px",
						overflowY: "auto",

						visibility: "visible",
						width: "100%"
					}}
				>
					{blog_comments?.comments?.length > 0 &&
						blog_comments?.comments?.map((comment, index) => {
							return (
								<CommentMessage
									key={comment?.id}
									comment={comment}
									blogSlug={param.slug && param.slug}
								/>
							);
						})}
					{blog_comments &&
						blog_comments?.pagination &&
						blog_comments?.pagination?.total_count > 0 &&
						blog_comments?.pagination?.total_count >
							blog_comments?.comments?.length + 1 && (
							<div
								style={{
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
									width: "100%"
								}}
							>
								<Button
									onClick={() => {
										dispatch(
											getBlogComments(
												param.slug,
												Number(blog_comments?.pagination?.page) + 1,
												20
											)
										);
									}}
									startIcon={
										<ExpandMoreIcon
											style={{ fill: "black" }}
											fontSize="large"
										/>
									}
									className="more_button"
								>
									{blog_comments?.pagination?.total_count -
										(blog_comments?.comments?.length + 1)}{" "}
									{"more comments"}
								</Button>
							</div>
						)}
				</ul>
			</Grid>
		</Grow>
	);
}
