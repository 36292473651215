import { GALLERIES, GALLERY, GALLERY_UPDATE, POOLS } from "../types";

export const GalleryReducer = (state = [], action) => {
	const { type, payload } = action;

	switch (type) {
		case GALLERY: {
			const { data } = payload;
			state = [];
			return data;
		}
		case GALLERY_UPDATE: {
			const { data } = payload;

			let newState;
			let newState2 = Object.assign({}, state);
			if (data.image) {
				newState = state?.gallery?.images;
				let obj = newState.find((item) => item.id === data.image.id);
				const idx = newState.indexOf(obj);
				newState[idx] = data.image;

				newState2.gallery.images = newState;
			} else {
				newState = state?.gallery?.videos;
				let obj = newState.find((item) => item.id === data.video.id);
				const idx = newState.indexOf(obj);
				newState[idx] = data.video;

				newState2.gallery.videos = newState;
			}

			return newState2;
		}

		default: {
			return state;
		}
	}
};
