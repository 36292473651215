import { BLOG, DELETE_BLOG, UPDATE_BLOG } from "../types";

export const BlogReducer = (state = {}, action) => {
	const { type, payload } = action;

	switch (type) {
		case BLOG: {
			const { data } = payload;
			return data;
		}
		case UPDATE_BLOG: {
			const { data } = payload;
			return data;
		}
		/*     case MODEL_MEDIA: {
      const { data } = payload;
      return data;
    } */
		case DELETE_BLOG: {
			return {};
		}

		default: {
			return state;
		}
	}
};
