import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import { Backdrop } from "@mui/material";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import CssBaseline from "@mui/material/CssBaseline";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import Container from "@mui/material/Container";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import Logo from "../../assets/Logo.svg";
import {
	assignCameraName,
	changeCameraRecord,
	changeCameraSDN,
	changeCameraStatus
} from "../../redux/actions/cameraAction";
import { useDispatch, useSelector } from "react-redux";
import { Input } from "@material-ui/core";
import CheckIcon from "@mui/icons-material/Check";
import { assignLiveShowName } from "../../redux/actions/liveshowAction";
import Skeleton from "@mui/material/Skeleton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { setLoading } from "../../redux/reducers/rootReducer";
import ReactHlsPlayer from "react-hls-player/dist";
import VideoCameraBackIcon from "@mui/icons-material/VideoCameraBack";
import { useNavigate } from "react-router-dom";
const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 400,
	bgcolor: "background.paper",
	border: "2px solid #000",
	textAlign: "center",
	margin: "0 auto",
	p: 4
};
const theme = createTheme({
	palette: {
		success: {
			// Purple and green play nicely together.
			main: "#78F73B"
		},
		error: {
			// This is green.A700 as hex.
			main: "#F73B3B"
		},
		off: {
			main: "#800000"
		},
		on: {
			main: "#7CFC00"
		}
	}
});
export default function GalleryCard({ gallery }) {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [open, setOpen] = React.useState(false);

	const handleNavigate = () => {
		navigate("/gallery/" + gallery.id);
	};
	const handleClose = () => setOpen(false);

	const [addFormData, setAddFormData] = React.useState({});
	const [log, setLog] = React.useState("");
	const [check, setcheck] = React.useState(false);

	const cardClass = ["cameraCard"];

	const [NameData, setNameData] = React.useState([]);

	const handleAddForm = (event) => {
		event.preventDefault();
		const fieldName = event.target.getAttribute("name");
		const fieldValue = event.target.value;
		const newNameData = { ...NameData };
		newNameData[fieldName] = fieldValue;
		setNameData(newNameData);
	};
	const changeHandle = (event, value) => {
		//event.preventDefault();
		camera.freeUnlocked = value;
		setChecked(value);
		const newFormData = { ...addFormData };
		if (value == false) {
			newFormData["freeUnlocked"] = 0;
		} else {
			newFormData["freeUnlocked"] = 1;
		}
		setAddFormData(newFormData);
		dispatch(changeCameraStatus(gallery.id, newFormData));
	};
	function changeHandleName(id) {
		let newLiveshow = dispatch(assignCameraName(NameData, id));
	}
	const [imageLoaded, setImageLoaded] = React.useState(false);
	const [SND, setSDN] = React.useState("");
	const [record, setRecord] = React.useState("");

	const admin = useSelector((state) => state.admin);
	const loading = useSelector((state) => state.loading);

	React.useEffect(() => {
		if (loading.isLoading == false && check == true) {
			handleClose();
			setcheck(false);
		}
	}, [loading]);
	return (
		<Card className={cardClass}>
			<CardActionArea onClick={handleNavigate}>
				<CardMedia
					component="img"
					height="80"
					image={
						gallery && gallery.preview_image
							? gallery.preview_image
							: "https://imagedelivery.net/jGELM9koCbLli_SZCyPbdQ/68931281-aae4-4659-a42c-065fc49cec00/cameraThumb"
					}
					title={gallery && gallery.slug}
				/>

				{/*   ((camera && camera.type == 'common') && (admin.user && admin.user.role !== 'superadmin')) ? "BadgeText_common" : */}
				<Typography
					gutterBottom
					variant="h7"
					component="span"
					className={"BadgeText"}
				>
					{gallery.name}
				</Typography>
			</CardActionArea>
		</Card>
	);
}
