import React, { useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { SidebarDataSuper } from "./SidebarDataSuper";
import {
	Box,
	Button,
	Divider,
	Menu,
	MenuItem,
	Typography
} from "@mui/material";
import Avatar from "@mui/material/Avatar";
import Logo from "../../assets/Logo.svg";
import GirlIcon from "@material-ui/icons/Face";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import LogoutIcon from "@mui/icons-material/Logout";
import { connect, useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../../redux/actions/userAction";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import IconButton from "@mui/material/IconButton";
import { SidebarHide } from "../../redux/reducers/rootReducer";
import { getEnvironment } from "../../redux/actions/environmentAction";
import ConfirmModal from "../Utility/ConfirmModal";
import { changePrerecordingMode } from "../../redux/actions/cameraAction";
import SidebarSection from "./SidebarSection";

const mapStateToProps = (state) => {
	return {
		environment: state.environment
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getEnvironment: () => dispatch(getEnvironment())
	};
};

function Sidebar({ getEnvironment, environment }) {
	const dispatch = useDispatch();
	const location = useLocation();

	const [anchorEl, setAnchorEl] = React.useState(null);

	const open = Boolean(anchorEl);

	const admin = useSelector((state) => state.admin);

	const [recordingModeStatus, setRecordingModeStatus] = useState(
		environment?.prerecording_mode
	);
	const [showModal, setShowModal] = useState(false);

	const handleShowModal = () => {
		// Show the modal when the action button is clicked.
		setShowModal(true);
	};

	const handleCloseModal = () => {
		// Close the modal when the "Cancel" button inside the modal is clicked.
		setShowModal(false);
	};

	function changeHandleCheck() {
		dispatch(changePrerecordingMode(recordingModeStatus == true ? 0 : 1));
		setShowModal(false);
	}

	React.useEffect(() => {
		getEnvironment();
	}, [location.pathname]);

	React.useEffect(() => {
		setRecordingModeStatus(environment?.prerecording_mode);
	}, [environment]);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const [sidebar, setSidebar] = useState(true);
	const showSidebar = () => {
		setSidebar(!sidebar);
	};
	React.useEffect(() => {
		if (!sidebar) {
			dispatch(SidebarHide({ hide: true }));
		} else {
			dispatch(SidebarHide({ hide: false }));
		}
	}, [sidebar]);

	let navigate = useNavigate();
	const routeChange = () => {
		console.log("route change: " + admin.access_token);
		dispatch(logoutUser(admin.access_token));
		navigate("/login");
	};
	const [openSections, setOpenSections] = useState({});

	// Toggle function to manage opening and closing sections
	const handleToggle = (sectionName) => {
		setOpenSections((prevState) => ({
			...prevState,
			[sectionName]: !prevState[sectionName]
		}));
	};
	return (
		<>
			<nav className={sidebar ? "nav-menu active" : "nav-menu disable"}>
				<div
					className={sidebar ? "btn-container-close" : "btn-container"}
					style={{ zIndex: 1000000 }}
				>
					<IconButton
						edge="start"
						color="inherit"
						onClick={showSidebar}
						aria-label="close"
						style={{
							width: "fit-content",
							backgroundColor: "black"
						}}
					>
						{sidebar ? (
							<ArrowBackIosNewRoundedIcon style={{ color: "white" }} />
						) : (
							<MenuRoundedIcon style={{ color: "white" }} />
						)}
					</IconButton>
				</div>
				<ul className="nav-menu-items" style={{ overflowY: "auto" }}>
					<li className="nav-top">
						<NavLink to={"/dashBoard"}>
							<Typography
								component="a"
								sx={{
									mr: 1,
									display: { xs: "none", md: "flex" },
									textDecoration: "none",
									justifyContent: "center",
									backgroundColor: "#000000",
									width: "100%",
									height: "10%",
									alignItems: "center"
								}}
							>
								<Avatar
									sx={{
										display: { xs: "none", md: "flex" },
										mr: 0.5,
										width: "150px",
										marginBottom: "20px",
										justifyContent: "center",
										backgroundColor: "#000000"
									}}
									variant="square"
									src={Logo}
								/>
							</Typography>
						</NavLink>
						<Typography
							component="a"
							className="navlinkName"
							sx={{
								mr: 1,
								display: { xs: "none", md: "flex" },
								textDecoration: "none",
								justifyContent: "center",
								backgroundColor: "#000000",
								width: "100%",
								height: "10%",
								alignItems: "center"
							}}
						>
							<Button
								style={{
									textTransform: "unset",
									display: "flex",
									flexDirection: "row",
									width: "100%"
								}}
								id="basic-button"
								aria-controls={open ? "basic-menu" : undefined}
								aria-haspopup="true"
								aria-expanded={open ? true : undefined}
								onClick={handleClick}
								endIcon={
									<ArrowDropDownRoundedIcon style={{ color: "white" }} />
								}
							>
								<Box
									style={{
										borderBottom: "none",
										color: "#78F73B",
										display: "flex",

										alignItems: "start",
										width: "fit-content",
										minWidth: "100px",
										textTransform: "unset",
										overflow: "hidden",
										whiteSpace: "nowrap"
									}}
								>
									<GirlIcon style={{ color: "white", marginRight: "10px" }} />
									<Typography style={{ textTransform: "unset" }}>
										{admin.user && admin.user.username}
									</Typography>
								</Box>
							</Button>
							<Box>
								<Menu
									id="basic-menu"
									anchorEl={anchorEl}
									open={open}
									onClose={handleClose}
									MenuListProps={{
										"aria-labelledby": "basic-button"
									}}
								>
									<Typography sty>
										<MenuItem
											style={{
												width: "150px",
												paddingTop: "0px",
												paddingBottom: "0px",
												textTransform: "unset"
											}}
											onClick={routeChange}
										>
											<LogoutIcon />
											Logout
										</MenuItem>
									</Typography>
								</Menu>
							</Box>
						</Typography>
					</li>
					{admin?.user &&
						SidebarDataSuper?.filter((item) => {
							if (item?.childrens) {
								return item?.childrens?.some(
									(child) =>
										!child?.permission ||
										admin?.available_permissions?.includes(child?.permission)
								);
							}
							return (
								!item?.permission ||
								admin?.available_permissions?.includes(item?.permission)
							);
						}).map((item, index) => (
							<li key={index} className={item?.cName}>
								{item.childrens ? (
									<SidebarSection
										item={item}
										isOpen={openSections[item?.name]}
										onToggle={() => handleToggle(item?.name)}
										permissions={admin?.available_permissions}
									/>
								) : (
									admin?.available_permissions?.includes(item?.permission) && (
										<NavLink to={item?.link}>
											<span>{item?.name}</span>
										</NavLink>
									)
								)}
							</li>
						))}
					{admin.user &&
						admin?.available_permissions?.includes("panel_girl_management") && (
							<li
								style={{
									display: "flex",
									flexDirection: "column",
									paddingInline: 10
								}}
							>
								<div
									style={{
										marginTop: "20px",
										display: "flex",
										flexDirection: "column",
										gap: "10px"
									}}
								>
									<Divider w />
									<Button
										size="big"
										variant="contained"
										onClick={handleShowModal}
										color={recordingModeStatus ? "success" : "error"}
									>
										{recordingModeStatus
											? "Enable all streams"
											: "Disable all streams"}
									</Button>
								</div>

								<ConfirmModal
									open={showModal}
									onClose={handleCloseModal}
									onConfirm={changeHandleCheck}
									text={
										recordingModeStatus
											? "enable all streams"
											: "disable all streams"
									}
									color={recordingModeStatus ? "success" : "error"}
								/>
							</li>
						)}
				</ul>
			</nav>
		</>
	);
}

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
