export const USER = "USER";
export const ADMIN = "ADMIN";
export const USERS = "USERS";
export const USERS_TRANSACTIONS = "USERS_TRANSACTIONS";
export const USER_TRANSACTIONS = "USER_TRANSACTIONS";
export const USER_SUBSCRIPTIONS = "USER_SUBSCRIPTIONS";
export const USER_TRANSACTION = "USER_TRANSACTION";
export const MODEL = "MODEL";
export const MODEL_EARNINGS = "MODEL_EARNINGS";
export const MODELS = "MODELS";
export const MODELS_ORDER = "MODELS_ORDER";
export const MODELS_EARNINGS = "MODELS_EARNINGS";
export const UPDATE = "UPDATE";
export const FILTERS = "FILTERS";
export const UPDATE_MODEL = "UPDATE_MODEL";
export const DELETE_MODEL = "DELETE_MODEL";
export const DELETE_MODEL_MEDIA = "DELETE_MODEL_MEDIA";
export const ADD_MODEL = "ADD_MODEL";
export const MODEL_MEDIA = "MODEL_MEDIA";

export const BLOG = "BLOG";
export const BLOGS = "BLOGS";
export const UPDATE_BLOG = "UPDATE_BLOG";
export const DELETE_BLOG = "DELETE_BLOG";
export const ADD_BLOG = "ADD_BLOG";

export const BLOG_COMMENTS = "BLOG_COMMENTS";
export const BLOG_COMMENTS_PAGINATION = "BLOG_COMMENTS_PAGINATION";

export const BLOG_COMMENT_UPDATE = "BLOG_COMMENT_UPDATE";

export const DASHBOARD_DATA = "DASHBOARD_DATA";
export const UPDATE_ENVIRONMENT_DATA = "UPDATE_ENVIRONMENT_DATA";
export const UPDATE_ENVIRONMENT_PRERECORDING_STATUS =
	"UPDATE_ENVIRONMENT_PRERECORDING_STATUS";

export const UPDATE_USER = "UPDATE_USER";
export const DELETE_USER = "DELETE_USER";
export const DELETE_TRANSACTION = "DELETE_TRANSACTION";
export const DELETE_USER_SUBSCRIPTIONS = "DELETE_USER_SUBSCRIPTIONS";
export const DELETE_USER_TRANSACTIONS = "DELETE_USER_TRANSACTIONS";
export const CAMERA = "CAMERA";
export const CAMERAS = "CAMERAS";
export const ADMIN_CAMERA = "ADMIN_CAMERA";
export const LIVESHOW = "LIVESHOW";
export const LIVESHOWS = "LIVESHOWS";
export const LIVESHOW_SCHEDULES = "LIVESHOW_SCHEDULES";
export const AUTH_ADMIN_LOGIN = "AUTH_ADMIN_LOGIN";
export const AUTH_ADMIN_LOGOUT = "AUTH_ADMIN_LOGOUT";
export const SET_LOADING = "SET_LOADING";
export const REMOVE_LOADING = "REMOVE_LOADING";
export const SET_LOADING_CHAT = "SET_LOADING_CHAT";
export const INCREMENT_PROGRESS = "INCREMENT_PROGRESS";
export const DECREMENT_PROGRESS = "DECREMENT_PROGRESS";
export const TIME_PICKER = "TIME_PICKER";
export const FETCH_CHATS = "FETCH_CHATS";

export const SET_LOADINGS = "SET_LOADINGS";
export const CHAT = "CHAT";
export const REMOVE_CHATS = "REMOVE_CHATS";
export const REMOVE_MESSAGES = "REMOVE_MESSAGES";
export const REMOVE_CHAT = "REMOVE_CHAT";
export const ADD_MESSAGE = "ADD_MESSAGE";
export const SET_MESSAGES = "SET_MESSAGES";
export const ADD_MESSAGE_TO_MESSAGES = "ADD_MESSAGE_TO_MESSAGES";
export const ADD_MESSAGES_TO_CHAT = "ADD_MESSAGES_TO_CHAT";
export const ADD_MORE_MESSAGES_TO_CHAT = "ADD_MORE_MESSAGES_TO_CHAT";
export const ADD_MESSAGE_TO_CHAT = "ADD_MESSAGE_TO_CHAT";
export const ADD_MESSAGE_TO_CHAT_OFFSET = "ADD_MESSAGE_TO_CHAT_OFFSET";

export const MODAL_ENABLE = "MODAL_ENABLE";
export const MODAL_DISABLE = "MODAL_DISABLE";

export const LIVESHOW_TAB = "LIVESHOW_TAB";
export const LIVESHOW_TAB_REMOVE = "LIVESHOW_TAB_REMOVE";

export const ERROR = "ERROR";
export const ERRORS = "ERRORS";

export const ERROR_REMOVE = "ERROR_REMOVE";
export const ERRORS_REMOVE = "ERRORS_REMOVE";

export const MESSAGE = "MESSAGE";
export const MESSAGES = "MESSAGES";
export const MORE_MESSAGES = "MORE_MESSAGES";
export const MESSAGE_REMOVE = "MESSAGE_REMOVE";
export const MESSAGES_REMOVE = "MESSAGES_REMOVE";

export const FETCH_PRIVATE_CHATS = "FETCH_PRIVATE_CHATS";
export const FETCH_NOTIFICATIONS = "FETCH_NOTIFICATIONS";
export const FETCH_SESSION_REQUESTS = "FETCH_SESSION_REQUESTS";

export const GEOBLOCK_EXCEPTIONS = "GEOBLOCK_EXCEPTIONS";
export const GEOBLOCK_RESTRICTIONS = "GEOBLOCK_RESTRICTIONS";

export const GEOBLOCK_SETTINGS = "GEOBLOCK_SETTINGS";

export const POOLS = "POOLS";
export const POOL_UPDATE = "POOL_UPDATE";

export const GALLERIES = "GALLERIES";
export const GALLERY_UPDATE = "GALLERY_UPDATE";
export const GALLERY = "GALLERY";

export const PROMOS = "PROMOS";
export const PROMO = "PROMO";
export const DELETE_PROMO = "DELETE_PROMO";

export const EVENTS = "EVENTS";
export const MAGICS = "MAGICS";
export const FETCH_MAGICS = "FETCH_MAGICS";

export const FETCH_MAGICS_STATUS = "FETCH_MAGICS_STATUS";

export const SIDEBAR_HIDE = "SIDEBAR_HIDE";

export const TUBE = "TUBE";
export const TUBE_PAGINATION = "TUBE_PAGINATION";
export const TUBE_VIDEO = "TUBE_VIDEO";
export const TUBE_VIDEO_UPLOAD = "TUBE_VIDEO_UPLOAD";

export const MICROPHONES = "MICROPHONES";
export const MICROPHONE_UPDATE = "MICROPHONE_UPDATE";

export const WHX_EVENT = "WHX_EVENT";
export const WHX_EVENT_TASK_CREATE = "WHX_EVENT_TASK_CREATE";
export const WHX_EVENT_TASK_UPDATE = "WHX_EVENT_TASK_UPDATE";
export const WHX_EVENT_TASK_REMOVE = "WHX_EVENT_TASK_REMOVE";
export const WHX_EVENT_PARTICIPANT_UPDATE = "WHX_EVENT_PARTICIPANT_UPDATE";
