import * as React from "react";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import { useDispatch } from "react-redux";
import {
	TableContainer,
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell
} from "@mui/material";
import { Autocomplete } from "@mui/material";
import { TextField } from "@mui/material";
import { liveShowSchedule } from "../../redux/actions/liveshowAction";

export default function LiveSchedule(props) {
	const dispatch = useDispatch();
	const [selected, setSelected] = React.useState(true);
	function check() {
		setSelected(!selected);
	}

	const [selectedOptions, setSelectedOptions] = React.useState([]);
	const handleAutocompleteChange = (target) => {
		event.preventDefault();
		const array = addFormData;
		const obj = {
			roomId: target.target.value ? target.target.value.id : null,
			participants: target.target.performers,
			scheduleId: target.target.id
		};
		if (array.length == 0) {
			array.push(obj);
		} else {
			array.forEach(function (elem, arr) {
				if (elem.scheduleId == obj.scheduleId) {
					elem.participants = obj.participants;
					elem.roomId = obj.roomId;
				} else {
					if (array.some((u) => u.scheduleId === obj.scheduleId)) {
					} else {
						array.push(obj);
					}
				}
			});
		}
		setAddFormData(array);
	};
	const [addFormData, setAddFormData] = React.useState([]);
	function changeHandle(liveshowId) {
		const sendSchedule = addFormData.find((u) => u.scheduleId == liveshowId);
		if (sendSchedule == undefined) {
			alert("choose a room!");
		}
		if (sendSchedule.roomId == null) {
			alert("choose a room!");
		} else {
			dispatch(liveShowSchedule([sendSchedule]));
		}
	}
	return (
		<>
			<TableContainer
				className="SchrollBarShedule"
				component={Paper}
				style={{ overflow: "auto", marginRight: "40px" }}
				sx={{ maxHeight: "500px", marginBottom: "20px" }}
			>
				<Table aria-label="simple table" stickyHeader>
					<TableHead>
						<TableRow>
							<TableCell style={{ borderBottom: "none" }}>
								{props.name}
							</TableCell>
							<TableCell style={{ borderBottom: "none" }}></TableCell>
							<TableCell align="right" style={{ borderBottom: "none" }}>
								Move to LiveShow room
							</TableCell>
							<TableCell
								align="left"
								style={{ borderBottom: "none" }}
							></TableCell>
						</TableRow>
					</TableHead>
					<TableBody>
						{props.live &&
							props.live.map((row) => (
								<TableRow
									key={row.id}
									sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
									style={{ maxHeight: "20px" }}
								>
									{/*    {console.log(row)}
                    {console.log(schedule)} */}
									<TableCell style={{ borderBottom: "none" }}>
										{row.performers
											.map((performer) => performer.firstName)
											.join(", ")}
									</TableCell>
									<TableCell
										style={{ borderBottom: "none" }}
										className="TableCellTime"
									>
										{row.scheduledTime}
									</TableCell>
									<TableCell style={{ borderBottom: "none" }} align="right">
										<Autocomplete
											className="ScheduleInput"
											disablePortal
											id="combo-box-demo"
											onChange={(event, value) => {
												handleAutocompleteChange({
													target: {
														id: row.id,
														value: value,
														performers: row.performers
													}
												});
											}}
											options={props.rooms}
											getOptionLabel={(option) => option.name}
											renderInput={(params) => (
												<TextField {...params} disabled variant="standard" />
											)}
										/>
									</TableCell>
									<TableCell style={{ borderBottom: "none" }} align="left">
										<Button
											size="small"
											variant="contained"
											style={{ color: "white", backgroundColor: "#707070" }}
											className="create"
											onClick={() => changeHandle(row.id)}
										>
											Move
										</Button>
									</TableCell>
								</TableRow>
							))}
					</TableBody>
				</Table>
			</TableContainer>
		</>
	);
}
