import * as React from "react";
import { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import {
	CardActionArea,
	Dialog,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Select
} from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import {
	assignCameraName,
	changeCameraRecord,
	changeCameraSDN,
	changeCameraStatus
} from "../../redux/actions/cameraAction";
import { useDispatch, useSelector } from "react-redux";
import { Input, MenuItem } from "@material-ui/core";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { createTheme } from "@mui/material/styles";
import ReactHlsPlayer from "react-hls-player/dist";
import CloseIcon from "@mui/icons-material/Close";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: "fit-content",
	bgcolor: "background.paper",
	border: "2px solid #000",
	textAlign: "center",
	margin: "0 auto",
	p: 4
};
const theme = createTheme({
	palette: {
		success: {
			// Purple and green play nicely together.
			main: "#78F73B"
		},
		error: {
			// This is green.A700 as hex.
			main: "#F73B3B"
		},
		off: {
			main: "#800000"
		},
		on: {
			main: "#7CFC00"
		}
	}
});
export default function RoomCard({ camera, event_keys }) {
	const dispatch = useDispatch();
	const [open, setOpen] = React.useState(false);
	const mainVideoScreen = React.useRef();
	const [mute, setMute] = React.useState(true);
	const [volume, setVolume] = React.useState(50);
	const [prevent, setPrevent] = React.useState(true);
	let newHeight;
	const loading = useSelector((state) => state.loading);
	const handleOpen = () => {
		setOpen(true);
	};
	const handleClose = () => setOpen(false);

	const [addFormData, setAddFormData] = React.useState({});
	const [log, setLog] = React.useState("");
	const [check, setcheck] = React.useState(false);

	React.useEffect(() => {
		if (camera && camera.hls_url) {
			var fragmentExtension = ".ts";
			var originalOpen = XMLHttpRequest.prototype.open;
			const url = new URL(camera.hls_url);

			const queryParams = new URLSearchParams(url.search);
			const subscriberCode = queryParams.get("subscriberCode");
			const subscriberId = queryParams.get("subscriberId");

			window.XMLHttpRequest.prototype.open = function () {
				if (arguments[1].endsWith(fragmentExtension)) {
					arguments[1] =
						arguments[1] +
						"?" +
						`subscriberId=${subscriberId}&subscriberCode=${subscriberCode}`;
				}
				originalOpen.apply(this, arguments);
			};
		}
	}, [camera]);

	const [eventKeys, setEventKeys] = useState([]);

	useEffect(() => {
		// Map over the event_keys array and transform each value into an object with value and label properties
		const mappedEventKeys = event_keys?.map((key) => ({
			value: key,
			label: key // You can customize the label as needed
		}));
		// Prepend a default option with value null
		if (mappedEventKeys && mappedEventKeys?.length > 0) {
			const optionsWithDefaultNull = [...mappedEventKeys];
			setEventKeys(optionsWithDefaultNull);
		}
	}, [event_keys]);
	const [selected, setSelected] = React.useState(null);
	React.useEffect(() => {
		// Check if the camera object has a subscription_package
		if (camera && camera?.subscription_package) {
			// Use the index received from the backend to set the default value
			const index = camera.subscription_package;
			if (index >= 0 && index < eventKeys?.length) {
				setSelected(eventKeys[index]?.value);
			} else {
				// If index is out of bounds, set the default value to the first item
				setSelected(eventKeys[0]?.value);
			}
		} else {
			// If camera object or subscription_package is not present, set default value to null
			setSelected(eventKeys[0]?.value);
		}
	}, [camera, event_keys]); // Run effect whenever camera object changes

	const handleChangeCampaign = (event) => {
		const selectedIndex = eventKeys?.findIndex(
			(option) => option.value === event.target.value
		);

		setSelected(event.target.value);
		const newFormData = { ...addFormData };
		newFormData["subscription_package"] = selectedIndex;

		setAddFormData(newFormData);
		dispatch(changeCameraStatus(camera.id, newFormData));
	};
	function submitStatus(item, text) {
		setLog(item);
		const newFormData = { ...addFormData };
		if (item == 0) {
			if (text == "showInHome_logged") {
				camera.showInHome_logged = 1;
			} else {
				camera.showInHome_nonlogged = 1;
			}
			newFormData[text] = 1;
		} else {
			if (text == "showInHome_logged") {
				camera.showInHome_logged = 0;
				camera.showInHome_nonlogged = 0;
			} else {
				camera.showInHome_logged = 0;
				camera.showInHome_nonlogged = 0;
			}
			newFormData[text] = 0;
		}
		setAddFormData(camera);
		dispatch(changeCameraStatus(camera.id, camera));
		setcheck(true);
	}

	const [checked, setChecked] = React.useState("");

	const changeHandle = (event, value) => {
		//event.preventDefault();
		camera.xpass_required = value;
		setChecked(value);
		const newFormData = { ...addFormData };
		if (value == false) {
			newFormData["xpass_required"] = 0;
		} else {
			newFormData["xpass_required"] = 1;
		}
		setAddFormData(newFormData);
		dispatch(changeCameraStatus(camera.id, newFormData));
	};
	const [checkedLive, setCheckedLive] = React.useState("");
	const changeHandleLive = (event, value) => {
		//event.preventDefault();
		camera.is_live = value;
		setCheckedLive(value);
		const newFormData = { ...addFormData };
		if (value == false) {
			newFormData["is_live"] = 0;
		} else {
			newFormData["is_live"] = 1;
		}
		setAddFormData(newFormData);
		dispatch(changeCameraStatus(camera.id, newFormData));
	};
	const cardClass = ["cameraCard"];
	let badgeText = "";
	if (camera.showInHome_logged == 1) {
		cardClass.push("showInHome_logged");
		cardClass.filter((data) => data != "showInHome_nonlogged");
		badgeText = "Logged IN";
	}
	if (camera.showInHome_nonlogged == 1) {
		cardClass.push("showInHome_nonlogged");
		cardClass.filter((data) => data != "showInHome_logged");
		badgeText = "Logged OUT";
	}
	if (camera.showInHome_nonlogged == 1 && camera.showInHome_logged == 1) {
		badgeText = "Everywhere";
	}

	const [NameData, setNameData] = React.useState([]);

	const handleAddForm = (event) => {
		event.preventDefault();
		const fieldName = event.target.getAttribute("name");
		const fieldValue = event.target.value;
		const newNameData = { ...NameData };
		newNameData[fieldName] = fieldValue;
		setNameData(newNameData);
	};

	function changeHandleName(id) {
		let newLiveshow = dispatch(assignCameraName(NameData, id));
	}

	const [imageLoaded, setImageLoaded] = React.useState(false);
	const [SND, setSDN] = React.useState("");
	const [record, setRecord] = React.useState("");

	function changeHandleSDN(item) {
		item == true ? (camera.CDNisActive = false) : (camera.CDNisActive = true);
		setSDN(camera.CDNisActive);
		dispatch(changeCameraSDN(camera.id, item));
	}

	function changeHandleRecording(item) {
		item == true
			? (camera.recording_status = false)
			: (camera.recording_status = true);
		setRecord(camera.recording_status);
		dispatch(changeCameraRecord(camera.id));
	}

	const admin = useSelector((state) => state.admin);

	React.useEffect(() => {
		if (loading.isLoading == false && check == true) {
			handleClose();
			setcheck(false);
		}
	}, [loading]);
	const heartBeat = camera.recording_status == true ? "record" : "";

	const arr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];

	// var stockChart = new CanvasJS.StockChart("stockChartContainer", {
	//   // exportEnabled: true,

	//   charts: [
	//     {
	//       backgroundColor: "black",
	//       axisX: {
	//         crosshair: {
	//           enabled: true,
	//           snapToDataPoint: true,
	//           valueFormatString: "MMM YYYY",
	//         },
	//       },
	//       axisY: {
	//         crosshair: {
	//           enabled: true,
	//           snapToDataPoint: true,
	//           valueFormatString: "$#,###.00M",
	//         },
	//       },
	//       data: [
	//         {
	//           type: "splineArea",
	//           color: "#77F73B",
	//           xValueFormatString: "MMM YYYY",
	//           yValueFormatString: "$#,###.##M",
	//           dataPoints: dataPoints,
	//         },
	//       ],
	//     },
	//   ],
	//   // navigator: {
	//   //   slider: {
	//   //     minimum: new Date(2010, 00, 01),
	//   //     maximum: new Date(2018, 00, 01),
	//   //   },
	//   // },
	// });

	const [selectedImageVersion, setSelectedImageVersion] = React.useState(
		Date.now()
	);

	React.useEffect(() => {
		if (!open) {
			const interval = setInterval(
				() => setSelectedImageVersion(Date.now()),
				20000
			);
			return () => {
				clearInterval(interval);
			};
		}
	}, [open]);

	return (
		<Card className={cardClass.join(" ")}>
			<CardActionArea onClick={handleOpen}>
				<div className="badge">{badgeText}</div>
				{camera &&
				camera.type == "common" &&
				admin.user &&
				admin.user.email == "admin@yopmail.com" ? (
					<ReactHlsPlayer
						id="main_video"
						src="rtsp://admin:WhxXXx971!@84.50.88.9:2001/cam/realmonitor?channel=1&subtype=1"
						controls={false}
						style={{
							maxWidth: "140px",
							minWidth: "140px",
							width: "100%",
							height: "80px"
						}}
						muted={true}
						autoPlay
					/>
				) : (
					<CardMedia
						component="img"
						height="80"
						style={{
							maxWidth: "140px",
							minWidth: "140px",
							width: "100%",
							filter: !camera.is_live && "grayscale(100%)"
						}}
						image={camera && camera.thumbnail_url + "?" + selectedImageVersion}
						title={camera.camera_name}
					/>
				)}
				{/* {((camera && camera.type == 'common') && (admin.user && admin.user.role !== 'superadmin')) ? "BadgeText_common" : '' */}
				<Typography
					gutterBottom
					variant="h7"
					component="span"
					style={{
						color: camera?.xpass_required ? "yellow" : "white"
					}}
					className={
						camera && camera.type == "common" ? "BadgeText_common" : "BadgeText"
					}
				>
					{camera.name}
				</Typography>
				{/* ((camera && camera.type == 'common') && (admin.user && admin.user.role !== 'superadmin')) ? "CurrentOnline_common" :  */}
				<Typography
					gutterBottom
					variant="h7"
					component="span"
					className={
						camera && camera.type == "common" && admin.user
							? "CurrentOnline_common"
							: "CurrentOnline"
					}
					style={{ opacity: !camera.is_live && "1" }}
				>
					{camera.is_live ? (
						<VisibilityIcon fontSize="small" style={{ marginRight: "3px" }} />
					) : (
						<VisibilityOffIcon
							fontSize="small"
							style={{ marginRight: "3px", color: "red" }}
						/>
					)}{" "}
					{camera.viewers_count}
				</Typography>
				{/* ((camera && camera.type == 'common') && (admin.user && admin.user.role !== 'superadmin')) ? "CurrentOnline_common" :  */}
				<Box
					className={
						admin.user && camera.recording_status == true
							? "Recording_common"
							: "Recording"
					}
				>
					<Typography
						gutterBottom
						variant="h7"
						component="span"
						className={camera.recording_status == true ? "record" : ""}
						style={{
							color: "black",
							textTransform: "uppercase",
							marginBottom: "0px"
						}}
					>
						rec{/* {camera.viewers_count} */}
					</Typography>
				</Box>
			</CardActionArea>

			<Dialog
				className="pop-up-camera tips_button"
				open={open}
				onClose={handleClose}
			>
				{loading && loading.isLoading == true ? (
					<div className="loader-container">
						<div className="spinner"></div>
					</div>
				) : (
					""
				)}
				<DialogTitle style={{ display: "flex", justifyContent: "center" }}>
					<IconButton
						edge="start"
						color="inherit"
						onClick={handleClose}
						aria-label="close"
						style={{
							width: "fit-content",
							marginLeft: "15px",
							marginTop: "5px",
							position: "absolute",
							right: "10px",
							top: "2px"
						}}
					>
						<CloseIcon />
					</IconButton>
				</DialogTitle>

				<DialogContent style={{ padding: "0px" }}>
					<DialogContentText
						textAlign={"left"}
						sx={{ mb: 3 }}
						style={{ marginBottom: "0px" }}
					>
						<Box
							sx={{
								display: "flex",
								flexDirection: "column",
								alignItems: "flex-start",
								gap: "5px",
								width: "100%",
								marginBottom: "10px",
								background: "black",
								borderRadius: "10px"
							}}
							initial={{ opacity: 0, y: 40 }}
						>
							<Grid
								style={{
									display: "flex",
									flexDirection: "row",
									alignItems: "center",
									alignContent: "center",
									gap: "10px",
									padding: "5px",
									width: "100%"
								}}
							>
								<div
									style={{
										display: "flex",
										flexDirection: "row",
										alignItems: "center",
										alignContent: "center"
									}}
								>
									<ul
										className="ChatListElem_wrap_live"
										style={{ marginBottom: "5px" }}
									>
										<Input
											className="msg"
											name="name"
											defaultValue={camera.name}
											key={camera.name}
											disableUnderline="true"
											style={{ color: "#78F73B" }}
											onChange={handleAddForm}
										></Input>
									</ul>
									<Button
										size="small"
										variant="contained"
										type="submit"
										onClick={() => {
											changeHandleName(camera.id);
										}}
										style={{
											color: "black",
											backgroundColor: "#78F73B",
											maxWidth: "80px",
											maxHeight: "42px",
											minWidth: "80px",
											minHeight: "42px",
											borderRadius: "10px",
											marginLeft: "15px",
											marginRight: "15px",
											textTransform: "unset",
											fontSize: "1rem",
											fontWeight: "800"
										}}
										className="create"
									>
										Save
									</Button>
								</div>
								<Button
									fullWidth
									variant="outlined"
									sx={{ mt: 3, mb: 2 }}
									color="success"
									onClick={() => {
										submitStatus(camera.showInHome_logged, "showInHome_logged");
									}}
									style={{
										margin: "0",
										marginBottom: "0px",
										color: "#78F73B",
										borderColor: "#78F73B",
										whiteSpace: "nowrap",
										maxWidth: "200px"
									}}
								>
									Set as logged in home
								</Button>
								<Button
									fullWidth
									variant="outlined"
									sx={{ mt: 3, mb: 2 }}
									color="error"
									onClick={() => {
										submitStatus(
											camera.showInHome_nonlogged,
											"showInHome_nonlogged"
										);
									}}
									style={{
										margin: "0",
										marginBottom: "0px",
										color: "#FD0580",
										borderColor: "#FD0580",
										whiteSpace: "nowrap",
										maxWidth: "200px"
									}}
								>
									Set as logged-out home
								</Button>
								<FormControlLabel
									style={{
										color: "white",
										display: "flex",
										width: "fit-content",
										whiteSpace: "nowrap"
									}}
									control={
										<Checkbox
											name="is_live"
											value="allowExtraEmails"
											color="primary"
											style={{}}
											checked={camera.is_live ? true : false}
										/>
									}
									onChange={changeHandleLive}
									label="Is Live"
								/>
								{admin?.available_permissions?.includes(
									"panel_user_subscriptions"
								) &&
									eventKeys &&
									eventKeys?.length > 0 && (
										<Select
											className="PayoutSelect"
											style={{
												maxWidth: "200px",
												marginLeft: "auto"
											}}
											value={selected == null ? "Default camera" : selected}
											onChange={handleChangeCampaign}
											displayEmpty
											renderValue={(value) => {
												return (
													<Box sx={{ display: "flex", gap: 1 }}>
														<div
															style={{
																display: "flex",
																width: "-webkit-fill-available",
																justifyContent: "center"
															}}
														>
															{value}
														</div>
													</Box>
												);
											}}
										>
											{eventKeys?.map((option, index) => (
												<MenuItem
													style={{ textAlign: "center" }}
													key={index}
													value={option.value}
												>
													{option.label}
												</MenuItem>
											))}
										</Select>
									)}
								{admin?.available_permissions?.includes(
									"panel_user_subscriptions"
								) && (
									<FormControlLabel
										style={{
											color: "white",
											display: "flex",
											width: "fit-content",
											whiteSpace: "nowrap"
										}}
										control={
											<Checkbox
												name="xpass_required"
												value="allowExtraEmails"
												color="primary"
												checked={camera.xpass_required ? true : false}
											/>
										}
										onChange={changeHandle}
										label="XPASS REQUIRED"
									/>
								)}
							</Grid>
						</Box>
						<Grid
							style={{ display: "flex", position: "relative", width: "100%" }}
						>
							<ReactHlsPlayer
								id="main_video"
								src={camera.hls_url}
								controls={false}
								style={{
									width: "100%",
									aspectRatio: "16/9",
									borderRadius: "5px"
								}}
								muted={true}
								autoPlay
							/>
							<Button
								fullWidth
								disabled={loading?.isLoading == true}
								variant="contained"
								sx={{ mt: 3, mb: 2 }}
								onClick={() => {
									changeHandleRecording(camera.recording_status);
								}}
								style={{
									margin: "0",
									width: "fit-content",
									position: "absolute",
									top: "10px",
									left: "10px",
									backgroundColor: camera.recording_status ? "red" : "#78f73b",
									color: camera.recording_status ? "white" : "black"
									/*                       color: "#FD0580",
                                                      borderColor: "#FD0580", */
								}}
							>
								{camera.recording_status ? "Stop Recording" : "Start Recording"}
							</Button>
						</Grid>
						{/* <div
              id="stockChartContainer"
              style={{ width: "100%", height: "200px" }}
            ></div> */}
						{/* <div>
              <CanvasJSChart
                // ref={canvasRef}
                containerProps={{ width: "100%", height: "100px" }}
                options={options}
              />
            </div> */}
					</DialogContentText>
				</DialogContent>
			</Dialog>
		</Card>
	);
}
