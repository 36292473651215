import { ERROR, ERROR_REMOVE } from "../types";

export const errorReducer = (state = {}, action) => {
	const { type, payload } = action;

	switch (type) {
		case ERROR: {
			let { data } = payload;
			return data;
		}
		case ERROR_REMOVE: {
			return {};
		}
		default: {
			return state;
		}
	}
};
