import { MenuItem } from "@mui/material";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import ArrowDropUpRoundedIcon from "@mui/icons-material/ArrowDropUpRounded";
import Button from "@mui/material/Button";
import { useNavigate } from "react-router-dom";

function SidebarSection({ item, isOpen, onToggle, permissions }) {
	let navigate = useNavigate();
	return (
		<div style={{ width: "100%" }}>
			<Button
				className="Menu_button"
				aria-controls={isOpen ? "fade-menu" : undefined}
				aria-haspopup="true"
				aria-expanded={isOpen ? "true" : undefined}
				onClick={onToggle}
				endIcon={
					isOpen ? (
						<ArrowDropUpRoundedIcon
							style={{ color: "white", width: "2em", height: "2em" }}
						/>
					) : (
						<ArrowDropDownRoundedIcon
							style={{ color: "white", width: "2em", height: "2em" }}
						/>
					)
				}
				style={{
					color: isOpen && "#77f73b",
					backgroundColor: isOpen && "#2E2E2E"
				}}
			>
				{item?.name}
			</Button>
			{isOpen &&
				item?.childrens?.map(
					(link) =>
						permissions?.includes(link?.permission) && (
							<MenuItem
								className={`sidebar_menu_item ${"/" + link?.link === window.location.pathname && "menu_active"}`}
								onClick={() => navigate("/" + link?.link)}
							>
								<span
									style={{
										width: "50%",
										display: "flex",
										justifyContent: "flex-start"
									}}
								>
									{link?.name}
								</span>
							</MenuItem>
						)
				)}
		</div>
	);
}
export default SidebarSection;
