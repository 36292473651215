import React, { useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Sidebar from "../Sidebar/Sidebar";
import AllCameras from "../AllCameras/AllCameras";
import ManageLiveShows from "../ManageLiveShows/ManageLiveShows";
import Earnings from "../Earnings/Earnings";
import Users from "../Users/Users";
import GirlManagement from "../GirlManagement/GirlManagement";
import ModelProfile from "../ModelProfile/ModelProfile";
import "../../App.scss";
import DashBoard from "../DashBoard/DashBoard";
import ChartModeration from "../ChatModeration/ChatModeration";
import Geoblock from "../Geoblock/Geoblock";
import UserProfile from "../UserProfile/UserProfile";
import { connect, useDispatch } from "react-redux";
import history from "../../redux/history";
import AddModelProfile from "../AddModelProfile/AddModelProfile";
import AddUserProfile from "../AddUserProfile/AddUserProfile";
import { removeChats } from "../../redux/actions/chatActions";
import Transactions from "../Transactions/Transactions";
import MuiAlert from "@mui/material/Alert";
import {
	removeMessage,
	removeMessages
} from "../../redux/reducers/rootReducer";
import TransactionDetails from "../TransactionDetails/TransactionDetails";
import ModelEarnings from "../ModelEarnings/ModelEarnings";
import Pools from "../Pools/Pools";
import Blogs from "../Blogs/Blogs";
import Blog from "../Blog/Blog";
import AddBlog from "../AddBlog/AddBlog";
import Promos from "../Promos/Promos";
import Promo from "../Promos/Promo";
import Events from "../Events/Events";
import Galleries from "../Galleries/Galleries";
import EditGallery from "../Galleries/EditGallery";
import MagicX from "../Events/EventsMagic";
import CamerasActivity from "../AllCameras/CamerasActivity";
import Tube from "../Tube/Tube";
import Microphones from "../Microphones/Microphones";
import Stud from "../StudX/Stud";
import Queen from "../QueenX/Queen";
import Milf from "../MilfX/Milf";

const mapStateToProps = (state) => {
	return {
		admin: state.admin,
		error: state.error,
		message: state.message
	};
};

const Alert = React.forwardRef(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

function MainContainer({ admin, message }) {
	const location = useLocation();
	const navigate = useNavigate();
	const dispatch = useDispatch();

	const handleClose = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}
		dispatch(removeMessage());
	};

	const handleExited = () => {
		dispatch(removeMessage());
	};

	useEffect(() => {
		if (location.pathname == "/login") {
			navigate("/dashBoard");
		}
	}, []);

	if (location.pathname !== "/chatModeration") {
		dispatch(removeChats());
		dispatch(removeMessages());
	}

	let alertClass = ["alert-whx"];

	if (message && message.type == "success") {
		alertClass.push("success");
		alertClass.filter((data) => data != "error");
	}

	if (message && message.type == "error") {
		alertClass.push("error");
		alertClass.filter((data) => data != "success");
	}
	return (
		<div className="maincontainer">
			<Sidebar admin={admin} />
			<Routes history={history}>
				<Route path="/" element={<DashBoard admin={admin} />} />
				<Route path="/dashBoard" element={<DashBoard admin={admin} />} />
				<Route path="*" element={<DashBoard admin={admin} />} />

				{admin?.available_permissions?.includes("panel_girl_management") && (
					<>
						<Route
							path="/girlManagement"
							element={<GirlManagement admin={admin} />}
						/>
						<Route path="/addModelProfile" element={<AddModelProfile />} />
						<Route
							path="/modelProfile/:id"
							element={<ModelProfile admin={admin} />}
						/>
					</>
				)}
				{admin?.available_permissions?.includes("panel_liveshow") && (
					<Route
						path="/liveShows"
						element={<ManageLiveShows admin={admin} />}
					/>
				)}

				{admin?.available_permissions?.includes("panel_cameras") && (
					<>
						<Route path="/cameras" element={<AllCameras admin={admin} />} />
					</>
				)}
				{admin?.available_permissions?.includes("panel_special_events") && (
					<>
						<Route
							path="/microphones"
							element={<Microphones admin={admin} />}
						/>
						<Route path="/stud-x" element={<Stud admin={admin} />} />

						<Route path="/queen-x" element={<Queen admin={admin} />} />
						<Route path="/milf-x" element={<Milf admin={admin} />} />
					</>
				)}

				{admin?.available_permissions?.includes("panel_cameras_activity") && (
					<Route
						path="/cameras_activity"
						element={<CamerasActivity admin={admin} />}
					/>
				)}

				{admin?.available_permissions?.includes("panel_chat_moderation") && (
					<Route
						path="/chatModeration"
						element={<ChartModeration admin={admin} />}
					/>
				)}

				{admin?.available_permissions?.includes("panel_earnings") && (
					<>
						<Route
							path="/modelEarnings/:id"
							element={<ModelEarnings admin={admin} />}
						/>
						<Route path="/earnings" element={<Earnings admin={admin} />} />
					</>
				)}
				{admin?.available_permissions?.includes("panel_transactions") && (
					<>
						<Route
							path="/transactions"
							element={<Transactions admin={admin} />}
						/>
						<Route
							path="/transactionDetails/:id"
							element={<TransactionDetails admin={admin} />}
						/>
					</>
				)}

				{admin?.available_permissions?.includes("panel_users") && (
					<>
						<Route path="/users" element={<Users admin={admin} />} />
						<Route
							path="/userProfile/:id"
							element={<UserProfile admin={admin} />}
						/>
						<Route
							path="/addUserProfile"
							element={<AddUserProfile admin={admin} />}
						/>
					</>
				)}

				{admin?.available_permissions?.includes("panel_geoblock") && (
					<Route path="/geoBlock" element={<Geoblock admin={admin} />} />
				)}

				{admin?.available_permissions?.includes("panel_tipping_pools") && (
					<Route path="/pools" element={<Pools admin={admin} />} />
				)}

				{admin?.available_permissions?.includes("panel_blogs") && (
					<>
						<Route path="/blogs" element={<Blogs admin={admin} />} />
						<Route path="/blog/:slug" element={<Blog admin={admin} />} />
						<Route path="/addBlog" element={<AddBlog admin={admin} />} />
					</>
				)}
				{admin?.available_permissions?.includes("panel_promos") && (
					<>
						<Route path="/promos" element={<Promos admin={admin} />} />
						<Route path="/promo/:id" element={<Promo admin={admin} />} />
					</>
				)}
				{admin?.available_permissions?.includes("panel_events_schedule") && (
					<Route path="/events_schedule" element={<Events admin={admin} />} />
				)}
				{admin?.available_permissions?.includes("panel_events_magicx") && (
					<Route path="/events_magic" element={<MagicX admin={admin} />} />
				)}

				{admin?.available_permissions?.includes("panel_galleries") && (
					<>
						<Route path="/galleries" element={<Galleries admin={admin} />} />
						<Route
							path="/gallery/:id"
							element={<EditGallery admin={admin} />}
						/>
					</>
				)}
				{admin?.available_permissions?.includes("panel_tube") && (
					<Route path="/tube" element={<Tube />} />
				)}
			</Routes>
		</div>
	);
}

export default connect(mapStateToProps, null)(MainContainer);
