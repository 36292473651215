import * as React from "react";
import Box from "@mui/material/Box";
import {
	Avatar,
	Button,
	Grid,
	Grow,
	ListItem,
	Typography
} from "@mui/material";

import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { DeleteBlogComment } from "../../redux/actions/blogActions";

function CommentMessage({ user, comment, blogSlug }) {
	const [checked, setChecked] = React.useState(false);
	const elem = React.useRef();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [loaded, setLoaded] = React.useState(false);
	const [imageLoaded, setImageLoaded] = React.useState(false);
	//const messages = useSelector((state) => state.messages);
	React.useEffect(() => {
		if (comment?.description === "") {
			return;
		}
		setTimeout(() => {
			setChecked(true);
		}, 30);
	}, []);

	const [msgClasses, setMsgClasses] = React.useState("NewCommentListElem_wrap");

	if (!comment) {
		return;
	}

	const handleMenu = (e) => {
		e.preventDefault();
		setOpenMenu(true);
		setAnchorElMenu(e.currentTarget);
	};

	return (
		<Box className={"CommentMessage" + " user"} data-googleoff="index">
			<ListItem
				ref={elem}
				key={comment && comment.id}
				id={comment && comment.id}
				className={"ChatListElem"}
				data-nosnippet="true"
			>
				<Grow
					timeout={370}
					delay={200}
					direction={"left"}
					in={checked}
					mountOnEnter
					unmountOnExit
				>
					<Box className={msgClasses}>
						<Box
							onClick={(e) => handleMenu(e)}
							className={
								"flex items-center ImgPlace" +
								(comment?.author?.is_premium
									? " border-anim-rotate-rainbow"
									: "")
							}
							sx={{
								gap: "8px",
								mb: "auto",
								marginLeft: window.innerWidth < 900 ? "5px" : ""
							}}
						>
							<Box
								style={{
									width: window.innerWidth < 900 ? "35px" : "40px",
									height: window.innerWidth < 900 ? "35px" : "40px",
									zIndex: 1
								}}
							>
								<Avatar
									className={
										"smooth-image image-" +
											(imageLoaded ? "visible" : "hidden") +
											comment !==
											null && comment?.vip
											? "img vip"
											: "img"
									}
									src={comment?.author?.smallAvatar}
									alt=""
									style={{
										borderRadius: "50% !important",
										width: "40px",
										height: "40px",
										cursor:
											user?.role === "model" &&
											comment?.author?.role &&
											comment?.author?.role !== "model" &&
											"pointer"
									}}
									onLoad={() => setImageLoaded(true)}
									loading="lazy"
								/>
							</Box>
						</Box>

						<Box
							id={"message_wrap_" + comment.id}
							className="message_wrap"
							style={{
								width: "100%",
								opacity: comment?.deleted_at?.length > 0 && "0.5"
							}}
						>
							<Box
								style={{ display: "flex", alignItems: "center", gap: "16px" }}
							>
								<Typography fontSize="0.85rem" className="username">
									{comment?.author?.username}
								</Typography>

								<Grid style={{ display: "flex", alignItems: "center" }}>
									<Typography
										variant="body2"
										className="post_content"
										style={{
											display: "flex",
											alignItems: "center",
											gap: "5px",
											fontSize: "1rem",
											color: "#77F73B"
										}}
									>
										{comment?.reactions_count > 0
											? comment?.reactions_count
											: "0"}{" "}
										{"likes"}
									</Typography>
								</Grid>
								<Button
									fontSize="0.85rem"
									onClick={() => {
										dispatch(DeleteBlogComment(blogSlug, comment?.id));
									}}
									disabled={comment?.deleted_at?.length > 0 && true}
									style={{
										color: "black",
										backgroundColor: "red",
										textTransform: "none",
										borderRadius: "10px",
										marginLeft: "10px"
									}}
								>
									{comment?.deleted_at?.length > 0 ? "Deleted" : "Delete"}
								</Button>
							</Box>
							<Box
								id={"message_" + comment?.id}
								className="msg"
								data-message={comment?.description}
								data-id={comment?.id}
							>
								<Typography fontSize="0.85rem" color="white">
									{comment?.description}
								</Typography>
							</Box>
						</Box>
						{/* )} */}
					</Box>
				</Grow>
			</ListItem>
		</Box>
	);
}

export default CommentMessage;
