import * as React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { Box, Container, Grid, Typography } from "@mui/material";
import LiveSchedule from "../LiveSchedule/LiveShedule";
import Button from "@mui/material/Button";
import { ThemeProvider } from "@mui/material/styles";
import { connect, useDispatch, useSelector } from "react-redux";
import {
	assignLiveShow,
	assignLiveShowName,
	getLiveshows,
	stopLiveShow
} from "../../redux/actions/liveshowAction";
import { Input } from "@material-ui/core";
import CheckIcon from "@mui/icons-material/Check";
import { setMessage } from "../../redux/reducers/rootReducer";
import moment from "moment";

const mapStateToProps = (state) => {
	return {
		liveshows: state.liveshows,
		liveshowShedule: state.liveshowShedule,
		liveshow: state.liveshow
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		setMessage: (data, type) => dispatch(setMessage(data, type))
	};
};

function Liveshows({ liveshows, liveshowShedule, theme, liveshowType }) {
	const dispatch = useDispatch();
	let onlyCouple = liveshowType === "couples_liveshows";
	React.useEffect(() => {
		dispatch(getLiveshows(onlyCouple));
	}, []);

	React.useEffect(() => {
		let initialForm = [];
		liveshows &&
			liveshows.rooms &&
			liveshows.rooms.map((liveshow) => {
				let participants_array = [];
				if (liveshow.performers && liveshow.performers.length > 0) {
					participants_array = liveshow.performers.map(function (performer) {
						return performer.id;
					});
				}

				const initialObject = {
					roomId: liveshow.id,
					participants: participants_array
				};

				initialForm.push(initialObject);
			});
		setAddFormData(initialForm);
	}, [liveshows]);

	const [addFormData, setAddFormData] = React.useState([]);
	const [showResults, setShowResults] = React.useState({
		show: false,
		id: ""
	});

	const handleAutocompleteChange = (target) => {
		var task_names = target.target.value.map(function (task, index, array) {
			return task.id;
		});

		const array = addFormData;

		const obj = {
			roomId: target.target.id,
			participants: task_names,
			schedule: target.target.schedule ? target.target.schedule : null
		};

		if (array.length == 0) {
			array.push(obj);
		} else {
			array.forEach(function (elem, arr) {
				if (elem.roomId == obj.roomId) {
					elem.participants = obj.participants;
				} else {
					if (array.some((u) => u.roomId === obj.roomId)) {
					} else {
						array.push(obj);
					}
				}
			});
		}
		setAddFormData(array);
		if (target.target.is_live == 1) {
			setShowResults({
				show: true,
				id: target.target.id
			});
		}
	};

	function update(id, onlyCouple = false) {
		const sendGirls = addFormData.find((u) => u.roomId == id);
		const newArr = addFormData.filter((x) => x.roomId != id);
		setAddFormData(newArr);
		dispatch(assignLiveShowName(sendGirls, id, onlyCouple));
		setShowResults(false);
	}

	function changeHandle(item, id, onlyCouple = false) {
		if (item == 1) {
			dispatch(stopLiveShow(id, onlyCouple));
		} else {
			const sendGirls = addFormData.find((u) => u.roomId == id);
			if (sendGirls == undefined || sendGirls.length == 0) {
				let form = {
					roomId: id,
					schedule: null,
					participants: []
				};
				dispatch(assignLiveShow(form, id, onlyCouple));
			} else {
				const newArr = addFormData.filter((x) => x.roomId != id);
				setAddFormData(newArr);
				dispatch(assignLiveShow(sendGirls, id, onlyCouple));
				return true;
			}
		}
	}

	React.useEffect(() => {
		liveshows &&
			liveshows.rooms &&
			liveshows.rooms.forEach(function (elem, arr) {
				if (liveshowShedule.length == 0) {
					return;
				}
				if (elem.id == liveshowShedule[0].roomId) {
					elem.performers = liveshowShedule[0].participants;
					handleAutocompleteChange({
						target: {
							id: liveshowShedule[0].roomId,
							value: liveshowShedule[0].participants,
							schedule: liveshowShedule[0].scheduleId
						}
					});
				}
			});
	}, [liveshowShedule]);

	const [NameData, setNameData] = React.useState([]);
	const handleAddForm = (target) => {
		event.preventDefault();
		const fieldName = event.target.getAttribute("name");
		const fieldValue = event.target.value;
		const newNameData = { ...NameData };
		newNameData[fieldName] = fieldValue;
		const array = NameData;
		const obj = {
			roomId: target.target.id,
			name: newNameData.name
		};
		if (array.length == 0) {
			array.push(obj);
		} else {
			array.forEach(function (elem, arr) {
				if (elem.roomId == obj.roomId) {
					elem.name = obj.name;
				} else {
					if (array.some((u) => u.roomId === obj.roomId)) {
					} else {
						array.push(obj);
					}
				}
			});
		}
		setNameData(array);
	};

	function changeHandleName(id, onlyCouple = false) {
		const sendGirls = NameData.find((u) => u.roomId == id);
		const newArr = NameData.filter((x) => x.roomId != id);
		setNameData(newArr);
		dispatch(assignLiveShowName(sendGirls, id, onlyCouple));
	}

	const [time, setTime] = React.useState(Date.now());

	React.useEffect(() => {
		const interval = setInterval(() => setTime(Date.now()), 1000);
		return () => {
			clearInterval(interval);
		};
	}, []);

	function getTimeStamp(time, dateCreated) {
		const startDate = moment(dateCreated * 1000);
		const timeEnd = moment(time);
		const diff = timeEnd.diff(startDate);
		var diffDuration = moment.duration(diff);
		const hours = diffDuration.hours(); // get hours with getHours method
		const minutes = diffDuration.minutes(); // get minutes with getMinutes method
		const seconds = diffDuration.seconds(); // get seconds with getSeconds method
		const timeString = `${pad(hours)}:${pad(minutes)}:${pad(seconds)}`;
		return timeString; // finally, join to a time string
	}

	function pad(num) {
		return ("0" + num).slice(-2);
	}

	const loading = useSelector((state) => state.loading);

	return (
		<>
			{loading && loading.isLoading == true ? (
				<div className="loader-container">
					<div className="spinner"></div>
				</div>
			) : (
				""
			)}
			{liveshows &&
				liveshows.rooms &&
				liveshows.rooms.map((liveshow) => (
					<Container
						style={{ margin: "0", padding: "0", minWidth: "800px" }}
						className="InputContainer"
					>
						<Box sx={{ bgcolor: "transparent", paddingRight: "10px" }} md={3}>
							<ul className="ChatListElem_wrap_live">
								<Input
									className="msg"
									name="name"
									defaultValue={
										typeof liveshow.name === "object"
											? liveshow?.name?.en
											: liveshow.name
									}
									key={
										typeof liveshow.name === "object"
											? liveshow?.name?.en
											: liveshow.name
									}
									disableUnderline="true"
									onChange={() => {
										handleAddForm({
											target: { id: liveshow.id }
										});
									}}
								></Input>
							</ul>
						</Box>
						<Box sx={{ bgcolor: "transparent", paddingRight: "10px" }} md={3}>
							<Button
								size="small"
								variant="contained"
								type="submit"
								className="btn btn-primary create"
								onClick={() => {
									changeHandleName(liveshow.id, onlyCouple);
								}}
								sx={{
									color: "black",
									backgroundColor: "#78F73B",
									maxWidth: "40px",
									maxHeight: "23px",
									minWidth: "40px",
									minHeight: "23px",
									borderRadius: "4px",
									marginLeft: "15px",
									marginRight: "15px",
									textTransform: "unset"
								}}
								startIcon={
									<CheckIcon style={{ paddingLeft: "10px", width: "1.4em" }} />
								}
							></Button>
						</Box>
						<Autocomplete
							className="LazyInput"
							multiple
							name="participants"
							key={liveshow.name}
							id="id"
							onChange={(event, value) => {
								handleAutocompleteChange({
									target: {
										id: liveshow.id,
										value: value,
										is_live: liveshow.is_live
									}
								});
							}}
							options={liveshows.models ? liveshows.models : []}
							defaultValue={liveshow.performers && liveshow.performers}
							key={liveshow.performers && liveshow.performers}
							getOptionLabel={(option) => option.inHouseName || ""}
							renderInput={(params) => (
								<TextField
									{...params}
									InputProps={{ ...params.InputProps, disableUnderline: true }}
									variant="standard"
									name="participants"
								/>
							)}
						/>

						<Box
							sx={{
								bgcolor: "transparent",
								paddingLeft: "15px",
								marginTop: "5px",
								minWidth: "40px"
							}}
							md={3}
						>
							<ThemeProvider theme={theme}>
								<Button
									id={liveshow.id}
									size="small"
									variant="outlined"
									/* color="success" */
									style={{ paddingLeft: "10px", maxWidth: "50px" }}
									onClick={() => {
										changeHandle(liveshow.is_live, liveshow.id, onlyCouple);
									}}
									color={liveshow.is_live == 1 ? "error" : "success"}
									defaultValue={liveshow.is_live == 1 ? "error" : "success"}
									key={liveshow.is_live == 1 ? "error" : "success"}
								>
									{liveshow.is_live == 1 ? "LogOut" : "Assign"}
								</Button>
							</ThemeProvider>
						</Box>
						<Box
							sx={{
								bgcolor: "transparent",
								paddingLeft: "15px",
								marginTop: "5px",
								minWidth: "40px"
							}}
							md={3}
						>
							<Typography
								style={{
									color: "white",
									paddingLeft: "30px",
									maxWidth: "50px"
								}}
							>
								{liveshow.liveshow &&
									getTimeStamp(time, liveshow.liveshow.startedAt)}
							</Typography>
						</Box>
						<Box
							sx={{
								bgcolor: "transparent",
								paddingLeft: "25px",
								marginTop: "5px",
								marginLeft: "20px",
								minWidth: "40px",
								position: "relative"
							}}
							md={3}
						>
							{showResults.show &&
							showResults.id == liveshow.id &&
							liveshow.is_live == 1 ? (
								<Button
									id={liveshow.id}
									size="small"
									variant="outlined"
									/* color="success" */
									style={{
										maxWidth: "50px",
										marginLeft: "20px"
									}}
									onClick={() => {
										update(liveshow.id, onlyCouple);
									}}
								>
									{"Update"}
								</Button>
							) : null}
						</Box>
					</Container>
				))}

			<Box>
				<Grid container spacing={0}>
					<Grid item xs={4} md={2} spacing={0} className="LiveShow">
						<Typography
							variant="h4"
							gutterBottom
							component="div"
							style={{ color: "white" }}
						>
							Live Show Schedules
						</Typography>
					</Grid>
				</Grid>
			</Box>
			<Container
				style={{
					margin: "0",
					padding: "0",
					minWidth: "1200px",
					display: "flex",
					alignItems: "flex-start"
				}}
			>
				<LiveSchedule
					name="Today"
					live={
						liveshows.scheduled_liveshows && liveshows.scheduled_liveshows.today
					}
					rooms={liveshows && liveshows.rooms}
				/>
				<LiveSchedule
					name="Tomorrow"
					live={
						liveshows.scheduled_liveshows &&
						liveshows.scheduled_liveshows.tomorrow
					}
					rooms={liveshows && liveshows.rooms}
				/>
			</Container>
		</>
	);
}

export default connect(mapStateToProps, mapDispatchToProps)(Liveshows);
