import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import "../../App";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useNavigate, useParams } from "react-router-dom";
import { Container, Input } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { connect, useDispatch, useSelector } from "react-redux";
import Skeleton from "@mui/material/Skeleton";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import {
	delete_Blog,
	deleteBlog,
	getBlog,
	getBlogComments,
	getBlogs,
	updateBlog
} from "../../redux/actions/blogActions";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { TagsInput } from "react-tag-input-component";
import "./CustomQuillStyles.css";
import { useSnackbar } from "notistack";
import { removeErrors } from "../../redux/reducers/rootReducer";
import BlogComments from "./BlogComments";
import useVaporUpload from "../Upload/VaporUpload";

const mapStateToProps = (state) => {
	return {
		blogObject: state.blog,
		blog_comments: state.blog_comments
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		getBlog: (slug) => dispatch(getBlog(slug)),
		removeBlog: (slug) => dispatch(delete_Blog(slug))
	};
};

function Blog({ getBlog, removeBlog, blogObject, blog_comments }) {
	const dispatch = useDispatch();
	const param = useParams();
	const navigate = useNavigate();
	React.useEffect(() => {
		removeBlog(param.slug);
		getBlog(param.slug);
		dispatch(getBlogComments(param.slug));
	}, []);
	const [blog, setBlock] = React.useState(blogObject && blogObject.blog);
	React.useEffect(() => {
		setBlock(blogObject && blogObject.blog);
	}, [blogObject]);

	const [selected, setSelected] = React.useState(blog && blog.tags);
	const [check, setCheck] = React.useState(false);

	React.useEffect(() => {
		setCheck(true);
		setSelected(blog?.tags);
		setCheck(false);
	}, [blog?.tags]);

	const [addFormData, setAddFormData] = React.useState({
		title: "",
		description: "",
		is_visible: "",
		createdAt: "",
		tags: "",
		content: ""
	});

	React.useEffect(() => {
		setAddFormData({
			title: blog && blog.title,
			description: blog && blog.description,
			is_visible: blog && blog.is_visible,
			createdAt: blog && blog.createdAt,
			tags: blog && blog.tags,
			content: blog && blog.content
		});
	}, [blog]);

	const handleAddForm = (event) => {
		event.preventDefault();
		const fieldName = event.target.getAttribute("name");
		const fieldValue = event.target.value;
		const newFormData = { ...addFormData };
		newFormData[fieldName] = fieldValue;
		setAddFormData(newFormData);
	};

	const handleDeleteSubmit = (id) => {
		dispatch(deleteBlog(param.slug));
		navigate("/blogs");
		dispatch(getBlogs());
	};

	React.useEffect(() => {
		setImageUrl(blog && blog.image_url);
	}, [blog]);

	const [selectedImage, setSelectedImage] = React.useState(null);
	const [imageUrl, setImageUrl] = React.useState(null);

	const { uploadFile, uploadProgress } = useVaporUpload();
	React.useEffect(() => {
		if (selectedImage) {
			const updateImage = async () => {
				setImageUrl(URL.createObjectURL(selectedImage));
				const newFormData = { ...addFormData };
				const { vaporResponse } = await uploadFile(selectedImage);
				newFormData["image"] = vaporResponse.key;

				setAddFormData(newFormData);
			};
			updateImage();
		}
	}, [selectedImage]);

	function returnToUsers(event) {
		event.preventDefault();
		navigate("/blogs");
	}

	const [checked, setChecked] = React.useState("");

	const changeHandle = (event, value) => {
		blog.is_visible = value;
		setChecked(value);
		const newFormData = { ...addFormData };
		if (value == false) {
			newFormData["is_visible"] = 0;
		} else {
			newFormData["is_visible"] = 1;
		}
		setAddFormData(newFormData);
	};

	const [blogCategoryId, setBlogCategoryId] = React.useState(
		blog && blog.blog_category_id
	);

	React.useEffect(() => {
		setBlogCategoryId(blog?.blog_category_id);
	}, [blog]);
	const handleAutocompleteChange = (event, value) => {
		event.preventDefault();
		setBlogCategoryId(value.id);
	};

	const handleAddFormSubmit = (event) => {
		event.preventDefault();
		addFormData["tags"] = selected;
		addFormData["content"] = value;
		addFormData["blog_category_id"] = blogCategoryId;
		dispatch(updateBlog(addFormData, param.slug));
		// navigate("/blogs");
	};

	const [value, setValue] = React.useState(blog && blog?.content);

	React.useEffect(() => {
		if (blog && blog?.content) {
			setValue(blog?.content);
		}
	}, [blog?.content]);

	const modules = {
		toolbar: [
			// [{ font: [] }],
			[{ header: [1, 2, 3, 4, 5, 6, false] }],
			["bold", "italic", "underline", "strike"],
			[{ color: [] }, { background: [] }],
			[{ list: "ordered" }, { list: "bullet" }],
			[{ indent: "-1" }, { indent: "+1" }],
			["link", "image", "video"],
			["clean"]
		]
	};

	const { enqueueSnackbar } = useSnackbar();
	const errorsSubmit = useSelector((state) => state.errors);
	React.useEffect(() => {
		if (errorsSubmit && errorsSubmit.slug) {
			enqueueSnackbar(errorsSubmit.slug[0], { variant: "error" });
		}
		if (errorsSubmit && errorsSubmit.title) {
			enqueueSnackbar(errorsSubmit.title[0], { variant: "error" });
		}
		if (errorsSubmit && errorsSubmit.description) {
			enqueueSnackbar(errorsSubmit.description[0], { variant: "error" });
		}
		if (errorsSubmit && errorsSubmit.blog_category_id) {
			enqueueSnackbar(errorsSubmit.blog_category_id[0], {
				variant: "error"
			});
		}
		setTimeout(() => {
			dispatch(removeErrors());
		}, 5000);
	}, [errorsSubmit]);

	const loading = useSelector((state) => state.loading);
	return (
		<>
			<Grid
				sx={{
					flexGrow: 1,
					// maxWidth: "900px",
					margin: "0",
					height: "100%",
					minHeight: "1000px",
					marginLeft: "290px"
				}}
			>
				<Grid container spacing={0}>
					<Grid item xs={4} md={2} spacing={0} className="LiveShow">
						{!blog ? (
							<Skeleton width={330} height={55} />
						) : (
							<Typography
								variant="h4"
								gutterBottom
								component="div"
								style={{ color: "white" }}
							>
								{blog && blog.title}
							</Typography>
						)}
					</Grid>
				</Grid>
				<Button
					startIcon={<ArrowBackIosIcon />}
					className="ModelProfilePage_arrow"
					variant="outlined"
					onClick={returnToUsers}
				>
					All Blogs
				</Button>
				<Grid
					style={{
						display: "flex",
						flexDirection: window.innerWidth < 1600 ? "column" : "row",
						gap: "20px",
						width: "100%"
					}}
				>
					<Grid style={{ width: "50%" }}>
						<Container
							maxWidth="xl"
							style={{
								color: "#ffffff",
								width: "100%",
								height: "100%",
								backgroundColor: "#000000",
								position: "relative",
								marginLeft: "unset"
							}}
							sx={{ my: 3 }}
							className="ProvileContainer"
						>
							{loading && loading.isLoading == true ? (
								<div className="loader-container">
									<div className="spinner"></div>
								</div>
							) : (
								""
							)}
							<Grid spacing={1} className="ModelProfilePage_wrap">
								<Grid item xs={6} md={4}>
									<Box component="div" className="ModelProfilePage_main">
										<Stack
											direction="row"
											className="ModelProfilePage_row"
											spacing={1}
											style={{ flexGrow: "0.6" }}
											style={{
												alignItems: "baseline"
											}}
										>
											<Typography
												gutterBottom
												component="div"
												className="Blog_elements"
											>
												Blog Image
											</Typography>
											<Box
												style={{
													display: "flex",
													flexDirection: "column",
													alignItems: "flex-start",
													width: "100%"
												}}
											>
												<div
													style={{
														display: "flex",
														flexDirection: "column",
														alignItems: "center"
													}}
												>
													<Box
														mt={2}
														textAlign="center"
														style={{
															width: "200px",
															height: "100px",
															backgroundColor: "rgb(41, 41, 41)",
															borderRadius: "5px"
														}}
													>
														{imageUrl && (
															<img
																src={imageUrl}
																height="100px"
																width="200px"
																accept="image/x-png,image/gif,image/jpeg"
																style={{ borderRadius: "5px" }}
															/>
														)}
													</Box>

													<input
														accept="image/*"
														style={{ display: "none" }}
														id="select-image"
														multiple
														type="file"
														name="myFiles"
														onChange={(e) => {
															setSelectedImage(e.target.files[0]);
														}}
													/>
													<label
														htmlFor="select-image"
														style={{ marginTop: "10px" }}
													>
														<Button variant="raised" component="span">
															Upload
														</Button>
													</label>
												</div>
											</Box>
										</Stack>
										<Stack
											direction="row"
											className="ModelProfilePage_row"
											spacing={1}
											style={{
												alignItems: "baseline"
											}}
										>
											<Typography
												gutterBottom
												component="div"
												className="Blog_elements"
											>
												title
											</Typography>
											<Input
												className="inputBoxProfile"
												name="title"
												defaultValue={blog && blog.title}
												key={blog && blog.title}
												disableUnderline="true"
												style={{ backgroundColor: "#292929" }}
												onChange={handleAddForm}
											></Input>
										</Stack>
										<Stack
											direction="row"
											className="ModelProfilePage_row"
											spacing={1}
											style={{
												alignItems: "baseline"
											}}
										>
											<Typography
												gutterBottom
												component="div"
												className="Blog_elements"
											>
												description
											</Typography>
											<Input
												className="inputBoxProfile"
												name="description"
												defaultValue={blog && blog.description}
												key={blog && blog.description}
												disableUnderline="true"
												style={{ backgroundColor: "#292929" }}
												onChange={handleAddForm}
											></Input>
										</Stack>
										<Stack
											direction="row"
											className="ModelProfilePage_row"
											spacing={1}
											style={{
												alignItems: "baseline"
											}}
										>
											<Typography
												gutterBottom
												component="div"
												className="Blog_elements"
											>
												category
											</Typography>
											<Autocomplete
												className="ScheduleInputStatus"
												disablePortal
												id="combo-box-demo"
												disableClearable
												options={blogObject && blogObject.blog_categories}
												defaultValue={
													blogObject &&
													blogObject?.blog_categories?.find(
														(v) => v.id == blog?.blog_category_id
													)
												}
												key={
													blogObject &&
													blogObject?.blog_categories?.find(
														(v) => v.id == blog?.blog_category_id
													)
												}
												getOptionLabel={(option) => option.name}
												renderInput={(params) => (
													<TextField
														{...params}
														disabled
														variant="standard"
														name="role"
													/>
												)}
												onChange={handleAutocompleteChange}
											/>
										</Stack>
									</Box>
								</Grid>
								<Grid
									item
									xs={6}
									md={8}
									style={{
										margin: "0 auto",
										textAlign: "center",
										display: "flex",
										flexDirection: "row",
										alignItems: "center"
									}}
								>
									<Box component="div" className="ModelProfilePage_main">
										<Stack
											direction="row"
											className="ModelProfilePage_row"
											spacing={1}
											style={{
												alignItems: "baseline"
											}}
										>
											<Typography
												gutterBottom
												component="div"
												className="Blog_elements"
											>
												Views
											</Typography>
											<Typography
												gutterBottom
												component="div"
												className="UserProfilePage_elements"
												style={{ display: "flex", justifyContent: "start" }}
											>
												{blog && blog?.views_count}
											</Typography>
										</Stack>
										<Stack
											direction="row"
											className="ModelProfilePage_row"
											spacing={1}
											style={{
												alignItems: "baseline"
											}}
										>
											<Typography
												gutterBottom
												component="div"
												className="Blog_elements"
											>
												Reactions
											</Typography>
											<Typography
												gutterBottom
												component="div"
												className="UserProfilePage_elements"
												style={{ display: "flex", justifyContent: "start" }}
											>
												{blog && blog?.reactions_count}
											</Typography>
										</Stack>
										<Stack
											direction="row"
											className="ModelProfilePage_row"
											spacing={1}
											style={{
												alignItems: "baseline"
											}}
										>
											<Typography
												gutterBottom
												component="div"
												className="Blog_elements"
											>
												Created
											</Typography>
											<Typography
												gutterBottom
												component="div"
												className="UserProfilePage_elements"
												style={{ display: "flex", justifyContent: "start" }}
											>
												{blog && blog.createdAt}
											</Typography>
										</Stack>
										<Stack
											direction="row"
											className="ModelProfilePage_row"
											spacing={1}
										>
											<Typography
												gutterBottom
												component="div"
												className="Blog_elements"
											></Typography>
											<FormControlLabel
												style={{ color: "white" }}
												control={
													<Checkbox
														name="is_visible"
														value="allowExtraEmails"
														color="primary"
														checked={blog && blog.is_visible ? true : false}
													/>
												}
												onChange={changeHandle}
												label="Visible"
											/>
										</Stack>
									</Box>
								</Grid>
							</Grid>
							<Grid spacing={1} className="userProfile_grid">
								<Grid
									item
									xs={12}
									style={{
										minWidth: "100%",
										display: "flex",
										flexDirection: "column",
										justifyContent: "center"
									}}
								>
									<Stack
										direction="row"
										className="ModelProfilePage_row"
										spacing={1}
									>
										{selected && (
											<TagsInput
												value={selected}
												onChange={setSelected}
												name="tags"
												placeHolder="enter tags"
											/>
										)}
									</Stack>
									<Stack direction="row" className="Blog_content" spacing={1}>
										<Typography
											gutterBottom
											component="div"
											className="Blog_elements"
										></Typography>
										<ReactQuill
											theme="snow"
											modules={modules}
											value={value}
											onChange={setValue}
											className="custom-quill"
										/>
									</Stack>
								</Grid>
							</Grid>
							<Grid spacing={1} className="userProfile_grid">
								<Grid
									item
									xs={6}
									style={{
										width: "100%",
										display: "flex",
										flexDirection: "row",
										justifyContent: "flex-end"
									}}
								>
									<Button
										onClick={handleAddFormSubmit}
										size="small"
										variant="contained"
										style={{
											color: "black",
											backgroundColor: "#FFD800",
											maxWidth: "100px",
											maxHeight: "33px",
											minWidth: "100px",
											minHeight: "33px",
											borderRadius: "4px"
										}}
										className="create"
									>
										Save
									</Button>
								</Grid>
								<Grid
									item
									xs={6}
									style={{
										width: "85%",
										display: "flex",
										flexDirection: "row",
										justifyContent: "flex-end"
									}}
								>
									<Button
										onClick={handleDeleteSubmit}
										size="small"
										variant="contained"
										style={{
											color: "black",
											backgroundColor: "red",
											maxWidth: "130px",
											maxHeight: "33px",
											minWidth: "130px",
											minHeight: "33px",
											borderRadius: "4px"
										}}
										className="create"
									>
										Delete Blog
									</Button>
								</Grid>
							</Grid>
						</Container>
					</Grid>

					<Grid style={{ width: "30%" }}>
						<BlogComments blog_comments={blog_comments} />
					</Grid>
				</Grid>
			</Grid>
		</>
	);
}

export default connect(mapStateToProps, mapDispatchToProps)(Blog);
