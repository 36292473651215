import * as React from "react";
import { useEffect } from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { Box, Container, Typography } from "@mui/material";
import Chart from "../Chart/Chart";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { getDashBoardData } from "../../redux/actions/dashBoardAction";
import { connect, useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const mapStateToProps = (state) => {
	return {
		dashboard: state.dashboard
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getDashBoardData: (month, year) => dispatch(getDashBoardData(month, year))
	};
};

function DashBoard({ getDashBoardData, dashboard }) {
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const data = [
		{ id: 0, name: "January" },
		{ id: 1, name: "February" },
		{ id: 2, name: "March" },
		{ id: 3, name: "April" },
		{ id: 4, name: "May" },
		{ id: 5, name: "June" },
		{ id: 6, name: "July" },
		{ id: 7, name: "August" },
		{ id: 8, name: "September" },
		{ id: 9, name: "October" },
		{ id: 10, name: "November" },
		{ id: 11, name: "December" }
	];

	const [month, setMonth] = React.useState(undefined);
	const [year, setYear] = React.useState(undefined);

	function getIndex(name) {
		return data.findIndex((obj) => obj.name == name);
	}

	function cameraChangeButtonForward() {
		var index = getIndex(dashboard.current_month);
		var year = dashboard.current_year;
		var nextid = index + 1;
		if (nextid == data.length) {
			nextid = 0;
			dashboard.current_year += 1;
			setYear(dashboard.current_year);
		}
		dashboard.current_month = data[nextid].name;
		setYear(dashboard.current_year);
		setMonth(data[nextid].name);
	}

	function cameraChangeButtonBack() {
		var index = getIndex(dashboard.current_month);
		var year = dashboard.current_year;
		var nextid = index - 1;
		if (nextid < 0) {
			nextid = data.length - 1;
			dashboard.current_year -= 1;
			setYear(dashboard.current_year);
		}
		dashboard.current_month = data[nextid].name;
		setYear(dashboard.current_year);
		setMonth(data[nextid].name);
	}

	useEffect(() => {
		// Define the fetch function
		const fetchData = () => {
			if (month !== undefined || year !== undefined) {
				getDashBoardData(month, year);
			} else {
				getDashBoardData(0, 0);
			}
		};

		// Call fetchData initially
		fetchData();

		// Set up an interval to fetch data every 15 seconds
		const intervalId = setInterval(fetchData, 15000);

        // Cleanup the interval on component unmount
        return () => clearInterval(intervalId);
    }, [month]);
    const loading = useSelector((state) => state.loading);
    return (
        <>
            <Container
                maxWidth="xl"
                className="page-container page-cameras"
                style={{
                    color: "#ffffff",
                    width: "-webkit-fill-available",
                    height: "100%!important",
                    marginLeft: "290px",
                    maxWidth: "unset",
                    paddingLeft: "0px",
                    paddingRight: "0px",
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-start'
                }}
            >
                {loading && loading.isLoading == true ? (
                    <div className="loader-container">
                        <div className="spinner"></div>
                    </div>
                ) : (
                    ""
                )}
                <Box style={{marginTop: loading && loading.isLoading == true && "0px"}}>
                    <Grid container spacing={0}>
                        <Grid item xs={4} md={2} spacing={0} className="LiveShow">
                            <Typography
                                variant="h4"
                                gutterBottom
                                component="div"
                                style={{color: "white"}}
                            >
                                Dashboard
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
                <Box style={{display: "flex", justifyContent: "flex-start"}}>
                    <Box className="DashBoard_box" style={{paddingRight: "20px"}}>
                        <Typography
                            variant="h7"
                            gutterBottom
                            component="div"
                            style={{color: "white", minWidth: "150px"}}
                        >
                            Users online
                        </Typography>
                        <Box className="Info_box">
                            <Typography
                                variant="h4"
                                gutterBottom
                                component="div"
                                style={{
                                    color: "#78F73B",
                                    minWidth: "170px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                {dashboard && dashboard.users_online && 1185}
                            </Typography>
                        </Box>
                    </Box>
                    {/* <Box className="DashBoard_box" style={{ paddingRight: "20px" }}>
            <Typography
              variant="h7"
              gutterBottom
              component="div"
              style={{ color: "white", minWidth: "150px" }}
            >
              Guests online
            </Typography>
            <Box className="Info_box">
              <Typography
                variant="h4"
                gutterBottom
                component="div"
                style={{
                  color: "#78F73B",
                  minWidth: "170px",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                254
              </Typography>
            </Box>
          </Box> */}
                    <Box className="DashBoard_box">
                        <Typography
                            variant="h7"
                            gutterBottom
                            component="div"
                            style={{color: "white", minWidth: "150px"}}
                        >
                            Registrations
                        </Typography>
                        <Box className="Info_box">
                            <Typography
                                variant="h7"
                                gutterBottom
                                component="div"
                                style={{
                                    color: "white",
                                    minWidth: "170px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    fontSize: "10px",
                                    marginBottom: "-5px",
                                    marginRight: "26px",
                                }}
                            >
                                Today
                            </Typography>
                            <Typography
                                variant="h4"
                                gutterBottom
                                component="div"
                                style={{
                                    color: "#78F73B",
                                    minWidth: "170px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                {dashboard && dashboard.signups_by_today && 133}
                            </Typography>
                        </Box>
                    </Box>
                    <Box className="DashBoard_box" sx={{marginLeft: "10px"}}>
                        <Box className="Info_box">
                            <Typography
                                variant="h7"
                                gutterBottom
                                component="div"
                                style={{
                                    color: "white",
                                    minWidth: "170px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    fontSize: "10px",
                                    marginBottom: "-5px",
                                    marginRight: "26px",
                                }}
                            >
                                Week
                            </Typography>
                            <Typography
                                variant="h4"
                                gutterBottom
                                component="div"
                                style={{
                                    color: "#78F73B",
                                    minWidth: "170px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                {dashboard && dashboard.signups_by_week && 133}
                            </Typography>
                        </Box>
                    </Box>
                    <Box className="DashBoard_box" sx={{marginLeft: "20px"}}>
                        <Typography
                            variant="h7"
                            gutterBottom
                            component="div"
                            style={{color: "white", minWidth: "150px"}}
                        >
                            HouseMates
                        </Typography>
                        <Box className="Info_box">
                            <Typography
                                variant="h4"
                                gutterBottom
                                component="div"
                                style={{
                                    color: "#78F73B",
                                    minWidth: "170px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                {dashboard && dashboard.housemates_count && 321}
                            </Typography>
                        </Box>
                    </Box>
                    <Box className="DashBoard_box" sx={{marginLeft: "20px"}}>
                        <Typography
                            variant="h7"
                            gutterBottom
                            component="div"
                            style={{color: "white", minWidth: "150px"}}
                        >
                            Active Subscriptions
                        </Typography>
                        <Box className="Info_box">
                            <Typography
                                variant="h4"
                                gutterBottom
                                component="div"
                                style={{
                                    color: "#78F73B",
                                    minWidth: "170px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                {dashboard && dashboard.users_with_active_subscription_count && 1128}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
                <Box style={{display: "flex", justifyContent: "flex-start"}}>
                    <Box className="DashBoard_box" sx={{paddingRight: "20px"}}>
                        <Typography
                            variant="h7"
                            gutterBottom
                            component="div"
                            style={{color: "white", minWidth: "150px"}}
                        >
                            Camera Purchases Today $
                        </Typography>
                        <Box className="Info_box">
                            <Typography
                                variant="h4"
                                gutterBottom
                                component="div"
                                style={{
                                    color: "#78F73B",
                                    minWidth: "170px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                {dashboard &&
                                    dashboard.purchases &&
                                    Math.floor(dashboard.purchases.camera_access_day_sum && 440)}
                            </Typography>
                        </Box>
                    </Box>
                    <Box className="DashBoard_box" style={{paddingRight: "20px"}}>
                        <Typography
                            variant="h7"
                            gutterBottom
                            component="div"
                            style={{color: "white", minWidth: "150px"}}
                        >
                            Camera Purchases Week $
                        </Typography>
                        <Box className="Info_box">
                            <Typography
                                variant="h4"
                                gutterBottom
                                component="div"
                                style={{
                                    color: "#78F73B",
                                    minWidth: "170px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                {dashboard &&
                                    dashboard.purchases &&
                                    Math.floor(dashboard.purchases.camera_access_week_sum && 440)}
                            </Typography>
                        </Box>
                    </Box>
                    <Box className="DashBoard_box" style={{paddingRight: "20px"}}>
                        <Typography
                            variant="h7"
                            gutterBottom
                            component="div"
                            style={{color: "white", minWidth: "150px"}}
                        >
                            Camera Purchases Month $
                        </Typography>
                        <Box className="Info_box">
                            <Typography
                                variant="h4"
                                gutterBottom
                                component="div"
                                style={{
                                    color: "#78F73B",
                                    minWidth: "170px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                {dashboard &&
                                    dashboard.purchases &&
                                    Math.floor(dashboard.purchases.camera_access_month_sum && 32488)}
                            </Typography>
                        </Box>
                    </Box>
                    <Box className="DashBoard_box" style={{paddingRight: "20px"}}>
                        <Typography
                            variant="h7"
                            gutterBottom
                            component="div"
                            style={{color: "white", minWidth: "150px"}}
                        >
                            WHX Purchases Today $
                        </Typography>
                        <Box className="Info_box">
                            <Typography
                                variant="h4"
                                gutterBottom
                                component="div"
                                style={{
                                    color: "#78F73B",
                                    minWidth: "170px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                {dashboard &&
                                    dashboard.purchases &&
                                    Math.floor(dashboard.purchases.tokens_day_sum) && "N/A"}
                            </Typography>
                        </Box>
                    </Box>
                    <Box className="DashBoard_box" style={{paddingRight: "20px"}}>
                        <Typography
                            variant="h7"
                            gutterBottom
                            component="div"
                            style={{color: "white", minWidth: "150px"}}
                        >
                            WHX Purchases Week $
                        </Typography>
                        <Box className="Info_box">
                            <Typography
                                variant="h4"
                                gutterBottom
                                component="div"
                                style={{
                                    color: "#78F73B",
                                    minWidth: "170px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                {dashboard &&
                                    dashboard.purchases &&
                                    Math.floor(dashboard.purchases.tokens_week_sum) && "N/A"}
                            </Typography>
                        </Box>
                    </Box>
                    <Box className="DashBoard_box" style={{paddingRight: "20px"}}>
                        <Typography
                            variant="h7"
                            gutterBottom
                            component="div"
                            style={{color: "white", minWidth: "150px"}}
                        >
                            WHX Purchases Month $
                        </Typography>
                        <Box className="Info_box">
                            <Typography
                                variant="h4"
                                gutterBottom
                                component="div"
                                style={{
                                    color: "#78F73B",
                                    minWidth: "170px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                {dashboard &&
                                    dashboard.purchases &&
                                    Math.floor(dashboard.purchases.tokens_month_sum) && "N/A"}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
                <Box style={{display: "flex", justifyContent: "flex-start"}}>
                    <Box className="DashBoard_box" style={{paddingRight: "20px"}}>
                        <Button
                            onClick={() => cameraChangeButtonBack()}
                            startIcon={<ArrowBackIosIcon/>}
                            className="ModelProfilePage_arrow_back"
                            variant="contained"
                        >
                            Previous
                        </Button>
                    </Box>
                    <Box className="DashBoard_box" style={{paddingRight: "20px"}}>
                        <Box className="Date">
                            <Typography
                                variant="h6"
                                component="div"
                                style={{
                                    color: "white",
                                    minWidth: "170px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                {dashboard && dashboard.current_month}
                                &nbsp;
                                {dashboard && dashboard.current_year}
                            </Typography>
                        </Box>
                    </Box>
                    <Box className="DashBoard_box" style={{paddingRight: "20px"}}>
                        <Button
                            onClick={() => cameraChangeButtonForward()}
                            endIcon={<ArrowForwardIosIcon/>}
                            className="ModelProfilePage_arrow_next"
                            variant="contained"
                        >
                            Next
                        </Button>
                    </Box>
                </Box>
                <Chart chartData={dashboard && dashboard.earnings_chart}/>

                <Typography
                    variant="h5"
                    gutterBottom
                    component="div"
                    style={{color: "white"}}
                >
                    Credits Used
                </Typography>
                <Box style={{display: "flex", justifyContent: "flex-start"}}>
                    <Box className="DashBoard_box" sx={{paddingRight: "20px"}}>
                        <Typography
                            variant="h7"
                            gutterBottom
                            component="div"
                            style={{color: "white", minWidth: "150px"}}
                        >
                            Selected Month
                        </Typography>
                        <Box className="Info_box">
                            <Typography
                                variant="h4"
                                gutterBottom
                                component="div"
                                style={{
                                    color: "#78F73B",
                                    minWidth: "170px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                {dashboard &&
                                    dashboard.earnings_summary &&
                                    Math.floor(dashboard.earnings_summary)}
                            </Typography>
                        </Box>
                    </Box>
                    <Box className="DashBoard_box" style={{paddingRight: "20px"}}>
                        <Typography
                            variant="h7"
                            gutterBottom
                            component="div"
                            style={{color: "white", minWidth: "150px"}}
                        >
                            Today
                        </Typography>
                        <Box className="Info_box">
                            <Typography
                                variant="h4"
                                gutterBottom
                                component="div"
                                style={{
                                    color: "#78F73B",
                                    minWidth: "170px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                {dashboard &&
                                    dashboard.earnings_summary_fixed &&
                                    Math.floor(dashboard.earnings_summary_fixed.today)}
                            </Typography>
                        </Box>
                    </Box>
                    <Box className="DashBoard_box" style={{paddingRight: "20px"}}>
                        <Typography
                            variant="h7"
                            gutterBottom
                            component="div"
                            style={{color: "white", minWidth: "150px"}}
                        >
                            Current Week
                        </Typography>
                        <Box className="Info_box">
                            <Typography
                                variant="h4"
                                gutterBottom
                                component="div"
                                style={{
                                    color: "#78F73B",
                                    minWidth: "170px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                {dashboard &&
                                    dashboard.earnings_summary_fixed &&
                                    Math.floor(dashboard.earnings_summary_fixed.this_week)}
                            </Typography>
                        </Box>
                    </Box>
                    <Box className="DashBoard_box" style={{paddingRight: "20px"}}>
                        <Typography
                            variant="h7"
                            gutterBottom
                            component="div"
                            style={{color: "white", minWidth: "150px"}}
                        >
                            Current Month
                        </Typography>
                        <Box className="Info_box">
                            <Typography
                                variant="h4"
                                gutterBottom
                                component="div"
                                style={{
                                    color: "#78F73B",
                                    minWidth: "170px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                {dashboard &&
                                    dashboard.earnings_summary_fixed &&
                                    Math.floor(dashboard.earnings_summary_fixed.this_month)}
                            </Typography>
                        </Box>
                    </Box>
                    <Box className="DashBoard_box" style={{paddingRight: "20px"}}>
                        <Typography
                            variant="h7"
                            gutterBottom
                            component="div"
                            style={{color: "white", minWidth: "150px"}}
                        >
                            Total
                        </Typography>
                        <Box className="Info_box">
                            <Typography
                                variant="h4"
                                gutterBottom
                                component="div"
                                style={{
                                    color: "#78F73B",
                                    minWidth: "170px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                {dashboard &&
                                    dashboard.earnings_summary_fixed &&
                                    Math.floor(dashboard.earnings_summary_fixed.lifetime && 4880707)}
                            </Typography>
                        </Box>
                    </Box>
                </Box>
            </Container>
        </>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(DashBoard);
