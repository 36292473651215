import * as React from "react";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import {
	Box,
	Stack,
	Table,
	TableBody,
	TableContainer,
	TableHead,
	TableRow,
	Typography
} from "@mui/material";
import SearchBar from "material-ui-search-bar";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import GirlIcon from "@material-ui/icons/Face";
import { Link, useNavigate } from "react-router-dom";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";
import { connect, useSelector } from "react-redux";
import { getBlogs } from "../../redux/actions/blogActions";

const mapStateToProps = (state) => {
	return {
		blogs: state.blogs
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		getBlogs: () => dispatch(getBlogs())
	};
};

function Blogs({ blogs, getBlogs }) {
	React.useEffect(() => {
		getBlogs();
	}, []);

	let navigate = useNavigate();

	const StyledTableRow = styled(TableRow)(({ theme }) => ({
		"&:nth-of-type(odd)": {
			backgroundColor: "rgb(0 0 0)"
		},
		"&:nth-of-type(even)": {
			backgroundColor: "#ffffff14"
		},
		// hide last border
		"&:last-child td, &:last-child th": {
			border: 0
		},
		"&.MuiTableRow-root:hover": {
			backgroundColor: "#555555"
		}
	}));

	const StyledTableCell = styled(TableCell)(({ theme }) => ({
		[`&.${tableCellClasses.head}`]: {
			backgroundColor: "#292929",
			color: theme.palette.common.white
		},
		[`&.${tableCellClasses.body}`]: {
			fontSize: 14
		}
	}));

	const [rows, setRows] = React.useState(blogs);

	React.useEffect(() => {
		setRows(blogs);
	}, [blogs]);
	const [searched, setSearched] = React.useState("");

	const requestSearch = (searchVal) => {
		const filtereredRows = blogs.filter((row) => {
			return row.title.toLowerCase().includes(searchVal.toLowerCase());
		});
		setRows(filtereredRows);
	};
	const cancelSearch = () => {
		setSearched("");
		requestSearch(searched);
	};

	const loading = useSelector((state) => state.loading);
	return (
		<>
			{loading && loading.isLoading == true ? (
				<div className="loader-container">
					<div className="spinner"></div>
				</div>
			) : (
				""
			)}
			<Stack
				className="page-container"
				spacing={3}
				sx={{ width: 700, marginLeft: "290px", borderRadius: "10px" }}
			>
				<Box>
					<Grid container spacing={0}>
						<Grid item xs={4} md={2} spacing={0} className="LiveShow">
							<Typography
								variant="h4"
								gutterBottom
								component="div"
								style={{ color: "white" }}
							>
								Blog Management
							</Typography>
						</Grid>
					</Grid>
				</Box>
				<Box className="Search_box">
					<Typography
						variant="h6"
						gutterBottom
						component="div"
						style={{ color: "white", marginRight: "63px" }}
					>
						Find blog
					</Typography>
					<SearchBar
						style={{ height: "35px" }}
						className="SearchBar"
						placeholder=""
						value={searched}
						onChange={(searchVal) => requestSearch(searchVal)}
						onCancelSearch={() => cancelSearch()}
					/>
				</Box>
				<Box
					style={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "flex-end"
					}}
				>
					<Link to={"/addBlog"}>
						<Button
							size="small"
							variant="contained"
							style={{
								color: "black",
								backgroundColor: "#78F73B",
								maxWidth: "160px",
								maxHeight: "33px",
								minWidth: "160px",
								minHeight: "33px",
								borderRadius: "4px",
								textTransform: "unset"
							}}
							className="create"
						>
							<PersonAddAltIcon style={{ marginRight: "10px" }} />
							Create New Blog
						</Button>
					</Link>
				</Box>
				<TableContainer
					className="SchrollBar"
					component={Paper}
					style={{ overflow: "auto", marginRight: "40px" }}
					sx={{ maxHeight: "800px" }}
				>
					<Table aria-label="simple table" stickyHeader>
						<TableHead>
							<StyledTableRow>
								<StyledTableCell style={{ borderBottom: "none" }} align="left">
									Tittle
								</StyledTableCell>
								<StyledTableCell style={{ borderBottom: "none" }} align="right">
									Created
								</StyledTableCell>
								<StyledTableCell style={{ borderBottom: "none" }} align="right">
									Visible
								</StyledTableCell>
							</StyledTableRow>
						</TableHead>
						<TableBody>
							{blogs &&
								rows.map((row) => (
									<StyledTableRow
										onClick={() => {
											navigate("/blog/" + row.slug);
										}}
										hover
										key={row.id}
										sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
									>
										<TableCell
											align="left"
											style={{
												borderBottom: "none",
												color: "#78F73B",
												display: "flex",
												alignItems: "center"
											}}
										>
											<GirlIcon
												style={{ color: "white", marginRight: "10px" }}
											/>
											{row.title}
										</TableCell>
										<TableCell
											align="right"
											style={{ borderBottom: "none", color: "#FFD800" }}
										>
											{row.createdAt}
										</TableCell>
										<TableCell
											align="right"
											style={{
												borderBottom: "none",
												color: `${row.is_visible == 1 ? "green" : "red"}`
												/* display: "none", */
											}}
										>
											{row.is_visible == 1 ? "visible" : "not visible"}
										</TableCell>
									</StyledTableRow>
								))}
						</TableBody>
					</Table>
				</TableContainer>
			</Stack>
		</>
	);
}

export default connect(mapStateToProps, mapDispatchToProps)(Blogs);
