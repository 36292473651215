import * as React from "react";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import {
	TableContainer,
	Table,
	TableHead,
	TableBody,
	TableRow
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { Stack } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Typography } from "@mui/material";
import { Input } from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {
	deleteException,
	deleteRestriction,
	GeoblockExceptionsAdd,
	GeoblockRestrictionsAdd
} from "../../redux/actions/geoblockActions";
import { useDispatch } from "react-redux";

export default function GeoBlockTable(data) {
	const dispatch = useDispatch();
	const StyledTableRow = styled(TableRow)(({ theme }) => ({
		"&:nth-of-type(even)": {
			backgroundColor: theme.palette.action.hover
		},
		// hide last border
		"&:last-child td, &:last-child th": {
			border: 0
		}
	}));

	const StyledTableCell = styled(TableCell)(({ theme }) => ({
		[`&.${tableCellClasses.head}`]: {
			backgroundColor: "#292929",
			color: theme.palette.common.white
		},
		[`&.${tableCellClasses.body}`]: {
			fontSize: 14
		}
	}));

	const [rows, setRows] = React.useState(data.data && data.data.ips);
	React.useEffect(() => {
		setRows(data.data && data.data.ips);
	}, [data.data && data.data.ips]);

	const [addFormData, setAddFormData] = React.useState({
		from: "",
		to: ""
	});

	const handleAddForm = (event) => {
		event.preventDefault();
		const fieldName = event.target.getAttribute("name");
		const fieldValue = event.target.value;
		const newFormData = { ...addFormData };
		newFormData[fieldName] = fieldValue;
		setAddFormData(newFormData);
	};
	const handleAddFormSubmit = (event) => {
		event.preventDefault();
		if (data.data.type == "exception") {
			dispatch(GeoblockExceptionsAdd(addFormData));
		}
		if (data.data.type == "restriction") {
			dispatch(GeoblockRestrictionsAdd(addFormData));
		}
	};
	const handleDeleteClick = (ipId) => {
		if (data.data.type == "exception") {
			dispatch(deleteException(ipId));
		}
		if (data.data.type == "restriction") {
			dispatch(deleteRestriction(ipId));
		}
	};

	return (
		<>
			<form className="Search_box" clean>
				<Stack direction="row" className="GeoBlock_row" spacing={1}>
					<Typography
						gutterBottom
						component="div"
						className="GeoBlock_elements"
					>
						From
					</Typography>
					<Input
						className="inputBoxGeo"
						type="text"
						name="from"
						required="required"
						disableUnderline="true"
						style={{ backgroundColor: "black" }}
						onChange={handleAddForm}
					></Input>
					<Typography
						gutterBottom
						component="div"
						className="GeoBlock_elements"
					>
						To
					</Typography>
					<Input
						className="inputBoxGeo"
						type="text"
						name="to"
						required="required"
						disableUnderline="true"
						style={{ backgroundColor: "black" }}
						onChange={handleAddForm}
					></Input>
					<Button
						size="small"
						variant="contained"
						type="submit"
						onClick={handleAddFormSubmit}
						style={{
							color: "black",
							backgroundColor: "#78F73B",
							maxWidth: "50px",
							maxHeight: "33px",
							minWidth: "50px",
							minHeight: "33px",
							borderRadius: "4px",
							marginLeft: "15px",
							marginRight: "15px",
							textTransform: "unset"
						}}
						className="create"
					>
						Add
					</Button>
				</Stack>
			</form>

			<TableContainer
				className="SchrollBar_Geo"
				component={Paper}
				style={{
					overflow: "auto",
					marginRight: "40px",
					maxWidth: "565px"
				}}
				sx={{ maxHeight: "500px" }}
			>
				<Table aria-label="simple table" stickyHeader>
					<TableHead>
						<StyledTableRow>
							<StyledTableCell style={{ borderBottom: "none" }} align="left">
								IP Address From
							</StyledTableCell>
							<StyledTableCell style={{ borderBottom: "none" }} align="center">
								IP Adress To
							</StyledTableCell>
							<StyledTableCell
								style={{ borderBottom: "none" }}
								align="right"
							></StyledTableCell>
						</StyledTableRow>
					</TableHead>
					<TableBody>
						{rows &&
							rows.map((row) => (
								<StyledTableRow
									key={row.id}
									sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
								>
									<TableCell
										align="left"
										style={{
											borderBottom: "none",
											color: "white",
											display: "flex",
											alignItems: "center"
										}}
									>
										{row.from}
									</TableCell>
									<TableCell
										align="center"
										style={{ borderBottom: "none", color: "white" }}
									>
										{row.to}
									</TableCell>
									<TableCell
										align="right"
										style={{ borderBottom: "none", color: "white" }}
									>
										<Button
											style={{ color: "#ED2779", width: "10px" }}
											type="button"
											onClick={() => handleDeleteClick(row.id)}
										>
											<DeleteForeverIcon fontSize="small" />
										</Button>
									</TableCell>
								</StyledTableRow>
							))}
					</TableBody>
				</Table>
			</TableContainer>
		</>
	);
}
