import { MODAL_ENABLE, MODAL_DISABLE } from "../types";

export const modalReducer = (state = false, action) => {
	const { type, payload, content } = action;
	switch (type) {
		case MODAL_ENABLE: {
			let { data } = payload;
			return {
				name: data,
				content: content.content
			};
		}
		case MODAL_DISABLE: {
			let { data } = payload;
			if (state.name == data) {
				return {};
			} else {
				return state;
			}
		}
		default: {
			return state;
		}
	}
};
