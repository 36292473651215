import { TUBE, TUBE_PAGINATION, TUBE_VIDEO, TUBE_VIDEO_UPLOAD } from "../types";
import { setLoading, setMessage } from "../reducers/rootReducer";
import {
	API_ADMIN_TUBE_IMAGES_ROUTE,
	API_ADMIN_TUBE_ROUTE,
	API_FETCH_UPDATE_IMAGE_GALLERIE_ROUTE,
	API_FETCH_UPDATE_VIDEO_GALLERIE_ROUTE
} from "../../utils/consts";

export const TubeObject = (data) => {
	return {
		type: TUBE,
		payload: { data }
	};
};
export const TubeObjectPagination = (data) => {
	return {
		type: TUBE_PAGINATION,
		payload: { data }
	};
};
export const TubeVideoObject = (data) => {
	return {
		type: TUBE_VIDEO,
		payload: { data }
	};
};
export const TubeVideoUpload = (data) => {
	return {
		type: TUBE_VIDEO_UPLOAD,
		payload: { data }
	};
};
export const getTube =
	(type = null) =>
	async (dispatch) => {
		console.log(type);
		dispatch(setLoading({ camera: "tube", type: true }));
		try {
			const response = await fetch(
				process.env.REACT_APP_BACKEND_URL +
					(type != null ? API_ADMIN_TUBE_IMAGES_ROUTE : API_ADMIN_TUBE_ROUTE),
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Accept: "application/json",
						Authorization: `Bearer ${sessionStorage.getItem("token")}`
					}
					//credentials: 'include',
				}
			);
			//response.data
			const data = await response.json();
			if (response.status >= 200 && response.status <= 299) {
				dispatch(setLoading({ camera: "blog", type: false }));
				if (data.data) {
					dispatch(TubeObject(data.data));
				}
			} else {
				console.log("logMeOut", response.status, data);
				dispatch(setLoading({ camera: "tube", type: false }));
				//dispatch(userLogout());
				//sessionStorage.removeItem("token");
			}
		} catch (error) {
			console.log(error);
			dispatch(setLoading({ camera: "tube", type: false }));
		}
	};
export const getTubeWithPagination =
	(type = null, page = null) =>
	async (dispatch) => {
		console.log(type);
		dispatch(setLoading({ camera: "tube", type: true }));
		try {
			const response = await fetch(
				process.env.REACT_APP_BACKEND_URL +
					(type != null ? API_ADMIN_TUBE_IMAGES_ROUTE : API_ADMIN_TUBE_ROUTE) +
					`?page=${page}`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Accept: "application/json",
						Authorization: `Bearer ${sessionStorage.getItem("token")}`
					}
					//credentials: 'include',
				}
			);
			//response.data
			const data = await response.json();
			if (response.status >= 200 && response.status <= 299) {
				dispatch(setLoading({ camera: "blog", type: false }));
				if (data.data) {
					dispatch(TubeObjectPagination(data.data));
				}
			} else {
				console.log("logMeOut", response.status, data);
				dispatch(setLoading({ camera: "tube", type: false }));
				//dispatch(userLogout());
				//sessionStorage.removeItem("token");
			}
		} catch (error) {
			console.log(error);
			dispatch(setLoading({ camera: "tube", type: false }));
		}
	};

export const UpdateTubeVideo =
	(id, item, posterImage = false) =>
	async (dispatch) => {
		try {
			const options = {
				method: "POST",
				headers: posterImage
					? {
							Authorization: `Bearer ${sessionStorage.getItem("token")}`
						}
					: {
							Authorization: `Bearer ${sessionStorage.getItem("token")}`,
							"Content-Type": "application/json",
							Accept: "application/json"
						},
				body: posterImage ? item : JSON.stringify(item)
				//credentials: 'include',
			};
			const response = await fetch(
				process.env.REACT_APP_BACKEND_URL +
					API_FETCH_UPDATE_VIDEO_GALLERIE_ROUTE +
					"/" +
					id,
				options
			);
			//response.data
			const data = await response.json();

			if (data.type == "success") {
				dispatch(TubeVideoObject(data.data.video));
				dispatch(setMessage(data.message, data.type));
			}
		} catch (error) {
			console.log(error);
		}
	};
export const UpdateTubeImage = (id, item) => async (dispatch) => {
	try {
		const options = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: `Bearer ${sessionStorage.getItem("token")}`
			},
			body: JSON.stringify(item)
			//credentials: 'include',
		};
		const response = await fetch(
			process.env.REACT_APP_BACKEND_URL +
				API_FETCH_UPDATE_IMAGE_GALLERIE_ROUTE +
				"/" +
				id,
			options
		);
		//response.data
		const data = await response.json();

		if (data.type == "success") {
			dispatch(TubeVideoObject(data.data.image));
			dispatch(setMessage(data.message, data.type));
		}
	} catch (error) {
		console.log(error);
	}
};
export const UpdateTubeMediaItem = (id, item) => async (dispatch) => {
	try {
		dispatch(setLoading({ camera: "tube", type: true }));
		const options = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: `Bearer ${sessionStorage.getItem("token")}`
			},
			body: JSON.stringify(item)
			//credentials: 'include',
		};
		const response = await fetch(
			process.env.REACT_APP_BACKEND_URL +
				API_FETCH_UPDATE_IMAGE_GALLERIE_ROUTE +
				"/" +
				id,
			options
		);
		//response.data
		const data = await response.json();

		if (data.type == "success") {
			dispatch(setLoading({ camera: "tube", type: false }));
			dispatch(TubeVideoObject(data.data.image));
			dispatch(setMessage(data.message, data.type));
		} else {
			dispatch(setLoading({ camera: "tube", type: false }));
		}
	} catch (error) {
		dispatch(setLoading({ camera: "tube", type: false }));
		console.log(error);
	}
};
export const DeleteTubeVideo = (id) => async (dispatch) => {
	try {
		const options = {
			method: "DELETE",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: `Bearer ${sessionStorage.getItem("token")}`
			}
			//credentials: 'include',
		};
		const response = await fetch(
			process.env.REACT_APP_BACKEND_URL +
				API_FETCH_UPDATE_VIDEO_GALLERIE_ROUTE +
				"/" +
				id,
			options
		);
		//response.data
		const data = await response.json();

		if (data.type == "success") {
			dispatch(setMessage(data.message, data.type));
			dispatch(getTube(null));
		}
	} catch (error) {
		console.log(error);
	}
};
export const DeleteTubeImage = (id) => async (dispatch) => {
	try {
		const options = {
			method: "DELETE",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: `Bearer ${sessionStorage.getItem("token")}`
			}
			//credentials: 'include',
		};
		const response = await fetch(
			process.env.REACT_APP_BACKEND_URL +
				API_FETCH_UPDATE_IMAGE_GALLERIE_ROUTE +
				"/" +
				id,
			options
		);
		//response.data
		const data = await response.json();

		if (data.type == "success") {
			dispatch(setMessage(data.message, data.type));
			dispatch(getTube("images"));
		}
	} catch (error) {
		console.log(error);
	}
};
