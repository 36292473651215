import React from "react";
import { BarChart, Bar, XAxis, YAxis, Tooltip, Legend } from "recharts";

export default function Chart({ chartData }) {
	return (
		<BarChart
			style={{ backgroundColor: "black" }}
			width={1200}
			height={300}
			data={chartData && chartData}
			margin={{
				top: 30,
				right: 30,
				left: 20,
				bottom: 5
			}}
		>
			<Legend
				width={600}
				wrapperStyle={{
					top: 0,
					right: 0,
					backgroundColor: "black",
					lineHeight: "40px"
				}}
			/>
			<Bar dataKey="itemTipGeneral" name="General" stackId="a" fill="#a6d75b" />
			<Bar
				dataKey="itemTipLiveshow"
				name="Liveshow"
				stackId="a"
				fill="rgb(167, 37, 249)"
			/>
			<Bar
				dataKey="itemTipPersonal"
				name="Personal"
				stackId="a"
				fill="#22a7f0"
			/>
			<Bar dataKey="itemTipPrivate" name="Private" stackId="a" fill="#ffd800" />
			<Bar
				dataKey="itemTipPrivateMessage"
				name="PrivateMessage"
				stackId="a"
				fill="#76c68f"
			/>
			<Bar dataKey="itemTipPool" name="Pool" stackId="a" fill="#115f9a" />
			<Bar dataKey="itemTipLush" name="Lush" stackId="a" fill="#ED2779" />
			<XAxis dataKey="name" stroke="white" />
			<YAxis stroke="white" />
			<Tooltip
				wrapperStyle={{ width: "fit-content", backgroundColor: "black" }}
				contentStyle={{ backgroundColor: "black" }}
				labelStyle={{ display: "none" }}
				cursor={{ fill: "transparent" }}
			/>
		</BarChart>
	);
}
