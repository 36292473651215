import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import { Box, List, Skeleton, Stack, Tab, Typography } from "@mui/material";
import { connect, useDispatch, useSelector } from "react-redux";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import UploadTubeVideos from "../Upload/UploadTubeVideos";
import {
	getTube,
	getTubeWithPagination
} from "../../redux/actions/TubeActions";
import TubeCard from "./TubeCard";
import UploadTubePhotos from "../Upload/UploadTubePhotos";
import { useEffect, useState } from "react";
import ShowMoreButton from "./ShowMoreButton";

const mapStateToProps = (state) => {
	return {
		gallery: state.gallery,
		model: state.model,
		tube: state.tube
	};
};

function Tube({ gallery, model, tube }) {
	const dispatch = useDispatch();
	const [pagination, setPagination] = useState(tube?.pagination);
	const [isLoadingMore, setIsLoadingMore] = useState(false);
	const [value, setValue] = useState("1");
	const loading = useSelector((state) => state.loading);
	const backendHasMore =
		parseInt(pagination?.page) * pagination?.limit <= pagination?.total_count;

	useEffect(() => {
		setPagination(tube?.pagination);
	}, [tube?.pagination]);

	useEffect(() => {
		dispatch(getTube(null));
	}, []);

	const handleChange = (event, newValue) => {
		setValue(newValue);

		if (newValue == 2) {
			dispatch(getTube("images"));
		} else {
			dispatch(getTube(null));
		}
	};

	const handlePaginate = () => {
		if (backendHasMore) {
			const page = parseInt(pagination?.page) + 1;
			if (value == 2) {
				dispatch(getTubeWithPagination("images", page));
			} else {
				dispatch(getTubeWithPagination(null, page));
			}
		}
	};
	return (
		<>
			<Stack
				spacing={3}
				sx={{ width: 1300, marginLeft: "290px", marginTop: "40px" }}
			>
				{loading && loading.isLoading == true ? (
					<div className="loader-container">
						<div className="spinner"></div>
					</div>
				) : (
					""
				)}
				<Box
					style={{ marginTop: loading && loading.isLoading == true && "0px" }}
				>
					<Grid
						container
						spacing={0}
						style={{ display: "flex", flexDirection: "column" }}
					>
						<Grid item xs={4} md={2} spacing={0} className="Pools">
							{!gallery && !gallery.gallery ? (
								<Skeleton width={330} height={55} />
							) : (
								<>
									<Typography
										variant="h4"
										gutterBottom
										component="div"
										style={{ color: "white", marginBottom: "unset" }}
									>
										Tube
									</Typography>
								</>
							)}
						</Grid>
					</Grid>
				</Box>
				<Box>
					<Grid item xs={4} md={2} spacing={0}>
						<Typography
							variant="h5"
							gutterBottom
							component="div"
							style={{ color: "#FFFFFF" }}
						>
							Tube Videos
						</Typography>
					</Grid>
					<Box sx={{ width: "100%", typography: "body1", color: "#FFFFFF" }}>
						<TabContext value={value}>
							<Box
								sx={{
									borderBottom: 1,
									borderColor: "#FFFFFF",
									color: "#FFFFFF"
								}}
							>
								<TabList
									onChange={handleChange}
									aria-label="lab API tabs example"
								>
									<Tab style={{ color: "#FFFFFF" }} label="VIDEOS" value="1" />
									<Tab style={{ color: "#FFFFFF" }} label="PHOTOS" value="2" />
								</TabList>
							</Box>
							<TabPanel
								value="1"
								style={{
									paddingBottom: "0px"
								}}
							>
								<Paper style={{ width: "95%", minHeight: "100px" }}>
									<List className="AllRoomBlock_list_images">
										<UploadTubeVideos
											filters={tube.filters}
											id={model.user && model.user.id}
										/>
										{tube &&
											tube.media &&
											tube.media.length > 0 &&
											tube.media.map((video) => {
												return (
													<TubeCard filters={tube.filters} mediaItem={video} />
												);
											})}
									</List>
								</Paper>
							</TabPanel>
							<TabPanel
								value="2"
								style={{
									paddingBottom: "0px"
								}}
							>
								<Paper style={{ width: "95%", minHeight: "100px" }}>
									<List className="AllRoomBlock_list_images">
										<UploadTubePhotos
											filters={tube.filters}
											id={model.user && model.user.id}
										/>
										{tube &&
											tube.media &&
											tube.media.length > 0 &&
											tube.media.map((image) => {
												return (
													<TubeCard
														filters={tube.filters}
														mediaItem={image}
														isPhoto={true}
													/>
												);
											})}
									</List>
								</Paper>
							</TabPanel>
						</TabContext>
						{backendHasMore && (
							<ShowMoreButton
								onClick={handlePaginate}
								showMore={true}
								loading={isLoadingMore}
								withIcon
							/>
						)}
					</Box>
				</Box>
			</Stack>
		</>
	);
}

export default connect(mapStateToProps)(Tube);
