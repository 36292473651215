import moment from "moment";
import {
	CORE_API_CHAT_ROUTE,
	CORE_API_GET_MESSAGES_ROUTE,
	CORE_API_SEND_GLOBAL_TIPS_ROUTE,
	CORE_API_SEND_MESSAGE_ROUTE,
	WHX_BACKEND_API_CHAT_URL
} from "../../utils/consts";
import { setLoading, setMessage } from "../reducers/rootReducer";

import {
	ADD_MESSAGE_TO_CHAT,
	ADD_MESSAGE_TO_CHAT_OFFSET,
	ADD_MESSAGE_TO_MESSAGES,
	ADD_MESSAGES_TO_CHAT,
	ADD_MORE_MESSAGES_TO_CHAT,
	MESSAGES,
	MORE_MESSAGES,
	REMOVE_MESSAGES
} from "../types";
import { getPrivateChats } from "./chatActions";

export const allMessages = (data) => {
	return {
		type: MESSAGES,
		payload: { data }
	};
};
export const MoreMessages = (data) => {
	return {
		type: MORE_MESSAGES,
		payload: { data }
	};
};

export const addMessage = (data) => {
	return {
		type: ADD_MESSAGE_TO_MESSAGES,
		payload: { data }
	};
};

export const setMessagesToState = (data) => {
	return {
		type: MESSAGES,
		payload: { data }
	};
};
export const chatMessages = (data) => {
	return {
		type: ADD_MESSAGES_TO_CHAT,
		payload: { data }
	};
};
export const chatMoreMessages = (data) => {
	return {
		type: ADD_MORE_MESSAGES_TO_CHAT,
		payload: { data }
	};
};
export const chatMessage = (chat, data) => {
	return {
		type: ADD_MESSAGE_TO_CHAT,
		payload: { data },
		chat: { chat }
	};
};
export const chatMessageOffset = (chat, data) => {
	return {
		type: ADD_MESSAGE_TO_CHAT_OFFSET,
		payload: { data },
		chat: { chat }
	};
};
export const removeMessages = () => {
	return {
		type: REMOVE_MESSAGES
	};
};

function getSocketToken() {
	if (
		sessionStorage.getItem("chat-data") &&
		sessionStorage.getItem("chat-data") !== undefined
	)
		return sessionStorage.getItem("chat-data");

	let token = null;
	fetch(process.env.REACT_APP_BACKEND_URL + "/api/v1/admin/environment", {
		method: "GET",
		headers: {
			"Content-Type": "application/json",
			Accept: "application/json",
			Authorization: `Bearer ${sessionStorage.getItem("token")}`
		}
		//credentials: 'include',
	})
		.then((response) => response.json())
		.then((data) => {
			token = data.data.chat_data.kewb_access_token;
			sessionStorage.setItem("chat-data", token);
			dispatch(getPrivateChats());
			return token;
		})
		.catch((error) => {
			return null;
		});

	return token;
}

export const getMessages =
	(chatId, chat, token, offset = 0) =>
	async (dispatch) => {
		dispatch(setLoading({ camera: "messages", type: true }));
		try {
			/*    let token = getSocketToken(); */
			if (!token) token = sessionStorage.getItem("chat-data");

			let requestMessages = await fetch(
				process.env.REACT_APP_CHAT_URL +
					CORE_API_CHAT_ROUTE +
					chatId +
					CORE_API_GET_MESSAGES_ROUTE,
				{
					method: "POST",
					headers: {
						"Content-Type": "application/json",
						Authorization: `Bearer ${token}`
					},
					//credentials: 'include',
					body: JSON.stringify({
						limit: 100,
						onlyOwn: false,
						offset: offset
					})
				}
			);
			let responseMessages = await requestMessages.json();
			if (responseMessages.success == false) {
				sessionStorage.removeItem("chat-data");
				requestMessages = await fetch(
					process.env.REACT_APP_BACKEND_URL + "/api/v1/admin/environment",
					{
						method: "GET",
						headers: {
							"Content-Type": "application/json",
							Accept: "application/json",
							Authorization: `Bearer ${sessionStorage.getItem("token")}`
						}
						//credentials: 'include',
					}
				);
				let responseMessages2 = await requestMessages.json();
				token = responseMessages2.data.chat_data.kewb_access_token;
				sessionStorage.setItem("chat-data", token);
				if (token) {
					requestMessages = await fetch(
						process.env.REACT_APP_CHAT_URL +
							CORE_API_CHAT_ROUTE +
							chatId +
							CORE_API_GET_MESSAGES_ROUTE,
						{
							method: "POST",
							headers: {
								"Content-Type": "application/json",
								Authorization: `Bearer ${token}`
							},
							//credentials: 'include',
							body: JSON.stringify({
								limit: 100,
								onlyOwn: false,
								offset: offset
							})
						}
					);
					responseMessages = await requestMessages.json();
				}
			}
			let messages = [];
			if (responseMessages.data) {
				const messagesArray = responseMessages.data.reverse();

				messagesArray.forEach((message) => {
					const unixTime = message.createdAt;
					const time = moment(unixTime).format("HH:mm");
					messages.push({
						id: parseInt(message.id),
						message: message.message,
						type: message.messageType,
						banned: message.banned == true ? true : false,
						deleted: message.deleted == true ? true : false,
						senderIp: message.senderIp ? message.senderIp : null,
						data: {
							avatar: message.data.avatar ? message.data.avatar : "",
							name: message.data.name,
							userId: message.data.userId,
							time: time
						}
					});
				});
				if (offset != 0) {
					dispatch(MoreMessages(messages));
					dispatch(setLoading({ camera: "messages", type: false }));
				} else {
					dispatch(allMessages(messages));
					dispatch(setLoading({ camera: "messages", type: false }));
				}
				if (chat) {
					if (offset > 0) {
						messages.reverse().map((message) => {
							dispatch(chatMessageOffset(chat, message));
						});
					} else {
						chat["messages"] = messages;
						dispatch(chatMessages(chat));
						dispatch(setLoading({ camera: "messages", type: false }));
					}
				}
			}
		} catch (error) {
			console.log(error);
			dispatch(setLoading({ camera: "messages", type: false }));
		}
	};

function index(messages) {
	if (!messages) return;
	let newMessages = [];
	messages.map((message, index) => {
		if (message["isPrevious"] !== null) {
			let lastMessage = messages[index - 1];
			message.isPrevious =
				lastMessage && message.data.userId == lastMessage.data.userId
					? true
					: false;
			if (message.isPrevious) {
				message.previousSameType =
					lastMessage && message.type == lastMessage.type ? true : false;

				if (message.previousSameType) {
					message.previousId = lastMessage.previousId
						? lastMessage.previousId
						: lastMessage.id;
				}

				if (!lastMessage.previousSameType) {
					message.previousId = lastMessage.id;
				}
			}
		}
		newMessages.push(message);
	});
	return newMessages;
}

export const sendMessage = (messg, chatId) => async (dispatch) => {
	try {
		let token = sessionStorage.getItem("chat-data");

		//const request
		await fetch(
			process.env.REACT_APP_CHAT_URL +
				CORE_API_CHAT_ROUTE +
				chatId +
				CORE_API_SEND_MESSAGE_ROUTE,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`
				},
				//credentials: 'include',
				body: JSON.stringify(messg)
			}
		);
	} catch (error) {
		console.log(error);
	}
};
export const DeleteMessage = (messg, chatId) => async (dispatch) => {
	try {
		let token = sessionStorage.getItem("chat-data");

		//const request
		let requestMessages = await fetch(
			process.env.REACT_APP_CHAT_URL +
				CORE_API_CHAT_ROUTE +
				chatId +
				CORE_API_GET_MESSAGES_ROUTE,
			{
				method: "DELETE",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`
				},
				//credentials: 'include',
				body: JSON.stringify(messg)
			}
		);
		let responseMessages = await requestMessages.json();
		if (responseMessages.success) {
			dispatch(setMessage("Message was deleted", "success"));
		} else {
			dispatch(setErrors("Message was not deleted", "error"));
		}
	} catch (error) {
		console.log(error);
	}
};

export const sendGlobalTips = (value) => async (dispatch) => {
	try {
		let token = sessionStorage.getItem("core_api_token");
		//const request
		await fetch(
			"https://stage1.warehouse-x.io" + CORE_API_SEND_GLOBAL_TIPS_ROUTE,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`
				},
				//credentials: 'include',
				body: JSON.stringify(value)
			}
		);
	} catch (error) {
		console.log(error);
	}
};

export const appendMessageToState = (messg, chat) => async (dispatch) => {
	dispatch(chatMessage(chat, messg));
	dispatch(addMessage(messg));
};

export const setMessages = (messages) => async (dispatch) => {
	dispatch(setMessagesToState(messages));
};
