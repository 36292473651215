import React, { useState } from "react";
import "antd/dist/antd.css";
import { PlusOutlined } from "@ant-design/icons";
import { Upload } from "antd";
import { deleteMedia } from "../../redux/actions/modelAction";
import { connect, useDispatch, useSelector } from "react-redux";
import {
	Autocomplete,
	Backdrop,
	Box,
	Button,
	Container,
	Grid,
	IconButton,
	Input,
	Modal,
	Stack,
	TextField
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { UpdateImage } from "../../redux/actions/GalleriesActions";
import CheckboxUI from "../../UI/CheckboxUI";
import useVaporUpload from "./VaporUpload";
import axios from "axios";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 400,
	bgcolor: "background.paper",
	border: "2px solid #000",
	textAlign: "center",
	margin: "0 auto",
	p: 4
};

const getBase64 = (file) =>
	new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);

		reader.onload = () => resolve(reader.result);

		reader.onerror = (error) => reject(error);
	});
const mapStateToProps = (state) => {
	return {
		gallery: state.gallery
	};
};

const UploadGallery = ({ gallery }) => {
	const [fileList, setFileList] = useState([]);
	const [previewVisible, setPreviewVisible] = useState(false);
	const [previewImage, setPreviewImage] = useState("");
	const [previewTitle, setPreviewTitle] = useState("");
	const loading = useSelector((state) => state.loading);
	const [fileId, setId] = React.useState("");
	const [premium, setPremium] = React.useState("");
	const [selectedOptions, setSelectedOptions] = React.useState([]);
	const [selectedTags, setSelectedTags] = React.useState();
	const [metaDescription, setMetaDescription] = React.useState("");
	const [title, setTitle] = React.useState("");
	const [description, setDescription] = React.useState("");
	const handleAutocompleteChange = (target) => {
		var task_names = target.target.value;
		setSelectedOptions(task_names);
	};
	const dispatch = useDispatch();
	React.useEffect(() => {
		setFileList([]);
	}, []);

	React.useEffect(() => {
		let newFileList = [];
		if (
			gallery &&
			gallery.gallery &&
			gallery.gallery.images &&
			gallery.gallery.images.length == 0
		) {
			setFileList([]);
		}
		gallery &&
			gallery.gallery &&
			gallery.gallery.images &&
			gallery.gallery.images.forEach((image) => {
				newFileList.push({
					uid: image.id,
					is_premium: image.is_premium,
					performers: image.performers ? image.performers : [],
					metaTags: image.metaTags ? image.metaTags : [],
					metaDescription: image.metaDescription && image.metaDescription,
					title: image.title && image.title,
					description: image.description && image.description,
					status: "done",
					thumbUrl: image.mediaMeta && image.mediaMeta.thumbnail260,
					url: image.path,
					response: '{"status": "success"}', // response from server
					linkProps: '{"download": "image"}'
				});
				setFileList(newFileList);
			});
	}, [gallery]);

	const handleCancel = () => {
		setPreviewVisible(false);
		setTitle("");
		setDescription("");
	};

	const handlePreview = async (file) => {
		if (!file.url && !file.preview) {
			file.preview = await getBase64(file.originFileObj);
		}
		setPreviewImage(file.url || file.preview);
		setPremium(file.is_premium);
		setId(file.uid);
		var task_names =
			file.performers &&
			file.performers.map(function (task, index, array) {
				return task.id;
			});
		setSelectedOptions(task_names);
		setSelectedTags(file.metaTags);
		setMetaDescription(file.metaDescription);
		setDescription(file.description);
		setTitle(file.title);
		setPreviewVisible(true);
		setPreviewTitle(
			file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
		);
	};

	const handleChange = ({ fileList: newFileList }) => {
		setFileList(newFileList);
	};

	const handleRemove = (fileId) => {
		dispatch(deleteMedia(fileId, "file"));
		setFileList((prevFileList) =>
			prevFileList.filter((file) => file.uid !== fileId)
		);
		handleCancel();
	};
	const changeHandle = () => {
		setPremium(!premium);
	};
	const handleSave = () => {
		const obj = {
			participants: selectedOptions,
			is_premium: premium,
			metaDescription: inputList.metaDescription,
			metaTags: selectedTags,
			title: inputList.title,
			description: inputList.description
		};

		dispatch(UpdateImage(fileId, obj, false));
		setPreviewVisible(false);
		setMetaDescription("");
		setTitle("");
		setDescription("");
	};
	const [inputList, setInputList] = React.useState({
		metaDescription: metaDescription && metaDescription,
		title: title && title,
		description: description && description
	});
	React.useEffect(() => {
		setInputList({
			metaDescription: metaDescription && metaDescription,
			title: title && title,
			description: description && description
		});
	}, [metaDescription, title, description]);
	const handleInputChange = (e) => {
		const fieldName = event.target.getAttribute("name");
		const fieldValue = event.target.value;
		const newFormData = { ...inputList };
		newFormData[fieldName] = fieldValue;
		setInputList(newFormData);
	};
	const { uploadFile } = useVaporUpload();
	const customRequest = async ({ file, onSuccess, onError, onProgress }) => {
		try {
			console.log(file);
			const { vaporResponse } = await uploadFile(file);

			const formData = new FormData();
			formData.append("images", vaporResponse.key);
			formData.append("mediaType", "image");

			const response = await axios.post(
				`${process.env.REACT_APP_BACKEND_URL}/api/v1/admin/manager/gallery/upload-items/${
					gallery && gallery.gallery && gallery.gallery.id
				}`,
				formData,
				{
					headers: {
						Authorization: `Bearer ${sessionStorage.getItem("token")}`
					},
					onUploadProgress: (progressEvent) => {
						const percentCompleted = Math.round(
							(progressEvent.loaded * 100) / progressEvent.total
						);
						onProgress({ percent: percentCompleted });
					}
				}
			);

			onSuccess(response.data, file);
			if (response.status === 201) {
				console.log(response);
				const newPhoto = {
					uid: response.data.file.id,
					status: "done",
					url:
						response.data.file &&
						response.data.file.path &&
						response.data.file.path,
					response: '{"status": "success"}',
					linkProps: '{"download": "image"}'
				};
				setFileList((prevList) => {
					const newList = [...prevList];
					newList.pop(); // Remove the last item
					newList.push(newPhoto);
					return newList;
				});
			}
		} catch (error) {
			onError(error);
		}
	};
	const uploadButton = (
		<div>
			<PlusOutlined style={{ color: "white" }} />
			<div
				style={{
					marginTop: 8,
					color: "white"
				}}
			>
				Upload
			</div>
		</div>
	);
	const props = {
		customRequest: customRequest,
		listType: "picture-card",
		name: "images",
		multiple: true
	};

	return (
		<>
			<Upload
				className={"custom-upload"}
				{...props}
				fileList={fileList}
				data={{ mediaType: "image" }}
				onPreview={handlePreview}
				onChange={handleChange}
				/*onRemove={handleRemove}*/
				defaultFileList={setFileList}
				key={setFileList}
				style={{ backgroundColor: "black", color: "white" }}
			>
				{fileList.length >= 30 ? null : uploadButton}
			</Upload>
			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				closeAfterTransition
				open={previewVisible}
				// onCancel={handleCancel}
				onClose={handleCancel}
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500
				}}
				className="camera_details"
			>
				<Box
					sx={style}
					style={{
						marginTop: "50px"
					}}
				>
					{loading && loading.isLoading == true ? (
						<div className="loader-container">
							<div className="spinner"></div>
						</div>
					) : (
						""
					)}
					<IconButton className="BackButton" onClick={handleCancel}>
						<ClearIcon />
					</IconButton>
					<Container component="main" maxWidth="xs">
						<Box
							sx={{
								marginTop: 2,
								display: "flex",
								flexDirection: "row",
								alignItems: "center"
							}}
							md={3}
						>
							<Box
								sx={{
									bgcolor: "transparent",
									paddingRight: "10px",
									paddingBottom: "10px"
								}}
								md={3}
							>
								<img
									style={{
										width: "50%",
										height: "50%"
									}}
									src={previewImage}
								/>
							</Box>
						</Box>
						<Box
							sx={{
								marginTop: 2,
								display: "flex",
								flexDirection: "column",
								alignItems: "center"
							}}
						>
							<Box component="form" noValidate sx={{ mt: 3 }}>
								<Grid item xs={12}>
									<CheckboxUI
										style={{ color: "white" }}
										title={"Premium"}
										name="freeUnlocked"
										value="allowExtraEmails"
										color="primary"
										onChange={changeHandle}
										checked={premium == 0 ? false : true}
									/>
								</Grid>
							</Box>
						</Box>
					</Container>
					<Autocomplete
						className="LazyInput"
						multiple
						name="participants"
						id="id"
						style={{ width: "100%" }}
						onChange={(event, value) => {
							handleAutocompleteChange({
								target: {
									value: value
								}
							});
						}}
						options={
							gallery && gallery.filters && gallery.filters.models
								? Object.keys(gallery.filters.models)
								: []
						}
						defaultValue={selectedOptions && selectedOptions}
						key={selectedOptions && selectedOptions}
						getOptionLabel={(option) => gallery.filters.models[option] || ""}
						renderInput={(params) => (
							<TextField
								{...params}
								disabled
								variant="standard"
								name="participants"
							/>
						)}
					/>
					<Stack
						direction="row"
						className="Pools_row"
						spacing={1}
						style={{ paddingTop: "15px", paddingBottom: "15px" }}
					>
						<Autocomplete
							multiple
							id="tags-standard"
							freeSolo={true}
							fullWidth
							value={selectedTags && selectedTags}
							onChange={(event, value) => setSelectedTags(value)}
							options={[]}
							sx={{
								backgroundColor: "#1d1d1d",
								borderRadius: "4px",
								textAlign: "left",
								fontSize: "14px"
							}}
							className="tags-input"
							renderInput={(params) => (
								<TextField
									{...params}
									variant="standard"
									placeholder="Input tags"
									placeholderStyle={{ color: "red" }}
									sx={{
										borderRadius: "4px",
										padding: "0px 10px 0px 5px",
										textAlign: "left",
										fontSize: "14px"
									}}
								/>
							)}
						/>
					</Stack>
					<Stack
						direction="row"
						style={{ paddingBottom: "15px" }}
						className="Pools_row"
					>
						<Input
							value={inputList.metaDescription}
							name="metaDescription"
							multiline
							onChange={(e) => {
								handleInputChange(e);
							}}
							style={{ padding: "5px", height: "unset" }}
							placeholder="Meta Description"
							className="Pools_input_text"
							disableUnderline="true"
						></Input>
					</Stack>
					<Stack
						direction="row"
						style={{ paddingBottom: "15px" }}
						className="Pools_row"
					>
						<Input
							value={inputList.title}
							name="title"
							multiline
							onChange={(e) => {
								handleInputChange(e);
							}}
							style={{ padding: "5px", height: "unset" }}
							placeholder="Title"
							className="Pools_input_text"
							disableUnderline="true"
						></Input>
					</Stack>
					<Stack
						direction="row"
						style={{ paddingBottom: "15px" }}
						className="Pools_row"
					>
						<Input
							value={inputList.description}
							name="description"
							multiline
							onChange={(e) => {
								handleInputChange(e);
							}}
							style={{ padding: "5px", height: "unset" }}
							placeholder="Description"
							className="Pools_input_text"
							disableUnderline="true"
						></Input>
					</Stack>
					<Button
						onClick={handleSave}
						variant="contained"
						style={{
							width: "100%",
							marginTop: "10px",
							color: "black",
							backgroundColor: "#78F73B"
						}}
					>
						Save
					</Button>
					{fileId && (
						<Button
							type="error"
							onClick={() => {
								handleRemove(fileId);
							}}
							variant="contained"
							style={{
								width: "100%",
								marginTop: "10px",
								color: "black",
								backgroundColor: "red"
							}}
						>
							Delete
						</Button>
					)}
				</Box>
			</Modal>
		</>
	);
};

export default connect(mapStateToProps)(UploadGallery);
