export const SidebarDataSuper = [
	{
		name: "Home",
		link: "dashBoard",
		cName: "nav-text",
		permission: "panel_statistics"
	},
	{
		name: "Girl Management",
		link: "girlManagement",
		cName: "nav-text",
		permission: "panel_girl_management"
	},
	{
		name: "Live Rooms",
		link: "liveShows",
		cName: "nav-text",
		permission: "panel_liveshow"
	},
	{
		name: "Cameras",
		link: "cameras",
		cName: "nav-text",
		childrens: [
			{
				name: "All Cameras",
				link: "cameras",
				cName: "nav-text",
				permission: "panel_cameras"
			},
			{
				name: "Camera Activity",
				link: "cameras_activity",
				cName: "nav-text",
				permission: "panel_cameras_activity"
			}
		]
	},
	{
		name: "Chat Moderation",
		link: "chatModeration",
		cName: "nav-text",
		permission: "panel_chat_moderation"
	},
	{
		name: "Earnings",
		link: "earnings",
		cName: "nav-text",
		permission: "panel_earnings"
	},
	{
		name: "Transactions",
		link: "transactions",
		cName: "nav-text",
		permission: "panel_transactions"
	},
	{
		name: "Users",
		link: "users",
		cName: "nav-text",
		permission: "panel_users"
	},
	{
		name: "Geoblock",
		link: "geoBlock",
		cName: "nav-text",
		permission: "panel_geoblock"
	},
	{
		name: "Tipping Pools",
		link: "pools",
		cName: "nav-text",
		permission: "panel_tipping_pools"
	},
	{
		name: "Blogs",
		link: "blogs",
		cName: "nav-text",
		permission: "panel_blogs"
	},
	{
		name: "Promos",
		link: "promos",
		cName: "nav-text",
		permission: "panel_promos"
	},
	{
		name: "Events",
		link: "events",
		cName: "nav-text",

		childrens: [
			{
				name: "Events Schedule",
				link: "events_schedule",
				cName: "nav-text",
				permission: "panel_events_schedule"
			},
			{
				name: "Magic X ",
				link: "events_magic",
				cName: "nav-text",
				permission: "panel_events_magicx"
			}
		]
	},
	{
		name: "Galleries",
		link: "/galleries",
		cName: "nav-text",
		permission: "panel_galleries"
	},
	{ name: "Tube", link: "/tube", cName: "nav-text", permission: "panel_tube" },
	{
		name: "Stud-X",
		link: "stud",
		cName: "nav-text",
		childrens: [
			{
				name: "Microphones",
				link: "microphones",
				cName: "nav-text",
				permission: "panel_special_events"
			},
			{
				name: "Stud-X",
				link: "stud-x",
				cName: "nav-text",
				permission: "panel_special_events"
			}
			/*{
				name: "Stud Actions",
				link: "stud",
				cName: "nav-text",
				permission: "panel_cameras_activity"
			}*/
		]
	},
	{
		name: "Queen-X",
		link: "queen",
		cName: "nav-text",
		childrens: [
			{
				name: "Microphones",
				link: "microphones",
				cName: "nav-text",
				permission: "panel_special_events"
			},
			{
				name: "Queen-X",
				link: "queen-x",
				cName: "nav-text",
				permission: "panel_special_events"
			}
		]
	},
	{
		name: "Milf-X",
		link: "milf",
		cName: "nav-text",
		childrens: [
			{
				name: "Microphones",
				link: "microphones",
				cName: "nav-text",
				permission: "panel_special_events"
			},
			{
				name: "Milf-X",
				link: "milf-x",
				cName: "nav-text",
				permission: "panel_special_events"
			}
		]
	}
	/*   { name: "Global", link: "y", cName: "nav-text",permission:" }, */
];
