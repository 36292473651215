import * as React from "react";
import { useCallback, useEffect, useRef, useState } from "react";
import "antd/dist/antd.css";
import { PlusOutlined } from "@ant-design/icons";
import "video-react/dist/video-react.css";
import { connect, useDispatch, useSelector } from "react-redux";
import {
	Autocomplete,
	Backdrop,
	Box,
	Button,
	Container,
	Grid,
	IconButton,
	Input,
	Modal,
	Stack,
	TextField
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import CheckboxUI from "../../UI/CheckboxUI";
import { LoadingButton } from "@mui/lab";
import {
	TubeVideoUpload,
	UpdateTubeVideo
} from "../../redux/actions/TubeActions";
import { setMessage } from "../../redux/reducers/rootReducer";
import useVaporUpload from "./VaporUpload";
import { defaultTags } from "./UploadTubePhotos";
import Typography from "@mui/material/Typography";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 700,
	bgcolor: "background.paper",
	border: "2px solid #000",
	textAlign: "center",
	margin: "0 auto",
	p: 4,
	maxHeight: "700px",
	overflow: "auto"
};

const mapStateToProps = (state) => {
	return {
		gallery: state.gallery
	};
};

const UploadTubeVideos = ({ filters }) => {
	const dispatch = useDispatch();
	const loading = useSelector((state) => state.loading);
	const [uploadedVideo, setUploadedVideo] = useState(null);
	const [premium, setPremium] = useState(false);
	const [tube, setTube] = useState(true);
	const [selectedOptions, setSelectedOptions] = useState([]);
	const [selectedTags, setSelectedTags] = useState(defaultTags);
	const [metaDescription, setMetaDescription] = useState("");
	const [title, setTitle] = useState("");
	const [description, setDescription] = useState("");
	const [reel, setReel] = useState(uploadedVideo?.is_vertical ? true : false);
	const [selectedImage, setSelectedImage] = React.useState(null);
	const [posterImage, setPosterImage] = React.useState(null);
	const handleAutocompleteChange = (target) => {
		var task_names = target.target.value;
		setSelectedOptions(task_names);
	};

	const changeHandle = () => {
		setPremium(!premium);
	};

	const changeHandleTubeIsReel = () => {
		setReel(!reel);
	};
	useEffect(() => {
		setReel(uploadedVideo?.is_vertical ? true : false);
	}, [uploadedVideo]);

	useEffect(() => {
		const handleUpload = async () => {
			if (selectedImage) {
				try {
					const { vaporResponse } = await uploadFile(selectedImage);
					setPosterImage(vaporResponse.key);
					setSelectedImage(null);
					dispatch(setMessage("Poster image successfully updated", "success"));
				} catch (error) {
					console.error("Error uploading file: ", error);
					setSelectedImage(null);
				}
			}
		};

		handleUpload();
	}, [selectedImage]);
	const captureFrame = (video, currentTime) => {
		return new Promise((resolve, reject) => {
			const canvas = document.createElement("canvas");
			const context = canvas.getContext("2d");
			video.currentTime = currentTime;

			video.onseeked = () => {
				canvas.width = video.videoWidth;
				canvas.height = video.videoHeight;
				context.drawImage(video, 0, 0, canvas.width, canvas.height);
				canvas.toBlob((blob) => {
					if (blob) {
						resolve(blob);
					} else {
						reject(new Error("Failed to create Blob"));
					}
				}, "image/jpeg");
			};

			video.onerror = (error) => {
				reject(error);
			};
		});
	};
	const handleThumbUpdate = async () => {
		const video = document.querySelector("video.VideoInput_video");
		try {
			const imageBlob = await captureFrame(video, currentTime);

			// Create a File from the Blob
			const file = new File([imageBlob], `frame_${currentTime}.jpeg`, {
				type: "image/jpeg",
				lastModified: Date.now()
			});
			setSelectedImage(file);
		} catch (error) {
			console.error("Error capturing frame: ", error);
		}
	};

	const handleSave = () => {
		const obj = {
			participants: selectedOptions,
			is_premium: premium,
			is_tube: tube,
			metaDescription: inputList.metaDescription,
			tags: selectedTags,
			title: inputList.title,
			description: inputList.description,
			is_vertical: reel,
			poster_image: posterImage
		};
		dispatch(UpdateTubeVideo(uploadedVideo.id, obj));
		setUploadedVideo(null);
		setMetaDescription("");
		setTitle("");
		setDescription("");
		setPosterImage(null);
		setSelectedOptions([]);
		closeCustomModal();
	};

	const [inputList, setInputList] = useState({
		metaDescription: metaDescription,
		title: title,
		description: description
	});

	useEffect(() => {
		setInputList({
			metaDescription: metaDescription,
			title: title,
			description: description
		});
	}, [metaDescription, title, description]);

	const handleInputChange = useCallback((e) => {
		const fieldName = e.target.getAttribute("name");
		const fieldValue = e.target.value;
		setInputList((prevState) => ({
			...prevState,
			[fieldName]: fieldValue
		}));
	}, []);

	const [customModalVisible, setCustomModalVisible] = useState(false);
	const openCustomModal = () => {
		setCustomModalVisible(true);
		setInputList({
			metaDescription: "",
			title: "",
			description: ""
		});
	};

	const closeCustomModal = () => {
		if (uploading) {
			return dispatch(setMessage("Video Loading is in progress", "error"));
		}
		setCustomModalVisible(false);
		setSource(null);
		setUploadedVideo(null);
	};
	const inputRef = useRef();

	const [source, setSource] = useState();
	const [uploading, setUploading] = useState(false);
	const { uploadFile } = useVaporUpload();
	const handleFileChange = async (event) => {
		const file = event.target.files[0];
		const url = URL.createObjectURL(file);
		setUploading(true);
		const { vaporResponse } = await uploadFile(file);
		const formData = new FormData();
		formData.append("mediaType", "video");
		formData.append("title", vaporResponse.uuid);
		formData.append("fullMovie", vaporResponse.key);

		fetch(
			process.env.REACT_APP_BACKEND_URL +
				"/api/v1/admin/manager/tube/upload-video",
			{
				method: "POST",
				body: formData,
				headers: {
					Authorization: `Bearer ${sessionStorage.getItem("token")}`
				}
			}
		)
			.then((response) => {
				if (!response.ok) {
					setUploading(false);
					setSource(null);
					throw new Error(`Can't upload video, try another one`);
				}
				return response.json();
			})
			.then((data) => {
				if (data.type == "success") {
					dispatch(TubeVideoUpload(data.data));
					dispatch(setMessage(data.message, data.type));
					setUploadedVideo(data.data);
					setUploading(false);
					setSource(url);
				}
				// Handle success response
			})
			.catch((error) => {
				// Handle error
				setUploading(false);
				setSource(null);
				dispatch(setMessage(error?.message, "error"));
			})
			.finally(() => {
				setTimeout(() => {
					setUploading(false);
				}, 2000);
			});
	};

	const handleChoose = (event) => {
		inputRef.current.click();
	};
	const [currentTime, setCurrentTime] = useState(0);

	const handleTimeUpdate = (event) => {
		setCurrentTime(event?.target?.currentTime);
	};
	const formatTime = (time) => {
		const minutes = Math.floor(time / 60);
		const seconds = Math.floor(time % 60);
		const milliseconds = Math.floor((time % 1) * 1000);
		return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}:${String(milliseconds).padStart(3, "0")}`;
	};
	return (
		<>
			<div
				style={{
					background: "grey ",
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					justifyContent: "center",
					borderRadius: "5px",
					padding: "15px",
					width: "100px",
					cursor: "pointer"
				}}
				onClick={openCustomModal}
			>
				<PlusOutlined style={{ color: "white" }} />
				<div
					style={{
						marginTop: 8,
						color: "white"
					}}
				>
					Upload
				</div>
			</div>
			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				closeAfterTransition
				open={customModalVisible}
				onCancel={closeCustomModal}
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500,
					onClick: closeCustomModal
				}}
				className="camera_details"
			>
				<Box
					sx={style}
					style={{
						marginTop: "50px"
					}}
				>
					{loading && loading.isLoading == true ? (
						<div className="loader-container">
							<div className="spinner"></div>
						</div>
					) : (
						""
					)}
					<IconButton className="BackButton" onClick={closeCustomModal}>
						<ClearIcon />
					</IconButton>
					<Container component="main" maxWidth="xs">
						<div className="VideoInput">
							<input
								ref={inputRef}
								className="VideoInput_input"
								type="file"
								onChange={handleFileChange}
								accept="video/*"
							/>
							{!source && (
								<LoadingButton
									loading={uploading}
									sx={{
										mt: 3,
										mb: 3,
										py: 1.5,
										borderRadius: "10px",
										textTransform: "unset",
										fontSize: "1.1rem",
										".MuiCircularProgress-root": {
											width: "23px !important",
											height: "23px !important"
										},
										".MuiLoadingButton-loadingIndicator": {
											color: "black"
										}
									}}
									style={{
										width: "100%",
										marginTop: "10px",
										color: uploading ? "#78F73B" : "black",
										backgroundColor: "#78F73B"
									}}
									onClick={handleChoose}
								>
									Upload Video
								</LoadingButton>
							)}
							{source && (
								<video
									className="VideoInput_video"
									style={{
										minWidth: "300px",
										width: "100%",
										height: "300px",
										objectFit: "contain"
									}}
									controls
									autoPlay={true}
									src={source}
									onTimeUpdate={handleTimeUpdate}
								/>
							)}
							{source && (
								<Box
									style={{
										display: "flex",
										flexDirection: "column",
										alignItems: "center",
										width: "100%",
										marginTop: "10px"
									}}
								>
									<Typography style={{ color: "#78F73B", fontSize: "1rem" }}>
										{formatTime(currentTime)}
									</Typography>
									<Button
										variant="contained"
										style={{
											width: "fit-content",
											marginTop: "10px",
											color: "black",
											backgroundColor: "#78F73B"
										}}
										onClick={handleThumbUpdate}
									>
										Update poster image
									</Button>
								</Box>
							)}
						</div>
						<Box
							sx={{
								marginTop: 2,
								display: "flex",
								flexDirection: "column",
								alignItems: "center"
							}}
						>
							<Box component="form" noValidate sx={{ mt: 3 }}>
								<Grid item xs={12}>
									<CheckboxUI
										style={{ color: "white" }}
										title={"Non Nude"}
										name="freeUnlocked"
										value="allowExtraEmails"
										color="primary"
										onChange={changeHandle}
										checked={premium == 0 ? true : false}
									/>

									<CheckboxUI
										style={{ color: "white" }}
										title={"Reel"}
										name="freeUnlocked"
										value="allowExtraEmails"
										color="primary"
										onChange={changeHandleTubeIsReel}
										checked={reel == 0 ? false : true}
									/>
								</Grid>
							</Box>
						</Box>
					</Container>

					<Autocomplete
						className="LazyInput"
						multiple
						name="participants"
						id="id"
						style={{ width: "100%", marginBottom: "15px", maxWidth: "100%" }}
						onChange={(event, value) => {
							handleAutocompleteChange({
								target: {
									value: value
								}
							});
						}}
						options={
							filters && filters.models ? Object.keys(filters.models) : []
						}
						defaultValue={selectedOptions && selectedOptions}
						key={selectedOptions && selectedOptions}
						getOptionLabel={(option) => filters.models[option] || ""}
						renderInput={(params) => (
							<TextField
								{...params}
								placeholder={"Participants"}
								variant="standard"
								name="participants"
								sx={{
									borderRadius: "4px",
									padding: "0px 10px 0px 5px",
									textAlign: "left",
									fontSize: "14px"
								}}
							/>
						)}
					/>
					<Stack
						direction="row"
						style={{ paddingBottom: "15px" }}
						className="Pools_row"
					>
						<Input
							value={inputList.title}
							name="title"
							multiline
							onChange={(e) => {
								handleInputChange(e);
							}}
							style={{ padding: "5px", height: "unset" }}
							placeholder="Title"
							className="Pools_input_text"
							disableUnderline="true"
						></Input>
					</Stack>
					{/*	<Stack
						direction="row"
						style={{ paddingBottom: "15px" }}
						className="Pools_row"
					>
						<Input
							value={inputList.description}
							name="description"
							multiline
							onChange={(e) => {
								handleInputChange(e);
							}}
							style={{ padding: "5px", height: "unset" }}
							placeholder="Description"
							className="Pools_input_text"
							disableUnderline="true"
						></Input>
					</Stack>*/}
					<Stack
						direction="row"
						className="Pools_row"
						style={{ paddingBottom: "15px" }}
						spacing={1}
					>
						<Autocomplete
							multiple
							id="tags-standard"
							freeSolo={true}
							fullWidth
							onChange={(event, value) => setSelectedTags(value)}
							defaultValue={selectedTags}
							value={selectedTags}
							options={[]}
							sx={{
								backgroundColor: "#1d1d1d",
								borderRadius: "4px",
								textAlign: "left",
								fontSize: "14px"
							}}
							className="tags-input"
							renderInput={(params) => (
								<TextField
									{...params}
									variant="standard"
									placeholder="Input tags"
									placeholderStyle={{ color: "red" }}
									sx={{
										borderRadius: "4px",
										padding: "0px 10px 0px 5px",
										textAlign: "left",
										fontSize: "14px"
									}}
								/>
							)}
						/>
					</Stack>
					{/*<Stack
						direction="row"
						style={{ paddingBottom: "15px" }}
						className="Pools_row"
					>
						<Input
							value={inputList.metaDescription}
							name="metaDescription"
							multiline
							onChange={(e) => {
								handleInputChange(e);
							}}
							style={{ padding: "5px", height: "unset" }}
							placeholder="Meta Description"
							className="Pools_input_text"
							disableUnderline="true"
						></Input>
					</Stack>*/}

					{/*<Button*/}
					{/*    onClick={handleSave}*/}
					{/*    variant="contained"*/}
					{/*    loading={uploading}*/}
					{/*    disabled={source == null}*/}
					{/*    style={{*/}
					{/*        width: "100%",*/}
					{/*        marginTop: "10px",*/}
					{/*        color: "black",*/}
					{/*        backgroundColor: "#78F73B",*/}
					{/*        opacity: source == null && "0.5"*/}
					{/*    }}*/}
					{/*>*/}
					{/*    Save*/}
					{/*</Button>*/}
					<LoadingButton
						type="submit"
						onClick={handleSave}
						variant="contained"
						disabled={source == null}
						sx={{
							mt: 3,
							mb: 3,
							py: 1.5,
							borderRadius: "10px",
							textTransform: "unset",
							fontSize: "1.1rem",
							".MuiCircularProgress-root": {
								width: "23px !important",
								height: "23px !important"
							},
							".MuiLoadingButton-loadingIndicator": {
								color: "black"
							}
						}}
						style={{
							width: "100%",
							marginTop: "10px",
							color: "black",
							backgroundColor: "#78F73B",
							opacity: source == null && "0.5"
						}}
					>
						{"Save"}
					</LoadingButton>
				</Box>
			</Modal>
		</>
	);
};

export default connect(mapStateToProps)(UploadTubeVideos);
