import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { useDispatch, useSelector } from "react-redux";
import { Button, Container, Fade, Grow, Tab } from "@mui/material";
import { hideModal, setMessage } from "../../redux/reducers/rootReducer";
import axios from "axios";
import CloseButton from "../../UI/CloseButton";
import TabPanel from "@mui/lab/TabPanel";
import TabContext from "@mui/lab/TabContext";
import { gsap } from "gsap";
import TabList from "@mui/lab/TabList";

export default function LushModal({ modal }) {
	const dispatch = useDispatch();
	const model = useSelector((state) => state.model.user);
	const [lovense_qr, setlovense_qr] = React.useState(model?.lovense_qr);
	const [ohmibod_qr, setohmibod_qr] = React.useState(model?.ohmibod_qr);
	const [neocamx_qr, setneocamx_qr] = React.useState(model?.neocamx_qr);
	React.useEffect(() => {
		setlovense_qr(model?.lovense_qr);
		setohmibod_qr(model?.ohmibod_qr);
		setneocamx_qr(model?.neocamx_qr);
	}, [model]);
	const [value, setValue] = React.useState("lush");

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	const stylePass = {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		maxWidth: 480,
		minWidth: 320,
		bgcolor: "#171717",
		height: "fit-content",
		minHeight: "50vh",
		maxHeight: "80vh",
		width: "100%",
		p: 2,
		color: "#FFFFFF",
		borderRadius: "10px"
	};

	const handleClose = () => {
		// setSearchParams({login: false});
		dispatch(hideModal("lushPairing"));
	};

	const animationRef = React.useRef();
	const animationRef2 = React.useRef();
	const tl = React.useRef();
	const tl2 = React.useRef();

	function startAnimation() {
		tl.current.play();
		tl2.current.play();
	}

	function stopAnimation() {
		tl.current.reversed(true);
		tl2.current.reversed(true);
	}

	React.useLayoutEffect(() => {
		let ctx = gsap.context(() => {
			tl.current = gsap.timeline({ paused: true });
			tl.current.to(animationRef.current, {
				opacity: 0,
				duration: 0.1
			});
			tl2.current = gsap.timeline({ paused: true });
			tl2.current.to(animationRef2.current, {
				opacity: 0,
				duration: 0.1
			});
		});
		return () => ctx.revert();
	}, []);

	const changeHandle = async (type) => {
		/*  if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        } */
		startAnimation();
		const res = await axios.post(
			process.env.REACT_APP_BACKEND_URL +
				`/api/v1/admin/manager/model-profile/${model?.id}/lush/update/${type}`,
			{},
			{
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
					Authorization: `Bearer ${sessionStorage.getItem("token")}`
				}
			}
		);
		if (res.data.data) {
			setlovense_qr(res.data.data.lovense_qr);
			setohmibod_qr(res.data.data.ohmibod_qr);
			setneocamx_qr(res.data.data.neocamx_qr);
			dispatch(setMessage(res.data.message, "success"));
			stopAnimation();
		}
	};

	return (
		<Modal
			aria-labelledby="modal-modal-title"
			aria-describedby="modal-modal-description"
			open={modal && modal.name === "lushPairing"}
			onClose={handleClose}
		>
			<Box sx={stylePass}>
				<CloseButton onClick={handleClose} />
				<Fade in delay={200} timeout={400}>
					<Container
						maxWidth="xs"
						style={{
							backgroundColor: "#FFFFFF",
							borderRadius: "10px",
							padding: "40px"
						}}
					>
						<TabContext value={value}>
							<TabList
								value={value}
								onChange={handleChange}
								aria-label="Toy variants"
								sx={{
									".MuiTabs-flexContainer": {
										justifyContent: "center",
										gap: 2
									},
									".MuiTabs-indicator": {
										display: "none"
									},
									".Mui-selected": {
										color: "#77f73b !important"
									}
								}}
							>
								<Tab
									label="Lush"
									value="lush"
									sx={{ backgroundColor: "#000", borderRadius: "10px" }}
								/>
								<Tab
									label="OhMiBod"
									value="omnibot"
									sx={{ backgroundColor: "#000", borderRadius: "10px" }}
								/>

								<Tab
									label="NeocamX"
									value="neocamx"
									sx={{ backgroundColor: "#000", borderRadius: "10px" }}
								/>
							</TabList>
							<TabPanel value="lush">
								<Grow in delay={400} timeout={600}>
									<Box ref={animationRef}>
										<img
											alt="QR code"
											src={lovense_qr + "?" + Date.now()}
											style={{ margin: "0 auto", display: "block" }}
										/>
									</Box>
								</Grow>
								<Box
									style={{
										display: "flex",
										flexDirection: "column",
										alignItems: "center",
										marginTop: "30px"
									}}
								>
									<Button
										onClick={() => {
											changeHandle("lush");
										}}
										variant="raised"
										component="span"
										className="btn-primary"
										style={{
											padding: "5px 30px",
											color: "black",
											backgroundColor: "#77F73B"
										}}
									>
										{"Update qr code"}
									</Button>
								</Box>
							</TabPanel>
							<TabPanel value="omnibot">
								<Grow in delay={400} timeout={600}>
									<Box ref={animationRef2}>
										<img
											alt="QR code"
											src={ohmibod_qr + "?" + Date.now()}
											style={{
												margin: "0 auto",
												display: "block",
												width: "100%"
											}}
										/>
									</Box>
								</Grow>
								<Box
									style={{
										display: "flex",
										flexDirection: "column",
										alignItems: "center",
										marginTop: "30px"
									}}
								>
									<Button
										onClick={() => {
											changeHandle("ohmibod");
										}}
										variant="raised"
										component="span"
										className="btn-primary"
										style={{
											padding: "5px 30px",
											color: "black",
											backgroundColor: "#77F73B"
										}}
									>
										{"Update qr code"}
									</Button>
								</Box>
							</TabPanel>

							<TabPanel value="neocamx">
								<Grow in delay={400} timeout={600}>
									<Box ref={animationRef2}>
										<img
											alt="QR code"
											src={neocamx_qr + "?" + Date.now()}
											style={{
												margin: "0 auto",
												display: "block",
												width: "100%"
											}}
										/>
									</Box>
								</Grow>
								<Box
									style={{
										display: "flex",
										flexDirection: "column",
										alignItems: "center",
										marginTop: "30px"
									}}
								>
									<Button
										onClick={() => {
											changeHandle("neocamx");
										}}
										variant="raised"
										component="span"
										className="btn-primary"
										style={{
											padding: "5px 30px",
											color: "black",
											backgroundColor: "#77F73B"
										}}
									>
										{"Update qr code"}
									</Button>
								</Box>
							</TabPanel>
						</TabContext>
					</Container>
				</Fade>
			</Box>
		</Modal>
	);
}
