import React, { useEffect, useMemo, useState } from "react";
import "antd/dist/antd.css";
import { PlusOutlined } from "@ant-design/icons";
import { Upload } from "antd";
import ReactHlsPlayer from "react-hls-player";
import "video-react/dist/video-react.css";
import { deleteMedia } from "../../redux/actions/modelAction";
import { connect, useDispatch, useSelector } from "react-redux";
import axios from "axios";
import styled from "./StreamScreen.module.css";
import {
	Autocomplete,
	Backdrop,
	Box,
	Button,
	Container,
	Grid,
	IconButton,
	Input,
	Modal,
	Stack,
	TextField
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { UpdateVideo } from "../../redux/actions/GalleriesActions";
import CheckboxUI from "../../UI/CheckboxUI";
import useVaporUpload from "./VaporUpload";
import Typography from "@mui/material/Typography";

const style = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	width: 400,
	bgcolor: "background.paper",
	border: "2px solid #000",
	textAlign: "center",
	margin: "0 auto",
	p: 4,
	maxHeight: "700px",
	overflow: "auto"
};

const getBase64 = (file) =>
	new Promise((resolve, reject) => {
		const reader = new FileReader();
		reader.readAsDataURL(file);

		reader.onload = () => resolve(reader.result);

		reader.onerror = (error) => reject(error);
	});
const mapStateToProps = (state) => {
	return {
		gallery: state.gallery
	};
};

const UploadGalleryVideos = ({ gallery }) => {
	const dispatch = useDispatch();
	React.useEffect(() => {
		setFileList([]);
	}, []);
	const [previewVisible, setPreviewVisible] = useState(false);
	const [previewImage, setPreviewImage] = useState("");
	const [previewTitle, setPreviewTitle] = useState("");
	const [fileList, setFileList] = useState([]);
	const loading = useSelector((state) => state.loading);
	const [fileId, setId] = React.useState("");
	const [premium, setPremium] = React.useState("");
	const [tube, setTube] = React.useState("");
	const [selectedOptions, setSelectedOptions] = React.useState([]);
	const [selectedTags, setSelectedTags] = React.useState();
	const [metaDescription, setMetaDescription] = React.useState("");
	const [title, setTitle] = React.useState("");
	const [description, setDescription] = React.useState("");
	const [selectedImage, setSelectedImage] = React.useState(null);
	const handleAutocompleteChange = (target) => {
		var task_names = target.target.value;
		setSelectedOptions(task_names);
	};
	React.useEffect(() => {
		let newFileList = [];
		if (
			gallery &&
			gallery.gallery &&
			gallery.gallery.videos &&
			gallery.gallery.videos.length == 0
		) {
			setFileList([]);
		}
		gallery &&
			gallery.gallery &&
			gallery.gallery.videos &&
			gallery.gallery.videos.forEach((video) => {
				newFileList.push({
					uid: video.id,
					status: "done",
					is_premium: video.is_premium,
					performers: video.performers ? video.performers : [],
					metaTags: video.metaTags && video.metaTags,
					metaDescription: video.metaDescription && video.metaDescription,
					title: video.title && video.title,
					url:
						video.poster_image &&
						video.poster_image.mediaMeta &&
						video.poster_image.mediaMeta.imageLarge,
					response: '{"status": "success"}', // response from server
					linkProps: '{"download": "video"}'
				});

				setFileList(newFileList);
			});
	}, [gallery]);

	const handleCancel = () => {
		setPreviewVisible(false);
		setTitle("");
		setDescription("");
	};

	const handlePreview = async (file) => {
		const res = await axios.get(
			`${process.env.REACT_APP_BACKEND_URL}/api/v1/models/media/video/` +
				file.uid,
			{
				withCredentials: true,
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
					Authorization: `Bearer ${sessionStorage.getItem("token")}`
				}
			}
		);
		if (res.data) {
			setPreviewImage(res.data.data.player);
			setPremium(res.data.data.video.is_premium);
			setTube(res.data.data.video.is_tube);
			setId(res.data.data.video.id);
			var task_names = res.data.data.video.performers.map(
				function (task, index, array) {
					return task.id;
				}
			);
			setSelectedOptions(task_names);
			setSelectedTags(file.metaTags);
			setMetaDescription(file.metaDescription);
			setDescription(file.description);
			setTitle(file.title);
			setPreviewVisible(true);
			setPreviewTitle(
				file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
			);
		}
	};

	const handleChange = ({ fileList: newFileList }) => {
		setFileList(newFileList);
	};

	const handleRemove = (fileId) => {
		dispatch(deleteMedia(fileId, "video"));
		setFileList((prevFileList) =>
			prevFileList.filter((file) => file.uid !== fileId)
		);
		handleCancel();
	};
	const changeHandle = () => {
		setPremium(!premium);
	};
	const changeHandleTube = () => {
		setTube(!tube);
	};
	const handleSave = () => {
		/* if (selectedOptions.length == 0) {
             dispatch(setMessage('At least one participant is required', 'error'))
             return
         }*/

		const obj = {
			participants: selectedOptions,
			is_premium: premium,
			is_tube: tube,
			metaDescription: inputList.metaDescription,
			metaTags: selectedTags,
			title: inputList.title,
			description: inputList.description
		};
		dispatch(UpdateVideo(fileId, obj));
		setPreviewVisible(false);
		setMetaDescription("");
		setTitle("");
		setDescription("");
	};

	const { uploadFile } = useVaporUpload();
	useEffect(() => {
		const handleUpload = async () => {
			if (selectedImage) {
				try {
					const { vaporResponse } = await uploadFile(selectedImage);
					const formData = new FormData();
					formData.append("poster_image", vaporResponse.key);
					formData.append("title", inputList.title);
					formData.append("participants", selectedOptions ?? []);
					dispatch(UpdateVideo(fileId, formData, true));
				} catch (error) {
					console.error("Error uploading file: ", error);
				}
			}
		};

		handleUpload();
	}, [selectedImage]);
	const [inputList, setInputList] = React.useState({
		metaDescription: metaDescription && metaDescription,
		title: title && title,
		description: description && description
	});
	React.useEffect(() => {
		setInputList({
			metaDescription: metaDescription && metaDescription,
			title: title && title,
			description: description && description
		});
	}, [metaDescription, title, description]);
	const handleInputChange = (e) => {
		const fieldName = event.target.getAttribute("name");
		const fieldValue = event.target.value;
		const newFormData = { ...inputList };
		newFormData[fieldName] = fieldValue;
		setInputList(newFormData);
	};

	const customRequest = async ({ file, onSuccess, onError, onProgress }) => {
		try {
			console.log(file);
			const { vaporResponse } = await uploadFile(file);

			const formData = new FormData();
			formData.append("title", vaporResponse.uuid);
			formData.append("fullMovie", vaporResponse.key);

			const response = await axios.post(
				`${process.env.REACT_APP_BACKEND_URL}/api/v1/admin/manager/gallery/upload-video/${
					gallery && gallery.gallery && gallery.gallery.id
				}`,
				formData,
				{
					headers: {
						Authorization: `Bearer ${sessionStorage.getItem("token")}`
					},
					onUploadProgress: (progressEvent) => {
						const percentCompleted = Math.round(
							(progressEvent.loaded * 100) / progressEvent.total
						);
						onProgress({ percent: percentCompleted });
					}
				}
			);

			onSuccess(response.data, file);
			if (response.status === 200) {
				console.log(response);
				const newPhoto = {
					uid: response.data.data.id,
					status: "done",
					url:
						response.data.data &&
						response?.data?.data?.poster_image?.mediaMeta?.imageLarge &&
						response?.data?.data?.poster_image?.mediaMeta?.imageLarge,
					response: '{"status": "success"}',
					linkProps: '{"download": "video"}'
				};
				setFileList((prevList) => {
					const newList = [...prevList];
					newList.pop(); // Remove the last item
					newList.push(newPhoto);
					return newList;
				});
			}
		} catch (error) {
			onError(error);
		}
	};
	const uploadButton = (
		<div style={{ backgroundColor: "black !important" }}>
			<PlusOutlined style={{ color: "white" }} />
			<div
				style={{
					marginTop: 8,
					color: "white"
				}}
			>
				Upload
			</div>
		</div>
	);
	const props = {
		customRequest: customRequest,
		listType: "picture-card",
		name: "fullMovie",
		multiple: true
	};

	const [currentTime, setCurrentTime] = useState(0);

	const handleTimeUpdate = (event) => {
		setCurrentTime(event?.target?.currentTime);
	};

	const videoPlayer = useMemo(() => {
		return (
			<ReactHlsPlayer
				id="VideoInput_video"
				src={previewImage && previewImage}
				controls={true}
				className={styled.VideoScreen}
				style={{
					maxWidth: "1350px",
					minWidth: "300px",
					width: "100%",
					height: "300px"
				}}
				muted={true}
				autoPlay
				onTimeUpdate={handleTimeUpdate}
			/>
		);
	}, [previewImage]);
	const captureFrame = (video, currentTime) => {
		return new Promise((resolve, reject) => {
			const canvas = document.createElement("canvas");
			const context = canvas.getContext("2d");
			video.currentTime = currentTime;

			video.onseeked = () => {
				canvas.width = video.videoWidth;
				canvas.height = video.videoHeight;
				context.drawImage(video, 0, 0, canvas.width, canvas.height);
				canvas.toBlob((blob) => {
					if (blob) {
						resolve(blob);
					} else {
						reject(new Error("Failed to create Blob"));
					}
				}, "image/jpeg");
			};

			video.onerror = (error) => {
				reject(error);
			};
		});
	};
	const handleChoose = async () => {
		const video = document.getElementById("VideoInput_video");
		console.log(video);
		try {
			const imageBlob = await captureFrame(video, currentTime);

			// Create a File from the Blob
			const file = new File([imageBlob], `frame_${currentTime}.jpeg`, {
				type: "image/jpeg",
				lastModified: Date.now()
			});
			setSelectedImage(file);
		} catch (error) {
			console.error("Error capturing frame: ", error);
		}
	};
	const formatTime = (time) => {
		const minutes = Math.floor(time / 60);
		const seconds = Math.floor(time % 60);
		const milliseconds = Math.floor((time % 1) * 1000);
		return `${String(minutes).padStart(2, "0")}:${String(seconds).padStart(2, "0")}:${String(milliseconds).padStart(3, "0")}`;
	};
	return (
		<>
			<Upload
				className={"custom-upload"}
				{...props}
				fileList={fileList}
				data={{ mediaType: "video" }}
				onPreview={handlePreview}
				onChange={handleChange}
				/*	onRemove={handleRemove}*/
				defaultFileList={setFileList}
				key={setFileList}
				style={{ backgroundColor: "black", color: "white" }}
			>
				{fileList.length >= 20 ? null : uploadButton}
			</Upload>
			<Modal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				closeAfterTransition
				open={previewVisible}
				onCancel={handleCancel}
				BackdropComponent={Backdrop}
				BackdropProps={{
					timeout: 500
				}}
				className="camera_details"
			>
				<Box
					sx={style}
					style={{
						marginTop: "50px"
					}}
				>
					{loading && loading.isLoading == true ? (
						<div className="loader-container">
							<div className="spinner"></div>
						</div>
					) : (
						""
					)}
					<IconButton className="BackButton" onClick={handleCancel}>
						<ClearIcon />
					</IconButton>
					<Container component="main" maxWidth="xs">
						<Box
							sx={{
								marginTop: 2,
								display: "flex",
								flexDirection: "row",
								alignItems: "center"
							}}
							md={3}
						>
							<Box
								sx={{
									bgcolor: "transparent",
									paddingRight: "10px",
									paddingBottom: "10px"
								}}
								md={3}
							>
								{videoPlayer}
							</Box>
						</Box>
						<Box
							style={{
								display: "flex",
								flexDirection: "column",
								alignItems: "center",
								width: "100%",
								marginTop: "10px"
							}}
						>
							<Typography style={{ color: "#78F73B", fontSize: "1rem" }}>
								{formatTime(currentTime)}
							</Typography>
							<Button
								variant="contained"
								style={{
									width: "100%",
									marginTop: "10px",
									color: "black",
									backgroundColor: "#78F73B"
								}}
								onClick={handleChoose}
							>
								Update poster image
							</Button>
						</Box>
						<Box
							sx={{
								marginTop: 2,
								display: "flex",
								flexDirection: "column",
								alignItems: "center"
							}}
						>
							<Box component="form" noValidate sx={{ mt: 3 }}>
								<Grid item xs={12}>
									<CheckboxUI
										style={{ color: "white" }}
										title={"Premium"}
										name="freeUnlocked"
										value="allowExtraEmails"
										color="primary"
										onChange={changeHandle}
										checked={premium == 0 ? false : true}
									/>
									{/*    <CheckboxUI
                                        style={{color: "white"}}
                                        title={"Hide"}
                                        disabled={true}
                                        name="freeUnlocked"
                                        value="allowExtraEmails"
                                        color="primary"
                                        onChange={changeHandleTube}
                                        checked={false}
                                    />*/}
									<CheckboxUI
										style={{ color: "white" }}
										title={"Tube"}
										name="freeUnlocked"
										value="allowExtraEmails"
										color="primary"
										onChange={changeHandleTube}
										checked={tube == 0 ? false : true}
									/>
								</Grid>
							</Box>
						</Box>
					</Container>
					<Autocomplete
						className="LazyInput"
						multiple
						name="participants"
						id="id"
						style={{ width: "100%", marginBottom: "15px" }}
						onChange={(event, value) => {
							handleAutocompleteChange({
								target: {
									value: value
								}
							});
						}}
						options={
							gallery && gallery.filters && gallery.filters.models
								? Object.keys(gallery.filters.models)
								: []
						}
						defaultValue={selectedOptions && selectedOptions}
						key={selectedOptions && selectedOptions}
						getOptionLabel={(option) => gallery.filters.models[option] || ""}
						renderInput={(params) => (
							<TextField
								{...params}
								variant="standard"
								name="participants"
								sx={{
									borderRadius: "4px",
									padding: "0px 10px 0px 5px",
									textAlign: "left",
									fontSize: "14px"
								}}
							/>
						)}
					/>
					<Stack
						direction="row"
						style={{ paddingBottom: "15px" }}
						className="Pools_row"
					>
						<Input
							value={inputList.metaDescription}
							name="metaDescription"
							multiline
							onChange={(e) => {
								handleInputChange(e);
							}}
							style={{ padding: "5px", height: "unset" }}
							placeholder="Meta Description"
							className="Pools_input_text"
							disableUnderline="true"
						></Input>
					</Stack>
					<Stack
						direction="row"
						style={{ paddingBottom: "15px" }}
						className="Pools_row"
					>
						<Input
							value={inputList.title}
							name="title"
							multiline
							onChange={(e) => {
								handleInputChange(e);
							}}
							style={{ padding: "5px", height: "unset" }}
							placeholder="Title"
							className="Pools_input_text"
							disableUnderline="true"
						></Input>
					</Stack>
					<Stack
						direction="row"
						style={{ paddingBottom: "15px" }}
						className="Pools_row"
					>
						<Input
							value={inputList.description}
							name="description"
							multiline
							onChange={(e) => {
								handleInputChange(e);
							}}
							style={{ padding: "5px", height: "unset" }}
							placeholder="Description"
							className="Pools_input_text"
							disableUnderline="true"
						></Input>
					</Stack>
					<Button
						onClick={handleSave}
						variant="contained"
						style={{
							width: "100%",
							marginTop: "10px",
							color: "black",
							backgroundColor: "#78F73B"
						}}
					>
						Save
					</Button>
					{fileId && (
						<Button
							type="error"
							onClick={() => {
								handleRemove(fileId);
							}}
							variant="contained"
							style={{
								width: "100%",
								marginTop: "10px",
								color: "black",
								backgroundColor: "red"
							}}
						>
							Delete
						</Button>
					)}
				</Box>
			</Modal>
		</>
	);
};

export default connect(mapStateToProps)(UploadGalleryVideos);
