import * as React from "react";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { Autocomplete, Box, Stack, TextField, Typography } from "@mui/material";
import GeoBlockTable from "../GeoBlockTable/GeoBlockTable";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
	GeoblockSettings,
	getGeoblockExceptions,
	getGeoblockRestrictions,
	getGeoblockSettings
} from "../../redux/actions/geoblockActions";
import { connect, useDispatch, useSelector } from "react-redux";

function FunctionClick() {
	function clickHandler() {
		console.log("camera clicked");
	}
}

const mapStateToProps = (state) => {
	return {
		geoblock_exceptions: state.geoblock_exceptions,
		geoblock_restrictions: state.geoblock_restrictions,
		geoblock_settings: state.geoblock_settings
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		getGeoblockExceptions: () => dispatch(getGeoblockExceptions()),
		getGeoblockRestrictions: () => dispatch(getGeoblockRestrictions()),
		getGeoblockSettings: () => dispatch(getGeoblockSettings())
	};
};

function Geoblock({
	geoblock_exceptions,
	getGeoblockExceptions,
	getGeoblockRestrictions,
	geoblock_restrictions,
	geoblock_settings,
	getGeoblockSettings
}) {
	React.useEffect(() => {
		getGeoblockExceptions();
		getGeoblockRestrictions();
		getGeoblockSettings();
	}, []);
	const dispatch = useDispatch();
	const Countries = [
		{
			id: 1,
			title: "Estonia"
		},
		{
			id: 2,
			title: "Russia"
		},
		{
			id: 3,
			title: "Austria"
		},
		{
			id: 4,
			title: "urugvaj"
		},
		{
			id: 5,
			title: "sweden"
		},
		{
			id: 6,
			title: "soome"
		}
	];

	const [clicked, setClicked] = React.useState(
		geoblock_settings && geoblock_settings.ipBlocking
	);

	React.useEffect(() => {
		setClicked(geoblock_settings.ipBlocking);
	}, [geoblock_settings.ipBlocking]);

	const changeHandle = React.useCallback(() => {
		if (clicked == 1) {
			setClicked(0);
			geoblock_settings.ipBlocking = 0;
			dispatch(GeoblockSettings({ ipBlocking: 0 }));
		} else {
			setClicked(1);
			geoblock_settings.ipBlocking = 1;
			dispatch(GeoblockSettings({ ipBlocking: 1 }));
		}
	}, [clicked]);
	const theme = createTheme({
		palette: {
			success: {
				// Purple and green play nicely together.
				main: "#78F73B"
			},
			error: {
				// This is green.A700 as hex.
				main: "#F73B3B"
			}
		}
	});
	const loading = useSelector((state) => state.loading);
	return (
		<>
			<Stack
				className="page-container"
				spacing={3}
				sx={{ width: 1300, marginLeft: "290px" }}
			>
				{loading && loading.isLoading == true ? (
					<div className="loader-container">
						<div className="spinner"></div>
					</div>
				) : (
					""
				)}
				<Box
					style={{ marginTop: loading && loading.isLoading == true && "0px" }}
				>
					<Grid
						container
						spacing={0}
						style={{ display: "flex", flexDirection: "column" }}
					>
						<Grid item xs={4} md={2} spacing={0} className="LiveShow_Geo">
							<Typography
								variant="h4"
								gutterBottom
								component="div"
								style={{ color: "white" }}
							>
								IP Blocking & Exceptions
							</Typography>
						</Grid>
						<ThemeProvider theme={theme}>
							<Button
								size="big"
								variant="contained"
								/* color="success" */
								style={{
									paddingLeft: "10px",
									maxWidth: "140px",
									minWidth: "140px"
								}}
								onClick={() => changeHandle()}
								color={!geoblock_settings.ipBlocking == 1 ? "success" : "error"}
							>
								{!geoblock_settings.ipBlocking == 1
									? "Blocking On"
									: "Blocking Off"}
							</Button>
						</ThemeProvider>
					</Grid>
				</Box>
				<Grid spacing={1} className="ModelProfilePage_wrap">
					<Grid item xs={6} md={4}>
						<Typography
							variant="h4"
							gutterBottom
							component="div"
							style={{ color: "#ED2779", minWidth: "150px" }}
						>
							Blocked IP's
						</Typography>
						<GeoBlockTable
							data={geoblock_restrictions.data && geoblock_restrictions.data}
						/>
					</Grid>
					<Grid item xs={6} md={8} style={{ margin: "0 auto" }}>
						<Typography
							variant="h4"
							gutterBottom
							component="div"
							style={{ color: "#77F73B", minWidth: "150px" }}
						>
							IP Exceptions
						</Typography>
						<GeoBlockTable
							data={geoblock_exceptions.data && geoblock_exceptions.data}
						/>
					</Grid>
				</Grid>
				<Typography
					variant="h4"
					gutterBottom
					component="div"
					style={{ color: "#ED2779", minWidth: "150px" }}
				>
					Blocked Countries
				</Typography>
				<Autocomplete
					className="LazyInput"
					multiple
					id="combo-box-demo"
					options={Countries}
					getOptionLabel={(option) => option.title}
					renderInput={(params) => (
						<TextField {...params} disabled variant="standard" />
					)}
				/>
			</Stack>
		</>
	);
}

export default connect(mapStateToProps, mapDispatchToProps)(Geoblock);
