import * as React from "react";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import {
	Box,
	Container,
	Input,
	Stack,
	Table,
	TableBody,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	Typography
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { createTheme, styled, ThemeProvider } from "@mui/material/styles";
import GirlIcon from "@material-ui/icons/Face";
import { connect, useDispatch, useSelector } from "react-redux";
import DateRangePickerComp from "../DateRangePickerComp/DateRangePickerComp";
import { format } from "date-fns";
import { CreatePromo, GetPromos } from "../../redux/actions/PromosActions";
import { setMessage } from "../../redux/reducers/rootReducer";
import { useNavigate } from "react-router-dom";

const mapStateToProps = (state) => {
	return {
		promos: state.promos,
		time_picker: state.time_picker
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		GetPromos: () => dispatch(GetPromos())
	};
};

function Promos({ GetPromos, time_picker, promos }) {
	React.useEffect(() => {
		GetPromos();
	}, []);
	const dispatch = useDispatch();
	const navigate = useNavigate();

	const theme = createTheme({
		palette: {
			success: {
				// Purple and green play nicely together.
				main: "#78F73B"
			},
			error: {
				// This is green.A700 as hex.
				main: "#F73B3B"
			}
		}
	});
	const [rows, setRows] = React.useState(promos);
	React.useEffect(() => {
		setRows(promos);
	}, [promos]);
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(10);
	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	};

	const [inputList, setInputList] = React.useState([
		{
			title: "",
			description: "",
			issued_count: "",
			credits: "",
			days_access: "",
			period: {}
		}
	]);

	const handleInputChange = (e, index) => {
		const { name, value } = e.target;
		const list = [...inputList];
		list[index][name] = value;
		setInputList(list);
	};

	const [characterLimit] = React.useState(70);

	const handleSubmit = (e, index) => {
		e.preventDefault();

		const list = [...inputList];
		const obj = {
			title: inputList[index].title,
			description: inputList[index].description,
			issued_count: inputList[index].issued_count,
			credits: inputList[index].credits,
			days_access: inputList[index].days_access,
			period: {
				startDate:
					time_picker[0].startDate == undefined
						? ""
						: format(time_picker[0].startDate, "dd.MM.yyy"),
				endDate:
					time_picker[0].endDate == undefined
						? ""
						: format(time_picker[0].endDate, "dd.MM.yyy")
			}
		};
		if (obj.title.length == 0) {
			dispatch(setMessage("The title is required.", "error"));
			return;
		}
		if (obj.issued_count.length == 0) {
			dispatch(setMessage("The issued count is required.", "error"));
			return;
		}
		if (obj.credits.length == 0) {
			dispatch(setMessage("The credits is required.", "error"));
			return;
		}
		if (obj.days_access.length == 0) {
			dispatch(setMessage("The days access is required.", "error"));
			return;
		}
		if (obj.period.endDate.length == 0 || obj.period.startDate.length == 0) {
			dispatch(setMessage("The date is required.", "error"));
			return;
		}
		dispatch(CreatePromo(obj));
		setInputList([
			{
				title: "",
				description: "",
				issued_count: "",
				credits: "",
				days_access: "",
				period: {}
			}
		]);
	};

	const style = {
		position: "absolute",
		top: "50%",
		left: "50%",
		transform: "translate(-50%, -50%)",
		maxWidth: 1200,
		minWidth: 850,
		minHeight: 350,
		maxHeight: 500,
		/* width: '100%', */
		bgcolor: "background.paper",
		textAlign: "center",
		margin: "0 auto",
		p: 4
	};

	const StyledTableRow = styled(TableRow)(({ theme }) => ({
		"&:nth-of-type(odd)": {
			backgroundColor: "rgb(0 0 0)"
		},
		"&:nth-of-type(even)": {
			backgroundColor: "#ffffff14"
		},
		// hide last border
		"&:last-child td, &:last-child th": {
			border: 0
		},
		"&.MuiTableRow-root:hover": {
			backgroundColor: "#555555"
		}
	}));

	const StyledTableCell = styled(TableCell)(({ theme }) => ({
		[`&.${tableCellClasses.head}`]: {
			backgroundColor: "#292929",
			color: theme.palette.common.white
		},
		[`&.${tableCellClasses.body}`]: {
			fontSize: 14
		}
	}));

	const loading = useSelector((state) => state.loading);
	return (
		<>
			<Stack
				spacing={3}
				sx={{ width: 1300, marginLeft: "290px", marginTop: "40px" }}
			>
				{loading && loading.isLoading == true ? (
					<div className="loader-container">
						<div className="spinner"></div>
					</div>
				) : (
					""
				)}
				<Box>
					<Grid
						container
						spacing={0}
						style={{ display: "flex", flexDirection: "column" }}
					>
						<Grid item xs={4} md={2} spacing={0} className="Pools">
							<Typography
								variant="h4"
								gutterBottom
								component="div"
								style={{ color: "white", marginBottom: "unset" }}
							>
								Ongoing Promos
							</Typography>
						</Grid>

						{inputList.map((x, i) => {
							return (
								<Box
									component="div"
									sx={{
										flexGrow: 1,
										maxWidth: "650px",
										margin: "0",
										height: "100%"
										/* minHeight: "500px", */
									}}
								>
									<Container
										maxWidth="xl"
										style={{
											color: "#ffffff",
											width: "100%",
											height: "100%",
											backgroundColor: "#000000",
											borderRadius: "5px"
										}}
										sx={{ my: 3 }}
										className="PoolContainer"
									>
										<Grid spacing={1} className="Pool_wrap">
											<Grid item xs={12}>
												<Box component="div" className="Pool_main">
													<Stack direction="row" className="Pools_row">
														<Input
															value={x.title}
															name="title"
															onChange={(e) => {
																handleInputChange(e, i);
															}}
															placeholder="Promo title"
															className="Pools_input_text"
															disableUnderline="true"
															inputProps={{ maxLength: 70 }}
														></Input>
														<Typography
															color={
																x.title.length >= characterLimit
																	? "red"
																	: "white"
															}
															style={{ position: "absolute", left: "890px" }}
														>
															{x.title.length}/{characterLimit}
														</Typography>
													</Stack>
													<Stack className="Pools_row">
														<Input
															value={x.description}
															name="description"
															onChange={(e) => {
																handleInputChange(e, i);
															}}
															placeholder="Promo Text"
															className="Promos_input_text"
															disableUnderline="true"
															multiline
															/*    inputProps={{ maxLength: 70 }} */
														></Input>
														{/*   <Typography color={x.text.length >= characterLimit ? 'red' : 'white'} style={{ position: 'absolute', left: '890px' }}>
                                                            {x.text.length}/{characterLimit}
                                                        </Typography> */}
													</Stack>
													<Stack direction="row" className="Pools_row">
														<Typography width={"100px"}>
															Issue Amount
														</Typography>
														<Input
															value={x.issued_count}
															name="issued_count"
															placeholder="Number"
															onChange={(e) => {
																handleInputChange(e, i);
															}}
															className="Pools_input_credits"
															disableUnderline="true"
															inputProps={{ maxLength: 70 }}
														></Input>

														<Typography width={"60px"} marginLeft={"10px"}>
															Credits*
														</Typography>
														<Input
															value={x.credits}
															name="credits"
															placeholder="Amount"
															onChange={(e) => {
																handleInputChange(e, i);
															}}
															className="Pools_input_credits"
															disableUnderline="true"
															inputProps={{ maxLength: 70 }}
														></Input>
														<Typography width={"120px"} marginLeft={"10px"}>
															Camera Access*
														</Typography>
														<Input
															value={x.days_access}
															name="days_access"
															placeholder="Days"
															onChange={(e) => {
																handleInputChange(e, i);
															}}
															className="Pools_input_credits"
															disableUnderline="true"
															inputProps={{ maxLength: 70 }}
														></Input>
													</Stack>

													<Stack
														direction="row"
														className="Pools_row"
														style={{ justifyContent: "space-between" }}
													>
														<DateRangePickerComp closeOnSelect={true} />
														<ThemeProvider theme={theme}>
															<Button
																size="medium"
																variant="outlined"
																/* color="success" */
																style={{
																	paddingLeft: "15px",
																	marginLeft: "10px",
																	maxWidth: "90px"
																}}
																color={"success"}
																onClick={(e) => {
																	handleSubmit(e, i);
																}}
															>
																{"Create"}
															</Button>
														</ThemeProvider>
													</Stack>
												</Box>
											</Grid>
										</Grid>
									</Container>
								</Box>
							);
						})}

						<TableContainer
							className="SchrollBar"
							component={Paper}
							style={{ overflow: "auto", marginRight: "40px", width: "800px" }}
							sx={{ maxHeight: "700px" }}
						>
							<Table aria-label="simple table" stickyHeader>
								<TableHead>
									<StyledTableRow>
										<StyledTableCell
											style={{ borderBottom: "none" }}
											align="left"
										>
											Title
										</StyledTableCell>
										<StyledTableCell
											style={{ borderBottom: "none" }}
											align="right"
										>
											Valid from
										</StyledTableCell>

										<StyledTableCell
											style={{ borderBottom: "none" }}
											align="right"
										>
											Valid to
										</StyledTableCell>
										<StyledTableCell
											style={{ borderBottom: "none" }}
											align="right"
										>
											Issued count
										</StyledTableCell>
										<StyledTableCell
											style={{ borderBottom: "none" /* display: "none" */ }}
											align="right"
										>
											Is active
										</StyledTableCell>
									</StyledTableRow>
								</TableHead>
								<TableBody>
									{rows
										.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
										.map((row) => (
											<StyledTableRow
												hover={true}
												onClick={() => {
													navigate("/promo/" + row.id);
												}}
												key={row.id}
												tabIndex={-1}
												sx={{
													"&:last-child td, &:last-child th": { border: 0 }
												}}
											>
												<TableCell
													align="left"
													style={{
														borderBottom: "none",
														color: "#78F73B",
														display: "flex",
														alignItems: "center"
													}}
												>
													<GirlIcon
														style={{ color: "white", marginRight: "10px" }}
													/>
													{row.title}
												</TableCell>
												<TableCell
													align="right"
													style={{ borderBottom: "none", color: "#FFD800" }}
												>
													{row.valid_from}
												</TableCell>
												<TableCell
													align="right"
													style={{ borderBottom: "none", color: "#FFD800" }}
												>
													{row.valid_to}
												</TableCell>
												<TableCell
													align="right"
													style={{ borderBottom: "none", color: "#FFD800" }}
												>
													{row.used_coupons}/{row.issued_count}
												</TableCell>
												<TableCell
													align="right"
													style={{
														borderBottom: "none",
														color: `${row.is_active == 1 ? "green" : "red"}`
														/* display: "none", */
													}}
												>
													{row.is_active == 1 ? "active" : "disabled"}
												</TableCell>
											</StyledTableRow>
										))}
								</TableBody>
							</Table>
						</TableContainer>
						<TablePagination
							style={{ background: "#292929", color: "white" }}
							rowsPerPageOptions={[10, 25, 50]}
							component="div"
							count={rows.length}
							rowsPerPage={rowsPerPage}
							page={page}
							onPageChange={handleChangePage}
							onRowsPerPageChange={handleChangeRowsPerPage}
						/>
					</Grid>
				</Box>
			</Stack>
		</>
	);
}

export default connect(mapStateToProps, mapDispatchToProps)(Promos);
