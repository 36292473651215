import {
	API_FETCH_GET_PROMOS_ROUTE,
	API_FETCH_POOLS_ROUTE,
	API_FETCH_PROMO_ROUTE
} from "../../utils/consts";
import { DELETE_PROMO, POOL_UPDATE, PROMO, PROMOS } from "../types";
import { setLoading, setMessage } from "../reducers/rootReducer";

const allPromos = (data) => {
	return {
		type: PROMOS,
		payload: { data }
	};
};
const Promo = (data) => {
	return {
		type: PROMO,
		payload: { data }
	};
};
export const delete_Promo = (data) => {
	return {
		type: DELETE_PROMO,
		payload: { data }
	};
};

const patchPool = (data) => {
	return {
		type: POOL_UPDATE,
		payload: { data }
	};
};

export const GetPromos = () => async (dispatch) => {
	dispatch(setLoading({ camera: "promos", type: true }));
	try {
		const options = {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: `Bearer ${sessionStorage.getItem("token")}`
			}
		};
		const response = await fetch(
			process.env.REACT_APP_BACKEND_URL + API_FETCH_GET_PROMOS_ROUTE,
			options
		);
		//credentials: 'include',

		const data = await response.json();
		if (response.status >= 200 && response.status <= 299) {
			dispatch(allPromos(data.data));
			dispatch(setLoading({ camera: "promos", type: false }));
		} else {
			dispatch(setLoading({ camera: "promos", type: false }));
			/*  dispatch(userLogout());
             sessionStorage.removeItem("token"); */
		}
	} catch (error) {
		console.log(error);
		dispatch(setLoading({ camera: "promos", type: false }));
	}
};
export const GetPromo = (id) => async (dispatch) => {
	dispatch(setLoading({ camera: "promos", type: true }));
	try {
		const options = {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: `Bearer ${sessionStorage.getItem("token")}`
			}
		};
		const response = await fetch(
			process.env.REACT_APP_BACKEND_URL + API_FETCH_PROMO_ROUTE + "/" + id,
			options
		);
		//credentials: 'include',

		const data = await response.json();
		if (response.status >= 200 && response.status <= 299) {
			dispatch(Promo(data.data));
			dispatch(setLoading({ camera: "promos", type: false }));
		} else {
			dispatch(setLoading({ camera: "promos", type: false }));
			/*  dispatch(userLogout());
             sessionStorage.removeItem("token"); */
		}
	} catch (error) {
		console.log(error);
		dispatch(setLoading({ camera: "promos", type: false }));
	}
};

export const CreatePromo = (item) => async (dispatch) => {
	dispatch(setLoading({ camera: "promos", type: true }));
	try {
		const options = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: `Bearer ${sessionStorage.getItem("token")}`
			},
			body: JSON.stringify(item)
			//credentials: 'include',
		};
		const response = await fetch(
			process.env.REACT_APP_BACKEND_URL + API_FETCH_PROMO_ROUTE,
			options
		);
		//response.data
		const data = await response.json();

		if (data.type == "success") {
			dispatch(GetPromos());
			dispatch(setMessage(data.message, data.type));
			dispatch(setLoading({ camera: "promos", type: false }));
		}
		if (data.type == "false") {
			dispatch(setLoading({ camera: "promos", type: false }));
		}
	} catch (error) {
		console.log(error);
		dispatch(setLoading({ camera: "promos", type: false }));
	}
};
export const ExtendPool = (item, id) => async (dispatch) => {
	try {
		const options = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: `Bearer ${sessionStorage.getItem("token")}`
			},
			body: JSON.stringify(item)
			//credentials: 'include',
		};
		const response = await fetch(
			process.env.REACT_APP_BACKEND_URL +
				API_FETCH_POOLS_ROUTE +
				"/" +
				id +
				"/edit",
			options
		);
		//response.data
		const data = await response.json();

		if (data.type == "success") {
			dispatch(GetPromos());
			dispatch(setMessage(data.message, data.type));
		}
	} catch (error) {
		console.log(error);
	}
};
/* export const AccomplishPool = (id) => async (dispatch) => {
    try {
        const options = {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                'Authorization': `Bearer ${sessionStorage.getItem("token")}`,
            },
            //credentials: 'include',
        }
        const response = await fetch(process.env.REACT_APP_BACKEND_URL + API_FETCH_POOLS_ROUTE+'/'+id+'/edit/accomplish', options)
        //response.data
        const data = await response.json()

        if (data.type == 'success') {
            dispatch(GetPools())
            dispatch(setMessage(data.message,data.type));
        }
    } catch (error) {
        console.log(error)
    }
} */
export const TerminatePromo = (id) => async (dispatch) => {
	dispatch(setLoading({ camera: "promos", type: false }));
	try {
		const options = {
			method: "PUT",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: `Bearer ${sessionStorage.getItem("token")}`
			}
			//credentials: 'include',
		};
		const response = await fetch(
			process.env.REACT_APP_BACKEND_URL +
				API_FETCH_PROMO_ROUTE +
				"/" +
				id +
				"/status",
			options
		);
		//response.data
		const data = await response.json();

		if (data.type == "success") {
			dispatch(GetPromos());
			dispatch(setMessage(data.message, data.type));
			dispatch(setLoading({ camera: "promos", type: false }));
		}
	} catch (error) {
		console.log(error);
		dispatch(setLoading({ camera: "promos", type: false }));
	}
};
