import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { Persistor, store } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import { SnackbarProvider } from "notistack";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<Provider store={store}>
		<PersistGate loading={null} persistor={Persistor}>
			<BrowserRouter>
				<SnackbarProvider
					maxSnack={7}
					anchorOrigin={{
						vertical: "top",
						horizontal: "right"
					}}
				>
					<App />
				</SnackbarProvider>
			</BrowserRouter>
		</PersistGate>
	</Provider>
);
