import { USERS_TRANSACTIONS } from "../types";

export const usersEarningsReducer = (state = {}, action) => {
	const { type, payload } = action;

	switch (type) {
		case USERS_TRANSACTIONS: {
			const { data } = payload;
			state = {};
			return data;
		}

		default: {
			return state;
		}
	}
};
