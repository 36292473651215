import { DELETE_PROMO, PROMO } from "../types";

export const PromoReducer = (state = {}, action) => {
	const { type, payload } = action;

	switch (type) {
		case PROMO: {
			const { data } = payload;
			return data;
		}
		case DELETE_PROMO: {
			return {};
		}

		default: {
			return state;
		}
	}
};
