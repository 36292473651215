import { useEffect, useRef, useState } from "react";
import { DateRangePicker } from "react-date-range";
import format from "date-fns/format";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { useDispatch } from "react-redux";
import { User_date } from "../../redux/actions/userAction";

const DateRangePickerComp = () => {
	// date state
	const dispatch = useDispatch();
	const [range, setRange] = useState([
		{
			startDate: undefined,
			endDate: undefined,
			key: "selection"
		}
	]);
	dispatch(User_date(range));
	// open close
	const [open, setOpen] = useState(false);

	// get the target element to toggle
	const refOne = useRef(null);

	useEffect(() => {
		// event listeners
		document.addEventListener("keydown", hideOnEscape, true);
		document.addEventListener("click", hideOnClickOutside, true);
	}, []);

	// hide dropdown on ESC press
	const hideOnEscape = (e) => {
		if (e.key === "Escape") {
			setOpen(false);
		}
	};

	// Hide dropdown on outside click
	const hideOnClickOutside = (e) => {
		if (refOne.current && !refOne.current.contains(e.target)) {
			setOpen(false);
		}
	};

	return (
		<div className="calendarWrap">
			<span
				style={{ color: "white", paddingRight: "10px", paddingLeft: "10px" }}
			>
				From
			</span>
			<input
				value={
					range[0].startDate && `${format(range[0].startDate, "dd.MM.yyy")}`
				}
				readOnly
				className="inputBox"
				onClick={() => setOpen((open) => !open)}
			/>
			<span
				style={{ color: "white", paddingRight: "10px", paddingLeft: "10px" }}
			>
				To
			</span>
			<input
				value={range[0].endDate && `${format(range[0].endDate, "dd.MM.yyy")}`}
				readOnly
				className="inputBox"
				onClick={() => setOpen((open) => !open)}
			/>

			<div style={{ backgroundColor: "black" }} ref={refOne}>
				{open && (
					<DateRangePicker
						onChange={(item) => setRange([item.selection])}
						editableDateInputs={true}
						moveRangeOnFirstSelection={false}
						closeOnSelect={true}
						ranges={range}
						months={1}
						direction="horizontal"
						className="calendarElement"
					/>
				)}
			</div>
		</div>
	);
};

export default DateRangePickerComp;
