import {
	WHX_EVENT,
	WHX_EVENT_PARTICIPANT_UPDATE,
	WHX_EVENT_TASK_CREATE,
	WHX_EVENT_TASK_REMOVE,
	WHX_EVENT_TASK_UPDATE
} from "../types";

export const whxEventReducer = (state = {}, action) => {
	const { type, payload } = action;

	switch (type) {
		case WHX_EVENT: {
			const { data } = payload;
			state = {};
			return data;
		}
		case WHX_EVENT_TASK_CREATE: {
			const { data } = payload;
			const updatedStage = {
				...state.stage,
				tasks: [data, ...state.stage.tasks]
			};
			const newState = {
				...state,
				stage: updatedStage
			};
			return newState;
		}
		case WHX_EVENT_TASK_UPDATE: {
			const { data } = payload;
			const updatedStage = {
				...state.stage,
				tasks: state.stage.tasks.map((task) => {
					if (task.id === data.id) {
						return {
							...task,
							...data
						};
					}
					return task;
				})
			};
			return {
				...state,
				stage: updatedStage
			};
		}
		case WHX_EVENT_TASK_REMOVE: {
			const { data } = payload;
			const updatedStage = {
				...state.stage,
				tasks: state.stage.tasks.filter((task) => task.id !== data)
			};
			return {
				...state,
				stage: updatedStage
			};
		}
		case WHX_EVENT_PARTICIPANT_UPDATE: {
			const { data } = payload;
			const updatedStage = {
				...state.stage,
				participants: state.stage.participants.map((participant) => {
					if (participant.id === data.id) {
						return {
							...participant,
							...data
						};
					}
					return participant;
				})
			};
			return {
				...state,
				stage: updatedStage
			};
		}
		/*		case FETCH_MAGICS: {
			const { data } = payload;
			const newState = Object.assign([], state);
			let obj = newState.stages[data.magicx_stage_id - 1].tasks.find(
				(item) => item.id == data.id
			);
			const idx = newState.stages[data.magicx_stage_id - 1].tasks.indexOf(obj);
			newState.stages[data.magicx_stage_id - 1].tasks[idx] = data;
			return newState;
		}

		case FETCH_MAGICS_STATUS: {
			const { data } = payload;
			const newState = Object.assign([], state);
			let obj = newState.stages.find((item) => item.id == data.id);
			const idx = newState.stages.indexOf(obj);
			newState.stages[idx] = data;
			return newState;
		}*/
		default: {
			return state;
		}
	}
};
