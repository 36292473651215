import * as React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { Box, Container, Typography } from "@mui/material";
import { connect, useDispatch, useSelector } from "react-redux";
import { Input } from "@material-ui/core";
import { setMessage } from "../../redux/reducers/rootReducer";
import { LoadingButton } from "@mui/lab";
import Stack from "@mui/material/Stack";
import { useEffect, useState } from "react";
import {
	getMicrophones,
	updateMicrophone
} from "../../redux/actions/microphoneAction";
import { getActiveModels } from "../../redux/actions/modelAction";

const mapStateToProps = (state) => {
	return {
		micro: state.microphones,
		models: state.models
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		setMessage: (data, type) => dispatch(setMessage(data, type))
	};
};

function Microphones({ micro, models }) {
	const dispatch = useDispatch();
	React.useEffect(() => {
		dispatch(getMicrophones());
		dispatch(getActiveModels());
	}, []);
	const [activeModels, setActiveModels] = useState(models);
	const [microphones, setMicrophones] = useState(micro);
	const [selectedOptions, setSelectedOptions] = useState({});
	const [changedMicrophones, setChangedMicrophones] = useState([]);

	useEffect(() => {
		if (micro?.length > 0) {
			setMicrophones(micro);
			const initialSelectedOptions = {};
			micro.forEach((microphone) => {
				if (microphone?.user && microphone?.user?.inHouseName) {
					initialSelectedOptions[microphone?.id] = microphone?.user;
				}
			});
			setSelectedOptions(initialSelectedOptions);
		}
	}, [micro]);
	console.log(micro);
	useEffect(() => {
		if (models?.length > 0) {
			setActiveModels(models);
		}
	}, [models]);
	console.log(activeModels);

	const handleAutocompleteChange = (microphoneId, value) => {
		setSelectedOptions((prevState) => ({
			...prevState,
			[microphoneId]: value
		}));
		if (!changedMicrophones.includes(microphoneId)) {
			setChangedMicrophones((prevState) => [...prevState, microphoneId]);
		}
	};

	const loading = useSelector((state) => state.loading);

	const handleSave = (microphoneId) => {
		// Perform save action for the changed microphone
		const selectedOption = selectedOptions[microphoneId];
		// You can dispatch an action or perform any necessary logic here
		console.log(selectedOption);
		dispatch(updateMicrophone(microphoneId, selectedOption?.id));
		// After saving, remove the microphone from changedMicrophones
		setChangedMicrophones((prevState) =>
			prevState.filter((id) => id !== microphoneId)
		);
	};
	return (
		<>
			{loading && loading.isLoading == true ? (
				<div className="loader-container">
					<div className="spinner"></div>
				</div>
			) : (
				""
			)}
			<Stack
				className="page-container"
				spacing={3}
				sx={{ width: 900, marginLeft: "290px" }}
			>
				<Box>
					<Typography
						variant="h4"
						gutterBottom
						component="div"
						style={{ color: "white" }}
					>
						Manage Microphones
					</Typography>
					{microphones &&
						microphones?.length > 0 &&
						activeModels?.length > 0 &&
						microphones?.map((microphone) => (
							<Container
								style={{
									margin: "0",
									padding: "0",
									minWidth: "900px",
									justifyContent: "flex-start"
								}}
								className="InputContainer"
							>
								<Box
									sx={{ bgcolor: "transparent", paddingRight: "10px" }}
									md={3}
								>
									<ul className="ChatListElem_wrap_live">
										<Input
											className="msg"
											name="name"
											disabled={true}
											defaultValue={microphone.name}
											key={microphone.name}
											disableUnderline="true"
										></Input>
									</ul>
								</Box>
								<Autocomplete
									className="LazyInput"
									name="participant" // Change name to singular
									id="id"
									style={{
										width: "50%",
										marginBottom: "15px",
										maxWidth: "50%"
									}}
									value={selectedOptions[microphone.id] || {}} // Use selectedOptions specific to each microphone
									/*	defaultValue={microphone?.user?.inHouseName || {}}
									key={microphone?.user?.inHouseName || {}}*/
									onChange={(event, value) => {
										handleAutocompleteChange(microphone.id, value); // Pass the microphone ID along with the value
									}}
									isOptionEqualToValue={(option, value) =>
										option.id === value.id
									}
									options={activeModels ? activeModels : []}
									getOptionLabel={(option) => option.inHouseName || ""} // Display inHouseName as label
									renderInput={(params) => (
										<TextField
											{...params}
											InputProps={{
												...params.InputProps,
												disableUnderline: true,
												style: { color: "white" }
											}}
											sx={{
												borderRadius: "4px",
												padding: "0px 10px 0px 5px",
												textAlign: "left",
												fontSize: "14px"
											}}
											variant="standard"
											name="participants"
										/>
									)}
								/>
								{changedMicrophones.includes(microphone.id) && (
									<Box
										sx={{
											bgcolor: "transparent",
											marginLeft: "15px",
											minWidth: "40px"
										}}
									>
										<LoadingButton
											type="submit"
											onClick={() => handleSave(microphone.id)} // Pass
											variant="contained"
											sx={{
												/*	py: 1.5,*/
												px: 2.5,
												borderRadius: "10px",
												textTransform: "unset",
												fontSize: "1.1rem",
												".MuiCircularProgress-root": {
													width: "23px !important",
													height: "23px !important"
												},
												".MuiLoadingButton-loadingIndicator": {
													color: "black"
												}
											}}
											style={{
												width: "100%",
												color: "black",
												backgroundColor: "#78F73B"
											}}
										>
											{"Update"}
										</LoadingButton>
									</Box>
								)}
							</Container>
						))}
				</Box>
			</Stack>
		</>
	);
}

export default connect(mapStateToProps, mapDispatchToProps)(Microphones);
