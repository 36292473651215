import {
	API_ADMIN_EVENTS_ROUTE,
	API_ADMIN_MAGICS_ROUTE,
	API_ADMIN_TRICK_CREATE_ROUTE,
	API_ADMIN_TRICK_ROUTE
} from "../../utils/consts";
import { EVENTS, FETCH_MAGICS, FETCH_MAGICS_STATUS, MAGICS } from "../types";
import { setLoading, setMessage } from "../reducers/rootReducer";

const Events = (data) => {
	return {
		type: EVENTS,
		payload: { data }
	};
};
const Magics = (data) => {
	return {
		type: MAGICS,
		payload: { data }
	};
};
const Fetch_Magics = (data) => {
	return {
		type: FETCH_MAGICS,
		payload: { data }
	};
};
const Fetch_Magics_Status = (data) => {
	return {
		type: FETCH_MAGICS_STATUS,
		payload: { data }
	};
};

export const getEvents = (month, year) => async (dispatch) => {
	dispatch(setLoading({ camera: "events", type: true }));
	let response = "";
	try {
		if ((month || year) == 0 || (month || year) == undefined) {
			response = await fetch(
				process.env.REACT_APP_BACKEND_URL + API_ADMIN_EVENTS_ROUTE,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Accept: "application/json",
						Authorization: `Bearer ${sessionStorage.getItem("token")}`
					}
					//credentials: 'include',
				}
			);
		} else {
			response = await fetch(
				process.env.REACT_APP_BACKEND_URL +
					API_ADMIN_EVENTS_ROUTE +
					`?month=${month}&year=${year}`,
				{
					method: "GET",
					headers: {
						"Content-Type": "application/json",
						Accept: "application/json",
						Authorization: `Bearer ${sessionStorage.getItem("token")}`
					}
					//credentials: 'include',
				}
			);
		}
		const data = await response.json();
		if (response.status >= 200 && response.status <= 299) {
			dispatch(Events(data.data));
			dispatch(setLoading({ camera: "gallery", type: false }));
		} else {
			dispatch(userLogout());
			sessionStorage.removeItem("token");
		}
	} catch (error) {
		console.log(error);
		dispatch(setLoading({ camera: "gallery", type: false }));
	}
};

export const CreateEvent = (item) => async (dispatch) => {
	dispatch(setLoading({ camera: "gallery", type: true }));
	try {
		const options = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: `Bearer ${sessionStorage.getItem("token")}`
			},
			body: JSON.stringify(item)
			//credentials: 'include',
		};
		const response = await fetch(
			process.env.REACT_APP_BACKEND_URL + API_ADMIN_EVENTS_ROUTE,
			options
		);
		//response.data
		const data = await response.json();

		if (data.type == "success") {
			dispatch(getEvents());
			dispatch(setMessage(data.message, data.type));
		}
		if (data.type == "false") {
			dispatch(setLoading({ camera: "gallery", type: false }));
		}
	} catch (error) {
		console.log(error);
		dispatch(setLoading({ camera: "gallery", type: false }));
	}
};
export const UpdateEvent = (item, id) => async (dispatch) => {
	dispatch(setLoading({ camera: "gallery", type: true }));
	try {
		const options = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: `Bearer ${sessionStorage.getItem("token")}`
			},
			body: JSON.stringify(item)
			//credentials: 'include',
		};
		const response = await fetch(
			process.env.REACT_APP_BACKEND_URL + API_ADMIN_EVENTS_ROUTE + "/" + id,
			options
		);
		//response.data
		const data = await response.json();

		if (data.type == "success") {
			dispatch(getEvents());
			dispatch(setMessage(data.message, data.type));
		}
		if (data.type == "false") {
			dispatch(setLoading({ camera: "gallery", type: false }));
		}
	} catch (error) {
		console.log(error);
		dispatch(setLoading({ camera: "gallery", type: false }));
	}
};
export const deleteEvent = (id) => async (dispatch) => {
	try {
		const response = await fetch(
			process.env.REACT_APP_BACKEND_URL + API_ADMIN_EVENTS_ROUTE + "/" + id,
			{
				method: "DELETE",
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
					Authorization: `Bearer ${sessionStorage.getItem("token")}`
				}
				//credentials: 'include',
			}
		);
		//response.data
		const data = await response.json();
		if (response.status >= 200 && response.status <= 299) {
			dispatch(getEvents());
			dispatch(setMessage(data.message, data.type));
		} else {
			dispatch(setError(data));
			/*  dispatch(userLogout());
                   sessionStorage.removeItem("token"); */
		}
	} catch (error) {
		setMessage(error, "error");
	}
};

export const getMagics = () => async (dispatch) => {
	let response = "";
	try {
		response = await fetch(
			process.env.REACT_APP_BACKEND_URL + API_ADMIN_MAGICS_ROUTE,
			{
				method: "GET",
				headers: {
					"Content-Type": "application/json",
					Accept: "application/json",
					Authorization: `Bearer ${sessionStorage.getItem("token")}`
				}
				//credentials: 'include',
			}
		);
		const data = await response.json();
		if (response.status >= 200 && response.status <= 299) {
			dispatch(Magics(data.data));
		}
	} catch (error) {
		console.log(error);
	}
};

export const UpdateTrick = (item, replay, id) => async (dispatch) => {
	try {
		const options = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: `Bearer ${sessionStorage.getItem("token")}`
			},
			body: JSON.stringify({ name: item, video_url: replay })
			//credentials: 'include',
		};
		const response = await fetch(
			process.env.REACT_APP_BACKEND_URL + API_ADMIN_TRICK_ROUTE + id,
			options
		);
		//response.data
		const data = await response.json();

		if (data.type == "success") {
			dispatch(Fetch_Magics(data.data.trick));
			dispatch(setMessage(data.message, data.type));
		}
		if (data.type == "false") {
		}
	} catch (error) {
		console.log(error);
	}
};
export const UpdateTrickStatus = (item, id) => async (dispatch) => {
	try {
		const options = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: `Bearer ${sessionStorage.getItem("token")}`
			},
			body: JSON.stringify({ is_live: item })
			//credentials: 'include',
		};
		const response = await fetch(
			process.env.REACT_APP_BACKEND_URL +
				API_ADMIN_TRICK_ROUTE +
				id +
				"/status",
			options
		);
		//response.data
		const data = await response.json();

		if (data.type == "success") {
			dispatch(Fetch_Magics_Status(data.data.stage));
			dispatch(setMessage(data.message, data.type));
		}
		if (data.type == "false") {
		}
	} catch (error) {
		console.log(error);
	}
};
export const CreateTrick = (item, id, stage_id) => async (dispatch) => {
	try {
		const options = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: `Bearer ${sessionStorage.getItem("token")}`
			},
			body: JSON.stringify({
				name: item,
				participant_id: id,
				stage_id: stage_id
			})
			//credentials: 'include',
		};
		const response = await fetch(
			process.env.REACT_APP_BACKEND_URL + API_ADMIN_TRICK_CREATE_ROUTE,
			options
		);
		//response.data
		const data = await response.json();

		if (data.type == "success") {
			dispatch(Fetch_Magics_Status(data.data.stage));
			dispatch(setMessage(data.message, data.type));
		}
		if (data.type == "false") {
		}
	} catch (error) {
		console.log(error);
	}
};

export const TrickProceed = (id) => async (dispatch) => {
	try {
		const options = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: `Bearer ${sessionStorage.getItem("token")}`
			}
			//credentials: 'include',
		};
		const response = await fetch(
			process.env.REACT_APP_BACKEND_URL +
				API_ADMIN_TRICK_ROUTE +
				id +
				"/proceed",
			options
		);
		//response.data
		const data = await response.json();

		if (data.type == "success") {
			dispatch(Fetch_Magics_Status(data.data.stage));
			dispatch(setMessage(data.message, data.type));
		}
		if (data.type == "false") {
		}
	} catch (error) {
		console.log(error);
	}
};

export const StageSettingsUpdate = (item, id) => async (dispatch) => {
	try {
		const options = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: `Bearer ${sessionStorage.getItem("token")}`
			},
			body: JSON.stringify(item)
			//credentials: 'include',
		};
		const response = await fetch(
			process.env.REACT_APP_BACKEND_URL + API_ADMIN_MAGICS_ROUTE + "/" + id,
			options
		);
		//response.data
		const data = await response.json();

		if (data.type == "success") {
			dispatch(Fetch_Magics_Status(data.data.stage));
			dispatch(setMessage(data.message, data.type));
		}
		if (data.type == "false") {
		}
	} catch (error) {
		console.log(error);
	}
};

export const TrickDone = (item, id) => async (dispatch) => {
	try {
		const options = {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
				Accept: "application/json",
				Authorization: `Bearer ${sessionStorage.getItem("token")}`
			},
			body: JSON.stringify({ is_done: item })
			//credentials: 'include',
		};
		const response = await fetch(
			process.env.REACT_APP_BACKEND_URL +
				API_ADMIN_TRICK_ROUTE +
				id +
				"/status",
			options
		);
		//response.data
		const data = await response.json();

		if (data.type == "success") {
			dispatch(Fetch_Magics_Status(data.data.stage));
			dispatch(setMessage(data.message, data.type));
		}
		if (data.type == "false") {
		}
	} catch (error) {
		console.log(error);
	}
};
