import { MESSAGE, MESSAGE_REMOVE } from "../types";

export const messageReducer = (state = {}, action) => {
	const { type, payload } = action;

	switch (type) {
		case MESSAGE: {
			let { message, type } = payload;
			return {
				text: message,
				type: type
			};
		}
		case MESSAGE_REMOVE: {
			return {};
		}
		default: {
			return state;
		}
	}
};
